var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"match-result-form",attrs:{"data-app":""}},[_c('div',{staticClass:"p-1"},[_c('div',{staticClass:"master-data-title"},[_vm._v("รายชื่อนักกีฬา, บุคลากร")]),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headersAdmin,"items":this.teamData.sub_team_information,"hide-default-footer":""},on:{"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.count_players",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.count_players)+" คน ")]}},{key:"item.count_staffs",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.count_staffs)+" คน ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("fa-ellipsis-v")])]}}],null,true)},[_c('v-list',_vm._l((_vm.actions),function(action,index){return _c('v-list-item',{key:index},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onClick(action.key, item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(action.icon))]),_vm._v(_vm._s(action.text)+" ")],1)],1)}),1)],1)]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }