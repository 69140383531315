import Vue from 'vue'
import Vuex from 'vuex'
//import createPersistedState from "vuex-persistedstate";
import players from './players'
import user from './user'
import relation from './relation'
import education from './education'
import playerPosition from './player_position'
import staffPosition from './staff_position'
import globalConfigEducation from './global_config_education'
import globalConfigRelation from './global_config_relation'
import globalConfigCountry from './global_config_country'
import globalConfigProvince from './global_config_province'
import globalConfigDistrict from './global_config_district';
import globalConfigSubdistrict from './global_config_subdistrict';
import award from './award'
import register from './register'
import sportCategory from './sport_category'
import department from './department'
import injuryType from './injury_type'
import skill from './skill'
import physicalFitness from './physical_fitness'
import stadiums from './stadiums'
import teamPlayersHistories from './team_players_histories'
import { competition } from './competition'
import { sub_competition } from './sub_competition'
import { competition_section } from './competition_section'
import { versus_match } from './versus_match'
import { gallery } from './gallery'
import { match } from './match'
import { data_model } from './data_model'
import permission from './permission';
import global from './global';
import dashboard from './dashboard'

// import curriculum from './curriculum'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  },
  //plugins: [createPersistedState()],
  mutations: {
  },
  actions: {
  },
  modules: {
    player: players,
    user,
    relation,
    education,
    playerPosition,
    staffPosition,
    globalConfigEducation,
    globalConfigRelation,
    globalConfigCountry,
    globalConfigProvince,
    globalConfigDistrict,
    globalConfigSubdistrict,
    global,
    award,
    sportCategory,
    department,
    injuryType,
    skill,
    physicalFitness,
    stadiums,
    teamPlayersHistories,
		competition,
		sub_competition,
		competition_section,
		versus_match,
		gallery,
		match,
		data_model,
    permission,
    dashboard,
    register
    // curriculum
  }
})
