var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 px-6"},[_vm._l((_vm.round),function(group){return [_c('v-card',{key:group.id,staticClass:"my-2"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":group.competition_group_stage_team_join,"headers":_vm.groupHeaders(group),"item-key":"id","page":_vm.page,"items-per-page":_vm.pageSize,"hide-default-footer":true,"hide-default-header":true},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return _vm._l((props.headers),function(head,index){return _c('th',{key:index,staticClass:"text-center py-2",class:head.class},[(head.value == 'result-againt')?_c('v-img',{staticClass:"mx-auto",attrs:{"src":head.image,"max-height":"30","max-width":"30","contain":""}}):_c('span',[_vm._v(" "+_vm._s(head.text.toUpperCase())+" ")])],1)})}},{key:"item.team",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('v-img',{staticClass:"ma-3",attrs:{"src":item.team_image,"max-height":"30","max-width":"30","contain":""}}),_c('span',[_vm._v(_vm._s(_vm.language === "th" ? item.team_name : item.team_name_en))])],1)]}},{key:"item.result-againt",fn:function(ref){
var item = ref.item;
var header = ref.header;
return [_c('div',{staticClass:"d-flex justify-center"},[(item.team_id == header.team)?_c('span',[_vm._v("-")]):(!_vm.hasMatchResult(item, header.team))?_c('v-sheet',{staticClass:"\n                ma-2\n                d-flex\n                flex-column\n                justify-content-center\n                align-items-center\n                clickable\n              ",attrs:{"outlined":"","rounded":"","width":"50","height":"75"},on:{"click":function($event){return _vm.$emit('addResult', {
                  csid: group.competition_section_id,
                  groupId: group.id,
                  left_id: item.team_id,
                  right_id: header.team,
                })}}},[_c('div',{staticClass:"\n                  d-flex\n                  flex-column\n                  justify-content-center\n                  align-items-center\n                "},[_c('div',{staticClass:"text-decoration-underline text-center"},[_vm._v(" "+_vm._s(_vm.$t("sub_competition.add_score"))+" ")]),_c('v-icon',{staticClass:"mt-1",attrs:{"x-small":""}},[_vm._v("fa-plus")])],1)]):_c('v-sheet',{staticClass:"\n                ma-2\n                d-flex\n                flex-column\n                justify-content-center\n                align-items-center\n                clickable\n              ",attrs:{"rounded":"","width":"75","height":"50","color":_vm.resultClass(item, header.team, '--bg')},on:{"click":function($event){return _vm.onUpdateResult(item, header.team, group)}}},[_c('div',{staticClass:"\n                  d-flex\n                  flex-column\n                  justify-content-center\n                  align-items-center\n                "},[_c('div',{staticClass:"score--text text-decoration-underline"},[_vm._v(" "+_vm._s(_vm.matchResult(item, header.team))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.matchResultText(item, header.team))+" ")])])])],1)]}},{key:"item.points",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"\n              d-flex\n              flex-column\n              justify-content-center\n              align-items-center\n            "},[_c('v-text-field',{staticClass:"centered-input",staticStyle:{"width":"50px"},attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(item.points),callback:function ($$v) {_vm.$set(item, "points", $$v)},expression:"item.points"}})],1)]}},{key:"top",fn:function(ref){return [_c('div',{staticClass:"d-flex justify-space-between px-6 pt-2 align-items-center"},[_c('span',{staticClass:"text-primary",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.groupName(group)))]),_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"ma-2 pa-2",attrs:{"x-small":""},on:{"click":function($event){return _vm.onDeleteGroup(group)}}},[_vm._v("fa-trash")]),_c('v-btn',{staticClass:"text-decoration-underline",staticStyle:{"font-size":"16px"},attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.onEditGroup(group)}}},[_vm._v(_vm._s(_vm.$t("sub_competition.manage_sub_competition")))])],1)])]}},{key:"footer",fn:function(ref){return [_c('div',{staticClass:"col-12 d-flex justify-content-end"},[_c('v-btn',{staticClass:"ma-2",on:{"click":function($event){return _vm.saveGroupPoints(group)}}},[_vm._v(_vm._s(_vm.$t("sub_competition.save_score")))])],1)]}}],null,true)})],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }