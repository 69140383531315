<template>
  <div class="mb-5" id="honorClub1">
    <h4 class="text-primary mb-5">
      {{ $t("player.personal_info.honor_club") }}
    </h4>
    <div
      class="row col-md-12 m-0 p-0"
      v-for="(detail, i) in dataHonorClub.honor"
      :key="i"
    >
      <div class="col-md-2 mx-0 px-0 bold text-primary py-1">
        {{ detail.year[language] }}
      </div>
      <div class="col-md-6 px-0 py-1">{{ detail.name[language] }}</div>
      <div class="col-md-4 py-1">{{ detail.details[language] }}</div>
    </div>
    <div
      class="row col-md-12 m-0 p-0 d-flex justify-content-center"
      v-if="!dataHonorClub.honor"
    >
      <label>{{ $t("normal.no_data") }}</label>
    </div>
  </div>
</template>

<script>
import { languageService } from "../../helpers/language";

export default {
  name: "honorClub",
  props: ["honorClub"],
  data() {
    return {
      language: "th",
      dataHonorClub: {},
    };
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  mounted() {
    this.$emit(
      "heightSize",
      document.querySelector("#honorClub1").offsetHeight
    );
  },
  watch: {
    honorClub() {
      this.dataHonorClub = {
        honor: this.honorClub?.map((honor) => {
          return {
            year: {
              th: +honor?.honor_year + 543,
              en: honor?.honor_year,
            },
            name: {
              th: honor?.setting_sub_competition_name_th,
              en: honor?.setting_sub_competition_name_en,
            },
            details: {
              th: honor?.honor_name,
              en: honor?.honor_name_en,
            },
          };
        }),
      };
    },
  },
};
</script>