<template>
  <v-dialog
    :value="visible"
    @input="closeModal"
    width="800px"
    v-bind:hide-overlay="false"
    style="margin-top: auto"
  >
    <v-card>
      <div class="dialog-wrapper">
        <div class="dialog-header">
          <div class="dialog-header-title">{{ header }}</div>
          <div class="dialog-actions">
            <v-btn color="primary" plain class="btn-close" @click="closeModal">
              {{ $t("normal.cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!isEditable"
              class="m-l-5px"
              @click="submit"
            >
              {{ $t("normal.save") }}
            </v-btn>
          </div>
        </div>
        <div class="dialog-content">
          <div v-if="!this.historyId">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name">
                    {{
                      accountType === "player"
                        ? $t("team.athlete_real_name_th")
                        : $t("team.personnel_real_name_th")
                    }}
                  </label>
                  <v-combobox
                    v-model="form.accounts"
                    :readonly="!isEditable"
                    :placeholder="$t('normal.please_select')"
                    :items="accountList"
                    :item-text="'label_' + language"
                    item-value="value"
                    dense
                    outlined
                    hide-details
                  ></v-combobox>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name">
                    {{
                      accountType === "player"
                        ? $t("team.athlete_real_name_en")
                        : $t("team.personnel_real_name_en")
                    }}
                  </label>
                  <v-text-field
                    :value="firstnameEn"
                    readonly
                    dense
                    outlined
                    hide-details
                    :placeholder="
                      accountType === 'player'
                        ? $t('team.athlete_real_name_en')
                        : $t('team.personnel_real_name_en')
                    "
                  ></v-text-field>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name">
                    {{
                      accountType === "player"
                        ? $t("team.athlete_last_name_en")
                        : $t("team.personnel_last_name_en")
                    }}
                  </label>
                  <v-text-field
                    :value="lastnameEn"
                    readonly
                    dense
                    outlined
                    hide-details
                    :placeholder="
                      accountType === 'player'
                        ? $t('team.athlete_last_name_en')
                        : $t('team.personnel_last_name_en')
                    "
                  ></v-text-field>
                </div>
              </div>
            </div>
          </div>
          <template v-else>
            <div class="row">
              <div class="col-12">
                <label class="form-label" for="basic-addon-name">
                  {{
                    accountType === "player"
                      ? $t("menu.player")
                      : $t("menu.staff")
                  }}
                </label>
                <div
                  class="form-group"
                  style="
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                  "
                >
                  <v-avatar color="grey">
                    <v-icon dark> $user </v-icon>
                  </v-avatar>
                  <label class="form-label" for="basic-addon-name">
                    {{ name }}
                  </label>
                  <label class="form-label" for="basic-addon-name">
                    {{ nationality }}
                  </label>
                  <label class="form-label" for="basic-addon-name">
                    {{ age }} {{ $t("normal.year") }}
                  </label>
                  <label class="form-label" for="basic-addon-name">
                    {{ position }}
                  </label>
                  <!-- <v-avatar color="grey">
                    <v-icon dark>
                      $user
                    </v-icon>
                  </v-avatar> -->
                  <label class="form-label" for="basic-addon-name">
                    {{ teamName }}
                  </label>
                </div>
                <hr class="" style="width: auto" />
              </div>
            </div>
          </template>

          <div v-if="accountType === 'player'" class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name">{{
                  $t("player.athlete_info.position")
                }}</label>
                <v-select
                  item-value="id"
                  :item-text="'global_config_position_name_' + language"
                  v-model="form.global_config_positions"
                  :readonly="!isEditable"
                  :items="positionList"
                  :placeholder="$t('normal.please_select')"
                  dense
                  outlined
                >
                </v-select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name">{{
                  $t("player.athlete_info.shirt_number")
                }}</label>
                <v-text-field
                  type="number"
                  v-model="form.team_player_history_shirt_number"
                  :readonly="!isEditable"
                  dense
                  outlined
                  hide-details
                  :placeholder="$t('player.athlete_info.shirt_number')"
                ></v-text-field>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name">{{
                  $t("team.number_of_matches")
                }}</label>
                <v-text-field
                  type="number"
                  v-model="form.team_player_history_match_count"
                  :readonly="!isEditable"
                  dense
                  outlined
                  hide-details
                  :placeholder="$t('team.number_of_matches')"
                ></v-text-field>
              </div>
            </div>
          </div>

          <template v-else>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name">{{
                    $t("team.position")
                  }}</label>
                  <v-select
                    :item-text="'global_config_position_name_' + language"
                    item-value="id"
                    :items="positionList"
                    v-model="form.global_config_positions"
                    :readonly="!isEditable"
                    dense
                    outlined
                  >
                  </v-select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label class="form-label" for="basic-addon-name">{{
                    $t("staff.department")
                  }}</label>
                  <v-select
                    item-value="id"
                    :item-text="'global_config_department_name_' + language"
                    v-model="form.global_config_department"
                    :readonly="!isEditable"
                    :items="departmentList"
                    dense
                    outlined
                  >
                  </v-select>
                </div>
              </div>
            </div>
          </template>

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name">{{
                  $t("player.athlete_info.start_date")
                }}</label>
                <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      :value="showDate(form.team_player_history_begin_at)"
                      :placeholder="$t('normal.date_format')"
                      append-icon="fa-calendar"
                      hide-details
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.team_player_history_begin_at"
                    :readonly="!isEditable"
                    locale="us-us"
                    ref="picker"
                    min="1950-01-01"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name">{{
                  $t("player.athlete_info.end_date")
                }}</label>
                <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      hide-details
                      :value="showDate(form.team_player_history_end_at)"
                      :placeholder="$t('normal.date_format')"
                      append-icon="fa-calendar"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.team_player_history_end_at"
                    :readonly="!isEditable"
                    locale="us-us"
                    ref="picker"
                    min="1950-01-01"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>

          <div class="row">
            <div v-if="accountType === 'player'" class="col-6">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name"
                  >{{ $t("player.athlete_info.contract_type") }}
                </label>
                <v-select
                  v-model="form.team_player_history_loan"
                  :readonly="!isEditable"
                  :items="historystatus"
                  :placeholder="$t('normal.please_select')"
                  dense
                  outlined
                ></v-select>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <v-checkbox
                  v-model="form.team_player_history_status"
                  :readonly="!isEditable"
                  :label="$t('team.set_to_current_affiliation')"
                  color="success"
                  class="mt-5 pt-3"
                  hide-details
                >
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { appAxios } from "../../helpers/app-axios";
import format from "date-fns/format";
import differenceInYears from "date-fns/differenceInYears";
import { playerPositionState } from "../../store/team_players_histories";
import { languageService } from "../../helpers/language";

export default {
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    teamplayershistories: null,
    teamName: null,
    historyId: null,
    accountType: null,
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  beforeDestroy() {
    console.log("destroy team");
    this.subscription.unsubscribe();
  },
  methods: {
    async getChooseableAccount() {
      return appAxios
        .get(`/team/${this.teamId}/${this.accountType}s/choose`)
        .then((res) => {
          const data = res.data.data[`${this.accountType}s`];
          if (!data) return;
          this.accountList = data.map((ele) => ({
            label_th: `${
              ele.account_informations?.account_information_firstname_th ||
              this.$t("team.anonymous")
            } ${
              ele.account_informations?.account_information_lastname_th ||
              this.$t("team.no_last_name")
            }`,
            label_en: `${
              ele.account_informations?.account_information_firstname_en ||
              this.$t("team.anonymous")
            } ${
              ele.account_informations?.account_information_lastname_en ||
              this.$t("team.no_last_name")
            }`,
            ...ele,
          }));
        });
    },
    async getAllPosition() {
      return appAxios
        .get(`/setting/global/positions/${this.accountType}/all`, {
          params: {
            active: true,
          },
        })
        .then((res) => {
          const data = res.data?.data;
          this.$data.positionList = data;
        });
    },
    async getAllDepartment() {
      return appAxios
        .get(`/setting/global/departments/all`, {
          params: {
            active: true,
          },
        })
        .then((res) => {
          const data = res.data?.data;
          this.departmentList = data;
        });
    },
    getPlayerHistory() {
      appAxios
        .get(`team/${this.teamId}/${this.accountType}s/${this.historyId}`)
        .then((res) => {
          if (this.accountType === "player") {
            this.parsePlayer(res);
          } else {
            this.parseStaff(res);
          }
        });
    },
    parsePlayer(res) {
      const data = res.data.data?.team_player_history;
      if (!data) return;
      this.form = {
        accounts: data.accounts,
        global_config_positions: data.global_config_positions,
        team_player_history_match_count: data.team_player_history_match_count,
        team_player_history_shirt_number: data.team_player_history_shirt_number,
        team_player_history_begin_at: data.team_player_history_begin_at,
        team_player_history_end_at: data.team_player_history_end_at,
        team_player_history_status: data.team_player_history_status === 1,
        team_player_history_loan: data.team_player_history_loan,
      };
    },
    parseStaff(res) {
      const data = res.data.data?.team_staff_history;
      if (!data) return;
      this.form = {
        accounts: data.accounts,
        global_config_positions: data.global_config_positions,
        global_config_department: data.global_config_department,
        team_player_history_begin_at: data.team_staff_history_begin_at,
        team_player_history_end_at: data.team_staff_history_end_at,
        team_player_history_status: data.team_staff_history_status === 1,
      };
    },
    //
    closeModal() {
      this.$emit("update:historyId", null);
      this.$emit("update:visible", false);
    },
    submit() {
      const body = this.getSaveBody();
      this.saveHistory(body);
    },
    saveHistory(body) {
      if (this.historyId) {
        delete body.accounts;
        if (this.accountType === "player") {
          appAxios
            .post(
              `team/${this.teamId}/${this.accountType}s/${this.historyId}`,
              body
            )
            .then(() => {
              this.$emit("save");
              this.closeModal();
            });
        } else {
          appAxios
            .post(
              `team/${this.teamId}/${this.accountType}s/${this.historyId}/update`,
              body
            )
            .then(() => {
              this.$emit("save");
              this.closeModal();
            });
        }
      } else {
        appAxios
          .post(`team/${this.teamId}/${this.accountType}s/`, body)
          .then(() => {
            this.$emit("save");
            this.closeModal();
          });
      }
    },
    getSaveBody() {
      return this.accountType === "player"
        ? {
            ...this.form,
            accounts: Number(this.form.accounts.account_id),
            team_player_history_begin_at:
              this.form.team_player_history_begin_at === null
                ? null
                : new Date(this.form.team_player_history_begin_at),
            team_player_history_end_at:
              this.form.team_player_history_end_at === null
                ? null
                : new Date(this.form.team_player_history_end_at),
            team_player_history_status: this.form.team_player_history_status
              ? 1
              : 2,
          }
        : {
            ...this.form,
            accounts: Number(this.form.accounts.account_id),
            team_player_history_begin_at: undefined,
            team_player_history_end_at: undefined,
            team_player_history_status: undefined,
            team_staff_history_begin_at:
              this.form.team_player_history_begin_at === null
                ? null
                : new Date(this.form.team_player_history_begin_at),
            team_staff_history_end_at:
              this.form.team_player_history_end_at === null
                ? null
                : new Date(this.form.team_player_history_end_at),
            team_staff_history_status: this.form.team_player_history_status
              ? 1
              : 2,
          };
    },
    showDate(date) {
      if (!date) return;
      return format(new Date(date), "dd/MM/yyyy");
    },
    getAge(birthDate) {
      if (!birthDate) return "-";
      return differenceInYears(new Date(), new Date(birthDate));
    },
    getPosition(e) {
      const dataplayerPosition = this.playerPosition.find(
        (data) => data.id == e
      );
      if (this.language === "en") {
        return dataplayerPosition?.global_config_position_name_en;
      } else {
        return dataplayerPosition?.global_config_position_name_th;
      }
    },
  },
  mounted() {
    this.getChooseableAccount();
    this.getAllPosition();
    if (this.accountType === "staff") {
      this.getAllDepartment();
    }
    if (this.historyId) {
      this.getPlayerHistory();
    }
  },
  computed: {
    playerPosition() {
      return this.$store.state.teamPlayersHistories[playerPositionState];
    },
    maxDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
    isEditable() {
      return this.$store.state.permission.permissions.team_edit;
    },
    header() {
      if (this.accountType === "player") {
        return this.historyId
          ? this.$t("normal.update_information")
          : `${this.$t("player.add_player")} ${this.teamName}`;
      }
      return this.historyId
        ? this.$t("normal.update_information")
        : `${this.$t("normal.add_personnel")} ${this.teamName}`;
    },
    firstnameEn() {
      return this.form.accounts?.account_informations
        ?.account_information_firstname_en;
    },
    lastnameEn() {
      return this.form.accounts?.account_informations
        ?.account_information_lastname_en;
    },
    teamId() {
      return this.$route.params.id;
    },
    name() {
      if (this.language === "en") {
        return (
          this.form.accounts?.account_informations
            ?.account_information_firstname_en || "-"
        );
      } else {
        return (
          this.form.accounts?.account_informations
            ?.account_information_firstname_th || "-"
        );
      }
    },
    nationality() {
      if (this.language === "en") {
        return this.form.accounts?.account_informations
          ?.account_information_nationality_en;
      } else {
        return this.form.accounts?.account_informations
          ?.account_information_nationality;
      }
    },
    age() {
      return this.getAge(
        this.form.accounts?.account_informations?.account_information_dob
      );
    },
    position() {
      return this.getPosition(this.form.global_config_positions);
    },
  },
  data() {
    return {
      accountList: [],
      dataAccountPlayers: [],
      positionList: [],
      departmentList: [],

      start_date: null,
      end_date: null,
      country: [
        { value: 1, text: "การแข่งขัน" },
        { value: 2, text: "การซ้อม" },
        { value: 3, text: "อื่นๆ" },
      ],
      historystatus: [
        { value: false, text: this.$t("normal.regularly"), id: "1" },
        { value: true, text: this.$t("normal.borrow"), id: "2" },
      ],
      form: {},
      language: "th",
    };
  },
  watch: {
    language(newVal) {
      this.historystatus = this.historystatus.map((history) => {
        if (history.id === "1") {
          history.text = this.$t("normal.regularly", newVal);
        }
        if (history.id === "2") {
          history.text = this.$t("normal.borrow", newVal);
        }
        return history;
      });
    },
  },
};
</script>

<style></style>
