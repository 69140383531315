import { appAxios } from '../helpers/app-axios';

export const versus_match = {
	namespaced: true,

	state: () => ({
		stadiums: [],
		stadium_holder: [],
		referees: [],
		teams: [],
		left_player: [],
		right_player: [],
		left_staff: [],
		right_staff: [],
	}),

	mutations: {
		clearPlayerData(state) {
			state.left_player = []
			state.right_player = []
			state.left_staff = []
			state.teams = []
			state.referees = []
			state.stadiums = []
		},
		updateStadium(state, data) {
			state.stadiums = data.data.stadium.map(s => {
				return {
					value: s.id,
					text_th: s.setting_stadium_name_th,
					text_en: s.setting_stadium_name_en
				}
			})
		},
		updateReferee(state, data) {
			state.referees = data.data.referee.map(r => {
				return {
					value: parseInt(r.refereeId),
					text_th: r.refereeName || 'ไม่มีชื่อ - นามสกุล',
					text_en: r.refereeNameEn || 'No firstname - lastname',
					data: r
				}
			})
		},
		updateTeams(state, data) {
			state.teams = data.data.teams
		},
		updateTeamPlayers(state, data) {
			state[data.side] = data.players
		},
		updateTeamStaff(state, data) {
			state[data.side] = data.staff.map(staff => {
				return {
					...staff,
					value: staff.staffId
				}
			})
		},
		success(state) {
			state.success = true
		}
	},

	actions: {
		clearPlayerData({ commit }) {
			commit('clearPlayerData')
		},
		getMatchMaster({ commit }, data) {
			appAxios.get(`competition-sections/${data.csid}/versus-match/stadium`).then(response => {
				commit('updateStadium', response.data)
			})
			appAxios.get(`competition-sections/${data.csid}/versus-match/referee`).then(response => {
				commit('updateReferee', response.data)
			})
			appAxios.get(`sub-competitions/${data.sid}/setting-team-in-sub-competition/all`).then(response => {
				commit('updateTeams', response.data)
			})
		},
		getTeamData({ commit }, data) {
			appAxios.get(
				`competition-sections/${data.csid}/versus-match/teams/${data.teamId}/players`
			).then(response => {
				commit('updateTeamPlayers', { side: `${data.side}_player`, players: response.data.data.team_player })
			})
			appAxios.get(
				`competition-sections/${data.csid}/versus-match/teams/${data.teamId}/staff`
			).then(response => {
				commit('updateTeamStaff', { side: `${data.side}_staff`, staff: response.data.data.team_player })
			})
		},
		async createOrUpdateMatch({ commit }, data) {
			let payload = JSON.parse(JSON.stringify(data.payload))
			let matchId = payload.id
			let groupId = data.gid

			let url = matchId ?
				groupId ?
					`competition-sections/${data.csid}/group-stages/${groupId}/versus-match/${matchId}/update` :
					`competition-sections/${data.csid}/versus-match/${matchId}/update` :
				groupId ?
					`competition-sections/${data.csid}/group-stages/${groupId}/versus-match/create`:
					`competition-sections/${data.csid}/versus-match/create`
			delete payload.id
			delete payload.competition_section_id

			return await appAxios.post(
				url, payload
			).then(response => {
				if (response.data.success) {
					commit('success')
					return null
				} else {
					return response.data.message
				}
			}).catch(error => {
				return error.data.message
			})
		},
		async getVersusMatchResult({ dispatch }, data) {
			let url = data.gid ?
				`competition-sections/${data.csid}/group-stages/${data.gid}/versus-match${data.mid ? `/${data.mid}` : ''}` :
				`competition-sections/${data.csid}/versus-match/${data.mid}`

			return await appAxios.get(
				url
			).then(response => {
				dispatch('match/updateResultForm', response.data.data.versus_match, { root: true })
			})
		},
		deleteMatch({ dispatch }, data) {
			appAxios.post(
				`competition-sections/${data.csid}/versus-match/${data.mid}/delete`
			).then(() => {
				dispatch('competition_section/clearMatchGroupData', data.csid, { root: true })
				dispatch('competition_section/getVersusMatch', { sid: data.sid, csid: data.csid }, { root: true })
			})
		}
	}
}