export function checkPermission(route, permission) {
  // return true;
  if (route.includes('player') && permission.athlete) {
    return true;
  }
  if (route.includes('staff') && permission.staff) {
    return true;
  }
  if (route.includes('team') && permission.team) {
    return true;
  }
  if (route.includes('stadium') && permission.stadium) {
    return true;
  }
  if (route.includes('master_data') && permission.setting) {
    return true;
  }
  if (route.includes('competition') && permission.competition) {
    return true;
  }
  if (route.includes('association') && permission.application_management) {
    return true;
  }
  if ((route.includes("dashboard")) && permission.report) {
    return true;
  }

  if ((route.includes("register")) && permission.application_management) {
    return true;
  }

  if(route === '/' && permission.report){
    return true
  }

  return false;
}

// application_management: (...)
// application_management_edit: (...)
// athlete: (...)
// athlete_edit: (...)
// competition: (...)
// competition_edit: (...)
// report: (...)
// setting: (...)
// setting_edit: (...)
// stadium: (...)
// stadium_edit: (...)
// staff: (...)
// staff_edit: (...)
// team: (...)
// team_edit: (...)
