import { appAxios } from "../helpers/app-axios";

// change here
// const modelNameSingular = 'physicalFitness'
const modelNamePlural = 'physicalFitnesses'

// non change
const getAllName = `get${modelNamePlural.charAt(0).toUpperCase() + modelNamePlural.slice(1)}`

export default {
  state: {
    [modelNamePlural]: []
  },
  mutations: {
    [getAllName](state, { res }) {
      state[modelNamePlural] = res
    },
    savePhysicalFitness(state, { res }) {
      state[modelNamePlural] = res
    },
  },
  actions: {
    async [getAllName]({ commit }) {
      appAxios.get('setting/global/physical-fitness')
        .then((res) => {
          const data = res.data.data
          const input = []
          for(let i = 0; i < 10; i++) {
            input.push({
              id: data[i]['id'],
              global_config_physical_fitness_name_th: data[i] ? data[i]['global_config_physical_fitness_name_th'] : '',
              global_config_physical_fitness_name_en: data[i] ? data[i]['global_config_physical_fitness_name_en'] : '',
              global_config_physical_fitness_percentage: (i + 1) * 10
            })
          }

          // console.log(JSON.stringify(input))
          commit(getAllName, { res: input })
        })
    },
    async savePhysicalFitness({ commit }, payload) {
      appAxios.post('setting/global/physical-fitness', payload)
        .then((res) => {
          commit('savePhysicalFitness', { res: res.data.data })
        })
    },
  }
}