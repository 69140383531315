<template>
  <v-app id="team">
    <div class="content app-content h-100">
      <div class="container">
        <h2>{{ $t("team.all_team") }}</h2>
        <div class="d-flex justify-content-between mb-3">
          <div class="d-flex">
            <!-- <div class="mr-2 cursor-pointer align-items-center d-flex">
              <v-icon style="color: #B9B9C3">fa-upload</v-icon>
              <span class="ml-1"> นำเข้า </span>
            </div>
            <div class="mr-2 cursor-pointer align-items-center d-flex">
              <v-icon style="color: #B9B9C3">fa-download</v-icon>
              <span class="ml-1"> ดาวน์โหลด </span>
            </div> -->
            <div
              class="
                mr-2
                cursor-pointer
                align-items-center
                d-flex
                select-amount
                rounded
              "
            >
              <div class="image-upload" @change="onChangeImage">
                <label for="file-input">
                  <v-icon class="uploadImage">$importIcon</v-icon>
                </label>
                <input class="selectFile" id="file-input" type="file" />
              </div>
              <span> {{ $t("normal.import") }} </span>
              <span v-if="dataImport !== null" class="row ml-2">
                <v-img
                  src="@/assets/images/successImport.svg"
                  max-width="20"
                  class="mx-1"
                  @click="onModalSuccess"
                />
                <v-img
                  src="@/assets/images/Icon.svg"
                  max-width="20"
                  class="mx-1"
                  @click="onModalFail"
                />
              </span>
            </div>

            <div
              @click="deleteMultipleTeam()"
              v-if="isEditable"
              class="
                mx-2
                cursor-pointer
                align-items-center
                d-flex
                select-amount
                rounded
              "
            >
              <v-icon dark>fa-trash</v-icon>
              <span class="ml-1"> {{ $t("normal.delete_select") }} </span>
            </div>
            <div class="d-flex align-items-center">
              {{ $t("normal.items_selected", { count: selected.length || 0 }) }}
            </div>
          </div>
          <router-link v-if="isEditable" to="/team/add">
            <v-btn color="primary">+ {{ $t("team.add_team") }} </v-btn>
          </router-link>
        </div>
        <div
          class="d-flex justify-content-end align-items-center py-4 px-4"
          style="background: #ffffff"
        >
          {{ $t("normal.search") }}
          <div class="mx-3 search-box">
            <v-text-field
              v-model="searchName"
              dense
              outlined
              hide-details="true"
              :placeholder="$t('stadium.search_placeholder')"
            ></v-text-field>
          </div>
          <div class="border-item">
            <v-menu v-model="menuTeam" offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon x-small>fa-eye</v-icon>
                  <span class="mx-3">{{ $t("normal.display") }}</span>
                  <v-icon x-small>fa-chevron-down</v-icon>
                </div>
              </template>
              <div class="p-1 bg-white">
                <div>{{ $t("normal.filter") }}</div>
                <v-form>
                  <div>{{ $t("team.type_team") }}</div>
                  <v-select
                    v-model="standardTypes"
                    item-text="text"
                    item-value="value"
                    :items="configStandardTypes"
                    :placeholder="$t('normal.please_select')"
                    clearable
                    dense
                    outlined
                  >
                  </v-select>
                  <div>{{ $t("address.country") }}</div>
                  <v-autocomplete
                    v-model="country"
                    clearable
                    :item-text="'country_native1_name_' + language"
                    item-value="country_id"
                    :items="countries"
                    :placeholder="$t('normal.please_select')"
                    dense
                    outlined
                  >
                  </v-autocomplete>
                  <div>{{ $t("filter_player_staff.city") }}</div>
                  <v-autocomplete
                    v-model="province"
                    clearable
                    :item-text="'province_name_' + language"
                    item-value="id"
                    :items="provinces"
                    :placeholder="$t('normal.please_select')"
                    dense
                    outlined
                  >
                  </v-autocomplete>
                  <v-btn @click="getTeams()" color="primary" class="mt-3">{{
                    $t("normal.search")
                  }}</v-btn>
                </v-form>
              </div>
            </v-menu>
          </div>
        </div>
        <v-data-table
          :headers="headers[language]"
          :items="teams"
          :page.sync="page"
          :items-per-page="pageSize"
          v-model="selected"
          class="elevation-1"
          show-select
          item-key="id"
          @page-count="pageCount = $event"
          :hide-default-footer="true"
        >
          <template v-slot:item="row">
            <tr @click="onRowClick(row.item.id)">
              <td :id="`${row.item.id}`">
                <v-checkbox
                  :id="`${row.item.id}`"
                  :input-value="row.isSelected"
                  @click="onRowCheck($event, row)"
                ></v-checkbox>
              </td>
              <td>
                <!-- <img src="@/assets/images/logoTrans.png" width="20" /> -->
                {{ row.item.name[language] || "-" }}
              </td>
              <td>
                {{ row.item.type[language] || "-" }}
              </td>
              <td>
                {{ row.item.country[language] || "-" }}
              </td>
              <td>
                {{ row.item.province[language] || "-" }}
              </td>
              <!-- <td>{{ row.item.players }}</td>
              <td>{{ row.item.staffs }}</td> -->
              <td>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>fa-ellipsis-v</v-icon>
                    </v-btn>
                  </template>

                  <v-list dark>
                    <v-list-item :to="'/team/edit/' + row.item.id">
                      <v-icon x-small>fa-pen</v-icon>
                      <span class="ml-3">{{ $t("normal.edit") }}</span>
                    </v-list-item>
                    <v-list-item
                      v-if="isEditable"
                      @click="delectTeam(row.item.id)"
                    >
                      <v-icon x-small>fa-trash</v-icon>
                      <span class="ml-3">{{ $t("normal.delete") }}</span>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
        <!-- <br> -->
        <div class="" style="background: #ffffff">
          <TableFooter
            :perPage.sync="pageSize"
            :currentPage.sync="currentPage"
            :lastPage="lastPage"
            :total="total"
          />
        </div>
      </div>
    </div>
    <TeamImportModal
      :dialog.sync="showNotificationImportSuccessModal"
      :dataImport="dataSuccess"
      type="success"
      postion="team"
    />
    <TeamImportModal
      :dialog.sync="showNotificationImportFailModal"
      :dataImport="dataFail"
      type="fail"
      position="team"
    />
  </v-app>
</template>

<script>
import TableFooter from "../../components/TableFooter.vue";
import { appAxios } from "../../helpers/app-axios";
import { mapState } from "vuex";
import {
  countriesState,
  getCountries,
} from "../../store/global_config_country";
import {
  provincesState,
  getProvinces,
} from "../../store/global_config_province";
import TeamImportModal from "../../components/Team/TeamImportModal.vue";
import { languageService } from "../../helpers/language";
import MasterDataHelper from "@/helpers/master_data_helper.js";

//import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: "team",
  components: {
    TableFooter,
    TeamImportModal,
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  beforeDestroy() {
    console.log("destroy team");
    this.subscription.unsubscribe();
  },
  mounted() {
    this.getTeams();
    this.$store.dispatch(getCountries);
    this.$store.dispatch(getProvinces);
  },
  computed: {
    params() {
      return {
        size: this.pageSize,
        page: this.currentPage,
        name: this.searchName,
        local_types: this.standardTypes,
        setting_team_region_country_id: this.country,
        setting_team_region_province_id: this.province,
      };
    },
    ...mapState({
      countries: (state) => state.globalConfigCountry[countriesState],
      provinces: (state) => state.globalConfigProvince[provincesState],
      isEditable: (state) => state.permission.permissions.team_edit,
    }),
  },
  methods: {
    onModalSuccess() {
      this.$data.showNotificationImportSuccessModal = true;
      this.$data.showNotificationImportFailModal = false;
      if (this.$data.dataImport?.import_success?.count !== null) {
        this.$data.dataSuccess = this.$data.dataImport?.import_success;
      }
    },
    onModalFail() {
      this.$data.showNotificationImportFailModal = true;
      this.$data.showNotificationImportSuccessModal = false;

      if (this.$data.dataImport?.import_failed?.count !== null) {
        this.$data.dataFail = this.$data.dataImport?.import_failed;
      }
    },
    onRowClick(row) {
      this.$router.push({ path: `/team/edit/${row}` });
    },
    onRowCheck(event, row) {
      event.stopPropagation();
      const id = event.target.previousElementSibling.id;
      const isSelected = this.teams.find((team) => team.id === id).isSelected;
      this.teams = this.teams.map((team) => {
        if (team.id === id) {
          team.isSelected = !isSelected;
        }
        return team;
      });
      row.select(!isSelected);
    },
    async importData(e) {
      if (!e) return;
      this.file = e.target.files ? e.target.files[0] : null;
      let formData = new FormData();
      formData.append("file", this.file);
      appAxios.post(`team/team/import/excel/teams`, formData).then((res) => {
        if (res?.data?.success) {
          this.$data.dataImport = res?.data?.data?.team;
          this.getTeams();
        }
      });
    },
    onChangeImage(e) {
      const file = e.target.files[0];
      this.$data.item.preview = e;
      this.$data.item.imageUrl = URL.createObjectURL(file);
      this.importData(e);
    },
    genText: function (text) {
      alert(this.a + text);
      this.a = this.a + text;
    },
    standardType(e) {
      if (e == 6) {
        return "ระดับชาติ";
      } else if (e == 7) {
        return "ระดับสโมสร";
      } else {
        return "ระดับสโมสร";
      }
    },
    async getTeams() {
      appAxios.get("team", { params: this.params }).then((res) => {
        const team = res.data?.data?.team;
        const data = team?.data;
        console.log("team :: ", { team, data });
        if (!data) return;
        this.$data.teams = data.map((ele) => ({
          isSelected: false,
          id: ele.id,
          name: {
            th: ele.setting_team_informations?.setting_team_information_name_th,
            en: ele.setting_team_informations?.setting_team_information_name_en,
          },
          type: {
            th: ele.global_config_local_types?.global_config_local_type_name_th,
            en: ele.global_config_local_types?.global_config_local_type_name_en,
          },
          country: {
            th:
              ele.setting_team_regions?.setting_team_region_country_id
                ?.country_native1_name_th ||
              ele.setting_team_regions?.setting_team_region_country_id
                ?.country_native2_name_th,
            en:
              ele.setting_team_regions?.setting_team_region_country_id
                ?.country_native1_name_en ||
              ele.setting_team_regions?.setting_team_region_country_id
                ?.country_native2_name_en,
          },
          province: {
            th: ele.setting_team_regions?.setting_team_region_province_id
              ?.province_name_th,
            en: ele.setting_team_regions?.setting_team_region_province_id
              ?.province_name_en,
          },
          players: "-",
          staffs: "-",
        }));
        this.total = team.total;
        this.lastPage = team.lastPage;
      });
    },
    async delectTeam(id) {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            appAxios.post(`team/${id}/delete`).then(() => {
              this.getTeams();
            });
          }
        }
      );
    },
    async deleteMultipleTeam() {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            const payload = {
              team_id: this.selected.map((ele) => ele.id),
            };
            appAxios.post(`team/multi/delete`, payload).then(() => {
              this.selected = [];
              this.getTeams();
            });
          }
        }
      );
    },
  },
  watch: {
    selected() {
      // this.deleteAllTeam(this.selected)
      // return console.log(this.selected);
    },
    currentPage: function () {
      this.getTeams();
    },
    pageSize: function () {
      this.getTeams();
    },
    searchName: function () {
      this.getTeams();
    },
    language(newVal, oldVal) {
      console.log("language :: ", oldVal, newVal);
      this.configStandardTypes = [
        {
          text: this.$t("team.team_info.thai_national_team", newVal),
          value: "1",
        },
        {
          text: this.$t("team.team_info.international_team", newVal),
          value: "2",
        },
        { text: this.$t("team.team_info.thai_club", newVal), value: "3" },
        { text: this.$t("team.team_info.national_club", newVal), value: "4" },
      ];
    },
  },
  data() {
    return {
      showNotificationImportSuccessModal: false,
      showNotificationImportFailModal: false,
      dataImport: null,
      dataSuccess: null,
      dataFail: null,
      item: {
        preview: null,
        imageUrl: null,
      },
      page: 1,
      pageCount: 0,
      pageCounts: [10, 20, 50, 100],
      itemsPerPage: 10,
      range: [10, 20],
      lastPage: 0,
      menuTeam: false,
      standardTypes: null,
      country: null,
      province: null,
      // params:{},
      headers: {
        th: [
          {
            text: this.$t("team.all_team", "th"),
            value: "id",
            sortable: false,
          },
          {
            text: this.$t("team.type_team", "th"),
            value: "position",
            sortable: false,
          },
          {
            text: this.$t("address.country", "th"),
            value: "status",
            sortable: false,
          },
          {
            text: this.$t("address.city", "th"),
            value: "name",
            sortable: false,
          },
          {
            text: this.$t("normal.manage", "th"),
            value: "edit",
            sortable: false,
          },
        ],
        en: [
          {
            text: this.$t("team.all_team", "en"),
            value: "id",
            sortable: false,
          },
          {
            text: this.$t("team.type_team", "en"),
            value: "position",
            sortable: false,
          },
          {
            text: this.$t("address.country", "en"),
            value: "status",
            sortable: false,
          },
          {
            text: this.$t("address.city", "en"),
            value: "name",
            sortable: false,
          },
          {
            text: this.$t("normal.manage", "en"),
            value: "edit",
            sortable: false,
          },
        ],
      },
      teams: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      searchName: "",
      selected: [],
      configStandardTypes: [
        {
          text: this.$t("team.team_info.thai_national_team", this.language),
          value: "1",
        },
        {
          text: this.$t("team.team_info.international_team", this.language),
          value: "2",
        },
        {
          text: this.$t("team.team_info.thai_club", this.language),
          value: "3",
        },
        {
          text: this.$t("team.team_info.national_club", this.language),
          value: "4",
        },
      ],
      language: "th",
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  background: #f8f8f8;
}

.select-amount {
  padding: 0 10px;
  background: #1e73fd;
  color: white;
}

.search-box {
  width: 360px;
}

.page-size-select {
  width: 85px;
}

.image-upload,
.uploadImage {
  cursor: pointer;
}
.image-upload .selectFile {
  display: none;
}
</style>
