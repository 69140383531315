import { appAxios } from '../helpers/app-axios';

const formReset = {
	versus_match_date: '',
	versus_match_referee: {
		versus_match_referee_one: {},
		versus_match_referee_two: {},
		versus_match_referee_three: {},
		versus_match_referee_four: {},
		versus_match_referee_five: {},
		versus_match_referee_six: {}
	},
	versus_match_team_left: {
		versus_match_team_detail_staff: {},
		versus_match_team_detail_player: [],
		versus_match_score: null,
		versus_match_team_id: null,
		versus_match_team_name: null,
		versus_match_team_name_en: null,

	},
	versus_match_team_right: {
		versus_match_team_detail_staff: {},
		versus_match_team_detail_player: [],
		versus_match_score: null,
		versus_match_team_id: null,
		versus_match_team_name: null,
		versus_match_team_name_en: null,

	},
	versus_match_stadium_holder: {
		id: null,
		image: null,
		name_th: null,
		name_en: null
	}
}

const defaultPlayers = {
	all: [],
	captain: [],
	vice_captain: [],
	lineup: [],
	sub: [],
}

export const match = {
	namespaced: true,

	state: () => ({
		resultForm: JSON.parse(JSON.stringify(formReset)),
		home: JSON.parse(JSON.stringify( defaultPlayers)),
		away: JSON.parse(JSON.stringify(defaultPlayers)),
		positions: [],
	}),

	actions: {
		clearResultForm({ state, dispatch }) {
			state.resultForm = JSON.parse(JSON.stringify(formReset))
			state.home = JSON.parse(JSON.stringify(defaultPlayers))
			state.away = JSON.parse(JSON.stringify(defaultPlayers))
			dispatch('versus_match/clearPlayerData', null, { root: true })
		},
		updateResultForm({ state, dispatch }, data) {
			state.resultForm = {
				id: data.id,
				setting_stadiums: data.setting_stadiums,
				versus_match_visitors: data.versus_match_visitors,
				versus_match_stadium_holder: {
					id: "" + data?.versus_match_stadium_holder?.id || null,
					// id: '12',
					image: data?.versus_match_stadium_holder?.image,
					name_th: data?.versus_match_stadium_holder?.name_th,
					name_en: null
				} || {
					id: "" + data?.versus_match_stadium_holder?.id || null,
					// id: '12',
					image: null,
					name_th: null,
					name_en: null
				},
				versus_match_date: data.versus_match_date,
				versus_match_start_at: data.versus_match_start_at,
				versus_match_end_at: data.versus_match_end_at,
				versus_match_team_left: data.versus_match_team_detail_one_id || {
					versus_match_team_id: state.resultForm?.versus_match_team_left?.versus_match_team_id || null,
					versus_match_team_name: null,
					versus_match_team_name_en: null,
					versus_match_team_image: null,
					versus_match_score: null
				},
				versus_match_team_right: data.versus_match_team_detail_two_id || {
					versus_match_team_id: state.resultForm?.versus_match_team_right?.versus_match_team_id || null,
					versus_match_team_name: null,
					versus_match_team_name_en: null,

					versus_match_team_image: null,
					versus_match_score: null
				},
				versus_match_referee: {
					versus_match_referee_one: data.versus_match_referee?.versus_match_referee_one?.refereeId,
					versus_match_referee_two: data.versus_match_referee?.versus_match_referee_two?.refereeId,
					versus_match_referee_three: data.versus_match_referee?.versus_match_referee_three?.refereeId,
					versus_match_referee_four: data.versus_match_referee?.versus_match_referee_four?.refereeId,
					versus_match_referee_five: data.versus_match_referee?.versus_match_referee_five?.refereeId,
					versus_match_referee_six: data.versus_match_referee?.versus_match_referee_six?.refereeId,
				}
			}
			if (data.versus_match_team_detail_one_id?.versus_match_team_id) {
				dispatch('versus_match/getTeamData', {
					csid: data.competition_sections,
					teamId: data.versus_match_team_detail_one_id.versus_match_team_id,
					side: 'left'
				}, { root: true })
			}
			if (data.versus_match_team_detail_two_id?.versus_match_team_id) {
				dispatch('versus_match/getTeamData', {
					csid: data.competition_sections,
					teamId: data.versus_match_team_detail_two_id.versus_match_team_id,
					side: 'right'
				}, { root: true })
			}
		},

		getPlayerPositions({ state }) {
			appAxios.get(
				`setting/global/positions/player/all`
			).then(response => {
				state.positions = response.data.data.map(position => {
					return {
						...position,
						value: position.global_config_position_abbreviation,
						text: position.global_config_position_abbreviation,
					}
				})
			})
		}
	}
}