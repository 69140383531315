import { render, staticRenderFns } from "./CompeititonUser.vue?vue&type=template&id=532d8e8a&scoped=true&"
import script from "./CompeititonUser.vue?vue&type=script&lang=js&"
export * from "./CompeititonUser.vue?vue&type=script&lang=js&"
import style0 from "./CompeititonUser.vue?vue&type=style&index=0&id=532d8e8a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532d8e8a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VApp,VDataTable,VTextField})
