<template>
  <v-dialog :value="dialog" max-width="500px" @click:outside="closeModal">
    <v-card>
      <div class="float-right">
        <v-card-actions>
          <v-btn color="primary" text @click="closeModal" mx-2>
            {{ $t("normal.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!isEditable"
            @click="openAlert"
            mx-2
          >
            {{ $t("normal.save") }}
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-title>
        <span class="header text-primary">{{ $t("manage_license.name") }}</span>
      </v-card-title>

      <div class="card-body">
        <div class="row">
          <div class="col-8">
            <label>{{ $t("manage_license.define_email") }}</label>
            <v-text-field
              :rules="emailRules"
              v-model="data.email"
              :readonly="!isEditable"
              dense
              outlined
              :placeholder="$t('contact_information.email')"
            ></v-text-field>
          </div>
        </div>

        <div>{{ $t("manage_license.permission") }} : {{ data.permission }}</div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialog", "data"],
  methods: {
    closeModal() {
      this.$emit("update:dialog", false);
    },
    openAlert() {
      this.$swal({
        icon: "warning",
        title: "หากเปลี่ยนอีเมล สิทธิ์การใช้งานจะกลับไปเป็น default",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          // TODO: call confirm here
          this.closeModal();
        }
      });
    },
  },
  computed: {
    emailRules() {
      const rules = [];
      const isEmail = (value) => {
        // if (!value) return null;
        const emailRegex = new RegExp(
          /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
        );
        if (this.language === "th") {
          return !value || emailRegex.test(value) || "รูปแบบ email ไม่ถูกต้อง";
        } else {
          return !value || emailRegex.test(value) || "Email is not correct";
        }
      };
      rules.push(isEmail);
      return rules;
    },
    isEditable() {
      if (this.isPlayerRoute) {
        return this.$store.state.permission?.permissions?.athlete_edit;
      }
      return this.$store.state.permission?.permissions?.staff_edit;
    },
    isPlayerRoute() {
      return this.$route.path.includes("player");
    },
  },
};
</script>

<style></style>
