<template>
  <v-dialog :value="visible" max-width="1000px" @input="closeModal">
    <v-card>
      <v-card-title>
        <span class="header text-primary">{{$t('player.penalty.add_penalty_modal')}}</span>
      </v-card-title>

      <div class="card-body">
        <v-form ref="form">
          <section class="pb-3">
            <div class="row">
              <div class="col-6">
                <label>{{$t('player.penalty.penalty_name_th')}}</label>
                <v-text-field
                  dense
                  outlined
                  v-model="form.account_penalty_title_th"
                  :rules="requiredRules"
                  :readonly="!isEditable"
                  :placeholder="$t('player.penalty.penalty_name_th')"
                >
                </v-text-field>
              </div>
              <div class="col-6">
                <label>{{$t('player.penalty.penalty_name_en')}}</label>
                <v-text-field
                  dense
                  outlined
                  v-model="form.account_penalty_title_en"
                  :rules="requiredRules"
                  :readonly="!isEditable"
                  :placeholder="$t('player.penalty.penalty_name_en')"
                >
                </v-text-field>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label>{{$t('player.penalty.description_en')}}</label>
                <v-textarea
                  dense
                  outlined
                  v-model="form.account_penalty_description_th"
                  :readonly="!isEditable"
                  :placeholder="$t('player.penalty.description_en')"
                >
                </v-textarea>
              </div>
              <div class="col-6">
                <label>{{$t('player.penalty.description_en')}}</label>
                <v-textarea
                  dense
                  outlined
                  v-model="form.account_penalty_description_en"
                  :readonly="!isEditable"
                  :placeholder="$t('player.penalty.description_en')"
                >
                </v-textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label>{{$t('player.penalty.competititon')}}</label>

                <v-combobox
                  dense
                  outlined
                  @change="selectPenaltyCompetition"
                  v-model="form.account_penalty_competition_name"
                  :search-input.sync="searchCompetition"
                  :return-object="false"
                  :item-text="language==='th'?`text.th`:`text.en`"
                  item-value="value.th"
                  :items="competitionList"
                  :rules="requiredRules"
                  :readonly="!isEditable"
                  :placeholder="$t('player.penalty.competititon')"
                >
                </v-combobox>
              </div>
              <div class="col-6">
                <label>{{$t('player.penalty.date')}}</label>
                <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      :value="dateFormat"
                      :rules="appealRequiredRules"
                      readonly
                      :placeholder="$t('player.penalty.date')"
                      append-icon="fa-calendar"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    min="1950-01-01"
                    v-model="form.account_penalty_date"
                    :readonly="!isEditable"
                    locale="us-us"
                    :max="maxDate"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label>{{$t('player.penalty.match_th')}}</label>
                <v-text-field
                  dense
                  outlined
                  v-model="form.account_penalty_competition_match"
                  :rules="requiredRules"
                  :readonly="!isEditable"
                  :placeholder="$t('player.penalty.match_th')"
                >
                </v-text-field>
              </div>
              <div class="col-6">
                <label>{{$t('player.penalty.match_en')}}</label>
                <v-text-field
                  dense
                  outlined
                  v-model="form.account_penalty_competition_match_en"
                  :rules="requiredRules"
                  :readonly="!isEditable"
                  :placeholder="$t('player.penalty.match_en')"
                >
                </v-text-field>
              </div>
              <div class="col-6">
                <label>{{$t('player.penalty.duration_panelty')}}</label>
                <div class="row">
                  <div class="col-6">
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      v-model="form.account_penalty_duration"
                      :rules="requiredRules"
                      :readonly="!isEditable"
                    >
                    </v-text-field>
                  </div>
                  <div class="col-6">
                    <v-select
                      dense
                      outlined
                      :items="unitList"
                      :item-text="'text_'+language"
                      v-model="form.account_penalty_duration_unit"
                      :rules="requiredRules"
                      :readonly="!isEditable"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            class="d-flex"
            :class="
              isAppeal ? 'justify-content-between' : 'justify-content-end'
            "
          >
            <div v-if="isAppeal" class="text-left text-primary">
              {{$t('player.penalty.appealing')}}
            </div>
            <div
              @click="toggleIsAppeal"
              class="text-primary text-right cursor-pointer"
            >
              {{ appealText[language] }}
            </div>
          </div>
          <div v-if="isAppeal" class="mt-3">
            <div class="row">
              <div class="col-3">
                <label>{{$t('player.penalty.date_appealing')}}</label>
                <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      :value="apealFormat"
                      :rules="appealRequiredRules"
                      readonly
                      :placeholder="$t('normal.date_format')"
                      append-icon="fa-calendar"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    min="1950-01-01"
                    v-model="form.account_penalty_appeal_begin"
                    :rules="appealRequiredRules"
                    :readonly="!isEditable"
                    locale="us-us"
                    :max="maxDate"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="col-3">
                <label>{{$t('player.penalty.result_appaling')}}</label>
                <v-select
                  dense
                  outlined
                  :items="appealResultList"
                  :item-text="'text_'+language"
                  v-model="form.account_penalty_appeal_conclusion"
                  :readonly="!isEditable"
                  :placeholder="$t('player.penalty.result_appaling')"
                >
                </v-select>
              </div>
              <div class="col-6">
                <label>{{$t('player.penalty.duration_appealing')}}</label>
                <div class="row">
                  <div class="col-6">
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      v-model="form.account_penalty_appeal_duration"
                      :rules="appealRequiredRules"
                      :readonly="!isEditable"
                    >
                    </v-text-field>
                  </div>
                  <div class="col-6">
                    <v-select
                      dense
                      outlined
                      :items="unitList"
                      :item-text="'text_'+language"
                      v-model="form.account_penalty_appeal_duration_unit"
                      :rules="appealRequiredRules"
                      :readonly="!isEditable"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between mt-2">
            <v-checkbox
              v-model="form.account_penalty_present"
              :label="$t('player.penalty.curently_penalty')"
            ></v-checkbox>
            <div>
              <v-btn @click="submit" class="mr-2" dark color="primary"
                >{{$t('normal.save')}}</v-btn
              >
              <v-btn @click="closeModal" dark color="red">
                <v-icon dark> fa-trash </v-icon>
              </v-btn>
            </div>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "date-fns/format";
import { appAxios } from "../../helpers/app-axios";
import { languageService } from "../../helpers/language";

const initialForm = {
  account_id: undefined,
  account_penalty_title_th: undefined,
  account_penalty_title_en: undefined,
  account_penalty_description_th: undefined,
  account_penalty_description_en: undefined,
  account_penalty_competition_name: undefined,
  account_penalty_competition_name_en: undefined,
  account_penalty_competition_match: undefined,
  account_penalty_competition_match_en: undefined,
  account_penalty_date: format(new Date(), "yyyy-MM-dd"),
  account_penalty_duration: undefined,
  account_penalty_duration_unit: undefined,
  account_penalty_appeal_conclusion: false,
  account_penalty_present: true,
};

export default {
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    penalty: {
      default: null,
    },
  },
  mounted() {
    this.getCompetition();
    if (this.penalty) {
      this.form = JSON.parse(JSON.stringify(this.penalty));
      this.searchCompetition = this.penalty.account_penalty_competition_name;
      this.searchMatch = this.penalty.account_penalty_competition_match;
      if (this.penalty.account_penalty_appeal_begin) {
        this.isAppeal = true;
      }
    } else {
      this.form = JSON.parse(JSON.stringify(initialForm));
      this.isAppeal = false;
    }
    this.$data.form.account_id = this.accountId;
  },
  methods: {
    selectPenaltyCompetition(){
      // const competition = this.competitionList.find(
      //   (elm) => elm?.value?.th === newVal
      // );

      // this.form.account_penalty_competition_name_en = competition?.value?.en;

    },
    closeModal(save = false) {
      this.$emit("update:penalty", null);
      this.$emit("update:visible", false);
      if (save) {
        this.$emit("save");
      }
    },
    async submit() {

      const result = this.$refs.form.validate();
      const competition = this.competitionList.find(
        (elm) => elm?.value?.th === this.form.account_penalty_competition_name
      );
      this.form.account_penalty_competition_name_en = competition?.value?.en

      if (!result) return;
        await appAxios
          .post(`account/${this.accountId}/penalties`, this.form)
          .then(() => {
            this.closeModal(true);
          });
    },
    showDate(date) {
      if (!date) return null;
      return format(new Date(date), "dd/MM/yyyy");
    },
    toggleIsAppeal() {
      this.isAppeal = !this.isAppeal;
    },
    async getCompetition() {
      const params = {
        name: this.searchCompetition,
      };
      await appAxios.get("competitions", { params }).then((res) => {
        const data = res.data.data?.competition?.data;
        if (!data) return;
        this.competitionList = data.map((ele) => ({
          text: {
            th: ele.setting_competition_informations
              ?.setting_competition_information_name_th,
            en: ele.setting_competition_informations
              ?.setting_competition_information_name_en,
          },
          value: {
            th: ele.setting_competition_informations
              ?.setting_competition_information_name_th,
            en: ele.setting_competition_informations
              ?.setting_competition_information_name_en,
          },
        }));
      });
    },
    // async getMatch() {
    //   const params = {
    //     name: this.searchMatch
    //   }
    //   await appAxios.get(``)
    // }
  },
  computed: {
    isEditable() {
      if (this.isPlayerRoute) {
        return this.$store.state.permission?.permissions?.athlete_edit;
      }
      return this.$store.state.permission?.permissions?.staff_edit;
    },
    isPlayerRoute() {
      return this.$route.path.includes("player");
    },
    maxDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
    accountId() {
      return Number(this.$route.params?.id);
    },
    dateFormat() {
      return this.showDate(this.form.account_penalty_date);
    },
    apealFormat() {
      return this.showDate(this.form.account_penalty_appeal_begin);
    },
    appealText() {
      return this.isAppeal ? {
        th:"- ลบการยื่นอุทธรณ์",
        en:"Delete Appealing"
      } : {
        th:"+ การยื่นอุทธรณ์",
        en:"Appealing"
      }
      // return this.isAppeal ? "- ลบการยื่นอุทธรณ์" : "+ การยื่นอุทธรณ์";
    },
    requiredRules() {
      return [(v) => !!v || "required"];
    },
    appealRequiredRules() {
      let rules = [];
      if (this.isAppeal) {
        rules.push((v) => !!v || "required");
      }
      return rules;
    },
  },
  watch: {

    // isAppeal(newVal) {
    //   if (!newVal) {
    //     this.form.account_penalty_appeal_begin = format(new Date(), 'yyyy-MM-dd');
    //     this.form.account_penalty_appeal_conclusion = false;
    //     this.form.account_penalty_appeal_duration = null;
    //     this.form.account_penalty_appeal_duration_unit = null;
    //   } else {
    //     delete this.form.account_penalty_appeal_begin;
    //     delete this.form.account_penalty_appeal_duration;
    //     delete this.form.account_penalty_appeal_duration_unit;
    //   }
    // },

    searchCompetition(newVal) {

      const competition = this.competitionList.find(
        (elm) => (elm.value.th = newVal)
      );
      this.form.account_penalty_competition_name_en = competition?.value?.en;

      this.getCompetition();
    },
    searchMatch() {},
  },
  created() {
		this.subscription = languageService.getCurrent().subscribe((lang) => {
			if (lang === "en") {
				this.language = "en";
			} else {
				this.language = "th";
			}
		});
	},
  data() {
    return {
      language: "th",
      form: {},
      unitList: [
         { text_en: "Days", text_th: "วัน" , value: "DAYS" },
        { text_en: "Weeks", text_th: "สัปดาห์" , value: "WEEKS" },
        { text_en: "Months", text_th: "เดือน" , value: "MONTHS" },
        { text_en: "Years", text_th: "ปี" , value: "YEARS" },
        { text_en: "Matches", text_th: "แมตช์" , value: "MATCHES" },
        { text_en: "Seasons", text_th: "ฤดูกาล" , value: "SEASONS" },
      ],
      isAppeal: false,
      appealResultList: [
        { text_th: "ไม่อนุมัติ",text_en:"Disapproved", value: false },
        { text_th: "อนุมัติ",text_en:"Approved", value: true },
      ],
      competitionList: [],
      matchList: [
        {
          text: "test",
          value: "test",
        },
      ],
      searchCompetition: null,
      searchMatch: null,
    };
  },
};
</script>

<style></style>
