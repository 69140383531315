import Staff from '../views/Staff/Staff.vue'
import AddStaff from '../views/Staff/AddStaff.vue'
import permissionGuard from './guard/permissionGuard';
import CurriculumVitae from '../views/CurriculumVitae/CurriculumVitae.vue'


const player = [
    {
    path: '/staff',
    name: 'Staff',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Staff
    },
    {
        path: '/staff/add/',
        name: 'AddStaff',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter: permissionGuard,
        component: AddStaff
    },
    {
        path: '/staff/edit/:id',
        name: 'EditStaff',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter: permissionGuard,
        component: AddStaff,
        props: true
    },
    {
        path: '/staff/curriculumvitae/:id',
        name: 'CurriculumVitaePlayer',
        beforeEnter: permissionGuard,
        component: CurriculumVitae,
        props: true
    }



    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
]

export default player;
