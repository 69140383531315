<template>
	<div>
		<form class="needs-validation" novalidate="">
			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label class="form-label" for="basic-addon-name">{{$t('address.address_no_th')}}</label>
						<v-text-field
							v-model="address.address_detail"
							:readonly="!isEditable"
							dense
							outlined
							hide-details
							:placeholder="$t('address.address_no_th')"
						></v-text-field>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label class="form-label" for="basic-addon-name">{{$t('address.address_no_en')}}</label>
						<v-text-field
							v-model="address.address_detail_en"
							:readonly="!isEditable"
							dense
							outlined
							hide-details
							:placeholder="$t('address.address_no_en')"
						></v-text-field>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-6">
					<div class="form-group">
						<label class="form-label" for="basic-addon-name">{{$t('address.country')}}</label>
						<v-autocomplete
							:placeholder="$t('normal.please_select')"
							:items="countries"
							:item-text="'country_native1_name_'+language"
							item-value="country_id"
							:loading="isCountriesFetching"
							v-model="address.country_id"
							:readonly="!isEditable"
							dense
							outlined
							hide-details
						></v-autocomplete>
					</div>
				</div>
				<div class="col-6">
					<div class="form-group">
						<label class="form-label" for="basic-addon-name">{{$t('address.province')}}</label>
						<v-autocomplete
							:items="provinces"
							item-value="id"
							:item-text="'province_name_'+language"
							:loading="isProvincesFetching"
							:disabled="address.country_id !== '188'"
							v-model="address.province_id"
							:readonly="!isEditable"
							dense
							outlined
							hide-details
							:placeholder="$t('normal.please_select')"
						></v-autocomplete>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3 col-6">
					<div class="form-group">
						<label class="form-label" for="basic-addon-name">{{$t('address.district')}}</label>
						<v-autocomplete
							:items="districts"
							item-value="id"
							:item-text="'district_name_'+language"
							v-model="address.district_id"
							:readonly="!isEditable"
							:loading="isDistrictFetching"
							:disabled="address.country_id !== '188' || !address.province_id"
							dense
							outlined
							hide-details
							:placeholder="$t('normal.please_select')"
						></v-autocomplete>
					</div>
				</div>
				<div class="col-md-3 col-6">
					<div class="form-group">
						<label class="form-label" for="basic-addon-name">{{$t('address.sub_district')}}</label>
						<v-autocomplete
							v-model="address.subdistrict_id"
							:readonly="!isEditable"
							:disabled="!address.district_id || address.country_id !== '188'"
							:items="subdistricts"
							:loading="isSubdistrictFetching"
							:item-text="'sub_district_name_'+language"
							item-value="id"
							dense
							hide-no-data
							outlined
							hide-details
							:placeholder="$t('normal.please_select')"
						></v-autocomplete>
					</div>
				</div>
				<div class="col-6">
					<div class="form-group">
						<label class="form-label" for="basic-addon-name"
							>{{$t('address.zip_code')}}</label
						>
						<v-text-field
							v-model="address.zipcode"
							:readonly="!isEditable"
							:disabled="!address.subdistrict_id"
							dense
							outlined
							hide-details
							:placeholder="$t('address.zip_code')"
						></v-text-field>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import {
	countriesState,
	getCountries,
} from "../../store/global_config_country";
import {
	provincesState,
	getProvinces,
} from "../../store/global_config_province";
import { getDistrict } from "../../store/global_config_district";
import { getSubdistrict } from "../../store/global_config_subdistrict";
import { mapState } from "vuex";
import { languageService } from "../../helpers/language";

export default {
	props: {
		address: {
			default: {},
		},
	},
	data(){
		return{
			language: "th",
		}
	},
	mounted() {
		this.$store.dispatch(getCountries);
		this.$store.dispatch(getProvinces);
		const address = this.address;
		if (this.address.province_id) {
			this.$store.dispatch(getDistrict, this.address.province_id);
		}
		if (address.district_id) {
			this.$store.dispatch(getSubdistrict, address.district_id);
		}
	},
	created() {
		this.subscription = languageService.getCurrent().subscribe((lang) => {
			if (lang === "en") {
				this.language = "en";
			} else {
				this.language = "th";
			}
		});
	},
	computed: {
		...mapState({
			isCountriesFetching: (state) => state.globalConfigCountry.isFetching,
			countries: (state) => state.globalConfigCountry[countriesState],
			isProvincesFetching: (state) => state.globalConfigProvince.isFetching,
			provinces: (state) => state.globalConfigProvince[provincesState],
			isDistrictFetching(state) {
				state.globalConfigDistrict.isFetching; // to let vue know when should trigger state change
				return state.globalConfigDistrict[this.address.province_id]?.isFetching;
			},
			districts(state) {
				state.globalConfigDistrict.isFetching;
				return state.globalConfigDistrict[this.address.province_id]?.data;
			},
			isSubdistrictFetching(state) {
				state.globalConfigSubdistrict.isFetching;
				return state.globalConfigSubdistrict[this.address.district_id]
					?.isFetching;
			},
			subdistricts(state) {
				state.globalConfigSubdistrict.isFetching;
				return state.globalConfigSubdistrict[this.address.district_id]?.data;
			},
		}),
		isEditable() {
			if (this.isPlayerRoute) {
				return this.$store.state.permission?.permissions?.athlete_edit;
			}
			return this.$store.state.permission?.permissions?.staff_edit;
		},
		isPlayerRoute() {
			return this.$route.path.includes("player");
		},
		isPlayerId() {
			return Number(this.$route.params?.id);
		},
	},
	watch: {
		"address.country_id"() {
			if (this.address.country_id === "188") {
				this.$store.dispatch(getProvinces);
			} else {
				this.address.province_id = null;
				this.address.district_id = null;
				this.address.subdistrict_id = null;
				this.address.zipcode = null;
      }
		},
		"address.province_id"(newVal, old) {
			if (!newVal) {
				this.address.district_id = null;
				return;
			} else if (old) {
				this.address.subdistrict_id = null;
				this.address.zipcode = null;
			}

			this.$store.dispatch(getDistrict,newVal)
		},
		"address.district_id"(newVal, old) {
			if (!newVal) {
				this.address.subdistrict_id = null;
				return;
			} else if (old) {
				this.address.zipcode = null;
			}

			this.$store.dispatch(getSubdistrict, newVal);
		},
		"address.subdistrict_id"(newVal) {
			if (!newVal) {
				this.address.zipcode = null;
				return;
			}

			const matchSubdistrict = this.$store.state.globalConfigSubdistrict[
				this.address.district_id
			]?.data.find((ele) => ele.id == newVal);
			if (matchSubdistrict) {
				this.address.zipcode = matchSubdistrict.zipcode;
			}
		},
	},
	methods: {},
};
</script>

<style></style>
