<template>
  <v-app id="stadium">
    <div class="content app-content h-100">
      <div class="container">
        <h2>{{ $t("stadium.all_stadium") }}</h2>
        <div class="d-flex justify-content-between mb-3">
          <div class="d-flex">
            <!-- <div class="mr-2 cursor-pointer align-items-center d-flex">
              <v-icon style="color: #B9B9C3">fa-upload</v-icon>
              <span class="ml-1"> นำเข้า </span>
            </div>
            <div class="mr-2 cursor-pointer align-items-center d-flex">
              <v-icon style="color: #B9B9C3">fa-download</v-icon>
              <span class="ml-1"> ดาวน์โหลด </span>
            </div> -->
            <!-- <div v-if="isEditable" @click="deletemultiStadium()" class="mr-2 cursor-pointer align-items-center d-flex">
              <v-icon style="color: #B9B9C3">fa-trash</v-icon>
              <span class="ml-1"> {{ $t('normal.delete_select') }} </span>
            </div> -->

            <div
              color="primary"
              v-if="isEditable"
              @click="deletemultiStadium()"
              class="
                mr-2
                cursor-pointer
                align-items-center
                d-flex
                select-amount
                rounded
              "
            >
              <v-icon
                style="color: #ffffff; font-size: 1rem; margin-right: 0.5rem"
                >fa-trash</v-icon
              >
              <span class="ml-1"> {{ $t("normal.delete_select") }} </span>
            </div>
            <div class="d-flex align-items-center" style="color: #b9b9c3">
              <!-- เลือก {{ selected.length }} รายการ -->
              {{ $t("normal.items_selected", { count: selected.length || 0 }) }}
            </div>
          </div>
          <router-link v-if="isEditable" to="/stadium/add">
            <v-btn color="primary">+ {{ $t("stadium.add_stadium") }} </v-btn>
          </router-link>
        </div>
        <div
          class="d-flex justify-content-end align-items-center py-4 px-4"
          style="background: #ffffff"
        >
          {{ $t("normal.search") }}
          <div class="mx-3 search-box">
            <v-text-field
              v-model="searchName"
              dense
              outlined
              hide-details="true"
              :placeholder="$t('stadium.search_placeholder')"
            ></v-text-field>
          </div>
          <div class="border-item">
            <v-menu
              v-model="menuStaium"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon x-small>fa-eye</v-icon>
                  <span class="mx-3">{{ $t("normal.display") }}</span>
                  <v-icon x-small>fa-chevron-down</v-icon>
                </div>
              </template>
              <div class="p-1 bg-white">
                <div>{{ $t("normal.filter") }}</div>
                <v-form>
                  <div>{{ $t("address.country") }}</div>

                  <v-autocomplete
                    v-model="country"
                    clearable
                    :item-text="'country_native1_name_' + language"
                    item-value="country_id"
                    :items="countries"
                    :placeholder="$t('normal.please_select')"
                    dense
                    outlined
                  >
                  </v-autocomplete>
                  <div>{{ $t("address.province") }}</div>
                  <v-autocomplete
                    v-model="province"
                    clearable
                    :item-text="'province_name_' + language"
                    item-value="id"
                    :items="provinces"
                    :placeholder="$t('normal.please_select')"
                    dense
                    outlined
                  >
                  </v-autocomplete>
                  <v-btn @click="getStadium()" color="primary" class="mt-3">{{
                    $t("normal.search")
                  }}</v-btn>
                </v-form>
              </div>
            </v-menu>
          </div>
        </div>
        <v-data-table
          v-model="selected"
          :items="stadiums"
          :headers="headers[language]"
          item-key="id"
          :items-per-page="pageSize"
          class="elevation-1"
          show-select
          @page-count="pageCount = $event"
          :hide-default-footer="true"
        >
          <template v-slot:item="row">
            <tr @click="onRowClick(row.item.id)">
              <td>
                <v-checkbox
                  :id="`${row.item.id}`"
                  :input-value="row.isSelected"
                  @click="onRowCheck($event, row)"
                ></v-checkbox>
              </td>
              <td>
                <!-- <img src="@/assets/images/logoTrans.png" width="20" /> -->
                {{ row.item.name[language] }}
              </td>
              <td>
                {{ row.item.country[language] }}
              </td>
              <td>
                {{ row.item.province[language] }}
              </td>
              <td>{{ formatCapacity(row.item.capacity)}}</td>
              <td>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>fa-ellipsis-v</v-icon>
                    </v-btn>
                  </template>
                  <!-- <v-icon x-small>fa-pen</v-icon> -->

                  <v-list dark>
                    <v-list-item :to="'/stadium/edit/' + row.item.id">
                      <v-icon x-small>fa-pen</v-icon>
                      <span class="ml-3">
                        {{ $t("normal.edit") }}
                      </span>
                    </v-list-item>
                    <v-list-item
                      v-if="isEditable"
                      @click="delectStadium(row.item.id)"
                    >
                      <v-icon x-small>fa-trash</v-icon>
                      <span class="ml-3">
                        {{ $t("normal.delete") }}
                      </span>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
        <!-- <br> -->
        <div class="" style="background: #ffffff">
          <TableFooter
            :perPage.sync="pageSize"
            :currentPage.sync="currentPage"
            :lastPage="lastPage"
            :total="total"
          />
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import TableFooter from "../../components/TableFooter.vue";
import { appAxios } from "../../helpers/app-axios";
import { languageService } from "../../helpers/language";
import MasterDataHelper from "@/helpers/master_data_helper.js";

import { mapState } from "vuex";
import {
  countriesState,
  getCountries,
} from "../../store/global_config_country";
import {
  provincesState,
  getProvinces,
} from "../../store/global_config_province";

export default {
  name: "stadium",
  components: {
    TableFooter,
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  beforeDestroy() {
    this.subscription.unsubscribe();
  },
  mounted() {
    this.getStadium();
    this.$store.dispatch(getCountries);
    this.$store.dispatch(getProvinces);
  },
  computed: {

    dataStadium() {
      return this.stadium;
    },
    params() {
      return {
        size: this.pageSize,
        page: this.currentPage,
        name: this.searchName,
        setting_stadium_region_country_id: this.country,
        setting_stadium_region_province_id: this.province,
      };
    },
    ...mapState({
      countries: (state) => state.globalConfigCountry[countriesState],
      provinces: (state) => state.globalConfigProvince[provincesState],
      isEditable: (state) => state.permission.permissions.stadium_edit,
    }),
  },
  methods: {
    formatCapacity(capa){
      return capa.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    search() {
      this.$data.searching = true;
      this.currentPage = 1;
      this.getStadium();
    },
    async deletemultiStadium() {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            const payload = {
              stadium_id: this.selected.map((ele) => ele.id),
            };
            appAxios.post(`stadium/multi/delete`, payload).then(() => {
              this.selected = [];
              this.getStadium();
            });
          }
        }
      );
    },
    onRowClick(row) {
      this.$router.push({ path: `/stadium/edit/${row}` });
    },
    onRowCheck(event, row) {
      event.stopPropagation();
      const id = event.target.previousElementSibling.id;
      const isSelected = this.stadiums.find(
        (team) => team.id === id
      ).isSelected;
      this.stadiums = this.stadiums.map((stadium) => {
        if (stadium.id === id) {
          stadium.isSelected = !isSelected;
        }
        return stadium;
      });
      row.select(!isSelected);
    },
    async delectStadium(id) {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            appAxios.post(`stadium/${id}/delete`).then(() => {
              this.getStadium();
            });
          }
        }
      );
    },
    getStadium() {
      if (
        this.params?.setting_stadium_region_country_id ||
        this.params?.setting_stadium_region_province_id
      ) {
        this.params["page"] = 1;
      }
      appAxios.get("stadium", { params: this.params }).then((res) => {
        const stadium = res.data?.data?.stadium;
        const data = stadium?.data;
        if (!data) return;
        this.stadiums = data.map((ele) => ({
          name: {
            th: ele.setting_stadium_name_th || "-",
            en: ele.setting_stadium_name_en || "-",
          },
          country: {
            th:
              ele.setting_stadium_regions?.setting_stadium_region_country_id
                ?.country_native1_name_th || "-",
            en:
              ele.setting_stadium_regions?.setting_stadium_region_country_id
                ?.country_native1_name_en || "-",
          },
          province: {
            th:
              ele.setting_stadium_regions?.setting_stadium_region_province_id
                ?.province_name_th || "-",
            en:
              ele.setting_stadium_regions?.setting_stadium_region_province_id
                ?.province_name_en || "-",
          },
          capacity: ele.setting_stadium_capacity || "-",
          id: ele.id,
        }));
        this.total = stadium.total;
        this.lastPage = stadium.lastPage;
      });
    },
  },
  watch: {
    currentPage: function () {
      this.$data.searching = false;
      this.getStadium();
    },
    pageSize: function () {
      this.getStadium();
    },
    searchName: function () {
      this.getStadium();
    },
  },
  data() {
    return {
      searching: false,
      page: 1,
      pageCount: 0,
      pageCounts: [10, 20, 50, 100],
      itemsPerPage: 10,
      range: [10, 20],
      stadiums: [],
      lastPage: 1,
      total: 0,
      menuStaium: false,
      country: null,
      province: null,
      headers: {
        th: [
          {
            text: this.$t("stadium.stadium_name", "th"),
            value: "id",
            sortable: false,
          },
          {
            text: this.$t("address.country", "th"),
            value: "position",
            sortable: false,
          },
          {
            text: this.$t("address.city", "th"),
            value: "status",
            sortable: false,
          },
          {
            text: this.$t("stadium.capacity", "th"),
            value: "name",
            sortable: false,
          },
          {
            text: this.$t("normal.manage", "th"),
            value: "edit",
            sortable: false,
          },
        ],
        en: [
          {
            text: this.$t("stadium.stadium_name", "en"),
            value: "id",
            sortable: false,
          },
          {
            text: this.$t("address.country", "en"),
            value: "position",
            sortable: false,
          },
          {
            text: this.$t("address.city", "en"),
            value: "status",
            sortable: false,
          },
          {
            text: this.$t("stadium.capacity", "en"),
            value: "name",
            sortable: false,
          },
          {
            text: this.$t("normal.manage", "en"),
            value: "edit",
            sortable: false,
          },
        ],
      },
      api: "http://localhost:3000/users",
      pageSize: 10,
      currentPage: 1,
      searchName: "",
      selected: [],
      buttonEdit: [{ title: "แก้ไขข้อมูล" }, { title: "ลบรายการนี้" }],
      configCountry: [{ text: "ชื่อทีม/สโมสร", value: "id" }],
      configProvince: [{ text: "ชื่อทีม/สโมสร", value: "id" }],
      language: "th",
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  background: #f8f8f8;
}

.select-amount {
  padding: 0 10px;
  background: #1e73fd;
  color: white;
}

.search-box {
  width: 360px;
}

.page-size-select {
  width: 85px;
}
</style>
