var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-card',{},[_c('div',{staticClass:"py-3 pl-5",style:("color:#267FFF;font-size:26px")},[_vm._v(" รายชื่อทีมที่สมัคร ")]),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.listTeam,"hide-default-footer":"","show-select":"","items-per-page":_vm.listTeam.length},on:{"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":item.status === 'WAITING' && isSelected,"readonly":item.status !== 'WAITING',"disabled":item.status !== 'WAITING'},on:{"input":function($event){return select($event)}}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showDate(item.created_at))+" ")]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(item.status === "APPROVED")?_c('div',{staticClass:"text-primary font-weight-bold"},[_vm._v(" อนุมัติแล้ว ")]):(item.status === "REJECTED")?_c('div',{staticClass:"text-danger font-weight-bold"},[_vm._v(" ไม่อนุมัติ ")]):_c('div',{staticClass:"text-warning font-weight-bold"},[_vm._v("รอการอนุมัติ")])]}},{key:"top",fn:function(ref){return [_c('v-row',{staticClass:"px-6 d-flex flex-row justify-space-between"},[_c('v-col',{staticClass:"d-flex flex-row justify-center align-items-center",attrs:{"cols":"auto"}},[_c('div',{staticClass:"bg-primary px-2 rounded-lg text-white"},[_vm._v(" "+_vm._s(_vm.selectPerson.length)+" ")]),_c('div',{staticClass:"mx-5 d-flex ",class:{
                'text-success cursor-pointer': _vm.selectPerson.length > 0,
              },on:{"click":_vm.approveTeam}},[_c('v-icon',{class:{
                  'text-success cursor-pointer': _vm.selectPerson.length > 0,
                }},[_vm._v("$complete")]),_c('span',{staticClass:"m-auto ml-3"},[_vm._v("อนุมัติรายการที่เลือก")])],1),_c('div',{staticClass:"d-flex",class:{
                'text-danger cursor-pointer': _vm.selectPerson.length > 0,
              },on:{"click":_vm.onModalRejectComment}},[_c('v-icon',{class:{
                  'text-danger cursor-pointer': _vm.selectPerson.length > 0,
                }},[_vm._v("$close")]),_c('span',{staticClass:"m-auto ml-3"},[_vm._v("ไม่อนุมัติรายการที่เลือก")])],1)]),_c('v-col',{staticClass:"d-flex flex-row justify-center align-items-center",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("normal.search"))+" "),_c('div',{staticClass:"mx-3 search-box"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"true","placeholder":_vm.$t('sub_competition.search_team_placholder')},model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=$$v},expression:"searchName"}})],1)])],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"\n          d-flex\n          flex-column\n          align-items-center\n          justify-content-center\n          py-6\n        ",staticStyle:{"min-height":"400px"}},[_c('v-img',{staticClass:"ma-2",attrs:{"src":require("@/assets/images/cup.png"),"max-height":"128","contain":""}}),_c('h4',{staticClass:"grey--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm.$t("sub_competition.not_exist"))+" ")]),_c('h5',{staticClass:"grey--text text--lighten-2"},[_vm._v(" "+_vm._s(_vm.$t("sub_competition.please_add_team"))+" ")])],1)]},proxy:true},{key:"item.team",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('v-img',{staticClass:"ma-2",attrs:{"src":item.setting_team_media_profile_url,"max-height":"20","max-width":"20","contain":""}}),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(item["setting_team_information_name_" + _vm.$t("short_language")]))])],1)]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("fa-ellipsis-v")])]}}],null,true)},[_c('v-list',_vm._l((_vm.actions),function(action,index){return _c('v-list-item',{key:index},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onClick(action.key, item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(action.icon))]),_vm._v(_vm._s(action.text)+" ")],1)],1)}),1)],1)]}}],null,true),model:{value:(_vm.selectPerson),callback:function ($$v) {_vm.selectPerson=$$v},expression:"selectPerson"}})],1),_c('RejectModal',{attrs:{"dialog":_vm.showRejectModal},on:{"update:dialog":function($event){_vm.showRejectModal=$event},"commit":_vm.onCommitReject}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }