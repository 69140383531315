<template>
  <div class="mb-5" id="team1">
    <h4 class="text-primary mb-5">{{ $t("player.personal_info.team_cv") }}</h4>

    <div
      class="row col-md-12 m-0 p-0"
      v-for="(detail, i) in dataClubHistory.team"
      :key="i"
    >
      <div class="col-md-4 mx-0 px-0 bold text-primary py-1 col-lg-3">
        {{ detail.year[language] }}
      </div>
      <div class="col-md-4 px-0 py-1 col-lg-4">{{ detail.name[language] }}</div>
      <div class="col-md-4 py-1 col-xl-3">{{ detail.details[language] }}</div>
    </div>
    <div
      class="row col-md-12 m-0 p-0 d-flex justify-content-center"
      v-if="!dataClubHistory.team || dataClubHistory.team.length === 0"
    >
      <label>{{ $t("normal.no_data") }}</label>
    </div>
  </div>
</template>

<script>
import { languageService } from "../../helpers/language";
import { getYear } from "date-fns";

export default {
  name: "clubHistory",
  props: ["clubHistory", "type"],
  data() {
    return {
      language: "th",
      dataClubHistory: {},
    };
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  mounted() {
    this.$emit("heightSize", document.querySelector("#team1").offsetHeight);
  },
  watch: {
    clubHistory() {
      this.dataClubHistory = {
        team:
          this.type === "player"
            ? this.clubHistory?.map((his) => {
                return {
                  year: {
                    th:
                      (his?.team_player_history_begin_at
                        ? getYear(new Date(his.team_player_history_begin_at)) +
                          543
                        : "ไม่ระบุ") +
                      " - " +
                      (his?.team_player_history_end_at && his?.team_player_history_status !== 1
                        ? getYear(new Date(his?.team_player_history_end_at)) +
                          543
                        : "ปัจจุบัน"),
                    en:
                      (his?.team_player_history_begin_at
                        ? getYear(new Date(his.team_player_history_begin_at))
                        : "N/A") +
                      " - " +
                      (his?.team_player_history_end_at && his?.team_player_history_status !== 1
                        ? getYear(new Date(his?.team_player_history_end_at))
                        : "Present"),
                  },

                  name: {
                    th: his.setting_teams?.setting_team_informations
                      ?.setting_team_information_name_th,
                    en: his.setting_teams?.setting_team_informations
                      ?.setting_team_information_name_en,
                  },
                  details:
                    his?.team_player_history_status === 1
                      ? {
                          th: "ต้นสังกัดปัจจุบัน",
                          en: "Current Club",
                        }
                      : {
                          th: "",
                          en: "",
                        },
                };
              })
            : this.clubHistory?.map((his) => {
                return {
                  year: {
                    th:
                      (his?.team_player_history_begin_at
                        ? getYear(new Date(his.team_staff_history_begin_at))
                        : "ไม่ระบุ") +
                      " - " +
                      (his?.team_staff_history_end_at && his?.team_player_history_status !== 1
                        ? getYear(new Date(his?.team_staff_history_end_at))
                        : "ปัจจุบัน"),
                    en:
                      (his?.team_player_history_begin_at
                        ? getYear(new Date(his.team_staff_history_begin_at))
                        : "N/A") +
                      " - " +
                      (his?.team_staff_history_end_at && his?.team_player_history_status !== 1
                        ? getYear(new Date(his?.team_staff_history_end_at))
                        : "Present"),
                  },

                  name: {
                    th: his?.setting_teams?.setting_team_informations
                      ?.setting_team_information_name_th,
                    en: his?.setting_teams?.setting_team_informations
                      ?.setting_team_information_name_en,
                  },
                  details:
                    his?.team_player_history_status === 1
                      ? {
                          th: "ต้นสังกัดปัจจุบัน",
                          en: "Current Club",
                        }
                      : {
                          th: "",
                          en: "",
                        },
                };
              }),
      };
    },
  },
};
</script>