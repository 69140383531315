<template>
	<div class=" pb-0">
		<div class="mt-5">
			<div class="content-body">
				<div
					class="col-12 col-md-4 mb-1 d-flex justify-content-end float-right"
				>
					<v-btn
						class="float-right"
						color="primary"
						v-if="isEditable"
						@click="saveData()"
						>{{$t('normal.save')}}</v-btn
					>
				</div>

				<v-toolbar flat>
					<v-tabs
						v-model="tab"
						:show-arrows="false"
						active-class="text-white tab-color"
						hide-slider
					>
						<!-- <v-tabs-slider color="white"></v-tabs-slider>  -->
						<v-tab
							:disabled="isAddRoute && idx !== 0"
							class="app-rounded-top"
							v-for="(item, idx) in tabs"
							:key="idx"
						>
							<v-icon>{{ item.icon }}</v-icon>
							<div class="ml-2">
								<span> {{ item.topic }} </span> <br />
								<span class="font-small-1"> {{ item.detail }} </span>
							</div>
						</v-tab>
					</v-tabs>
				</v-toolbar>
			</div>
		</div>
		<v-tabs-items class="shadow" v-model="tab">
			<v-tab-item v-for="(item, idx) in tabContents" :key="idx" :value="idx">
				<component ref="tabContent" v-bind:is="item"></component>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>
<script>
import Tab11Content from "./Tab11Content.vue";

export default {
	name: "association-tabs",
	mounted() {},
	methods: {
		saveData() {
			const activeComponent = this.$refs.tabContent.find((ele) =>
				ele.$parent.$el.className.includes("active")
			);
			if (activeComponent && activeComponent.save) {
				activeComponent.save();
			}
		},
	},
	computed: {
		isEditable() {
			return this.$store.state.permission.permissions
				.application_management_edit;
		},
	},
	components: {
		Tab11Content,
	},
	data() {
		return {
			isAddRoute: false,
			tab: null,
			tabs: [
				{
					topic: "ตั้งค่าข้อมูลองค์กร",
					detail: "เพิ่มการตั้งค่าข้อมูลองค์กร",
					icon: "$association",
				},
			],
			tabContents: [Tab11Content],
		};
	},
	watch: {
		tab() {
			this.$router.push({ path: this.$route.path }).catch(() => {});
		},
	},
};
</script>
<style scoped lang="scss">
.tab-color {
	background-color: #0d6efd;
}
.v-tab {
	margin: 0 2px;
	&:not(.v-tab--active) {
		background: #f1f1f5;
	}
}

.form-container {
	margin: 0 auto;
}
@media (min-width: 576px) {
	.form-container {
		max-width: 540px;
	}
}
@media (min-width: 768px) {
	.form-container {
		max-width: 720px;
	}
}
@media (min-width: 992px) {
	.form-container {
		max-width: 960px;
	}
}
@media (min-width: 1200px) {
	.form-container {
		max-width: 1140px;
	}
}
.app-rounded-top {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
</style>
