<template>
  <v-dialog :value="visible" max-width="1000px" @input="closeModal">
    <v-card>
      <v-card-title>
        <span class="header text-primary">{{
          $t("player.injury.add_injury")
        }}</span>
      </v-card-title>

      <div class="card-body">
        <v-form ref="form">
          <section class="pb-3 border-bottom">
            <div class="row">
              <div class="col-12 col-md-4">
                <label>{{ $t("player.injury.injury_name_th") }}</label>
                <v-text-field
                  dense
                  outlined
                  required
                  :rules="requiredRules"
                  v-model="form.account_injury_name_th"
                  :readonly="!isEditable"
                  :placeholder="$t('player.injury.injury_name_th')"
                ></v-text-field>
              </div>
              <div class="col-12 col-md-4">
                <label>{{ $t("player.injury.injury_name_en") }}</label>
                <v-text-field
                  dense
                  outlined
                  :rules="requiredRules"
                  v-model="form.account_injury_name_en"
                  :readonly="!isEditable"
                  :placeholder="$t('player.injury.injury_name_en')"
                ></v-text-field>
              </div>
              <div class="col-12 col-md-4">
                <label>{{ $t("player.injury.group_injury") }}</label>
                <v-select
                  dense
                  outlined
                  :rules="requiredRules"
                  :items="injuryTypes"
                  v-model="form.global_config_injury_types"
                  :readonly="!isEditable"
                  :item-text="'global_config_injury_type_name_' + language"
                  item-value="id"
                  :placeholder="$t('player.injury.group_injury')"
                >
                </v-select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-4 col-lg-3">
                <label>{{ $t("player.injury.level_injury") }}</label>
                <v-select
                  dense
                  outlined
                  :rules="requiredRules"
                  :items="level"
                  item-value="value"
                  :item-text="'text_' + language"
                  v-model="form.account_injury_level"
                  :readonly="!isEditable"
                  :placeholder="$t('player.injury.level_injury')"
                ></v-select>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <label>{{ $t("player.injury.get_injury") }}</label>
                <v-select
                  dense
                  outlined
                  :rules="requiredRules"
                  v-model="form.account_injury_cause"
                  :readonly="!isEditable"
                  :items="causes"
                  :item-text="'text_' + language"
                  item-value="value"
                  :placeholder="$t('player.injury.get_injury')"
                ></v-select>
              </div>
              <div class="col-12 col-md-4 col-lg-3">
                <label>{{ $t("player.injury.date_injury") }}</label>
                <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      dense
                      :rules="requiredRules"
                      outlined
                      :value="showDate(form.account_injury_date)"
                      readonly
                      :placeholder="$t('normal.date_format')"
                      append-icon="fa-calendar"
                    ></v-text-field>
                  </template>
                  <v-date-picker

                    v-model="form.account_injury_date"
                    :readonly="!isEditable"
                    ref="picker"
                    min="1950-01-01"
                    :max="maxDate"
                    locale="us-us"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="col-12 col-lg-3">
                <v-radio-group
                  row
                  v-model="form.account_injury_status"
                  :readonly="!isEditable"
                  :rules="requiredRules"
                >
                  <v-radio
                    on-icon="fa-circle"
                    :value="1"
                    :label="$t('player.injury.first_injury')"
                  ></v-radio>
                  <v-radio
                    on-icon="fa-circle"
                    :value="2"
                    :label="$t('player.injury.old_injury')"
                  ></v-radio>
                </v-radio-group>
              </div>
            </div>
          </section>
          <section class="py-3">
            <div class="row">
              <div class="col-12 col-md- col-lg-6">
                <label>{{ $t("player.injury.location_th") }}</label>
                <v-text-field
                  dense
                  outlined
                  v-model="form.account_injury_medical"
                  :readonly="!isEditable"
                  :placeholder="$t('player.injury.location_th')"
                ></v-text-field>
              </div>
              <div class="col-12 col-md- col-lg-6">
                <label>{{ $t("player.injury.location_en") }}</label>
                <v-text-field
                  dense
                  outlined
                  v-model="form.account_injury_medical_en"
                  :readonly="!isEditable"
                  :placeholder="$t('player.injury.location_en')"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md- col-lg-6">
                <label>{{ $t("player.injury.doctor_th") }}</label>
                <v-text-field
                  dense
                  outlined
                  v-model="form.account_injury_doctor_name"
                  :readonly="!isEditable"
                  :placeholder="$t('player.injury.doctor_th')"
                ></v-text-field>
              </div>
              <div class="col-12 col-md- col-lg-6">
                <label>{{ $t("player.injury.doctor_en") }}</label>
                <v-text-field
                  dense
                  outlined
                  v-model="form.account_injury_doctor_name_en"
                  :readonly="!isEditable"
                  :placeholder="$t('player.injury.doctor_en')"
                ></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="form-group">
                  <label>{{ $t("player.injury.note_th") }}</label>
                  <v-textarea
                    dense
                    outlined
                    v-model="form.account_injury_remark"
                    :readonly="!isEditable"
                    rows="5"
                  ></v-textarea>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="form-group">
                  <label>{{ $t("player.injury.note_en") }}</label>
                  <v-textarea
                    dense
                    outlined
                    v-model="form.account_injury_remark_en"
                    :readonly="!isEditable"
                    rows="5"
                  ></v-textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-12">
                <div class="row">
                  <div class="col-3">
                    <label>{{ $t("player.injury.date_admit") }}</label>
                    <v-menu
                      ref="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          :value="
                            showDate(form.account_injury_admitted_begin_at)
                          "
                          readonly
                          :placeholder="$t('normal.date_format')"
                          append-icon="fa-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        min="1950-01-01"
                        v-model="form.account_injury_admitted_begin_at"
                        locale="us-us"
                        :readonly="!isEditable"
                        :max="maxDate"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-3">
                    <label>{{ $t("player.injury.date_cured") }}</label>
                    <v-menu
                      ref="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          readonly
                          :value="showDate(form.account_injury_admitted_end_at)"
                          :placeholder="$t('normal.date_format')"
                          append-icon="fa-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        min="1950-01-01"
                        v-model="form.account_injury_admitted_end_at"
                        :readonly="!isEditable"
                        locale="us-us"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>{{ $t("player.injury.recovery_period") }}</label>
                      <div class="row">
                        <div class="col-6">
                          <v-text-field
                            v-model="form.account_injury_recovery_time"
                            :readonly="!isEditable"
                            :rules="requiredRules"
                            dense
                            outlined
                            type="number"
                          ></v-text-field>
                        </div>
                        <div class="col-6">
                          <v-select
                            dense
                            outlined
                            :item-text="'text_' + language"
                            item-value="value"
                            :rules="requiredRules"
                            v-model="form.account_injury_recovery_time_unit"
                            :readonly="!isEditable"
                            :items="periods"
                          ></v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <label>{{ $t("player.injury.cost") }}</label>
                <v-text-field
                  dense
                  outlined
                  v-model="form.account_injury_cost"
                  :readonly="!isEditable"
                  :suffix="$t('player.injury.bath')"
                ></v-text-field>
              </div>
              <div
                class="
                  col-12 col-md-6
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <v-checkbox
                  v-model="form.account_injury_present"
                  :readonly="!isEditable"
                  :label="$t('player.injury.currently_injuries')"
                ></v-checkbox>
                <div>
                  <v-btn
                    color="primary"
                    @click="submit"
                    :disabled="!isEditable"
                    class="mr-2"
                    >{{ $t("normal.save") }}</v-btn
                  >
                  <!-- <v-btn color="red" @click="closeModal" dark><v-icon>fa-trash</v-icon></v-btn> -->
                </div>
              </div>
            </div>
          </section>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { inJuryTypesState, getAllInjuryTypes } from "../../store/injury_type";
import { mapState } from "vuex";
import format from "date-fns/format";
import { languageService } from "../../helpers/language";

const initialForm = {
  account_injury_name_th: null,
  account_injury_name_en: null,
  account_injury_date: null,
  account_injury_level: null,
  account_injury_cause: null,
  account_injury_status: 1,
  account_injury_present: false,
  account_injury_medical: null,
  account_injury_medical_en: null,
  account_injury_doctor_name: null,
  account_injury_doctor_name_en: null,
  account_injury_remark: null,
  account_injury_remark_en: null,
  account_injury_recovery_time: null,
  account_injury_recovery_time_unit: null,
  global_config_injury_types: null,
  account_injury_admitted_begin_at: null,
  account_injury_admitted_end_at: null,
  account_injury_cost: 0,
};

export default {
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    injury: null,
  },
  methods: {
    closeModal() {
      this.$emit("update:visible", false);
    },
    submit() {
      const result = this.$refs.form.validate();
      console.log("results", result);
      if(!result) return
      this.$emit("commit", this.$data.form);
      this.closeModal();
    },
    showDate(date) {
      if (!date) return;
      return format(new Date(date), "dd/MM/yyyy");
    },
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  mounted() {
    this.$store.dispatch(getAllInjuryTypes);
  },
  computed: {
    requiredRules() {
      const rules = [];
      rules.push((v) => !!v || `required`);
      return rules;
    },
    ...mapState({
      injuryTypes: (state) => state.injuryType[inJuryTypesState],
    }),
    isEditable() {
      if (this.isPlayerRoute) {
        return this.$store.state.permission?.permissions?.athlete_edit;
      }
      return this.$store.state.permission?.permissions?.staff_edit;
    },
    isPlayerRoute() {
      return this.$route.path.includes("player");
    },
    maxDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
  },
  watch: {
    visible() {
      if (this.injury) {
        this.$data.form = this.injury;
      } else {
        this.$data.form = JSON.parse(JSON.stringify(initialForm));
      }
    },
  },
  data() {
    return {
      language: "th",
      periods: [
        { text_en: "Days", text_th: "วัน", value: "DAYS" },
        { text_en: "Weeks", text_th: "สัปดาห์", value: "WEEKS" },
        { text_en: "Months", text_th: "เดือน", value: "MONTHS" },
        { text_en: "Years", text_th: "ปี", value: "YEARS" },
        { text_en: "Matches", text_th: "แมตช์", value: "MATCHES" },
        { text_en: "Seasons", text_th: "ฤดูกาล", value: "SEASONS" },
      ],
      level: [
        { value: "MINOR", text_en: "Minor", text_th: "เล็กน้อย" },
        { value: "MODERATE", text_en: "Moderate", text_th: "บาดเจ็บปานกลาง" },
        { value: "SERIOUSLY", text_en: "Seriously", text_th: "บาดเจ็บรุนแรง" },
      ],
      causes: [
        { value: "COMPETITION", text_en: "Competition", text_th: "การแข่งขัน" },
        { value: "TRAINING", text_en: "Training", text_th: "การซ้อม" },
        { value: "OTHERS", text_en: "Others", text_th: "อื่นๆ" },
      ],
      form: {},
    };
  },
};
</script>

<style></style>
