<template>
  <v-card class="match-result-form" data-app>
    <div class="p-1">
      <div class="master-data-title">รายชื่อนักกีฬา, บุคลากร</div>

      <v-data-table
        :headers="headersAdmin"
        :items="this.teamData.sub_team_information"
        class="elevation-1 row-pointer"
        hide-default-footer
        @page-count="pageCount = $event"
        @click:row="onRowClick"
      >
        <template v-slot:[`item.count_players`]="{ item }">
          {{ item.count_players }} คน
        </template>
        <template v-slot:[`item.count_staffs`]="{ item }">
          {{ item.count_staffs }} คน
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="pa-2" small v-bind="attrs" v-on="on"
                >fa-ellipsis-v</v-icon
              >
            </template>
            <v-list>
              <v-list-item v-for="(action, index) in actions" :key="index">
                <v-btn text @click="onClick(action.key, item)">
                  <v-icon small class="mr-2">{{ action.icon }}</v-icon
                  >{{ action.text }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ListRegister",

  components: {},
  methods: {
    ...mapActions("sub_competition", ["getSubCompetitions"]),
    onRowClick(row) {
      this.competitionId = this.$route.params.cid;
      this.subCompetitionId = this.$route.params.sid;
      this.$router.push({
        name: "sub-competition-list",
        params: {
          cid: this.competitionId,
          sid: this.subCompetitionId,
          tab: "teams",
          list: row.id,
        },
      });
    },
  },
  computed: {
    ...mapState("sub_competition", ["sub_competitions", "teamData"]),

    competitionData() {
      return this.sub_competitions.data;
    },
    actions() {
      return [
        { text: this.$t("normal.edit_list"), icon: "fa-pen", key: "edit" },
        // {
        //   text: this.$t("normal.delete_list"),
        //   icon: "fa-trash",
        //   key: "delete",
        // },
      ];
    },

    headersAdmin() {
      return [
        {
          text: this.$t("competition.type"),
          value:
            "setting_sub_competition_id.setting_sub_competition_type_id.setting_sub_competition_type_name_" +
            this.$t("short_language"),
          sortable: false,
        },
        {
          text: this.$t("competition.gender_type"),
          value:
            "setting_sub_competition_id.setting_sub_competition_sub_type_id.setting_sub_competition_sub_type_name_" +
            this.$t("short_language"),
          sortable: false,
        },
        {
          text: this.$t("competition.fee"),
          value: "setting_sub_competition_id.setting_sub_competition_fee",
          sortable: false,
        },
        {
          text: "นักกีฬาที่ลงทะเบียน",
          value: "count_players",
          sortable: false,
        },
        {
          text: "บุคลากร",
          value: "count_staffs",
          sortable: false,
        },
        { text: "จัดการ", value: "action", sortable: false },
      ];
    },
  },
};
</script>
