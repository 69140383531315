<template>
  <div class="row no-gutters p-md-3">
    <div class="col-md-4 col-xl-3 col-12">
      <div class="p-2">
        <div class="m-md-2">
          <div class="image-upload" @change="onChangeImage">
            <label for="file-input">
              <v-icon class="uploadImage">$imageIcon</v-icon>
            </label>

            <input
              class="selectFile"
              id="file-input"
              type="file"
              accept="image/*"
            />
          </div>
          <img
            v-if="item.imageUrl"
            :src="item.imageUrl"
            class="
              profileImage
              d-flex
              justify-content-center
              center
              p-1
              rounded-pill
            "
          />
          <img
            v-else-if="this.$data.dataImage"
            :src="this.$data.dataImage"
            class="
              profileImage
              d-flex
              justify-content-center
              center
              p-1
              rounded-pill
            "
          />
          <img
            v-else
            class="
              profileImage
              d-flex
              justify-content-center
              center
              p-1
              rounded-pill
            "
            src="@/assets/images/NoImage.png"
            alt="Alternative Text"
          />

          <div class="mb-2">
            <h4 class="text-center">
              <div v-if="language==='th'">{{ teamForm.setting_team_information_name_th }}</div>
              <div v-else>{{ teamForm.setting_team_information_name_en }}</div>

            </h4>
          </div>
        </div>

        <div
          class="border-top border-bottom py-10 px-3"
          style="overflow-x: auto"
        >
          <div class="text-center h4 my-3">
            <!-- ช่องทางการติดต่อ -->
            {{ $t("contact_information.contact") }}
            <v-icon
              @click="onClickTeamSocialContact"
              style="font-size: 1rem"
              class="ml-2 cursor-pointer"
              >fa-edit</v-icon
            >
          </div>
          <div class="row">
            <!-- <div class="col-4">โทรศัพท์</div> -->
            <div class="col-4">{{ $t("contact_information.phone") }}</div>
            <div class="col-8">
              {{
                teamSocialContactForm.setting_team_social_contact_phone || "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">E-mail</div>
            <div class="col-8">
              {{
                teamSocialContactForm.setting_team_social_contact_email || "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Facebook</div>
            <div class="col-8">
              {{
                teamSocialContactForm.setting_team_social_contact_facebook ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">LINE id</div>
            <div class="col-8">
              {{
                teamSocialContactForm.setting_team_social_contact_line || "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Instagram</div>
            <div class="col-8">
              {{
                teamSocialContactForm.setting_team_social_contact_instagram ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Youtube</div>
            <div class="col-8">
              {{
                teamSocialContactForm.setting_team_social_contact_youtube || "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">twitter</div>
            <div class="col-8">
              {{
                teamSocialContactForm.setting_team_social_contact_twitter || "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Website</div>
            <div class="col-8">
              {{
                teamSocialContactForm.setting_team_social_contact_website || "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Fax</div>
            <div class="col-8">
              {{ teamSocialContactForm.setting_team_social_contact_fax || "-" }}
            </div>
          </div>
        </div>
        <div class="border-bottom py-3">
          <div class="text-center my-3 font-weight-bolder">
            {{ $t("curriculumn.status") }}
          </div>
          <div class="row">
            <div class="col-6">
              <checkbox
                :checked="teamForm.active"
                :disabled="!isEditable"
                v-model="teamForm.active"
              >
                <span v-if="teamForm.active" class="input-box" slot="input-box">
                  <v-icon>fa-check</v-icon>
                </span>
                <span v-if="teamForm.active">{{
                  $t("curriculumn.public")
                }}</span>
                <span v-else>{{ $t("curriculumn.no_public") }}</span>
              </checkbox>
            </div>
            <div class="col-6">
              <div>
                <label> {{ $t("curriculumn.create_date") }} </label>
              </div>
              <div>
                <label class="text-primary">
                  {{ teamForm.createdAt }}
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div>
                <label> {{ $t("curriculumn.create_by") }} </label>
              </div>
              <div>
                <label style="word-break: break-all">
                  {{ teamForm.updatedBy }}
                </label>
              </div>
            </div>
            <div class="col-6">
              <div>
                <label> {{ $t("curriculumn.update_date") }} </label>
              </div>
              <div>
                <label class="text-primary">
                  {{ teamForm.updatedAt }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8 col-12 tab1-border-left">
      <!-- ข้อมูลทีม -->
      <v-form ref="formRef">
        <div>
          <div class="border-bottom p-2">
            <div>
              <!-- <h4 class="text-primary">ข้อมูลทีม</h4> -->
              <h4 class="text-primary">
                {{ $t("team.tab.team_information") }}
              </h4>
            </div>
            <div>
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("team.team_type")
                    }}</label>
                    <v-radio-group
                      :rules="ruleForm"
                      v-model="teamForm.global_config_local_types"
                      :readonly="!isEditable"
                      active-class="active-radio"
                      row
                      dense
                    >
                      <v-radio
                        :label="$t('team.team_info.thai_national_team')"
                        value="1"
                      ></v-radio>
                      <v-radio
                        :label="$t('team.team_info.international_team')"
                        value="2"
                      ></v-radio>
                      <v-radio
                        :label="$t('team.team_info.thai_club')"
                        value="3"
                      ></v-radio>
                      <v-radio
                        :label="$t('team.team_info.national_club')"
                        value="4"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("team.tab.abbreviation")
                    }}</label>
                    <v-text-field
                      v-model="teamForm.setting_team_information_abbreviation"
                      :readonly="!isEditable"
                      dense
                      outlined
                      :placeholder="$t('team.tab.abbreviation')"
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("team.team_name_th")
                    }}</label>
                    <v-text-field
                      type="text"
                      :rules="ruleForm"
                      v-model="teamForm.setting_team_information_name_th"
                      :readonly="!isEditable"
                      dense
                      outlined
                      hide-details
                      :placeholder="$t('team.team_name_th')"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("team.team_name_en")
                    }}</label>
                    <v-text-field
                      type="text"
                      :rules="ruleForm"
                      v-model="teamForm.setting_team_information_name_en"
                      :readonly="!isEditable"
                      dense
                      outlined
                      hide-details
                      :placeholder="$t('team.team_name_en')"
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      `${$t("team.team_nickname")} (${$t("country_th")})`
                    }}</label>
                    <v-text-field
                      v-model="teamForm.setting_team_information_nickname"
                      :readonly="!isEditable"
                      dense
                      outlined
                      hide-details
                      :placeholder="$t('team.team_nickname')"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      `${$t("team.team_nickname")} (${$t("country_en")})`
                    }}</label>
                    <v-text-field
                      v-model="teamForm.setting_team_information_nickname_en"
                      :readonly="!isEditable"
                      dense
                      outlined
                      hide-details
                      :placeholder="$t('team.team_nickname')"
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("team.year_established")
                    }}</label>
                    <v-text-field
                      v-model="teamForm.setting_team_information_established"
                      :readonly="!isEditable"
                      dense
                      outlined
                      hide-details
                      :placeholder="$t('team.year_established')"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ที่อยู่ -->
          <div class="p-2 border-bottom">
            <div>
              <h4 class="text-primary">{{ $t("address.address_no") }}</h4>
            </div>
            <div>
              <form class="needs-validation" novalidate="">
                <div class="row">
                  <div class="col-sm-12 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        `${$t("address.address_no")} (${$t("country_th")})`
                      }}</label>
                      <v-text-field
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('address.address_no')"
                        v-model="
                          teamForm.setting_team_regions
                            .setting_team_region_address_detail
                        "
                        :readonly="!isEditable"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        `${$t("address.address_no")} (${$t("country_en")})`
                      }}</label>
                      <v-text-field
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('address.address_no')"
                        v-model="
                          teamForm.setting_team_regions
                            .setting_team_region_address_detail_en
                        "
                        :readonly="!isEditable"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("address.country")
                      }}</label>
                      <v-autocomplete
                        v-model="
                          teamForm.setting_team_regions
                            .setting_team_region_country_id
                        "
                        :readonly="!isEditable"
                        :placeholder="$t('normal.please_select')"
                        :items="countries"
                        :item-text="'country_native1_name_' + language"
                        item-value="country_id"
                        :loading="isCountriesFetching"
                        dense
                        outlined
                        hide-details
                      ></v-autocomplete>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("filter_player_staff.city")
                      }}</label>
                      <v-autocomplete
                        v-model="
                          teamForm.setting_team_regions
                            .setting_team_region_province_id
                        "
                        :readonly="!isEditable"
                        :items="provinces"
                        :item-text="'province_name_' + language"
                        item-value="id"
                        :loading="isProvincesFetching"
                        :disabled="
                          teamForm.setting_team_regions
                            .setting_team_region_country_id !== '188'
                        "
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('normal.please_select')"
                      ></v-autocomplete>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-3 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("address.district")
                      }}</label>
                      <v-autocomplete
                        v-model="
                          teamForm.setting_team_regions
                            .setting_team_region_district_id
                        "
                        :readonly="!isEditable"
                        :items="districts"
                        item-value="id"
                        :item-text="'district_name_' + language"
                        :loading="isDistrictFetching"
                        :disabled="
                          !teamForm.setting_team_regions
                            .setting_team_region_province_id
                        "
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('normal.please_select')"
                      ></v-autocomplete>
                    </div>
                  </div>
                  <div class="col-sm-3 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("address.sub_district")
                      }}</label>
                      <v-autocomplete
                        v-model="
                          teamForm.setting_team_regions
                            .setting_team_region_subdistrict_id
                        "
                        :readonly="!isEditable"
                        :disabled="
                          !teamForm.setting_team_regions
                            .setting_team_region_district_id
                        "
                        :items="subdistricts"
                        :loading="isSubdistrictFetching"
                        :item-text="'sub_district_name_' + language"
                        item-value="id"
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('normal.please_select')"
                      ></v-autocomplete>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("address.zip_code")
                      }}</label>
                      <v-text-field
                        :disabled="
                          !teamForm.setting_team_regions
                            .setting_team_region_zipcode
                        "
                        :readonly="!isEditable"
                        v-model="
                          teamForm.setting_team_regions
                            .setting_team_region_zipcode
                        "
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('address.zip_code')"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- ข้อมูลเสื้อประจำทีม -->
          <div class="p-2 border-bottom">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
              "
            >
              <div>
                <h4 class="text-primary">{{ $t("team.team_is_uniforms") }}</h4>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-evenly;
                  align-items: center;
                "
              >
                <span class="mr-2 font-medium-2">
                  {{ $t("normal.year") }}
                </span>
                <v-select
                  v-model="uniformYear"
                  :items="years"
                  item-text="label"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                ></v-select>
                <v-btn
                  :disabled="!isEditable"
                  @click="openShirtModal"
                  depressed
                  class="float-right"
                  style="
                    border: 1px solid;
                    border-color: #d8d6df;
                    margin: 0px 0px 0px 5px;
                    height: 45px;
                  "
                >
                  <v-icon style="color: #726c84">fas fa-edit</v-icon></v-btn
                >
              </div>
            </div>
            <div>
              <div class="row">
                <div
                  class="col-md-4 col-12"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <label class="label font-medium-2">{{
                    $t("team.home_team_uniform")
                  }}</label>
                  <div class="position-relative">
                    <img
                      :src="
                        homeShirtImageUrl
                          ? homeShirtImageUrl
                          : 'https://lightmax.co.th/extensions/themes/gae_lightmax301/staticfiles/images/default/490x490.gif'
                      "
                      width="100%"
                      height="auto"
                      alt="Alternative Text"
                      class="d-flex justify-content-center"
                      style="border-radius: 10px"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-12"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <label class="label font-medium-2">{{
                    $t("team.away_team_uniform")
                  }}</label>
                  <div class="position-relative">
                    <img
                      :src="
                        awayShirtImageUrl
                          ? awayShirtImageUrl
                          : 'https://lightmax.co.th/extensions/themes/gae_lightmax301/staticfiles/images/default/490x490.gif'
                      "
                      width="100%"
                      height="auto"
                      alt="Alternative Text"
                      class="d-flex justify-content-center"
                      style="border-radius: 10px"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-12"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  "
                >
                  <label class="label font-medium-2">{{
                    $t("team.spare_team_uniform")
                  }}</label>
                  <div class="position-relative">
                    <img
                      :src="
                        spareShirtImageUrl
                          ? spareShirtImageUrl
                          : 'https://lightmax.co.th/extensions/themes/gae_lightmax301/staticfiles/images/default/490x490.gif'
                      "
                      width="100%"
                      height="auto"
                      alt="Alternative Text"
                      class="d-flex justify-content-center"
                      style="border-radius: 10px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ประวัติสำหรับแสดงบนเว็ปไซต์ -->
          <div class="p-2">
            <div>
              <h4 class="text-primary">{{ $t("team.team_history") }}</h4>
            </div>
            <div>
              <form class="needs-validation" novalidate="">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">
                        {{ $t("player.athlete_info.history_th") }}
                      </label>
                      <vue-editor
                        v-model="teamForm.setting_team_information_history_th"
                        :editorToolbar="customToolbar"
                        :disabled="!isEditable"
                      ></vue-editor>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">
                        {{ $t("player.athlete_info.history_en") }}
                      </label>
                      <vue-editor
                        v-model="teamForm.setting_team_information_history_en"
                        :editorToolbar="customToolbar"
                        :disabled="!isEditable"
                      ></vue-editor>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </v-form>
      <v-btn
        v-on:click="onClickNextTab()"
        elevation="2"
        class="btn-primary float-right mb-5"
      >
        {{ $t("normal.next") }}
        <v-icon right> fa-arrow-right </v-icon>
      </v-btn>
    </div>
    <TeamSocialContactModal
      :socialContact="teamSocialContactForm"
      @commit="onCommitSocialContact"
      :dialog.sync="showTeamSocialContactModal"
    />
    <TeamShirtModal
      :visible="dialog1"
      :teamId="teamId"
      @closeModal="closeModal1"
      @commit="handleSubmit1"
    ></TeamShirtModal>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { appAxios } from "../../helpers/app-axios";
import {
  countriesState,
  getCountries,
} from "../../store/global_config_country";
import {
  provincesState,
  getProvinces,
} from "../../store/global_config_province";
import { getDistrict } from "../../store/global_config_district";
import { getSubdistrict } from "../../store/global_config_subdistrict";
import { mapState } from "vuex";
import TeamSocialContactModal from "./TeamSocialContactModal.vue";
import TeamShirtModal from "./TeamShirtModal.vue";
import format from "date-fns/format";
import { languageService } from "../../helpers/language";

export default {
  components: {
    VueEditor,
    TeamSocialContactModal,
    TeamShirtModal,
  },
  name: "team-tab1",
  props: {
    tab: String,
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  beforeDestroy() {
    console.log("destroy team");
    this.subscription.unsubscribe();
  },
  mounted() {
    if (!this.isAddRoute) {
      const teamId = this.$route.params.id;
      this.$data.teamId = teamId;
      appAxios.get(`team/${teamId}`).then((res) => {
        const data = res.data.data.team;
        this.parseteamForm(data);
      });
      this.getShirtImage(teamId, this.currentYear);
      appAxios.get(`team/${teamId}/social-contact`).then((res) => {
        const data = res.data?.data?.social_contacts;
        this.parseteamSocialContact(data);
      });

      appAxios.get(`team/${teamId}/medias/profile`).then((res) => {
        const data = res.data?.data;
        if (!data) return;
        if (data?.profile?.setting_team_media_url !== undefined) {
          this.$data.dataImage = data.profile.setting_team_media_url;
        }
      });
    }
    this.$store.dispatch(getCountries);
    this.$store.dispatch(getProvinces);
    // const address = this.address;
    if (this.teamForm.setting_team_regions.setting_team_region_province_id) {
      this.$store.dispatch(
        getDistrict,
        this.teamForm.setting_team_regions.setting_team_region_province_id
      );
    }
    if (this.teamForm.setting_team_regions.setting_team_region_district_id) {
      this.$store.dispatch(
        getSubdistrict,
        this.teamForm.setting_team_regions.setting_team_region_district_id
      );
    }
  },
  updated() {
    console.log("Tab1 :: ", this.countries);
  },
  data() {
    return {
      // showRelationModal: false,
      dataImage: null,
      item: {
        image: null,
        preview: null,
        imageUrl: null,
      },
      customToolbar: [
        [{ size: [] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link"],
      ],
      dialog: false,
      dialog1: false,
      showTeamSocialContactModal: false,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 200,
        addRemoveLinks: true,
      },
      contact: [
        { label: "โทรศัพท์", data: "080-12345678" },
        { label: "E-mail", data: "test@test.com" },
        { label: "Facebook", data: "www.facebook.com" },
        { label: "Line id", data: "test_test" },
        { label: "Instagram", data: "www.instagram.com" },
        { label: "Youtube", data: "www.youtube.com" },
        { label: "twitter", data: "www.twitter.com" },
        { label: "Website", data: "-" },
        { label: "Fax", data: "-" },
      ],
      type: ["ทีมชาติไทย", "ทีมสโมสรไทย", "ทีมชาติคู่แข่ง", "สโมสรคู่แข่ง"],
      country: ["ไทย", "ญี่ปุ่น", "จีน"],
      contentThai: "<h1>Some initial content</h1>",
      teamId: null,
      setting_team_social_contacts: null,
      //
      shirtData: {},
      uniformYear: new Date().getFullYear(),
      isSubmitModal: false,
      //
      teamForm: {
        global_config_local_types: null,
        setting_team_information_abbreviation: null,
        setting_team_information_name_th: null,
        setting_team_information_name_en: null,
        setting_team_information_nickname: null,
        setting_team_information_nickname_en: null,
        setting_team_information_established: null,
        setting_team_information_history_th: null,
        setting_team_information_history_en: null,
        setting_team_regions: {
          setting_team_region_country_id: "188",
          setting_team_region_address_detail: null,
          setting_team_region_province_id: null,
          setting_team_region_district_id: null,
          setting_team_region_subdistrict_id: null,
          setting_team_region_zipcode: null,
        },
        active: false,
      },
      teamSocialContactForm: {
        // id: null,
        setting_team_social_contact_phone: null,
        setting_team_social_contact_email: null,
        setting_team_social_contact_facebook: null,
        setting_team_social_contact_line: null,
        setting_team_social_contact_instagram: null,
        setting_team_social_contact_twitter: null,
        setting_team_social_contact_youtube: null,
        setting_team_social_contact_website: null,
        setting_team_social_contact_fax: null,
      },
      language: "th",
    };
  },
  computed: {
    ...mapState({
      isCountriesFetching: (state) => state.globalConfigCountry.isFetching,
      countries: (state) => state.globalConfigCountry[countriesState],
      isProvincesFetching: (state) => state.globalConfigProvince.isFetching,
      provinces: (state) => state.globalConfigProvince[provincesState],
      isDistrictFetching(state) {
        state.globalConfigDistrict.isFetching; // to let vue know when should trigger state change
        return state.globalConfigDistrict[
          this.teamForm.setting_team_regions.setting_team_region_province_id
        ]?.isFetching;
      },
      districts(state) {
        state.globalConfigDistrict.isFetching;
        return state.globalConfigDistrict[
          this.teamForm.setting_team_regions.setting_team_region_province_id
          // this.address.province_id
        ]?.data;
      },
      isSubdistrictFetching(state) {
        state.globalConfigSubdistrict.isFetching;
        return state.globalConfigSubdistrict[
          this.teamForm.setting_team_regions.setting_team_region_district_id
        ]?.isFetching;
      },
      subdistricts(state) {
        state.globalConfigSubdistrict.isFetching;
        return state.globalConfigSubdistrict[
          this.teamForm.setting_team_regions.setting_team_region_district_id
        ]?.data;
      },
      isEditable: (state) => state.permission.permissions.team_edit,
    }),
    currentYear() {
      return new Date().getFullYear();
    },
    years() {
      const year = new Date().getFullYear();
      return Array.from(Array(year - 1995 + 1).keys()).map((ele) => {
        const value = 1995 + ele;
        return {
          value,
          label: `${value} ${
            value === year
              ? this.language === "th"
                ? "(ปัจจุบัน)"
                : "(present)"
              : ""
          }`,
        };
      });
    },
    ruleForm() {
      const rules = [];
      rules.push((v) => !!v || `required`);
      return rules;
    },
    homeShirtImageUrl() {
      if (!this.$data.shirtData[this.uniformYear]?.HOME) return null;
      if (!(this.$data.shirtData[this.uniformYear]?.HOME instanceof File))
        return this.$data.shirtData[this.uniformYear]?.HOME;
      return URL.createObjectURL(this.$data.shirtData[this.uniformYear]?.HOME);
    },
    awayShirtImageUrl() {
      if (!this.$data.shirtData[this.uniformYear]?.AWAY) return null;
      if (!(this.$data.shirtData[this.uniformYear]?.AWAY instanceof File))
        return this.$data.shirtData[this.uniformYear]?.AWAY;
      return URL.createObjectURL(this.$data.shirtData[this.uniformYear]?.AWAY);
    },
    spareShirtImageUrl() {
      if (!this.$data.shirtData[this.uniformYear]?.SPARE) return null;
      if (!(this.$data.shirtData[this.uniformYear]?.SPARE instanceof File))
        return this.$data.shirtData[this.uniformYear]?.SPARE;
      return URL.createObjectURL(this.$data.shirtData[this.uniformYear]?.SPARE);
    },
    isAddRoute() {
      return this.$route.path.includes("add");
    },
  },
  methods: {
    async onClickNextTab() {
      const teamId = await this.save(false);
      if (!teamId) return;
      return this.$router
        .push({
          path: `/team/edit/${teamId}`,
          query: { tab: 1 },
        })
        .catch(() => {});
    },
    async save(isRedirect = true) {
      if (!this.isEditable) return;
      const result = this.$refs.formRef.validate();
      if (!result) return;
      // console.log('test')
      // return true
      const teamId = await this.saveTeam();
      await this.saveTeamSocialContact(teamId);

      if (this.$data.item.image !== null) {
        await this.saveTeamProfile(teamId);
      }
      await this.savePublicTeam(teamId);
      await this.saveShirtImages(teamId);
      if (this.isAddRoute && isRedirect) {
        this.$data.teamForm.teamId = teamId;
        this.$router
          .push({
            path: `/team/edit/${teamId}`,
            query: { tab: 0 },
          })
          .catch(() => {});
        return;
      }
      return teamId;
    },
    async saveShirtImages(teamId) {
      const years = Object.keys(this.$data.shirtData);
      const shouldSaveHomeShirt = years.reduce((acc, curr) => {
        if (this.$data.shirtData[curr].HOME instanceof File) {
          acc.push({ year: curr, file: this.$data.shirtData[curr].HOME });
        }
        return acc;
      }, []);
      const savedHomeShirt = await Promise.all(
        shouldSaveHomeShirt.map(async (ele) => {
          return await this.saveShirtImage(teamId, ele.file, "home", ele.year);
        })
      );
      const shouldSaveAwayShirt = years.reduce((acc, curr) => {
        if (this.$data.shirtData[curr].AWAY instanceof File) {
          acc.push({ year: curr, file: this.$data.shirtData[curr].AWAY });
        }
        return acc;
      }, []);
      const savedAwayShirt = await Promise.all(
        shouldSaveAwayShirt.map(async (ele) => {
          return await this.saveShirtImage(teamId, ele.file, "away", ele.year);
        })
      );
      const shouldSaveSpareShirt = years.reduce((acc, curr) => {
        if (this.$data.shirtData[curr].SPARE instanceof File) {
          acc.push({ year: curr, file: this.$data.shirtData[curr].SPARE });
        }
        return acc;
      }, []);
      const savedSpareShirt = await Promise.all(
        shouldSaveSpareShirt.map(async (ele) => {
          return await this.saveShirtImage(teamId, ele.file, "spare", ele.year);
        })
      );
      return [...savedHomeShirt, ...savedAwayShirt, ...savedSpareShirt];
    },
    async savePublicTeam(teamId) {
      const publics = {
        setting_team_published: this.$data.teamForm.active,
      };
      return appAxios
        .post(`team/${teamId}/published/update`, publics)
        .then((res) => {
          res;
        });
    },
    async saveShirtImage(teamId, file, type, year) {
      const formData = new FormData();
      formData.append("file", file);
      return await appAxios
        .post(`team/${teamId}/uniforms/${type}/${year}`, formData)
        .then((res) => res);
    },
    async saveTeamProfile(teamId) {
      let formData = new FormData();
      formData.append("file", this.$data.item.image);
      appAxios
        .post(`team/${teamId}/medias/profile`, formData)
        .then((res) => res);
    },
    parseteamForm(data) {
      // console.log("data", data.setting_team_informations);
      this.$data.teamForm = {
        teamId: data.id,
        global_config_local_types: data.global_config_local_types,
        // setting_team_informations: {
        //   id: data.setting_team_informations.id,
        setting_team_information_abbreviation:
          data.setting_team_informations.setting_team_information_abbreviation,
        setting_team_information_name_th:
          data.setting_team_informations.setting_team_information_name_th,
        setting_team_information_name_en:
          data.setting_team_informations.setting_team_information_name_en,
        setting_team_information_nickname:
          data.setting_team_informations.setting_team_information_nickname,
        setting_team_information_nickname_en:
					data.setting_team_informations.setting_team_information_nickname_en,
        setting_team_information_established:
          data.setting_team_informations.setting_team_information_established,
        setting_team_information_history_th:
          data.setting_team_informations.setting_team_information_history_th,
        setting_team_information_history_en:
          data.setting_team_informations.setting_team_information_history_en,
        // setting_team_information_remark: data.setting_team_informations.setting_team_information_remark,
        // },
        setting_team_regions: {
          setting_team_region_country_id:
            data?.setting_team_regions?.setting_team_region_country_id,
          setting_team_region_address_detail:
            data?.setting_team_regions?.setting_team_region_address_detail,
          setting_team_region_address_detail_en:
						data?.setting_team_regions?.setting_team_region_address_detail_en,
          setting_team_region_province_id:
            data?.setting_team_regions?.setting_team_region_province_id,
          setting_team_region_district_id:
            data?.setting_team_regions?.setting_team_region_district_id,
          setting_team_region_subdistrict_id:
            data?.setting_team_regions?.setting_team_region_subdistrict_id,
          setting_team_region_zipcode:
            data?.setting_team_regions?.setting_team_region_zipcode,
        },

        active: data.setting_team_published,
        createdAt: format(new Date(data.created_at), "dd/MM/yyyy"),
        updatedAt: format(new Date(data.updated_at), "dd/MM/yyyy"),
        updatedBy: data.updated_by,
      };
    },
    async saveTeam() {
      const body = {
        ...this.$data.teamForm,
      };

      delete body.createdAt;
      delete body.updatedAt;
      delete body.updatedBy;
      // console.log("body.teamForm", body);
      // save team
      if (body.teamId) {
        const teamId = body.teamId;
        delete body.teamId;
        return await appAxios
          .post(`team/${teamId}/update`, body)
          .then((res) => {
            return res.data?.data?.team?.id;
          });
      } else {
        return await appAxios.post("team/create", body).then((res) => {
          return res.data?.data?.team?.id;
        });
      }
    },
    getShirtImage(teamId, year) {
      appAxios.get(`team/${teamId}/uniforms/${year}`).then((res) => {
        const data = res.data?.data?.uniform;
        if (!data) return;
        data.forEach((ele) => {
          if (!this.$data.shirtData[ele.setting_team_year]) {
            this.$set(this.$data.shirtData, ele.setting_team_year, {});
          }
          this.$set(
            this.$data.shirtData[ele.setting_team_year],
            ele.setting_team_uniform_type,
            ele.setting_team_uniform_image_url
          );
        });
      });
    },
    async saveTeamSocialContact(teamId) {
      const body = this.$data.teamSocialContactForm;
      appAxios.post(`team/${teamId}/social-contacts`, body).then((res) => res);
    },
    parseteamSocialContact(data) {
      if (!data) return;
      this.$data.teamSocialContactForm = data;
    },
    onCommitSocialContact(value) {
      this.$data.teamSocialContactForm = value;
    },
    onClickTeamSocialContact(e) {
      e.preventDefault();
      this.$data.showTeamSocialContactModal = true;
    },
    closeModal() {
      this.$data.showTeamSocialContactModal = false;
    },
    openCreateModal(e) {
      e.preventDefault();
      this.dialog = true;
    },
    openShirtModal(e) {
      e.preventDefault();
      if (!this.isEditable) return;
      this.dialog1 = true;
    },
    closeModal1() {
      this.dialog1 = false;
    },
    handleSubmit1(e) {
      this.$data.uniformYear = e.uniformYear;
      this.$set(this.$data.shirtData, e.uniformYear, {});
      this.$set(this.$data.shirtData[e.uniformYear], "HOME", e.HOME);
      this.$set(this.$data.shirtData[e.uniformYear], "AWAY", e.AWAY);
      this.$set(this.$data.shirtData[e.uniformYear], "SPARE", e.SPARE);
      this.closeModal1();
    },
    onChangeImage(e) {
      const file = e.target.files[0];
      this.$data.item.preview = e;
      this.$data.item.image = file;
      this.$data.item.imageUrl = URL.createObjectURL(file);
    },
  },
  watch: {
    "teamForm.setting_team_regions.setting_team_region_country_id"() {
      if (
        this.teamForm.setting_team_regions.setting_team_region_country_id !==
        "188"
      ) {
        this.teamForm.setting_team_regions.setting_team_region_province_id =
          null;
      } else {
        this.teamForm.setting_team_regions.setting_team_region_province_id =
          null;
        this.teamForm.setting_team_regions.setting_team_region_district_id =
          null;
        this.teamForm.setting_team_regions.setting_team_region_subdistrict_id =
          null;
        this.teamForm.setting_team_regions.setting_team_region_zipcode = null;
      }
    },
    "teamForm.setting_team_regions.setting_team_region_province_id"(
      newVal,
      old
    ) {
      if (!newVal) {
        this.teamForm.setting_team_regions.setting_team_region_district_id =
          null;
        return;
      } else if (old) {
        this.teamForm.setting_team_regions.setting_team_region_district_id =
          null;
        this.teamForm.setting_team_regions.setting_team_region_subdistrict_id =
          null;
        this.teamForm.setting_team_regions.setting_team_region_zipcode = null;
      }

      this.$store.dispatch(getDistrict, newVal);
    },
    "teamForm.setting_team_regions.setting_team_region_district_id"(
      newVal,
      old
    ) {
      if (!newVal) {
        this.teamForm.setting_team_regions.setting_team_region_subdistrict_id =
          null;
        return;
      } else if (old) {
        this.teamForm.setting_team_regions.setting_team_region_subdistrict_id =
          null;
        this.teamForm.setting_team_regions.setting_team_region_zipcode = null;
      }
      this.$store.dispatch(getSubdistrict, newVal);
    },
    "teamForm.setting_team_regions.setting_team_region_subdistrict_id"(newVal) {
      if (!newVal) {
        this.teamForm.setting_team_regions.setting_team_region_zipcode = null;
        return;
      }
      const matchSubdistrict = this.$store.state.globalConfigSubdistrict[
        this.teamForm.setting_team_regions.setting_team_region_district_id
      ]?.data.find((ele) => ele.id == newVal);
      if (matchSubdistrict) {
        this.teamForm.setting_team_regions.setting_team_region_zipcode =
          matchSubdistrict.zipcode;
      }
    },
    dialog1(newVal) {
      this.$data.isSubmitModal = !newVal;
    },
    uniformYear(newValue) {
      if (this.$data.isSubmitModal) return;
      this.getShirtImage(this.teamId, newValue);
    },
  },
};
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.active-radio .v-icon {
  color: red;
}

.download_image {
  float: right;
  margin: 0 0 0 0;
  bottom: 23px;
}

@media (min-width: 960px) {
  .tab1-border-left {
    border-left: 1px solid #ebe9f1 !important;
  }
}
.image-upload .uploadImage {
  position: absolute;
  right: 20%;
  cursor: pointer;
}
.image-upload .selectFile {
  display: none;
}
.imgPreview {
  transform: translateX(50%);
}
.profileImage {
  height: 200px;
  width: 200px;
  object-fit: cover;
}
</style>
