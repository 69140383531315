<template>
  <v-app :id="model.nameSingular">
    <div class="app-content content">
      <div class="content-wrapper">
        <div class="content-body">
          <div class="master-data-table">
            <div class="master-data-table-action">
              <v-btn
                color="primary"
                class="float-right"
                v-if="isEditable"
                @click="openCreateModal"
              >
                <v-icon>fa-plus</v-icon>
                <span class="m-l-5px">{{ $t("setting_award.add_award") }}</span>
              </v-btn>

              <v-dialog
                v-model="dialog"
                width="600px"
                v-bind:hide-overlay="false"
              >
                <v-card>
                  <div class="dialog-wrapper">
                    <div class="dialog-header">
                      <div class="dialog-header-title">
                        {{ $t("setting_award.add_award") }}
                      </div>
                      <div class="dialog-actions">
                        <v-btn
                          color="primary"
                          plain
                          class="btn-close"
                          @click="dialog = false && closeModal"
                        >
                          {{ $t("normal.cancel") }}
                        </v-btn>
                        <v-btn
                          color="primary"
                          class="m-l-5px"
                          v-if="isEditable"
                          @click="handleSubmit"
                        >
                          {{ $t("normal.save") }}
                        </v-btn>
                      </div>
                    </div>
                    <div class="dialog-content">
                      <v-form ref="form" class="needs-validation" novalidate="">
                        <div class="row">
                          <div class="col-md-6 col-12">
                            <div class="form-group">
                              <label for="global_config_award_name_th">{{
                                $t("setting_award.name_th")
                              }}</label>
                              <v-text-field
                                type="text"
                                :rules="ruleForm"
                                dense
                                outlined
                                id="global_config_award_name_th"
                                :readonly="!isEditable"
                                v-model="input.global_config_award_name_th"
                              />
                            </div>
                          </div>
                          <div class="col-md-6 col-12">
                            <div class="form-group">
                              <label for="global_config_award_name_en">{{
                                $t("setting_award.name_en")
                              }}</label>
                              <v-text-field
                                type="text"
                                :rules="ruleForm"
                                dense
                                outlined
                                id="global_config_award_name_en"
                                :readonly="!isEditable"
                                v-model="input.global_config_award_name_en"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-12"></div>
                          <div class="col-md-6 col-12">
                            <div class="field-status">
                              {{ $t("normal.status") }}
                              <checkbox
                                :checked="input.active"
                                :disabled="!isEditable"
                                v-model="input.active"
                              >
                                <span
                                  v-if="input.active"
                                  class="input-box"
                                  slot="input-box"
                                >
                                  <v-icon>fa-check</v-icon>
                                </span>
                                <span v-if="input.active">{{
                                  $t("normal.open_status")
                                }}</span>
                                <span v-else>{{
                                  $t("normal.close_status")
                                }}</span>
                              </checkbox>
                            </div>
                          </div>
                        </div>
                      </v-form>
                    </div>
                  </div>
                </v-card>
              </v-dialog>
            </div>

            <div class="master-data-table-wrapper">
              <div class="master-data-table-search">
                <div class="input-group">
                  <IconImage
                    className="icon-search"
                    src="assets/images/icons/search.svg"
                    width="21"
                    height="21"
                  ></IconImage>
                  <input
                    type="text"
                    v-model="search"
                    class="form-control"
                    :placeholder="$t('setting_award.search_placholder')"
                    @keyup="handleSearch"
                  />
                  <div class="input-group-append">
                    <v-menu
                      offset-y
                      rounded="lg"
                      content-class="filter-dropdown"
                      left
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" outlined>
                          <IconImage
                            className="icon-filter"
                            src="assets/images/icons/filter.svg"
                            width="22"
                            height="19"
                          ></IconImage>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <div class="title-bar">
                              {{ $t("normal.status") }}
                            </div>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <a @click="handleFilter('')">{{
                              $t("normal.all_status")
                            }}</a>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <a @click="handleFilter(true)">{{
                              $t("normal.open_status")
                            }}</a>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <a @click="handleFilter(false)">{{
                              $t("normal.close_status")
                            }}</a>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
              </div>

              <v-data-table
                :items="awards"
                :headers="headers"
                :search="search"
                class="elevation-1"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.global_config_award_name_th }}</td>
                    <td>{{ row.item.global_config_award_name_en }}</td>
                    <td>{{ row.item.created_at }}</td>
                    <td>
                      <div @click="handleStatus(row.item.id, $event)">
                        <checkbox
                          :checked="row.item.active"
                          :disabled="!isEditable"
                          v-model="row.item.active"
                        >
                          <span
                            v-if="row.item.active"
                            class="input-box"
                            slot="input-box"
                          >
                            <v-icon>fa-check</v-icon>
                          </span>
                          <span v-if="row.item.active">{{
                            $t("normal.open_status")
                          }}</span>
                          <span v-else>{{ $t("normal.close_status") }}</span>
                        </checkbox>
                      </div>
                    </td>
                    <td>
                      <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>fa-ellipsis-v</v-icon>
                          </v-btn>
                        </template>

                        <v-list dark>
                          <v-list-item
                            @click="openEditModal(row.item.id, $event)"
                          >
                            {{ $t("normal.edit") }}
                          </v-list-item>
                          <v-list-item
                            v-if="isEditable"
                            @click="destroy(row.item.id)"
                          >
                            {{ $t("normal.delete_list") }}
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </template>
              </v-data-table>

              <div class="d-flex justify-content-between">
                <div class="d-flex">
                  <span>
                    {{ currentItem }} {{ $t("normal.from") }} {{ total }}
                    {{ $t("normal.list") }}
                  </span>
                </div>
                <div class="d-flex">
                  <v-pagination
                    v-if="showPaginate"
                    v-model="page"
                    :length="lastPage"
                    :total-visible="7"
                    @input="handlePageChange"
                    circle
                  ></v-pagination>

                  <v-select
                    :items="pageCounts"
                    v-model="itemsPerPage"
                    solo
                    single-line
                    class="pagination-dropdown rounded-pill mx-1"
                    style="text-align: center; width: 100px"
                    @change="changeCounts"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import IconImage from "@/components/IconImage.vue";
import MasterDataHelper from "@/helpers/master_data_helper.js";

// change here
const modelNameSingular = "award";
const modelNamePlural = "awards";

// non change
const getAllName = `get${
  modelNamePlural.charAt(0).toUpperCase() + modelNamePlural.slice(1)
}`;
const createName = `create${
  modelNameSingular.charAt(0).toUpperCase() + modelNameSingular.slice(1)
}`;
const updateName = `update${
  modelNameSingular.charAt(0).toUpperCase() + modelNameSingular.slice(1)
}`;
const deleteName = `delete${
  modelNameSingular.charAt(0).toUpperCase() + modelNameSingular.slice(1)
}`;

export default {
  name: modelNameSingular,
  components: {
    IconImage,
  },
  data() {
    return {
      model: {
        nameSingular: modelNameSingular,
        namePlural: modelNamePlural,
      },
      dialog: false,
      dialogType: "",
      search: "",
      input: {
        global_config_award_name_th: "",
        global_config_award_name_en: "",
        active: true,
      },
      page: 1,
      pageCount: 2,
      pageCounts: [10, 20, 50, 100],
      itemsPerPage: 10,
      params: {},
      showPaginate: false,
    };
  },
  computed: {
    ruleForm() {
      const rules = [];
      rules.push((v) => !!v || `required`);
      return rules;
    },
    [modelNamePlural]() {
      return this.$store.state[modelNameSingular][modelNamePlural].data;
    },
    currentPage() {
      return this.$store.state[modelNameSingular][modelNamePlural].currentPage;
    },
    lastPage() {
      return (
        this.$store.state[modelNameSingular][modelNamePlural].lastPage || 15
      );
    },
    perPage() {
      return this.$store.state[modelNameSingular][modelNamePlural].perPage;
    },
    total() {
      return this.$store.state[modelNameSingular][modelNamePlural].total;
    },
    currentItem() {
      const obj = this.$store.state[modelNameSingular][modelNamePlural];
      if (obj.total) {
        if (!obj.currentPage) return "0 - 0";
        const currentItem =
          Number(obj.currentPage - 1) * Number(obj.perPage) + 1;
        if (obj.currentPage !== obj.lastPage) {
          return `${currentItem} - ${currentItem + Number(obj.perPage - 1)}`;
        } else {
          return `${currentItem} - ${Number(obj.total)}`;
        }
      } else {
        return "0 - 0";
      }
    },
    isEditable() {
      return this.$store.state.permission.permissions.setting_edit;
    },
    headers() {
      return [
        {
          text: this.$t("setting_award.name_th"),
          value: "global_config_award_name_th",
          sortable: false,
        },
        {
          text: this.$t("setting_award.name_en"),
          value: "global_config_award_name_en",
          sortable: false,
        },
        { text: this.$t("normal.date"), value: "created_at", sortable: false },
        { text: this.$t("normal.status"), value: "active", sortable: false },
        { text: this.$t("normal.manage"), value: "edit", sortable: false },
      ];
    },
  },
  created() {
    this.getAll().then(() => {
      this.showPaginate = true;
    });
  },
  methods: {
    async getAll(params = null) {
      params = { ...this.params, ...params };
      await this.$store.dispatch(getAllName, params);
    },
    async create() {
      await this.$store
        .dispatch(createName, this.input)
        .then(this.closeModal());
      await this.getAll();
    },
    async update() {
      const index = this[modelNamePlural].findIndex(
        (r) => r.id == this.input.id
      );
      this.input.created_at = this[modelNamePlural][index].created_at;
      await this.$store
        .dispatch(updateName, { index: index, payload: this.input })
        .then(this.closeModal());
      await this.getAll();
    },
    async destroy(id) {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.$t("short_language"))).then(
        async (result) => {
          if (result.value) {
            await this.$store.dispatch(deleteName, id);
            await this.getAll();
          }
        }
      );
    },
    closeModal() {
      this.dialog = false;
    },
    clearInput() {
      this.input = {
        // id: 0,
        global_config_award_name_th: "",
        global_config_award_name_en: "",
        active: true,
      };
    },
    openCreateModal(e) {
      e.preventDefault();
      this.clearInput();
      this.dialog = true;
      this.dialogType = "create";
    },
    openEditModal(id, e) {
      e.preventDefault();
      this.clearInput();
      const data = this[modelNamePlural].find((r) => r.id == id);
      this.input = {
        id: data.id,
        global_config_award_name_th: data.global_config_award_name_th,
        global_config_award_name_en: data.global_config_award_name_en,
        active: data.active,
      };
      this.dialog = true;
      this.dialogType = "edit";
    },
    handleSubmit(e) {
      e.preventDefault();
      const validate = this.$refs.form.validate();
      if (!validate) return;
      if (!this.isEditable) return;
      if (this.dialogType == "create") {
        this.create();
      } else {
        this.update();
      }
    },
    handleStatus(id, e) {
      e.preventDefault();
      if (!this.isEditable) return;
      const data = this[modelNamePlural].find((r) => r.id == id);
      data.active = !data.active;

      const index = this[modelNamePlural].findIndex((r) => r.id == id);
      this.$store
        .dispatch(updateName, { index: index, payload: data })
        .then(this.closeModal());
    },
    changeCounts: function (counts) {
      this.itemsPerPage = counts;
      this.page = 1;
      const params = {
        page: this.page,
        perPage: counts,
      };
      this.params = { ...this.params, ...params };
      this.getAll(this.params);
    },
    handlePageChange() {
      const params = {
        page: this.page,
        perPage: this.perPage,
      };
      this.params = { ...this.params, ...params };
      this.getAll(this.params);
    },
    handleSearch() {
      const params = {
        name: this.search,
      };
      this.params = { ...this.params, ...params };
      this.getAll(this.params);
    },
    handleFilter(active) {
      const params = {
        active: active,
      };
      if (active === "") {
        delete this.params.active;
        delete params.active;
      }
      this.params = { ...this.params, ...params };
      this.getAll(this.params);
    },
  },
};
</script>

<style>
</style>
