<template>
	<v-app id="association">
		<div class="content app-content h-100">
			<div class="container">
				<Tab11 />
				<!--<div class="shadow-lg p-3 mb-5 bg-white rounded">
					<Tab11Content />
				</div>-->
			</div>
		</div>

		<!-- <Tab11/>
        <div class="shadow-lg p-3 mb-5 bg-white rounded">
        <div class="content">
          <Tab11Content/>
        </div>
        </div> -->
	</v-app>
</template>

<script>
import Tab11 from "../../components/association/Tab11.vue";
// import Tab11Content from "../../components/association/Tab11Content.vue";

export default {
	name: "association",
	components: {
		Tab11,
		// Tab11Content,
	},
	mounted() {},
};
</script>

<style></style>
