<template>
	<div>
		<v-dialog
			:value="dialog"
			:persistent="true"
			:no-click-animation="true"
			max-width="700px"
		>
			<v-card>
				<div class="float-right">
					<v-card-actions>
						<v-btn color="primary" text @click="close" mx-2>
							{{$t('normal.cancel')}}
						</v-btn>
						<v-btn
							color="primary"
							:disabled="!isEditable"
							@click="onCommit"
							mx-2
						>
							{{$t('normal.confirm')}}
						</v-btn>
					</v-card-actions>
				</div>
				<v-card-title>
					<span class="header text-primary">{{$t('player.personal_info.education_info')}}</span>
				</v-card-title>

				<div class="card-body">
					<v-form ref="form" class="needs-validation" novalidate="">
						<div class="row">
							<div class="col-6">
								<div class="form-group">
									<label class="form-label" for="basic-addon-name"
										>{{$t('player.personal_info.school_name_th')}}</label
									>
									<v-text-field
										dense
										outlined
										:rules="ruleForm"
										v-model="form.account_education_name_th"
										:readonly="!isEditable"
										:placeholder="$t('player.personal_info.school_name_th')"
									></v-text-field>
								</div>
							</div>
							<div class="col-6">
								<div class="form-group">
									<label class="form-label" for="basic-addon-name"
										>{{$t('player.personal_info.school_name_en')}}</label
									>
									<v-text-field
										dense
										outlined
										v-model="form.account_education_name_en"
										:readonly="!isEditable"
										:placeholder="$t('player.personal_info.school_name_en')"
									></v-text-field>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-6">
								<div class="form-group">
									<label class="form-label" for="basic-addon-name"
										>{{$t('player.personal_info.faculty_th')}}</label
									>
									<v-text-field
										dense
										outlined
										v-model="form.account_education_faculty"
										:readonly="!isEditable"
										:placeholder="$t('player.personal_info.faculty_th')"
									></v-text-field>
								</div>
							</div>
							<div class="col-6">
								<div class="form-group">
									<label class="form-label" for="basic-addon-name"
										>{{$t('player.personal_info.faculty_en')}}</label
									>
									<v-text-field
										dense
										outlined
										v-model="form.account_education_faculty_en"
										:readonly="!isEditable"
										:placeholder="$t('player.personal_info.faculty_en')"
									></v-text-field>
								</div>
							</div>
							<div class="col-6">
								<div class="form-group">
									<label class="form-label" for="basic-addon-name"
										>{{$t('player.personal_info.education_level')}}</label
									>
									<v-select
										:placeholder="$t('normal.please_select')"
										dense
										outlined
										:rules="ruleForm"
										v-model="form.global_config_educations"
										:readonly="!isEditable"
										:item-text="'global_config_education_name_'+language"
										item-value="id"
										:items="educationList"
										:loading="isListFetching"
									></v-select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-4">
								<div class="form-group">
									<label class="form-label" for="basic-addon-name"
										>{{$t('player.personal_info.year_study')}}</label
									>
									<v-autocomplete
										dense
										outlined
										v-model="form.account_education_start_at"
										:items="years"
										:readonly="!isEditable"
										type="number"
										:placeholder="$t('player.personal_info.year_study')"
									></v-autocomplete>
								</div>
							</div>
							<div class="col-4">
								<div class="form-group">
									<label class="form-label" for="basic-addon-name"
										>{{$t('player.personal_info.year_graduation')}}</label
									>
									<v-autocomplete
										dense
										outlined
										:items="years"
										:disabled="form.account_education_current"
										v-model="form.account_education_end_at"
										:readonly="!isEditable"
										type="number"
										:placeholder="$t('player.personal_info.year_graduation')"
									></v-autocomplete>
								</div>
							</div>
							<div class="col-4">
								<div class="form-group">
									<v-checkbox
										:label="$t('player.personal_info.studying')"
										color="primary"
										class="mt-5 pt-3"
										v-model="form.account_education_current"
										:readonly="!isEditable"
										hide-details
									></v-checkbox>
								</div>
							</div>
						</div>
					</v-form>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { appAxios } from "../../helpers/app-axios";
import { languageService } from "../../helpers/language";

const initialForm = {
	global_config_educations: null,
	account_education_name_th: null,
	account_education_name_en: null,
	account_education_faculty: null,
	account_education_faculty_en: null,
	account_education_start_at: null,
	account_education_end_at: null,
	account_education_current: false,
};

export default {
	props: {
		dialog: {
			default: false,
			type: Boolean,
		},
		education: null,
	},
	name: "Education-Modal",
	methods: {
		close() {
			this.$emit("update:dialog", false);
		},
		onCommit() {
			const validate = this.$refs.form.validate();
			if (!validate) return;

			const matchLavel = this.$data.educationList?.find(
				(ele) => ele.id == this.$data.form.global_config_educations
			);
			this.$data.form.educationName = {
				th:matchLavel?.global_config_education_name_th,
				en:matchLavel?.global_config_education_name_en
			}
			this.$emit("commit", this.$data.form);
			this.close();
		},
		getEducationList() {
			this.$data.isListFetching = true;
			appAxios
				.get("setting/global/education", {
					params: { perPage: 30, active: true },
				})
				.then((res) => {
					const data = res.data?.data?.global_config_educations?.data;
					if (data) {
						this.$data.educationList = data;
					}
				})
				.finally(() => {
					this.$data.isListFetching = false;
				});
		},
	},
	created() {
		this.subscription = languageService.getCurrent().subscribe((lang) => {
			if (lang === "en") {
				this.language = "en";
			} else {
				this.language = "th";
			}
		});
	},
	computed: {
		ruleForm() {
			const rules = [];
			rules.push((v) => !!v || `required`);
			return rules;
		},
		isEditable() {
			if (this.isPlayerRoute) {
				return this.$store.state.permission?.permissions?.athlete_edit;
			}
			return this.$store.state.permission?.permissions?.staff_edit;
		},
		isPlayerRoute() {
			return this.$route.path.includes("player");
		},
		years() {
			const currentYear = new Date().getFullYear();
			const yearAmount = currentYear - 1950;
			return Array.from(Array(yearAmount).keys())
				.map((ele) => currentYear - ele)
				.sort((a, b) => b - a);
		},
	},
	watch: {
		dialog() {
			console.log('this.education',this.education);
			if (this.education) {
				this.$data.form = this.education;
			} else {
				this.$data.form = JSON.parse(JSON.stringify(initialForm));
			}
		},
	},
	mounted() {
		this.getEducationList();
	},
	data() {
		return {
			language: "th",
			isListFetching: false,
			educationList: [],
			form: {},
			educations:{}
		};
	},
};
</script>

<style></style>
