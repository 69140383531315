function handleChange(event, maxValue, minValue) {
  const element = event.target;
  const numberRegex = new RegExp(/^[0-9]*$/);
  if (!numberRegex.test(element.value)) {
    element.value = element.value.replace(/[^0-9]/g, '');
    const event = new Event('input', {bubbles: true})
    element.dispatchEvent(event)
    return;
  }
  if (element.value < minValue) {
    element.value = minValue;
    const event = new Event('input', {bubbles: true})
    element.dispatchEvent(event)
  } else if (element.value > maxValue) {
    element.value = maxValue;
    const event = new Event('input', {bubbles: true})
    element.dispatchEvent(event)
  }
}

export default {
  bind(el, binding) {
    const maxValue = binding.value.max;
    const minValue = binding.value.min;
    el.addEventListener('input', (event) => handleChange(event, maxValue, minValue))
  },
  unbind(el, binding) {
    const maxValue = binding.value.max;
    const minValue = binding.value.min;
    el.removeEventListener('input', (event) => handleChange(event, maxValue, minValue))
  }
}