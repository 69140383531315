import { appAxios } from '../helpers/app-axios';

//const modelNameSingular = 'globalConfigCountry'
export const provincesState = "provinces";
export const getProvinces = 'getProvinces';


export default {
  state: {
    [provincesState]: [],
    isFetching: false
  },
  mutations: {
    [getProvinces](state, data) {
      if (data) {
        state[provincesState] = data;
      }
    },
    setIsProvincesFetching(state, status) {
      state.isFetching = status;
    }
  },
  actions: {
    async [getProvinces]({ commit, state }) {
      if (state.isFetching || state[provincesState].length > 0) return;
      commit('setIsProvincesFetching', true);
      appAxios.get("regions/province").then((response) => {
        commit(getProvinces, response.data?.data?.provinces);
        commit('setIsProvincesFetching', false);
      });
    },
  },
};
