<template>
	<div :class="{mainTable:!size && !expand,fullTable:size ,columnTable:!size && expand}">
		<v-data-table
			:headers="headers || []"
			:items="items"
			:itemsPerPage="items.length || 10"
			hide-default-footer
		>

			<template v-slot:[`item`]="{ item }">
				<tr>
					<td class="nameSport">{{ item.name[language] }}</td>
					<template v-for="(value, index, i) in item">
						<td
							v-if="index !== 'name'"
							:key="i"
							:class="checkColor(value, index, item.name, i)"
							class="tableSport"
						>
							<div>
								{{ value }}
							</div>
						</td>
					</template>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default: null,
		},
		headers: {
			type: Array,
			default: null,
		},
		size:{
			type:Boolean,
			default:false
		},
		expand:{
			type:Boolean,
			default:false
		},
		language:{
			type:String,
			default:'th'
		}
	},
	mounted() {},
	methods: {
		checkColor(value, index, name, i) {
			if (index !== "name") {
				if (i === 0) {
					return `bgStart`;
				} else {
					const findObject = this.items.find((elm) => {
						return elm.name === name;
					});
					const position = findObject[index] - findObject[index - 1];
					if (position === 0) {
						return `bgMedium`;
					} else if (position > 0) {
						return `bgStart`;
					} else {
						return `bgLess`;
					}
				}
			}
		},
	},
	data: function() {
		return {
			dataItem: null,
			perviousData: 0,
		};
	},
};
</script>
<style>
.bgMedium {
	background-color: #ffeed9;
}
.bgLess {
	background-color: #f8b9b9;
}
.bgStart {
	background-color: #bcece6;
}
.tableSport {
	text-align: center;
	min-width: 100px;
	border: 3px white solid !important;
}
.nameSport {
	min-width: 100px;
	border: none !important;
	text-align: end;
}
.headerName {
	border: none !important;
}
.headerSport {
	border: none !important;
}
.mainTable .v-data-table__wrapper{
	height: 400px;
	overflow-y: scroll;
	overflow-x: hidden;
}
.columnTable .v-data-table__wrapper{
	max-height: 400px;
	overflow: scroll;
}
.fullTable .v-data-table__wrapper{
	height: 100vh;
	padding-top: 2vh;
	padding-right:5em;
}
</style>
