<template>
<v-app id="team">
 <div class="app-content content ">
        <div class="content-overlay"></div>
          <TeamTabs/>
  </div>
</v-app>
</template>

<script>
import TeamTabs from '../../components/Team/TeamTabs';

export default {
  name: 'AddTeam',
  components:{
    TeamTabs
  },
  mounted() {
      
  }
}
</script>

<style>

</style>
