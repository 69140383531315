<template>
  <v-app>

    <div class="content app-content">
      <div class="content-header">
        <div class="content-header-left">
          <div class="row d-flex align-items-center justify-space-between">
            <div class="col-12 col-md-8 d-flex align-items-center">
              <v-icon class="content-header-title float-left mb-0" small>
                fa-chevron-left
              </v-icon>
              <router-link to="/competition" custom>
                <h5 class="content-header-title float-left mb-0 ml-2">
                  {{ $t("competition.all") }}
                </h5>
              </router-link>
              <h5 class="content-header-title float-left mb-0 ml-2">/</h5>
              <router-link
                :to="{
                  name: 'competition-detail',
                  params: { id: competitionId },
                }"
                custom
              >
                <h5 class="content-header-title float-left mb-0 ml-2">
                  {{
                    competition.setting_competition_informations[
                      "setting_competition_information_name_" +
                        $t("short_language")
                    ]
                  }}
                </h5>
              </router-link>
              <h5 class="content-header-title float-left mb-0 ml-2">/</h5>
              <template v-if="resultAdding">
                <div class="clickable" @click="resultAdding = false">
                  <h5 class="content-header-title float-left mb-0 ml-2">
                    {{ items[tab] ? items[tab].text : "" }}
                  </h5>
                </div>
                <h5 class="content-header-title float-left mb-0 ml-2">/</h5>
                <h5 class="content-header-title float-left mb-0 ml-2">
                  {{ $t("competition.add_result_competition") }}
                </h5>
              </template>
              <h5 v-else class="content-header-title float-left mb-0 ml-2">
                {{ items[tab] ? items[tab].text : "" }}
              </h5>
            </div>
            <v-btn v-if="resultAdding" color="primary" @click="onSaveResult">{{
              $t("normal.save")
            }}</v-btn>
          </div>
        </div>
      </div>
      <div class="container">
        <template>
          <v-tabs
            v-model="tab"
            :show-arrows="true"
            active-class="text-white tab-color"
            hide-slider
            @change="onChangeTab"
          >
            <v-tab
              class="my-0 app-rounded-top"
              v-for="(item, index) in items"
              :class="elevation"
              :key="index"
            >
              <v-icon small>{{ item.icon }}</v-icon>
              <div class="ml-2">
                <span> {{ item.text }}</span>
              </div>
            </v-tab>
          </v-tabs>
          <div v-if="tab === 0 && !isAdmin">
            <RegisPlayerStaff class="pa-3"></RegisPlayerStaff>
          </div>
          <div v-if="tab === 1 && !isAdmin"><ListRegister /></div>
          <team-table
            v-if="tab == 0 && isAdmin"
            @openTeamSelect="dialogSelectTeam = true"
            @showSnackBar="showSnackBar"
          />
          <competition-round
            v-if="tab == 1 && isAdmin"
            @add="onAddCompetitionRound"
            @edit="onEditCompetitionRound"
            @addResult="onAddResult"
            @updateResult="onUpdateResult"
            @addGroup="onAddGroup"
            @editGroup="onEditGroup"
            @addGroupResult="onAddGroupResult"
            @updateGroupResult="onUpdateGroupResult"
          />
          <image-gallery v-if="tab == 2 && isAdmin" />
        </template>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

import RegisPlayerStaff from "@/components/Competition/User/RegisPlayerStaff";
import ListRegister from "@/components/Competition/User/ListRegister";
import ImageGallery from "@/components/Competition/Sub/ImageGallery";
import TeamTable from "@/components/Competition/Sub/TeamTable";
import CompetitionRound from "@/components/Competition/Sub/CompetitionRound";
export default {
  name: "SubCompetitionDetail",

  components: {
    RegisPlayerStaff,
    ImageGallery,
    TeamTable,
    CompetitionRound,
    ListRegister,
  },

  data() {
    return {
      pageSizes: [10, 20, 50, 100],
      pageSize: 10,
      currentPage: 1,

      tab: 0,
    };
  },

  mounted() {
    this.competitionId = this.$route.params.cid;
    this.subCompetitionId = this.$route.params.sid;
    // this.ta = this.$route.params.

    if (!this.isAdmin) {
      this.getSelfTeam();
    }

    // if(this.isAdmin){
    //   this.getDetail
    // }

    
    switch (this.$route.params.tab) {
      case "competition":
      case "1":
      case 1:
        
        this.tab = 1;
        break;
      case "gallery":
      case "2":
      case 2:
        this.tab = 2;
        break;
      default:
        this.tab = 0;
    }
  },

  computed: {
    ...mapState("competition", ["competition"]),
    ...mapState("sub_competition", ["personalData"]),

    isAdmin() {
      return this.$store.state.permission.role !== "B_TEAM_ADMIN";
    },
    items() {
      return !this.isAdmin
        ? [
            {
              text: "ใบสมัครแข่งขัน",
              // icon: "fa-users",
              key: 1,
            },
            { text: "ลงชื่อนักกีฬา, บุคลากร", icon: "" },
            // { text: this.$t("sub_competition.image_and_vedio"), icon: "fa-video" },
          ]
        : [
            {
              text: this.$t("sub_competition.team_join"),
              icon: "fa-users",
              key: 1,
            },
            { text: this.$t("sub_competition.competition"), icon: "fa-trophy" },
            {
              text: this.$t("sub_competition.image_and_vedio"),
              icon: "fa-video",
            },
          ];
    },
  },

  watch: {
    $route(to) {
      // Update current route path when route changes
      switch (to.params.tab) {
        case "competition":
      case "1":
      case 1:
        
        this.tab = 1;
        if (!this.isAdmin) {
        this.getDetailByTeam({
          teamId: this.personalData.id,
          competitionId: this.$route.params.cid,
        });
      }
        break;
      case "gallery":
      case "2":
      case 2:
        this.tab = 2;
        break;
      default:
        this.tab = 0;
      }
      // this.currentRoutePath = to.path;
    },

    personalData() {
      if (!this.isAdmin) {
        this.getDetailByTeam({
          teamId: this.personalData.id,
          competitionId: this.$route.params.cid,
        });
      }
    },
  },
  methods: {
    ...mapActions("sub_competition", ["getSelfTeam", "getDetailByTeam"]),

    onChangeTab(tab) {
      this.$router.push({
        name: "sub-competition-detail",
        params: {
          cid: this.competitionId,
          sid: this.subCompetitionId,
          tab: tab,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.competition-information {
  font-size: 14px;
}
.info-header {
  font-size: 20px;
}
.clickable {
  cursor: pointer;
}

.tab-color {
  background-color: #0d6efd;
}
.v-tab {
  margin: 0 2px;
  &:not(.v-tab--active) {
    background: #f1f1f5;
  }
}
.app-rounded-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.download-flow {
  position: absolute !important;
  right: 50px;
  top: 80px;
  background-color: #555658 !important;
  color: #fff !important;
}
</style>
