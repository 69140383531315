<template>
	<div class="content app-content">
    <div class="content-header">
      <div class="content-header-left">
        <div class="row mb-1">
          <div class="col-12 col-md-8 d-flex justify-center align-items-center">
            <router-link to="/competition" custom>
              <v-icon class="content-header-title float-left mb-0" small>
                fa-chevron-left
              </v-icon>
              <h6 class="content-header-title float-left mb-0 ml-1">
                {{$t('competition.all')}}
              </h6>
            </router-link>
						<h6 class="content-header-title float-left mb-0 ml-1">/</h6>
            <router-link to="/competition" custom>
              <h6 class="content-header-title float-left mb-0 ml-1">
                {{$t('competition.all')}}
              </h6>
            </router-link>
						<h6 class="content-header-title float-left mb-0 ml-1">/</h6>
            <router-link to="/competition" custom>
              <h6 class="content-header-title float-left mb-0 ml-1">
                {{$t('competition.all')}}
              </h6>
            </router-link>
						<h6 class="content-header-title float-left mb-0 ml-1">/</h6>
						<h6 class="content-header-title float-left mb-0 ml-1">
							{{$t('competition.add_result_competition')}}
						</h6>
          </div>
          <div class="col-12 col-md-4 d-flex justify-content-end align-items-center">
            <v-btn color="primary" @click="saveData()">
              {{$t('normal.save')}}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
		<result-form></result-form>
	</div>
</template>

<script>
import ResultForm from '@/components/Competition/Result/ResultForm.vue'
export default {
	name: 'CompetitionResult',

	components: {
		ResultForm
	},

	data() {
		return {

		}
	}
}
</script>