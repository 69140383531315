<template>
	<div class="container">
		<div class="content-header">
			<div class="content-header-left">
				<div class="row mb-4">
					<div class="col-12 col-md-8">
						<router-link to="/stadium" custom>
							<v-icon class="content-header-title float-left mb-0">
								fa-chevron-left
							</v-icon>
							<h3 class="content-header-title float-left mb-0 ml-2">
								{{ $t('stadium.all_stadium') }}
							</h3>
						</router-link>
					</div>
					<div
						v-if="isEditable"
						class="col-12 col-md-4 mb-1 d-flex justify-content-end"
					>
						<v-btn class="float-right" color="primary" @click="saveData()">
							{{ $t('normal.save') }}
						</v-btn>
					</div>
				</div>
			</div>
		</div>

		<div class="content-body">
			<v-toolbar flat>
				<v-tabs
					v-model="tab"
					:show-arrows="true"
					active-class="text-white tab-color"
					hide-slider
				>
					<!-- <v-tabs-slider color="white"></v-tabs-slider>  -->
					<v-tab
						:disabled="isAddRoute && idx !== 0"
						class="app-rounded-top"
						v-for="(item, idx) in tabs"
						:key="idx"
					>
						<v-icon>{{ item.icon }}</v-icon>
						<div class="ml-2">
							<span> {{ $t(item.topic) }} </span> <br />
							<span class="font-small-1"> {{ $t(item.detail) }} </span>
						</div>
					</v-tab>
				</v-tabs>
			</v-toolbar>
		</div>

		<v-tabs-items class="shadow" v-model="tab">
			<v-tab-item v-for="(item, idx) in tabContents" :key="idx" :value="idx">
				<component ref="tabContent" v-bind:is="item"></component>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>
<script>
import Tab1 from "./Tab1.vue";

export default {
	name: "stadium-tabs",
	mounted() {
		const currentTab = this.$route.query?.tab;
		const isAddRoute = this.$route.path.includes("add");

		if (isAddRoute) {
			this.$data.isAddRoute = true;
		}
		if (currentTab && !isAddRoute) {
			this.$data.tab = Number(currentTab);
		}
	},
	methods: {
		saveData() {
			const activeComponent = this.$refs.tabContent.find((ele) =>
				ele.$parent.$el.className.includes("active")
			);
			// this.$refs.tabContent[this.$data.tab || 0].save();
			if (activeComponent && activeComponent.save) {
				activeComponent.save();
			}
			// console.log(activeComponent)
			// this.$store.dispatch(insertPlayerData,this.$store.state[player][playerData])
		},
	},
	components: {
		Tab1,
	},
	data() {
		return {
			isAddRoute: false,
			tab: null,
			tabs: [
				{
					topic: "stadium.stadium_info",
					detail: "stadium.add_stadium_info",
					icon: "fa-users",
				},
			],
			tabContents: [Tab1]
		};
	},
	computed: {
		isEditable() {
			return this.$store.state.permission.permissions.stadium_edit;
		},
	},
	watch: {
		tab() {
			this.$router
				.push({ path: this.$route.path, query: { tab: this.$data.tab } })
				.catch(() => {});
		},
		$route() {
			const currentTab = this.$route.query?.tab;
			if (currentTab) {
				this.$data.tab = Number(currentTab);
			}
		},
	},
};
</script>
<style scoped lang="scss">
.tab-color {
	background-color: #0d6efd;
}
.v-tab {
	margin: 0 2px;
	&:not(.v-tab--active) {
		background: #f1f1f5;
	}
}
.form-container {
	margin: 0 auto;
}
@media (min-width: 576px) {
	.form-container {
		max-width: 540px;
	}
}
@media (min-width: 768px) {
	.form-container {
		max-width: 720px;
	}
}
@media (min-width: 992px) {
	.form-container {
		max-width: 960px;
	}
}
@media (min-width: 1200px) {
	.form-container {
		max-width: 1140px;
	}
}
.app-rounded-top {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
</style>
