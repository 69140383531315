<template>
  <div id="app">
    <template v-if="route.name === 'saveToken'">
      <router-view />
    </template>
    <template v-else>
      <Headder />
      <Menu></Menu>
      <!-- <div id="nav"> -->
      <!-- <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link> |
        <router-link to="/headder">Headder</router-link> -->
      <!-- </div> -->
      <router-view />
      <Footer />
      <ErrorSnackbar />
    </template>
    <v-btn
      style="z-index:9999;"
      class="btn-primary"
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      small
      color="primary"
      @click="toTop"
    >
      <v-icon> fa-arrow-up </v-icon>
    </v-btn>
  </div>
</template>

<script>
import Headder from "@/components/layouts/Headder.vue";
import Footer from "@/components/layouts/Footer.vue";
import Menu from "@/components/layouts/Menu.vue";
import ErrorSnackbar from '@/components/ErrorSnackbar.vue';

export default {
  components: {
    Headder,
    Footer,
    Menu,
    ErrorSnackbar
  },
  data() {
    return {
      fab: false,
    };
  },
  computed: {
    route() {
      return this.$route;
    },
  },
  methods: {
    loadIcon: function() {
      if (this.feather) {
        this.feather.replace({
          width: 14,
          height: 14,
        });
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  mounted() {
    this.loadIcon();
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @import './assets/app-assets/css/bootstrap.css';
@import './assets/app-assets/css/colors.css';
@import './assets/app-assets/css/components.css';
@import './assets/app-assets/css/themes/dark-layout.css';
@import './assets/app-assets/css/themes/bordered-layout.css';
@import './assets/app-assets/css/themes/semi-dark-layout.css'; */

* {
  font-family: "Kanit", sans-serif;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 70% !important;
}

input::placeholder,
textarea::placeholder {
  color: rgba(0, 0, 0, 0.2) !important;
}

.main-menu,
.header-navbar {
  z-index: 100;
}

.dz-error-message {
  top: 10px !important;
}

/*
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
