<template>
  <v-snackbar
    color="#c23b22"
    top
    right
    v-model="isShowSnackbar"
  >
    {{ snackbarText }}
    <template v-slot:action="{ attrs }">
      <v-btn text @click="isShowSnackbar = false" v-bind="attrs">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    isShowSnackbar: {
      set(value) {
        if (!value) {
        this.$store.dispatch("closeErrorSnackbar");
      }
      },
      get() {
        return this.$store.state.global.isShowErrorSnackbar;
      }
      // return false
    },
    snackbarText() {
      // return null
      return this.$store.state.global.errorText;
    },
  }
};
</script>
