<template>
  <div class="row justify-content-center">
    <div class="col-md-12 col-12 col-xl-10">
      <div>
        <div class="card-body">
          <div>
            <h4 class="card-title text-primary">{{$t('player.history_team.history_nation')}}</h4>
          </div>
          <v-data-table
            hide-default-footer
            :items="nation"
            :headers="headderAffiliate[language]"
            :items-per-page="nationPerPage"
          >
            <template v-slot:item="row">
              <tr>
                <!-- <td><img src="@/assets/images/logoTrans.png" width="20" /></td> -->
                <td><label v-if="language==='th'">{{ row.item.name || '-'}}</label>
                  <label v-else>{{ row.item.name_en || '-'}}</label></td>
                <td><label v-if="language==='th'">{{ row.item.position || '-'}}</label>
                  <label v-else>{{ row.item.position_en || '-'}}</label></td>
                <td>{{ row.item.no || '-'}}</td>
                <td>{{ row.item.count || '-'}}</td>
                <td>{{ row.item.contract[language] || '-'}}</td>
                <td>{{ row.item.start || '-'}}</td>
                <td>{{ row.item.end || '-'}}</td>
              </tr>
            </template>
            <template v-slot:footer="{}">
              <TableFooter
                :total="nationTotal"
                :perPage.sync="nationPerPage"
                :lastPage="nationLastPage"
                :currentPage.sync="nationPage"
              />
            </template>
          </v-data-table>
        </div>

        <div class="card-body">
          <div>
            <h4 class="card-title text-primary">{{$t('player.history_team.history_team')}}</h4>
          </div>
          <v-data-table
            hide-default-footer
            :items="club"
            :headers="headderClub[language]"
            :items-per-page="clubPerPage"
          >
            <template v-slot:item="row">
              <tr>
                <!-- <td><img src="@/assets/images/logoTrans.png" width="20" /></td> -->
                <td><label v-if="language==='th'">{{ row.item.name || '-'}}</label>
                  <label v-else>{{ row.item.name_en || '-'}}</label></td>
                <td><label v-if="language==='th'">{{ row.item.position || '-'}}</label>
                  <label v-else>{{ row.item.position_en || '-'}}</label></td>
                <td>{{ row.item.no || '-'}}</td>
                <td>{{ row.item.count || '-'}}</td>
                <td>{{ row.item.contract[language] || '-'}}</td>
                <td>{{ row.item.start || '-'}}</td>
                <td>{{ row.item.end || '-'}}</td>
              </tr>
            </template>
            <template v-slot:footer="{}">
              <TableFooter
                :perPage.sync="clubPerPage"
                :total="clubTotal"
                :lastPage="clubLastPage"
                :currentPage.sync="clubPage"
              />
            </template>
          </v-data-table>
        </div>
      </div>
      <v-btn
        elevation="2"
        class="btn-primary float-right mb-5"
        v-on:click="nextTab()"
      >
        {{$t('normal.next')}} <v-icon right> fa-arrow-right </v-icon>
      </v-btn>
      <v-btn
        elevation="2"
        class="btn-primary float-left mb-5"
        v-on:click="backTab()"
      >
        <v-icon left> fa-arrow-left </v-icon> {{$t('normal.previous')}}
      </v-btn>
    </div>
  </div>
</template>
<script>
import format from "date-fns/format";
import { appAxios } from "../../helpers/app-axios";
import TableFooter from "../TableFooter.vue";
import { languageService } from "../../helpers/language";

export default {
  components: {
    TableFooter,
  },
  name: "player-tabs5",
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  methods: {
    test: function() {
      alert("test");
    },
    nextTab() {
      this.$router.push({ path: "", query: { tab: 5 } });
      //console.log(this.tabs);
    },
    backTab() {
      this.$router.push({ path: "", query: { tab: 3 } });
      //console.log(this.tabs);
    },
    getShowDate(date) {
      if (!date) return "";
      return format(new Date(date), "dd/MM/yyyy");
    },
    getNationHistory() {
      appAxios
        .get(
          `account/${this.accountId}/${
            this.isPlayer ? "players" : "staffs"
          }/team-histories`,
          { params: this.nationParams }
        )
        .then((res) => {
          const data =
            res.data.data?.player_team_history;
          if (!data) return;
          this.nation = data.data.map((ele) => ({
            name:
              ele.setting_teams?.setting_team_informations
                ?.setting_team_information_name_th,
            name_en: ele.setting_teams?.setting_team_informations
                ?.setting_team_information_name_en,
            position: ele.accountPosition?.global_config_position_name_th,
            position_en: ele.accountPosition?.global_config_position_name_en,
            no: ele.team_player_history_shirt_number,
            count: ele.team_player_history_match_count,
            contract: ele.team_player_history_loan ? {th:"ยืมตัว",en:"Loan Contract"} : {th:"ประจำ",en:"Permanent"},
            start: this.getShowDate(ele[`team_${this.isPlayer ? 'player' : 'staff'}_history_begin_at`]),
            end: this.getShowDate(ele[`team_${this.isPlayer ? 'player' : 'staff'}_history_end_at`]),
          }));
          this.nationTotal = data.total;
          this.nationLastPage = data.lastPage;
        });
    },
    getClubHistory() {
      appAxios
        .get(
          `account/${this.accountId}/${
            this.isPlayer ? "players" : "staffs"
          }/team-histories`,
          { params: this.clubParams }
        )
        .then((res) => {
          const data =
            res.data.data?.player_team_history;
          if (!data) return;
          this.club = data.data.map((ele) => ({
            name:
              ele.setting_teams?.setting_team_informations
                ?.setting_team_information_name_th,
            name_en:ele.setting_teams?.setting_team_informations
                ?.setting_team_information_name_en,
            position: ele.accountPosition?.global_config_position_name_th,
            position_en: ele.accountPosition?.global_config_position_name_en,
            no: ele.team_player_history_shirt_number,
            count: ele.team_player_history_match_count,
            contract: ele.team_player_history_loan ? {th:"ยืมตัว",en:"Loan Contract"} : {th:"ประจำ",en:"Permanent"},
            start: this.getShowDate(ele[`team_${this.isPlayer ? 'player' : 'staff'}_history_begin_at`]),
            end: this.getShowDate(ele[`team_${this.isPlayer ? 'player' : 'staff'}_history_end_at`]),
          }));
          this.clubTotal = data.total;
          this.clubLastPage = data.lastPage
        });
    },
  },
  computed: {
    fitColor: function() {
      if (this.fit.value < 50) {
        return "red";
      } else {
        return "teal";
      }
    },
    accountId() {
      return Number(this.$route.params?.id);
    },
    isPlayer() {
      return this.$route.path.includes("player");
    },
    nationParams() {
      return {
        page: this.nationPage,
        size: this.nationPerPage,
        standard_types: 1,
      };
    },
    clubParams() {
      return {
        page: this.nationPage,
        size: this.nationPerPage,
        standard_types: 2,
      };
    },
  },
  data() {
    return {
      headderAffiliate:{
        th:[
        { text: "ทีมชาติชุด", value: "name", sortable: false,width: "30%" },
        { text: "ตำแหน่ง", value: "position", sortable: false,width: "20%" },
        { text: "หมายเลขเสื้อ", value: "no", sortable: false,width: "10%" },
        { text: "จำนวนนัดที่ลงเล่น", value: "time", sortable: false,width: "10%" },
        { text: "รูปแบบสัญญา", value: "contract", sortable: false ,width: "10%"},
        { text: "วันที่เริ่มต้น", value: "start", sortable: false,width: "10%" },
        { text: "วันที่สิ้นสุด", value: "end", sortable: false,width: "10%" },
      ],
        en:[
        { text: "National Team Level", value: "name", sortable: false ,width: "30%"},
        { text: "Position", value: "position", sortable: false,width: "20%" },
        { text: "Shirt Number", value: "no", sortable: false ,width: "10%"},
        { text: "Matches Played", value: "time", sortable: false ,width: "10%"},
        { text: "Type of Contract", value: "contract", sortable: false ,width: "10%"},
        { text: "Start Date", value: "start", sortable: false,width: "10%" },
        { text: "End Date", value: "end", sortable: false,width: "10%" },
      ],
      },
    headderClub:{
        th:[
        { text: "ชื่อทีม/สโมสร", value: "name", sortable: false,width: "30%" },
        { text: "ตำแหน่ง", value: "position", sortable: false ,width: "20%"},
        { text: "หมายเลขเสื้อ", value: "no", sortable: false,width: "10%" },
        { text: "จำนวนนัดที่ลงเล่น", value: "time", sortable: false,width: "10%" },
        { text: "รูปแบบสัญญา", value: "contract", sortable: false ,width: "10%"},
        { text: "วันที่เริ่มต้น", value: "start", sortable: false ,width: "10%"},
        { text: "วันที่สิ้นสุด", value: "end", sortable: false ,width: "10%"},
      ],
        en:[
        { text: "Team/ Club Name", value: "name", sortable: false,width: "30%" },
        { text: "Position", value: "position", sortable: false ,width: "20%"},
        { text: "Shirt Number", value: "no", sortable: false ,width: "10%"},
        { text: "Matches Played", value: "time", sortable: false,width: "10%" },
        { text: "Type of Contract", value: "contract", sortable: false,width: "10%" },
        { text: "Start Date", value: "start", sortable: false ,width: "10%"},
        { text: "End Date", value: "end", sortable: false ,width: "10%"},
      ],
      },

      nation: [],
      nationTotal: 0,
      nationPage: 1,
      nationPerPage: 10,
      nationLastPage: 1,
      club: [],
      clubTotal: 0,
      clubPage: 1,
      clubPerPage: 10,
      clubLastPage: 1
      //ex3: { label: 'ความพร้อมนักกีฬา', val: this.fit.value, color: 'red' },
    };
  },
  watch: {
    nationPage() {
      this.getNationHistory();
    },
    nationPerPage() {
      this.getNationHistory();
    },
    clubPage() {
      this.getClubHistory();
    },
    clubPerPage() {
      this.getClubHistory();
    },
  },
  mounted() {
    this.getNationHistory();
    this.getClubHistory();
  },
};
</script>
