import axios from 'axios'

//const modelNameSingular = 'globalConfigEducation'
const modelNamePlural = 'globalConfigEducations'

const getData = `get${modelNamePlural.charAt(0).toUpperCase() + modelNamePlural.slice(1)}`


export default {
  state: {
    [modelNamePlural]:{
      data: {
        global_config_educations:[{
          global_config_education_name_th:'ไม่พบข้อมูล'
        }]
    }
    }
  },
  mutations: {
    [getData](state,data){
      state[modelNamePlural] = data
    }
  },
  actions: {
    async [getData]({commit}){
      axios.get('setting/global/education').then(response =>{
        commit(getData, response.data)
      })
    }
  }
}