<template>
  <v-card class="match-result-form" data-app>
    <div>
      <div class="master-data-title">ใบสมัครแข่งขัน</div>

      <v-row class="my-5">
        <v-col cols="4">
          <label>ชื่อ-นามสกุล<span class="red--text">*</span></label>
          <v-text-field
            dense
            outlined
            hide-details
            v-model="form.fullname"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <label
            >{{ $t("player.table.position")
            }}<span class="red--text">*</span></label
          >
          <v-text-field
            dense
            outlined
            hide-details
            v-model="form.position"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <label
            >{{ $t("contact_information.phone_number")
            }}<span class="red--text">*</span></label
          >
          <v-text-field
            dense
            outlined
            hide-details
            v-model="form.phone"
          ></v-text-field>
        </v-col>
      </v-row>
      <div>
        มีความประสงค์ส่งทีมเข้าสมัครแข่งขันรายการ “{{
          this.competition.setting_competition_informations
            .setting_competition_information_name_th
        }}”
        <!-- {{this.competition.setting_competition_informations.setting_competition_information_name_th}} -->
      </div>
      <v-row class="mt-2">
        <v-col cols="6" class="d-flex justify-content-between"
          >ในนาม
          <span :style="`color:#267FFF;font-size:16px`">{{
            this.personalData.setting_team_informations
              .setting_team_information_name_th
          }}</span>
          <div></div
        ></v-col>
        <v-col cols="6" class="d-flex justify-content-between"
          >โดยมี
          <span :style="`color:#267FFF;font-size:16px`">{{
            this.personalData.setting_team_informations
              .setting_team_information_manager_name
          }}</span
          >เป็นผู้จัดการ</v-col
        >
        <v-col cols="6" class="d-flex justify-content-between"
          >โทรศัพท์
          <span :style="`color:#267FFF;font-size:16px`">{{
            this.personalData.setting_team_social_contacts
              .setting_team_social_contact_phone
          }}</span>
          <div></div
        ></v-col>
      </v-row>
    </div>
    <hr />
    <div>
      <v-row>
        <v-col cols="4" v-for="(item, idx) in competitionData" :key="idx">
          <div class="mb-2">
            ประเภท {{ item.setting_sub_competition_type_name_th }}
          </div>
          <v-checkbox
            v-model="selectedItems"
            v-for="(items, index) in item.setting_sub_competition_sub_type_ids"
            :key="index"
            :label="
              `${items.setting_sub_competition_sub_type_id.setting_sub_competition_sub_type_name_th} ${items.fee}`
            "
            :value="`${items.id}-${items.setting_competition_id}`"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>
    <hr />
    <div>
      <div>ลงชื่อ และยอมรับกฎระเบียบการแข่งขัน</div>
      <v-row>
        <v-col cols="4">
          <label>อัปโหลดลายเซ็น <span class="red--text">*</span></label>
          <vue-dropzone
            v-if="imageURL === ''"
            id="picture"
            :options="dropzoneOptions"
            :useCustomSlot="true"
            @vdropzone-success="handleResponse"
          >
            <div class="dropzone-custom-content">
              <p class="dropzone-custom-title">
                อัปโหลดไฟล์
              </p>
              <p>(.png ไม่มีพื้นหลัง, ขนาด 600x200 px)</p>
            </div>
          </vue-dropzone>
          <div v-else class="upload-image">
            <a :href="this.imageURL" target="_blank">
              <v-icon style="color: #B9B9C3">fa-upload</v-icon
              ><span class="ml-3">ลายเซ็นต์ “ผู้ลงทะเบียน”</span></a
            >
          </div>
        </v-col>
        <v-col
          cols="4"
          v-if="
            competition.competition_attachments.find(
              (a) => a.attachment_type === 'RULE'
            )
          "
        >
          <a
            :href="
              competition.competition_attachments.find(
                (a) => a.attachment_type === 'RULE'
              ).attachment_information[0].file_url
            "
            target="_blank"
          >
            <div
              class="upload-image mr-2 cursor-pointer align-items-center d-flex mt-6 "
            >
              <v-icon style="color: #B9B9C3">fa-upload</v-icon>
              <span class="ml-3 ">
                กฎระเบียบการแข่งขัน{{
                  this.competition.setting_competition_informations
                    .setting_competition_information_name_th
                }}
              </span>
            </div>
          </a>
        </v-col>
        <v-col cols="4" class="card-body">
          <v-checkbox
            label="ยอมรับกฎระเบียบการแข่งขัน"
            class="mt-5"
            v-model="form.agreement"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>
    <div class="w-100 text-end">
      <v-btn
        color="primary"
        @click="handleSubmit"
        :disabled="!form.agreement"
        >{{ $t("normal.save") }}</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import { languageService } from "../../../helpers/language";
import vue2Dropzone from "vue2-dropzone";
import { mapState, mapActions } from "vuex";
import { appAxios } from "../../../helpers/app-axios.js";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "RegisterPlayerStaff",

  components: {
    vueDropzone: vue2Dropzone,
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  computed: {
    competitionData() {
      const groupedData = this.groupBySubCompetitionTypeId(
        this.sub_competitions.data
      );

      return groupedData;
    },

    ...mapState("competition", ["competition"]),
    ...mapState("sub_competition", [
      "sub_competitions",
      "personalData",
      "teamData",
    ]),
  },
  async mounted() {
    this.competitionId = this.$route.params.cid;
    if (Object.keys(this.teamData).length > 0) {
      this.form = {
        fullname: this.teamData.fullname,
        agreement: this.teamData.agreement,
        phone: this.teamData.phone,
        position: this.teamData.position,
      };
      this.imageURL = this.teamData.signature_image_path;
      this.selectedItems = this.teamData.setting_competitions.map((sc) => {
        return sc.setting_sub_competition_id + "-" + sc.setting_competition_id;
      });
    }
    this.getSettingType();
    this.getSelfTeam();
    this.getCompetition(this.competitionId);
  },
  data() {
    return {
      imageURL: "",
      imagePath: "",
      language: "th",
      typeCompetitionList: [],
      form: {},
      selectedItems: [],
      dropzoneOptions: {
        url: `/api/v1/setting/team-form/signature`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            process.env.VUE_APP_TOKEN_KEY_ADMIN
          )}`,
        },
      },
    };
  },
  watch: {
    $route(to) {
      console.log("$route", to.params.tab);

    },

    teamData() {
      
      // console.log("teamData", this.teamData);
      if (Object.keys(this.teamData).length > 0) {
        this.form = {
          fullname: this.teamData.fullname,
          agreement: this.teamData.agreement,
          phone: this.teamData.phone,
          position: this.teamData.position,
        };

        this.imageURL = this.teamData.signature_image_path;
        this.selectedItems = this.teamData.setting_competitions.map((sc) => {
          return (
            sc.setting_sub_competition_id + "-" + sc.setting_competition_id
          );
        });
      }
    },
  },
  methods: {
    ...mapActions("competition", ["getCompetition"]),
    ...mapActions("sub_competition", ["getSubCompetitions", "getSelfTeam"]),
    groupBySubCompetitionTypeId(data) {
      const groupedData = {};
      // this.form={
      //   fullname:this.teamData.fullname,
      //   agreement:this.teamData.agreement,
      //   phone:this.teamData.phone,
      //   position:this.teamData.position
      // }

      // this.imageURL = this.teamData.signature_image_path
      // this.selectedItems=this.teamData.setting_competitions.map((sc)=>{
      //   return sc.setting_sub_competition_id+'-'+sc.setting_competition_id
      // })
      data.forEach((item) => {
        const subCompetitionTypeId = item.setting_sub_competition_type_id.id;
        if (!groupedData[subCompetitionTypeId]) {
          groupedData[subCompetitionTypeId] = {
            sub_competition_type_id: subCompetitionTypeId,
            setting_sub_competition_fee: item.setting_sub_competition_fee,
            setting_sub_competition_type_name_th:
              item.setting_sub_competition_type_id
                .setting_sub_competition_type_name_th,
            setting_sub_competition_sub_type_ids: [
              {
                id: item.id,
                setting_competition_id: item.setting_competition_id,
                setting_sub_competition_sub_type_id:
                  item.setting_sub_competition_sub_type_id,
                fee: item.setting_sub_competition_fee,
              },
            ],
          };
        } else {
          groupedData[
            subCompetitionTypeId
          ].setting_sub_competition_sub_type_ids.push({
            id: item.id,
            setting_competition_id: item.setting_competition_id,

            fee: item.setting_sub_competition_fee,
            setting_sub_competition_sub_type_id:
              item.setting_sub_competition_sub_type_id,
          });
        }
      });

      // console.log('groupedData',groupedData)
      // Convert the object to an array of values
      return Object.values(groupedData);
    },
    async onUploadSuccess(file, refName) {
      // console.log("file: ", file);
      this.$refs[refName][0].removeFile(file);
      this[refName]();
    },
    handleResponse(file, response) {
      this.imageURL = response.data.file.file_url;
      this.imagePath = response.data.file.file_path;
    },
    async handleSubmit() {
      // console.log("Upload", this.form, this.imageURL, this.selectedItems);

      const parsedCompetitions = this.selectedItems.map((item) => {
        const split = item.split("-");
        return {
          setting_competition_id: parseInt(split[1]),
          setting_sub_competition_id: parseInt(split[0]),
        };
      });
      const objData = {
        ...this.form,
        competition_id: +this.$route.params.cid,
        team_id: this.personalData.id,
        signature_image_path:this.teamData.signature_image_path??
          this.imagePath,
        setting_competitions: parsedCompetitions,
      };

      // if(this.personalData){
      //   objData['team_id'] = +this.personalData.id
      // }

      if (Object.keys(this.teamData).length > 0) {
        objData["id"] = +this.teamData.id;
      }

      await appAxios.post(`setting/team-form/create`, objData).then(() => {
        this.$router.push({
          name: "sub-competition-detail",
          params: { cid: this.competitionId, sid: "staff", tab: 1 },
        });
      });

      // await
    },
  },
};
</script>

<style scoped>
.match-result-form {
  font-size: 14px;
}

.centered-input >>> input {
  text-align: center;
}
.v-input--selection-controls {
  padding: 0;
  margin: 0;
}
.dropzone {
  min-height: 10px;
  border: 2px dashed #1161e0;
  background: #f8f8f8;
  position: relative;
}
.test {
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.625;
}
.textWord {
  word-break: break-all;
}
.container {
  position: relative;
  width: 60%;
}
.dropzone .dz-message {
  margin: 0 !important;
}
.upload-image {
  border: 2px solid #267fff;
  border-radius: 10px;
  padding: 10px 5px;
}
.upload-image span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
