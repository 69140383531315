<template>
  <v-app id="Dashboard">
    <!-- <div class="content app-content h-100"> -->
    <!-- <Tab1 /> -->
    <div v-if="isAdmin">
      <Tab1 />
    </div>
    <div v-else>
      <Home />
    </div>
    <!-- </div> -->
  </v-app>
</template>

<script>
import Tab1 from "../../components/Dashboard/Tab1.vue";
import Home from "../../components/Dashboard/Home.vue";
export default {
  name: "Dashboard",
  components: {
    Tab1,
    Home,
  },
  computed: {
    isAdmin() {
      return this.$store.state.permission.role !== "B_TEAM_ADMIN";
    },
  },
  mounted() {},
};
</script>

<style></style>
