import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home/Home.vue'
import About from '../views/Home/About.vue'
import Player from './player'
import Staff from './staff'
import MasterData from './master_data'
import Team from './team'
import Stadium from './stadium'
import Competition from './competition'
import Association from './association'
import SaveToken from '../views/Token/SaveToken.vue';
import authGuard from './guard/authGuard';
import permissionGuard from './guard/permissionGuard';
import Main from '../views/Main.vue';
import Register from '../views/Register/Register.vue'
// import Dashboard1 from './dashboard'
import Dashboard from '../views/Dashboard/Dashboard.vue'

//import Header from '../components/layouts/Header.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    beforeEnter: authGuard,
    component: Main,
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: permissionGuard,
      },
      {
        path: 'sso',
        name: 'saveToken',
        component: SaveToken
      },
      {
        path: 'report',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter: permissionGuard,
        component: About
        //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path:'register',
        name:'Register',
        beforeEnter: permissionGuard,
        component: Register
      },
      ...Player,
      ...Staff,
      ...MasterData,
      ...Team,
      ...Stadium,
      ...Competition,
      ...Association,
      // ...Dashboard1
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
