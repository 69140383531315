<template>
  <div class="py-2 px-6">
    <template v-for="item in round">
      <v-row
        class="d-flex justify-content-center align-items-center"
        :key="item.id"
      >
        <v-col
          :span="1"
          class="d-flex justify-content-center align-items-center"
        >
          {{ dateFormat(item.versus_match_date) }}
        </v-col>
        <v-col
          :span="4"
          class="d-flex justify-content-center align-items-center"
        >
          <div
            v-if="item.versus_match_team_detail_one_id"
            class="d-flex justify-center"
          >
            <v-img
              :src="
                item.versus_match_team_detail_one_id.versus_match_team_image
              "
              max-height="20"
              max-width="20"
              contain
              class="ma-2"
            ></v-img>
            <div>
              <div>
                {{
                  language === "th"
                    ? item.versus_match_team_detail_one_id
                        .versus_match_team_name
                    : item.versus_match_team_detail_one_id
                        .versus_match_team_name_en
                }}
              </div>
              <div :class="resultClass(item, 'home', '--text')">
                {{ result(item, "home") }}
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          :span="2"
          class="d-flex justify-content-center align-items-center"
        >
          <div class="d-flex justify-center">
            <v-sheet
              v-if="shouldAddResult(item)"
              outlined
              rounded
              width="65"
              height="65"
              class="
                ma-2
                pa-2
                d-flex
                flex-column
                justify-content-center
                align-items-center
                clickable
              "
              @click="onClickAddResult(item)"
            >
              <div
                class="
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                "
              >
                <div class="text-decoration-underline">
                  {{ $t("sub_competition.add_score") }}
                </div>
                <v-icon x-small class="mt-1">fa-plus</v-icon>
              </div>
            </v-sheet>
            <v-chip
              v-else
              :class="resultClass(item, 'home', '--bg')"
              label
              @click="$emit('updateResult', item)"
            >
              <span class="text-decoration-underline score--text">
                {{
                  item.versus_match_team_detail_one_id.versus_match_score || 0
                }}
                -
                {{
                  item.versus_match_team_detail_two_id.versus_match_score || 0
                }}
              </span>
            </v-chip>
          </div>
        </v-col>
        <v-col
          :span="4"
          class="d-flex justify-content-center align-items-center"
        >
          <div
            v-if="item.versus_match_team_detail_two_id"
            class="d-flex justify-center"
          >
            <v-img
              :src="
                item.versus_match_team_detail_two_id.versus_match_team_image
              "
              max-height="20"
              max-width="20"
              contain
              class="ma-2"
            ></v-img>
            <div>
              <div>
                {{
                  language === "th"
                    ? item.versus_match_team_detail_two_id
                        .versus_match_team_name
                    : item.versus_match_team_detail_two_id
                        .versus_match_team_name_en
                }}
              </div>
              <div :class="resultClass(item, 'away', '--text')">
                {{ result(item, "away") }}
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          :span="1"
          class="d-flex justify-content-center align-items-center"
        >
          <v-icon
            v-if="item.id"
            class="pa-3"
            x-small
            @click="onDeleteResult(item)"
            >fa-trash</v-icon
          >
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { format } from "date-fns";
import { th } from "date-fns/locale";
import MasterDataHelper from "@/helpers/master_data_helper.js";

import { mapActions } from "vuex";
import { languageService } from "../../../helpers/language";

export default {
  name: "MatchRound",

  props: ["round"],

  components: {},
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  data() {
    return {
      language: "th",
      page: 1,
      pageSize: 10,
      pageCount: 0,
      pageCounts: [10, 20, 50, 100],
      itemsPerPage: 10,
      range: [10, 20],
      dialogConfirmDelete: false,
      itemDeleting: null,
      subCompetitionId: null,
    };
  },

  mounted() {
    this.subCompetitionId = this.$route.params.sid;
  },

  methods: {
    ...mapActions("versus_match", ["deleteMatch"]),
    dateFormat(date) {
      try {
        return format(new Date(date), "dd/MM/yyyy", { locale: th });
      } catch (e) {
        return "-";
      }
    },
    shouldAddResult(item) {
      return (
        !item.id ||
        (item.versus_match_team_detail_one_id == null &&
          item.versus_match_team_detail_two_id == null) ||
        !item.versus_match_team_detail_one_id.versus_match_score ||
        !item.versus_match_team_detail_two_id.versus_match_score
      );
    },
    onClickAddResult(item) {
      if (!item.id) {
        this.$emit("addResult", item.competition_section_id);
      } else {
        this.$emit("updateResult", item);
      }
    },
    result(item, side) {
      if (
        item.versus_match_team_detail_one_id &&
        item.versus_match_team_detail_two_id
      ) {
        const diff =
          item.versus_match_team_detail_one_id.versus_match_score -
          item.versus_match_team_detail_two_id.versus_match_score;
        if (diff == 0) {
          return this.$t("competition_score.draw");
        } else if (diff > 0) {
          return side == "home"
            ? this.$t("competition_score.win")
            : this.$t("competition_score.lose");
        } else if (diff < 0) {
          return side == "away"
            ? this.$t("competition_score.win")
            : this.$t("competition_score.lose");
        }
      } else {
        return "";
      }
    },
    resultClass(item, side, append) {
      if (
        item.versus_match_team_detail_one_id &&
        item.versus_match_team_detail_two_id
      ) {
        const diff =
          item.versus_match_team_detail_one_id.versus_match_score -
          item.versus_match_team_detail_two_id.versus_match_score;
        if (diff == 0) {
          return `draw${append}`;
        } else if (diff > 0) {
          return side == "home" ? `win${append}` : `lost${append}`;
        } else if (diff < 0) {
          return side == "away" ? `win${append}` : `lost${append}`;
        }
      } else return "";
    },
    async onDeleteResult(item) {
      this.itemDeleting = item;
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            this.deleteMatch({
              sid: this.subCompetitionId,
              csid: this.itemDeleting.competition_section_id,
              mid: this.itemDeleting.id,
            });
          }
        }
      );
    },
    confirmDeleteItem() {
      this.dialogConfirmDelete = false;
      this.deleteMatch({
        sid: this.subCompetitionId,
        csid: this.itemDeleting.competition_section_id,
        mid: this.itemDeleting.id,
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("sub_competition.date"),
          value: "date",
          align: "center",
        },
        {
          text: this.$t("sub_competition.team") + " 1",
          value: "home",
          width: "30%",
          align: "center",
        },
        { text: "ผลการแข่งขัน", value: "result", align: "center" },
        {
          text: this.$t("sub_competition.team") + " 2",
          value: "away",
          width: "30%",
          align: "center",
        },
        { text: "", value: "actions", sortable: false, align: "center" },
      ];
    },
  },
};
</script>