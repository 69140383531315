import Team from '../views/Team/Team.vue'
import AddTeam from '../views/Team/AddTeam.vue'
import permissionGuard from './guard/permissionGuard';

const team = [
    {
        path: '/team',
        name: 'Team',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter: permissionGuard,
        component: Team
    },
    {
        path: '/team/add/',
        name: 'AddTeam',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter: permissionGuard,
        component: AddTeam
    },
    {
        path: '/team/edit/:id',
        name: 'EditTeam',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter: permissionGuard,
        component: AddTeam,
        props: true
    }
    
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
]

export default team;
