<template>
  <v-app :id="model.nameSingular">
    <div class="app-content content">
      <div class="content-wrapper">
        <div class="content-body content-bg">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="master-data-title">
                {{ $t('setting_player_status.setting_status_skill') }}
              </div>
            </div>
            <div class="col-12 col-md-6">
              <v-btn
                color="primary"
                class="m-l-5px float-right"
                v-if="isEditable"
                @click="handleSubmit"
              >
                {{ $t('normal.save') }}
              </v-btn>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-4 col-sm-4">
              {{ $t('setting_player_status.example') }}
            </div>

            <div class="col-12 col-md-4 col-sm-4">
              {{ $t('setting_player_status.description_th') }}
            </div>

            <div class="col-12 col-md-4 col-sm-4">
              {{ $t('setting_player_status.description_en') }}
            </div>
          </div>

          <div class="row" v-for="(item, index) in physicalFitnesses" :key="index">
            <div class="col-12 col-md-4 col-sm-4">
              <PhysicalProgressBar :label="item['global_config_physical_fitness_name_'+$t('short_language')]" :value="item.global_config_physical_fitness_percentage" />
            </div>

            <div class="col-12 col-md-4 col-sm-4">
              <div class="input-label-number">
                <div class="label-number">{{index + 1}}.</div>
                <input type="text" class="form-control" :id="'name_th' + index" :readonly="!isEditable" v-model="input[index].global_config_physical_fitness_name_th">
              </div>
            </div>

            <div class="col-12 col-md-4 col-sm-4">
              <div class="input-label-number">
                <div class="label-number">{{index + 1}}.</div>
                <input type="text" class="form-control" :id="'name_en' + index" :readonly="!isEditable" v-model="input[index].global_config_physical_fitness_name_en">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import PhysicalProgressBar from '@/components/PhysicalProgressBar.vue'

// change here
const modelNameSingular = 'physicalFitness'
const modelNamePlural = 'physicalFitnesses'

// non change
const getAllName = `get${modelNamePlural.charAt(0).toUpperCase() + modelNamePlural.slice(1)}`

export default {
  name: modelNameSingular,
  components:{
    PhysicalProgressBar,
  },
  data() {
    return {
      model: {
        nameSingular: modelNameSingular,
        namePlural: modelNamePlural,
      },
      // physicals: {
      //   10: 'มีอาการบาดเจ็บรุนแรง',
      //   20: 'มีอาการบาดเจ็บ',
      //   30: 'มีอาการอ่อนล้า',
      //   40: 'ขาดความฟิต',
      //   50: 'เสี่ยงที่จะลงเล่น',
      //   60: 'ลงเล่นได้ไม่เต็มเกม',
      //   70: 'ผ่านความฟิต',
      //   80: 'เรียกความฟิต',
      //   90: 'พร้อมลงเล่น',
      //   100: 'พร้อมลงเล่นสมบูรณ์'
      // }
    }
  },
  computed : {
    input() {
      return this.$store.state[modelNameSingular][modelNamePlural]
    },
    [modelNamePlural]() {
      return this.$store.state[modelNameSingular][modelNamePlural]
    },
    isEditable() {
      return this.$store.state.permission.permissions.setting_edit
    }
  },
  created() {
    this.getAll()
  },
  methods: {
    getAll() {
      this.$store.dispatch(getAllName)
    },
    savePhysicalFitness() {
      if (!this.isEditable) return;
      this.$store.dispatch('savePhysicalFitness', this.input)
    },
    handleSubmit(e) {
      e.preventDefault()
      this.savePhysicalFitness()
    },
  },
}
</script>

<style>
</style>
