import Relation from '../views/MasterData/Relation.vue'
import Education from '../views/MasterData/Education.vue'
import PlayerPosition from '../views/MasterData/PlayerPosition.vue'
import StaffPosition from '../views/MasterData/StaffPosition.vue'
import Award from '../views/MasterData/Award.vue'
import SportCategory from '../views/MasterData/SportCategory.vue'
import Department from '../views/MasterData/Department.vue'
import InjuryType from '../views/MasterData/InjuryType.vue'
import Skill from '../views/MasterData/Skill.vue'
import PhysicalFitness from '../views/MasterData/PhysicalFitness.vue'
import { snakeCase } from '../helpers/snakeCase';
import permissionGuard from './guard/permissionGuard';

const components = [
  Relation,
  Education,
  PlayerPosition,
  StaffPosition,
  Award,
  SportCategory,
  Department,
  InjuryType,
  Skill,
  PhysicalFitness,
]

const masterData = []

for(const c of components) {
  masterData.push({
    path: `/master_data/${snakeCase(c.name)}`,
    name: c.name.charAt(0).toUpperCase() + c.name.slice(1),
    beforeEnter: permissionGuard,
    component: c
  })
}

export default masterData;
