import { appAxios } from "../helpers/app-axios";
// import dataThailand from '../components/Dashboard/thailand.json'
import province from './global_config_province'

export const getMapPlayer = "getMapPlayer"
export const getPieStaffState = "getPieStaff"

export const getTablePlayerThaiState = "getTablePlayerThai"
export const getTablePlayerAllState = "getTablePlayerAllState"
export const tablePlayerAll = "tablePlayerAll"
export const getLineStaffState = "getLineStaff"

export const filterMap = "filtermap"
export const tablePlayerThai = "tablePlayerThai"
export default {
    state: {
        mapPlayerState: [],
        filterMapPlayer: [],
        pieStaff: {},
        tablePlayerThai: {},
        tablePlayerAll: {},
        lineStaff: [],

    },
    mutations: {
        [getMapPlayer](state, data) {
            const map = data?.res?.player.map((m) => {
                const short = province.state.provinces.find((elm) => elm.province_code === m.province_code)
                return {
                    text: short.province_name_abbreviation.toLowerCase(),
                    name_th: m.province_name,
                    name_en: short.province_name_en,
                    value: m.count_player
                }
            })
            state.mapPlayerState = map
        },
        [filterMap]() {

        },
        [getPieStaffState](state, { res }) {
            const name = res?.map((ele) => { return ele.global_config_position_name_th })
            const data = res?.map((ele) => { return ele.count })

            var result = name.map((names, index) => {
                return colorPieChart[index]
            })
            state.pieStaff = {
                th: {
                    labels: res?.map((ele) => { return ele.global_config_position_name_th }),
                    datasets: [
                        {
                            backgroundColor: result,
                            data
                        }
                    ]
                },
                en: {
                    labels: res?.map((ele) => { return ele.global_config_position_name_en }),
                    datasets: [
                        {
                            backgroundColor: result,
                            data
                        }
                    ]
                }
            }

        },
        [getTablePlayerThaiState](state, { res }) {
            if (res?.team?.data) {

                const header = [{
                    text: '', value: 'name', align: "center",
                    sortable: false, class: "headerName", width: '20%'
                }]
                const { data, ...others } = res?.team
                const head = data[0]?.series
                    .map(heads => {
                        return {
                            text: heads.year,
                            value: heads.year,
                            sortable: false,
                            align: "center",
                            class: "headerSport",
                        }
                    })
                const teamData = data?.map((elm) => {
                    const checkyear = elm?.series?.map((se) => {
                        return { [se.year]: se.count }
                    })
                    var newObj = Object.assign({}, {
                        name: {
                            th: elm.setting_team_information_name_th,
                            en: elm.setting_team_information_name_en
                        }
                    })
                    return Object.assign(newObj, ...checkyear)
                })
                state.tablePlayerThai = {
                    ...others,
                    header: header.concat(head),
                    data: teamData

                }
                console.log('state.tablePlayerThai', state.tablePlayerThai);
            }
        },
        [getTablePlayerAllState](state, { res }) {

            if (res?.team?.data) {

                const header = [{
                    text: '', value: 'name', align: "center",
                    sortable: false, class: "headerName", width: '20%'
                }]
                const { data, ...others } = res?.team
                const head = data[0]?.series
                    .map(heads => {
                        return {
                            text: heads.year,
                            value: heads.year,
                            sortable: false,
                            align: "center",
                            class: "headerSport",
                        }
                    })
                const teamData = data?.map((elm) => {
                    const checkyear = elm?.series.map((se) => {
                        return { [se.year]: se.count }
                    })

                    var newObj = Object.assign({}, {
                        name: {
                            th: elm.setting_team_information_name_th,
                            en: elm.setting_team_information_name_en
                        }
                    })
                    return Object.assign(newObj, ...checkyear)
                })
                state.tablePlayerAll = {
                    ...others,
                    header: header.concat(head),
                    data: teamData
                }
                console.log('state.tablePlayerAll', teamData, header.concat(head), state.tablePlayerAll);
            }
        },
        [getLineStaffState]() { }
    },
    actions: {
        async [getMapPlayer]({ commit }, payload) {
            const params = new URLSearchParams(payload);
            return appAxios.get(`dashboard/find-player-in-provinces`, { params }).then((res) => {
                commit(getMapPlayer, { res: res.data.data })
            })
        },
        async [filterMap]({ commit }, payload) {
            console.log('filterMap', payload, commit);

        },

        async [getPieStaffState]({ commit }) {
            return appAxios.get(`dashboard/personnel-ratio`).then((res) => {
                commit(getPieStaffState, { res: res.data.data })
            })
        },
        async [getTablePlayerThaiState]({ commit }, payload) {
            const date = new Date()
            const param = {
                ...payload,
                start_year: date.getFullYear() - 5,
                end_year: date.getFullYear()
            }
            const params = new URLSearchParams(param);
            appAxios.get(`dashboard/team-nation-player-history`, { params }).then((res) => {
                commit(getTablePlayerThaiState, { res: res.data.data })
            })
        },
        async [getTablePlayerAllState]({ commit }, payload) {
            const date = new Date()
            const param = {
                ...payload,
                start_year: date.getFullYear() - 5,
                end_year: date.getFullYear()
            }
            const params = new URLSearchParams(param);
            appAxios.get(`dashboard/team-club-player-history`, { params }).then((res) => {
                console.log('res.data.data ', res.data.data);
                commit(getTablePlayerAllState, { res: res.data.data })
            })
        },
        async [getLineStaffState]({ commit, state }) {
            console.log('getLineStaffState', commit, state);
        },
    }
}

const colorPieChart = [
    "#0D6188",
    "#58CCD3",
    "#26A790",
    "#0A8316",
    "#67EC74",
    "#DCE764",
    "#8F9A0F",
    "#8A670D",
    "#E7C367",
    "#C75E32",
    "#601D01",
    "#981B8B",
    "#EF80E4",
    "#EF7F85",
    "#BF242D",
    "#58ADD3",
    "#58ADD3",
    "#3EBFA8",
    "#0A836D",
    "#3FD34D",
    "#B0E46D",
    "#B0BC22",
    "#6B4E04",
    "#D2AA42",
    "#D87247",
    "#8B2F07",
    "#7D0D72",
    "#D352C6",
    "#F8AEB3",
    "#D74B53",
]
// const dataTable = {
//     header: [{ text: '', value: 'name' }, { text: '2018', value: '2018' }, { text: '2019', value: '2019' }, { text: '2020', value: '2020' }, { text: '2021', value: '2021' }, { text: '2022', value: '2022' }, { text: '2023', value: '2023' }],
//     data: [{
//         name: "U-15",
//         "2018": 3,
//         "2019": 6,
//         "2020": 24,
//         "2021": 24,
//         "2022": 59,
//         "2023": 30,

//     },
//     {
//         name: "U-18",
//         "2018": 59,
//         "2019": 30,
//         "2020": 30,
//         "2021": 30,
//         "2022": 59,
//         "2023": 30,

//     },
//     {
//         name: "U-20",
//         "2018": 11,
//         "2019": 23,
//         "2020": 24,
//         "2021": 10,
//         "2022": 59,
//         "2023": 30,

//     },
//     {
//         name: "U-22",
//         "2018": 34,
//         "2019": 30,
//         "2020": 49,
//         "2021": 55,
//         "2022": 59,
//         "2023": 30,

//     },
//     {
//         name: "ทีมชาติไทยชุดใหญ่",
//         "2018": 11,
//         "2019": 23,
//         "2020": 24,
//         "2021": 10,
//         "2022": 59,
//         "2023": 30,
//     },
//     {
//         name: "ทีมเยาวชนไม่เกิน 18 ปี",
//         "2018": 34,
//         "2019": 30,
//         "2020": 49,
//         "2021": 55,
//         "2022": 59,
//         "2023": 30,
//     },]
// }
