<template>
  <div class="container">
    <div class="content-header">
      <div class="content-header-left">
        <div class="row mb-4">
          <div class="col-12 col-md-8">
            <router-link to="/team" custom>
              <v-icon class="content-header-title float-left mb-0">
                fa-chevron-left
              </v-icon>
              <h3 class="content-header-title float-left mb-0 ml-2">
                <!-- ทีม/สโมสรทั้งหมด -->
                {{ $t("team.all_team") }}
              </h3>
            </router-link>
          </div>
          <div
            v-if="isEditable"
            class="col-12 col-md-4 mb-1 d-flex justify-content-end"
          >
            <v-btn class="float-right" color="primary" @click="saveData()">
              <!-- บันทึก -->
              {{ $t("normal.save") }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <div class="content-body">
      <v-toolbar flat>
        <v-tabs
          v-model="tab"
          :show-arrows="true"
          active-class="text-white tab-color"
          hide-slider
        >
          <!-- <v-tabs-slider color="white"></v-tabs-slider> :disabled="isAddRoute && idx !== 0" -->
          <v-tab
            :disabled="isAddRoute && idx !== 0"
            class="app-rounded-top"
            v-for="(item, idx) in tabs"
            :key="idx"
          >
            <v-icon>{{ item.icon }}</v-icon>
            <div class="ml-2">
              <span> {{ item.topic }} </span> <br />
              <span class="font-small-1"> {{ item.detail }} </span>
            </div>
          </v-tab>
        </v-tabs>
      </v-toolbar>
    </div>

    <v-tabs-items class="shadow" v-model="tab">
      <v-tab-item v-for="(item, idx) in tabContents" :key="idx" :value="idx">
        <component ref="tabContent" v-bind:is="item"></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import Tab1 from "./Tab1.vue";
import Tab2 from "./Tab2.vue";
import Tab3 from "./Tab3.vue";
import Tab4 from "./Tab4.vue";
import Tab5 from "./Tab5.vue";
import Tab6 from "./Tab6.vue";
import { languageService } from "../../helpers/language";
import { appAxios } from "../../helpers/app-axios";


export default {
  name: "team-tabs",
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  beforeDestroy() {
    console.log("destroy teamtab");
    this.subscription.unsubscribe();
  },
  mounted() {
    const currentTab = this.$route.query?.tab;
    const isAddRoute = this.$route.path.includes("add");
    if (isAddRoute) {
      this.$data.isAddRoute = true;
    }
    if (currentTab && !isAddRoute) {
      this.$data.tab = Number(currentTab);
    }
  },
  methods: {
    saveData() {
      // console.log(this.$refs.tabContent);
      const activeComponent = this.$refs.tabContent.find((ele) =>
        ele.$parent.$el.className.includes("active")
      );
      // this.$refs.tabContent[this.$data.tab || 0].save();
      if (activeComponent && activeComponent.save) {
        activeComponent.save();
      }
      // console.log(activeComponent)
      // this.$store.dispatch(insertPlayerData,this.$store.state[player][playerData])
    },
  },
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5,
    Tab6,
  },
  computed: {
    isEditable() {
      return this.$store.state.permission.permissions.team_edit;
    },
    methods:{
      saveData() {
        // console.log(this.$refs.tabContent);
        const activeComponent = this.$refs.tabContent.find(ele => ele.$parent.$el.className.includes('active'));
        // this.$refs.tabContent[this.$data.tab || 0].save();
        if (activeComponent && activeComponent.save) {
          activeComponent.save();
        }
        if(this.$data.tab === 5){
          const objData={
            "setting_team_information_remark": this.$store.state.sub_competition.remark_th,
            "setting_team_information_remark_en": this.$store.state.sub_competition.remark_en
          }

          appAxios
            .post(`team/${this.$data.teamId}/medias/remark`, objData)
        }
        // this.$store.dispatch(insertPlayerData,this.$store.state[player][playerData])
      },
    },
  },
  data() {
    return {
      isAddRoute: false,
      tab: null,
      tabs: [
        {
          topic: this.$t("team.tab.team_information", this.language),
          detail: this.$t("team.tab.add_team_information", this.language),
          icon: "fa-users",
        },
        {
          topic: this.$t("team.tab.athletes_in_team", this.language),
          detail: this.$t("team.tab.add_athlete_in_team", this.language),
          icon: "fa-user",
        },
        {
          topic: this.$t("team.tab.staffs_in_team", this.language),
          detail: this.$t("team.tab.add_staff_in_team", this.language),
          icon: "fa-user",
        },
        {
          topic: this.$t("team.tab.competition_information", this.language),
          detail: this.$t("team.tab.add_competition_information", this.language),
          icon: "$trophy",
        },
        {
          topic: this.$t("player.image.title", this.language),
          detail: this.$t("player.image.add_image", this.language),
          icon: "fa-film",
        },
        {
          topic: this.$t("player.document.title", this.language),
          detail: this.$t("player.document.add_document", this.language),
          icon: "fa-file-medical",
        },
      ],
      tabContents: [Tab1, Tab2, Tab3, Tab4, Tab5, Tab6],
      language: "th",
    };
  },
  watch: {
    tab() {
      this.$router
        .push({ path: this.$route.path, query: { tab: this.$data.tab } })
        .catch(() => {});
    },
    $route() {
      this.isAddRoute = this.$route.path.includes("add");
      const currentTab = this.$route.query?.tab;
      if (currentTab) {
        this.$data.tab = Number(currentTab);
      }
    },
    language(newVal) {
      this.tabs = [
        {
          topic: this.$t("team.tab.team_information", newVal),
          detail: this.$t("team.tab.add_team_information", newVal),
          icon: "fa-users",
        },
        {
          topic: this.$t("team.tab.athletes_in_team", newVal),
          detail: this.$t("team.tab.add_athlete_in_team", newVal),
          icon: "fa-user",
        },
        {
          topic: this.$t("team.tab.staffs_in_team", newVal),
          detail: this.$t("team.tab.add_staff_in_team", newVal),
          icon: "fa-user",
        },
        {
          topic: this.$t("team.tab.competition_information", newVal),
          detail: this.$t("team.tab.add_competition_information", newVal),
          icon: "$trophy",
        },
        {
          topic: this.$t("player.image.title", newVal),
          detail: this.$t("player.image.add_image", newVal),
          icon: "fa-film",
        },
        {
          topic: this.$t("player.document.title", newVal),
          detail: this.$t("player.document.add_document", newVal),
          icon: "fa-file-medical",
        },
      ];
    },
    data(){
      return {
        isAddRoute: false,
        tab: null,
        tabs: [
          {
            topic: "ข้อมูลทีม",
            detail: "เพิ่มข้อมูลทีม",
            icon: "fa-users",
          },
          {
            topic: "ข้อมูลนักกีฬาในทีม",
            detail: "เพิ่มข้อมูลนักกีฬาในทีม",
            icon: "fa-user",
          },
          {
            topic: "ข้อมูลบุคลากรในทีม",
            detail: "เพิ่มข้อมูลบุคลากรในทีม",
            icon: "fa-user",
          },
          {
            topic: "ข้อมูลการแข่งขัน",
            detail: "เพิ่มข้อมูลการแข่งขัน",
            icon: "$trophy",
          },
          {
            topic: "ภาพและวิดีโอ",
            detail: "เพิ่มภาพและวิดีโอ",
            icon: "fa-film",
          },
          {
            topic: "คลังเอกสาร",
            detail: "เพิ่มเอกสาร",
            icon: "fa-file-medical",
          },
        ],
        tabContents: [Tab1, Tab2, Tab3,  Tab4,  Tab5, Tab6],
        teamId:null
      }
    },
  },
};
</script>
<style scoped lang="scss">
.tab-color {
  background-color: #0d6efd;
}
.v-tab {
  margin: 0 2px;
  &:not(.v-tab--active) {
    background: #f1f1f5;
  }
}
.form-container {
  margin: 0 auto;
}
@media (min-width: 576px) {
  .form-container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .form-container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .form-container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .form-container {
    max-width: 1140px;
  }
}
.app-rounded-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
