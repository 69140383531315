<template>
  <v-app>
    <div class="content app-content">
      <div class="content-header mb-6">
        <div class="content-header-left">
          <div class="row d-flex align-items-center justify-space-between">
            <div class="col-12 col-md-8 d-flex align-items-center">
              <router-link to="/competition" custom>
                <v-icon small class="content-header-title float-left mb-0">
                  fa-chevron-left
                </v-icon>
                <h5 class="content-header-title float-left mb-0 ml-2">
                  {{ $t("competition.all") }}
                </h5>
              </router-link>
              <h5 class="content-header-title float-left mb-0 ml-2">/</h5>
              <h5 class="content-header-title float-left mb-0 ml-2">
                {{
                  competition.setting_competition_informations[
                    "setting_competition_information_name_" +
                      $t("short_language")
                  ]
                }}
              </h5>
            </div>
            <div v-if="!isAdmin">
              <v-btn class="download-flow" @click="download"
                ><v-icon class="mr-3"> fa-download </v-icon>ดาวน์โหลด</v-btn
              >
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <v-card>
          <v-row v-if="isAdmin">
            <v-col class="d-flex justify-end">
              <v-btn
                class="mx-2"
                color="primary"
                small
                @click="onEditCompetition"
                >{{ $t("normal.edit_list") }}</v-btn
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-items-center" cols="2">
              <v-img
                v-if="competition.setting_competition_media_profile_icon"
                class="d-flex justify-content-center center ma-5"
                :src="competition.setting_competition_media_profile_icon"
                width="128"
                contain
              />
              <v-img
                v-else
                class="d-flex justify-content-center center ma-5"
                src="@/assets/images/competition_placeholder.png"
                width="128"
                contain
              />
            </v-col>
            <v-col
              class="
                d-flex
                flex-column
                justify-center
                align-items-center
                border-right
                pa-2
              "
              cols="3"
            >
              <div>{{ name("th") }}</div>
              <div>{{ name("en") }}</div>
              <div>{{ competitionCode }}</div>
            </v-col>
            <v-col
              class="
                d-flex
                align-items-center
                justify-space-around
                text-center
                info-header
              "
            >
              <div>
                <div color="black">{{ $t("competition.abbreviation") }}</div>
                <div
                  class="grey--text"
                  v-if="this.$t('short_language') === 'th'"
                >
                  {{ abbreviation }}
                </div>
                <div class="grey--text" v-else>{{ abbreviationEn }}</div>
              </div>
              <div>
                <div color="black">{{ $t("competition.level") }}</div>
                <div class="grey--text">
                  {{ competitionLevel(competition.setting_competition_level) }}
                </div>
              </div>
              <div>
                <div color="black">{{ $t("sub_competition.year") }}</div>
                <div class="grey--text">
                  {{
                    competition.setting_competition_informations
                      .setting_competition_information_year
                  }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-16">
          <v-data-table
            :headers="isAdmin ? headersAdmin : headersStaff"
            :items="competitionData"
            :single-select="singleSelect"
            :page.sync="params.page"
            :items-per-page="params.size"
            class="elevation-1 row-pointer"
            hide-default-footer
            @page-count="pageCount = $event"
            @click:row="onRowClick"
          >
            <template v-slot:[`item.count_team`]="{ item }">
              {{ item.count_team }} ทีม
            </template>
            <template
              v-slot:[`item.status`]="{
                item,
              }"
            >
              <div
                v-if="item.status === `APPROVED`"
                class="text-primary font-weight-bold"
              >
                อนุมัติแล้ว
              </div>
              <div
                v-else-if="item.status === `REJECTED`"
                class="text-danger font-weight-bold"
              >
                ไม่อนุมัติ
              </div>
              <div
                v-else-if="item.status === `WAITING`"
                class="text-warning font-weight-bold"
              >
                รอการอนุมัติ
              </div>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="pa-2" small v-bind="attrs" v-on="on"
                    >fa-ellipsis-v</v-icon
                  >
                </template>
                <v-list>
                  <v-list-item v-for="(action, index) in actions" :key="index">
                    <v-btn text @click="onClick(action.key, item)">
                      <v-icon small class="mr-2">{{ action.icon }}</v-icon
                      >{{ action.text }}
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:top="{}">
              <v-row class="px-6 d-flex flex-row justify-space-between">
                <v-col
                  cols="auto"
                  class="d-flex flex-row justify-center align-items-center"
                >
                  <span class="text-primary">{{ $t("competition.type") }}</span>
                </v-col>
                <v-col
                  cols="auto"
                  class="d-flex flex-row justify-center align-items-center"
                >
                  <v-btn color="primary" v-if="!isAdmin" @click="onRowClick"
                    >สมัครแข่งขัน</v-btn
                  >
                  <v-btn color="primary" @click="onAddSubCompetition" v-else
                    >+ เพิ่มประเภทการแข่งขัน</v-btn
                  >
                </v-col>
              </v-row>
            </template>
            <template v-slot:footer="{}">
              <TableFooter
                :perPage.sync="params.size"
                :currentPage.sync="params.page"
                :lastPage="sub_competitions.lastPage"
                :total="sub_competitions.total"
              />
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
    <AddTypeCompetitionModal
      :dialog.sync="showAddTypeModal"
      @commit="handleSubmit"
    />
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { appAxios } from "../../helpers/app-axios";

import TableFooter from "@/components/TableFooter.vue";
import { languageService } from "../../helpers/language";
import MasterDataHelper from "@/helpers/master_data_helper.js";
import AddTypeCompetitionModal from "@/components/Competition/AddTypeCompetitionModal.vue";
export default {
  name: "CompetitionDetail",

  components: {
    TableFooter,
    AddTypeCompetitionModal,
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  data() {
    return {
      language: "th",
      competitionId: null,
      selectPerson: [],
      singleSelect: false,
      pageSizes: [10, 20, 50, 100],
      range: [10, 20],
      dialogConfirmDelete: false,
      itemDeleting: null,
      showAddTypeModal: false,
    };
  },

  mounted() {
    this.competitionId = this.$route.params.id;
    this.getCompetition(this.competitionId);
    this.fetchSubCompetition();
    this.setTeamData();
  },

  computed: {
    isAdmin() {
      return this.$store.state.permission.role !== "B_TEAM_ADMIN";
    },
    competitionData() {
      return this.sub_competitions.data;
    },
    ...mapState("sub_competition", ["sub_competitions", "params"]),
    ...mapState("competition", ["competition"]),
    competitionType() {
      return this.competition?.global_config_standard_types[
        "global_config_standard_type_name_" + this.$t("short_language")
      ];
    },
    abbreviation() {
      return (
        this.competition?.setting_competition_informations
          ?.setting_competition_information_abbreviation || "-"
      );
    },
    abbreviationEn() {
      return (
        this.competition?.setting_competition_informations
          ?.setting_competition_information_abbreviation_en || "-"
      );
    },
    competitionCode() {
      return (
        this.competition?.setting_competition_informations
          ?.setting_competition_information_code || "-"
      );
    },
    actions() {
      return [
        { text: this.$t("normal.edit_list"), icon: "fa-pen", key: "edit" },
        {
          text: this.$t("normal.delete_list"),
          icon: "fa-trash",
          key: "delete",
        },
      ];
    },
    headersAdmin() {
      return [
        {
          text: this.$t("competition.type"),
          value:
            "setting_sub_competition_type_id.setting_sub_competition_type_name_" +
            this.$t("short_language"),
          sortable: false,
        },
        {
          text: this.$t("competition.gender_type"),
          value:
            "setting_sub_competition_sub_type_id.setting_sub_competition_sub_type_name_" +
            this.$t("short_language"),
          sortable: false,
        },
        {
          text: this.$t("competition.fee"),
          value: "setting_sub_competition_fee",
          sortable: false,
        },
        {
          text: this.$t("competition.number_teams"),
          value: "count_team",
          sortable: false,
        },
        { text: "จัดการ", value: "action", sortable: false },
      ];
    },
    headersStaff() {
      return [
        {
          text: this.$t("competition.type"),
          value:
            "setting_sub_competition_type_id.setting_sub_competition_type_name_" +
            this.$t("short_language"),
          sortable: false,
        },
        {
          text: this.$t("competition.gender_type"),
          value:
            "setting_sub_competition_sub_type_id.setting_sub_competition_sub_type_name_" +
            this.$t("short_language"),
          sortable: false,
        },
        {
          text: this.$t("competition.fee"),
          value: "setting_sub_competition_fee",
          sortable: false,
        },
        {
          text: this.$t("competition.number_teams"),
          value: "count_team",
          sortable: false,
        },
        { text: "สถานะการสมัคร", value: "status", sortable: false },
      ];
    },
  },

  watch: {
    "params.page"() {
      this.fetchSubCompetition();
    },
    "params.size"() {
      this.params.page = 1;
      this.fetchSubCompetition();
    },
    "params.name"() {
      this.fetchSubCompetition();
    },
  },

  methods: {
    ...mapActions("sub_competition", [
      "getSubCompetitions",
      "deleteItem",
      "setTeamData",
    ]),
    ...mapActions("competition", ["getCompetition"]),
    async download() {
      for (const c of this.competition.competition_attachments) {
        for (const info of c.attachment_information) {

          const link = document.createElement("a");
          link.href = info.file_url;
          link.download = info.file_name;
          document.body.appendChild(link);
          link.setAttribute("target", "_blank");
          link.click();
          document.body.removeChild(link);
          await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before proceeding to the next download
        }
      }
    },
    name(language) {
      return (
        this.competition?.setting_competition_informations[
          `setting_competition_information_name_${language}`
        ] || "-"
      );
    },

    async handleSubmit(e) {
      await appAxios
        .post(
          `competitions/${this.competitionId}/sub-competitions/rugby-create`,
          {
            setting_sub_competitions: e,
          }
        )
        .then(() => {
          this.fetchSubCompetition();
        });
    },
    fetchSubCompetition() {
      this.getSubCompetitions({ competitionId: this.competitionId });
    },
    onRowClick(row) {
      this.$router.push({
        name: "sub-competition-detail",
        params: {
          cid: this.competitionId,
          sid: this.isAdmin ? row.id : "staff",
          tab: "teams",
        },
      });
    },
    onEditCompetition() {
      this.$router.push({
        name: "competition-update",
        params: { id: this.competitionId },
      });
    },
    onAddSubCompetition() {
      console.log("adding");
      this.showAddTypeModal = true;

      // this.$router.push({
      //   name: "add-sub-competition",
      //   params: { cid: this.competitionId },
      // });
    },
    dateFormat(date) {
      return format(new Date(date), "dd/MM/yyyy", { locale: th });
    },
    async onClick(action, item) {
      if (action === "edit") {
        this.$router.push({
          name: "sub-competition-update",
          params: { cid: this.competitionId, sid: item.id },
        });
      } else if (action === "delete") {
        this.dialogConfirmDelete = true;
        this.itemDeleting = item;
        await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
          async (result) => {
            if (result.value) {
              this.deleteItem({
                cid: this.competitionId,
                sid: this.itemDeleting.id,
              });
            }
          }
        );
      }
    },
    confirmDeleteItem() {
      this.dialogConfirmDelete = false;
      this.deleteItem({ cid: this.competitionId, sid: this.itemDeleting.id });
    },
    competitionLevel(level) {
      switch (level) {
        case "DISTRICT":
          return this.$t("competition_level.district");
        case "ZONE":
          return this.$t("competition_level.zone");
        case "REGIONAL":
          return this.$t("competition_level.regional");
        case "INTERNATIONAL":
          return this.$t("competition_level.national");
        case "CONTINENTAL":
          return this.$t("competition_level.continental");
        case "GLOBAL":
          return this.$t("competition_level.global");
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.content {
  background: #f8f8f8;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.no-data {
  cursor: default !important;
}
.download-flow {
  position: absolute !important;
  right: 50px;
  top: 80px;
  background-color: #555658 !important;
  color: #fff !important;
}
</style>
