<template>
  <v-dialog @input="closeModal" :value="dialog" max-width="60vw">
    <v-card>
      <div class="float-right">
        <v-card-actions>
          <v-btn color="primary" text @click="closeModal" mx-2>
            {{ $t("normal.cancel") }}
          </v-btn>
          <v-btn color="primary" @click="handleCommit" mx-2>
            {{ $t("normal.save") }}
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-title>
        <span class="header text-primary">{{
          $t("competition.add_type")
        }}</span>

      </v-card-title>
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            ประเภทการแข่งขัน <span style="color:red">*</span>
          </div>
          <div class="col-4">รุ่น/เพศ <span style="color:red">*</span></div>
          <div class="col-4">ค่าสมัคร <span style="color:red">*</span></div>
        </div>
        <div
          class="row"
          v-for="(item, idx) in listAddTypeCompetition"
          :key="idx"
        >
          <div class="col-4">
            <v-select
              dense
              @change="getSubSettingType"
              outlined
              :items="typeCompetitionList"
              item-value="value"
              item-text="label"
              v-model="item.setting_sub_competition_type_id"
            >
            </v-select>
          </div>
          <div class="col-4">
            <v-select
              dense
              outlined
              :items="typeSubCompetitionList"
              item-value="value"
              item-text="label"
              v-model="item.setting_sub_competition_sub_type_id"
            >
            </v-select>
        
          </div>
          <div class="col-3">
            <v-text-field
              dense
              outlined
              v-model="item.setting_sub_competition_fee"
              hide-details
            ></v-text-field>
          </div>
          <div class="col-1">
            <v-btn color="red" v-if="idx < listAddTypeCompetition.length - 1" @click="deleteList(idx)"
              ><v-icon small class="pa-3">fa-trash</v-icon></v-btn
            >
            <v-btn color="primary" v-else
              ><v-icon small class="pa-3" @click="addList"
                >fa-plus</v-icon
              ></v-btn
            >
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { appAxios } from "../../helpers/app-axios";

export default {
  name: "AddTypeCompetitionModal",
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      typeCompetitionList: [],
      typeSubCompetitionList: [],
      
      listAddTypeCompetition: [
        {
          setting_sub_competition_type_id: null,
          setting_sub_competition_sub_type_id: null,
          setting_sub_competition_fee: null,
        },
      ],
    };
  },
  components: {},
  async mounted() {
    this.getSettingType();
  },
  methods: {
    addList() {
      this.listAddTypeCompetition.push({
        setting_sub_competition_type_id: null,
          setting_sub_competition_sub_type_id: null,
          setting_sub_competition_fee: null,
      })
    },
    deleteList(itemToDelete) {
      // this.listAddTypeCompetition.splice(index, 1);
      const index = this.listAddTypeCompetition.indexOf(itemToDelete);

      if (index !== -1) {
        console.log('this.listAddTypeCompetition',this.listAddTypeCompetition)
        this.listAddTypeCompetition.splice(index, 1);
      }
    },

    async getSubSettingType(e) {
      return appAxios
        .get(`setting-sub-competition-type/${e}/sub-type`)
        .then((res) => {
          const data = res.data?.data;
          if (!data) return;
          this.typeSubCompetitionList = data?.dataLists?.map((ele) => ({
            label: ele.setting_sub_competition_sub_type_name_th,
            value: ele.id,
          }));
        });
    },
    async getSettingType() {
      return appAxios.get("setting-sub-competition-type").then((res) => {
        const data = res.data?.data;
        if (!data) return;

        this.typeCompetitionList = data?.dataLists?.map((ele) => ({
          label: ele.setting_sub_competition_type_name_th,
          value: ele.id,
        }));
      });
    },
    closeModal() {
      this.$emit("update:dialog", false);
    },
    handleCommit(){
      this.$emit("commit", this.listAddTypeCompetition);
      this.closeModal()
    }
  },
};
</script>
