import { appAxios } from '../helpers/app-axios';
const defaultCompetition = {
	setting_competition_informations: {},
	global_config_standard_types: {},
}

export const competition = {
	namespaced: true,

	state: () => ({
		competition: JSON.parse(JSON.stringify(defaultCompetition)),
		competitions: {},
		sub_competitions: [],
		social_contacts: {},
		params: {
			size: 10,
			page: 1,
			name: '',
		},
		success: null,
		error: null,
		profile_file: null,
		activeStatus: false
	}),

	mutations: {
		getCompetitions(state, data) {
			// console.log(' data.data.competition', data.data.competition)
			if (data.success) {
				state.competitions = data.data.competition
			}
		},
		getCompetition(state, data) {

			if (data.success) {
				state.competition = data.data.competition || {
					setting_competition_informations: {}
				}
				state.social_contacts = data.data?.competition?.setting_competition_social_contacts || {}
				state.activeStatus = data.data?.competition?.setting_competition_published
			} else {
				state.error = data.message
			}
		},
		updateCompetitionSocialContacts(state, data) {
			if (data.success) {
				state.social_contacts = data.data?.social_contacts || {}
			} else {
				state.error = data.message
			}
		},
		clearSocialContacts(state) {
			state.social_contacts = {}
		},
		saveData(state, data) {
			state.competition = data.data.competition
		},
		success(state, message) {
			state.success = message
			state.progress = 0
		},
		error(state, error) {
			state.error = error
		},
	},

	actions: {
		async getCompetitions({ commit, state }) {
			console.log('state.competitions',state)
			const params = new URLSearchParams(state.params);
			await appAxios.get('competitions', {params}).then(response => {
				commit('getCompetitions', response.data)
			})
		},
		clearCompetition({ state }) {
			state.social_contacts = {}
			state.profile_file = null
			state.competition = JSON.parse(JSON.stringify(defaultCompetition))
		},
		async getCompetition({ commit }, id) {
			await appAxios.get(`competitions/${id}`).then(response => {
				commit('getCompetition', response.data)
			})
		},
		async getCompetitionSocialContacts({ commit }, id) {
			await appAxios.get(`competitions/${id}/setting/social-contacts`).then(response => {
				commit('updateCompetitionSocialContacts', response.data)
			}).catch(() => {
				// console.log('getCompetitionSocialContacts Error: ', error.data.message)
			})
		},
		clearSocialContacts({ commit }) {
			commit('clearSocialContacts')
		},
		async updateSocialContacts({ state }, data) {
			state.competition.social_contacts = data
			state.social_contacts = data
		},
		async saveData({ state, commit, dispatch }) {
			console.log('state',state)

			let payload = {
				// "global_config_standard_types": state.competition.global_config_standard_types?.id || "1",
				"setting_competition_information_code": state.competition.setting_competition_informations.setting_competition_information_code || "",
				"setting_competition_information_name_th": state.competition.setting_competition_informations.setting_competition_information_name_th || "",
				"setting_competition_information_name_en": state.competition.setting_competition_informations.setting_competition_information_name_en || "",
				"setting_competition_information_organizer_th": state.competition.setting_competition_informations.setting_competition_information_organizer_th || "",
				"setting_competition_information_organizer_en": state.competition.setting_competition_informations.setting_competition_information_organizer_en || "",
				"setting_competition_information_abbreviation": state.competition.setting_competition_informations.setting_competition_information_abbreviation || "",
				"setting_competition_information_abbreviation_en": state.competition.setting_competition_informations.setting_competition_information_abbreviation_en || "",
				"setting_competition_information_history_th": state.competition.setting_competition_informations.setting_competition_information_history_th || "",
				"setting_competition_information_history_en": state.competition.setting_competition_informations.setting_competition_information_history_en || "",
				"setting_competition_level": state.competition.setting_competition_level || "",
				"setting_competition_information_year":  state.competition.setting_competition_informations.setting_competition_information_year || "0",
				"competition_attachments": state.competition.competition_attachments
			}
			console.log('state',state)
			let url = state.competition.id ? `competitions/${state.competition.id}/update` : `competitions/create`
			return await appAxios.post(url, payload).then(async response => {
				commit('saveData', response.data)
				if (state.profile_file != null) {
					const formData = new FormData();
					formData.append('file', state.profile_file, state.profile_file.name);

					await dispatch('uploadImageProfile', { competitionId: response.data.data.competition.id, formData })
				}
				const publics = {
					setting_competition_published: state.activeStatus
				}
				await appAxios.post(
					`competitions/${response.data.data.competition.id}/published/update`, publics
				).then(response => {
					console.log(response);
				}).catch(() => {
					// console.log('updateSocialContacts Error: ', error.data.message)
				})
				await appAxios.post(
					`competitions/${response.data.data.competition.id}/setting/social-contacts`, state.social_contacts
				).then(response => {
					commit('updateCompetitionSocialContacts', response.data)
				}).catch(() => {
					// console.log('updateSocialContacts Error: ', error.data.message)
				})
				return { success: true }
			}).catch(() => {
				// console.log('saveData Error: ', error.data.message)
				return { success: false }
			})
		},
		async deleteCompetition({ commit, dispatch }, id) {
			await appAxios.post(`competitions/${id}/delete`).then(() => {
				dispatch('getCompetitions')
			}).catch(error => {
				commit('error', error)
			})
		},
		async uploadImageProfile({ commit, state }, data) {
			let formData = data.formData
			commit('success', null)
			commit('error', null)

			let url = `competitions/${data.competitionId}/medias/profile`
			await appAxios.post(url, formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					onUploadProgress: function( progressEvent ) {
						state.progress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
					}.bind(this)
				}
			).then(() => {
				state.profile_file = null
				commit('success', 'อัพโหลดสำเร็จ')
			}).catch(() => {
				commit('error', 'การอัพโหลดไม่สำเร็จ')
			})
		},
		setProfileFile({ state }, file) {
			state.profile_file = file
		},
		setActiveStatus({ state }, active) {
			state.activeStatus = active
		},
		setParam({state}, param) {
			state.params = param
		}
	},
}