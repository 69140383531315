<template>
  <div class="row justify-content-center">
    <div class="col-md-12 col-12 col-xl-10">
      <div>
        <div v-if="isEditable" class="card-body">
          <div>
            <h4 class="card-title text-primary">
              {{ $t("player.image.upload_image") }}
            </h4>
          </div>
          <vue-dropzone
            v-if="imageDropzoneOptions"
            id="picture"
            :options="imageDropzoneOptions"
            :useCustomSlot="true"
            ref="getImage"
            @vdropzone-success="(file) => onUploadSuccess(file, 'getImage')"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">
                {{ $t("player.image.drag_image") }}
              </h3>
              <div class="subtitle">
                ({{ $t("player.image.sub_drag_image") }})
              </div>
            </div>
          </vue-dropzone>
        </div>
        <div class="card-body">
          <div>
            <h4 class="card-title text-primary">
              {{ $t("player.image.manage_image") }}
            </h4>
          </div>
          <v-row>
            <v-col v-for="(item, idx) in images" :key="idx" md="4" cols="6">
              <ImageMenuWrapper
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  child-flex
                "
                @onClick="(e) => onContextMenu(e, idx, 'images')"
              >
                <v-img
                  :src="item.account_media_url"
                  max-width="500"
                  max-height="250"
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </ImageMenuWrapper>
              <v-menu
                v-model="item.isShowMenu"
                :position-x="item.x"
                :position-y="item.y"
                absolute
                offset-y
                style="max-width: 600px"
              >
                <v-list>
                  <v-list-item class="cursor-pointer">
                    <v-list-item-title
                      @click="
                        () =>
                          download(
                            item.account_media_url,
                            item.account_media_name
                          )
                      "
                      ><a>{{
                        $t("player.image.size_image")
                      }}</a></v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item v-if="isEditable" class="cursor-pointer">
                    <v-list-item-title
                      @click="() => deleteMedia(item.id, 'getImage')"
                      >{{ $t("normal.delete_list") }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <TableFooter
            :total="imageTotal"
            :perPage.sync="imagePerPage"
            :lastPage="imageLastPage"
            :currentPage.sync="imagePage"
            :pageSizes="[9]"
          />
        </div>

        <div v-if="isEditable" class="card-body">
          <div>
            <h4 class="card-title text-primary">
              {{ $t("player.image.upload_video") }}
            </h4>
          </div>
          <vue-dropzone
            v-if="videoDropzoneOptions && isEditable"
            id="video"
            :options="videoDropzoneOptions"
            :useCustomSlot="true"
            ref="getVideo"
            @vdropzone-success="(file) => onUploadSuccess(file, 'getVideo')"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">
                {{ $t("player.image.drag_video") }}
              </h3>
              <div class="subtitle">
                ({{ $t("player.image.sub_drag_video") }})
              </div>
            </div>
          </vue-dropzone>
        </div>
        <div v-if="isEditable" class="card-body">
          <div>
            <h4 class="card-title text-primary">
              {{ $t("player.image.add_link_video") }}
            </h4>
          </div>
          <div class="row">
            <div class="col-md-10 col-sm-8 col-12">
              <v-text-field
                prepend-inner-icon="fa-link"
                hide-details
                dense
                outlined
                v-model="videoLink"
              ></v-text-field>
              <small
                >{{
                  $t("player.image.example_youtube")
                }}
                http://www.youtube.com/chanatip/Ym3HHa798y7</small
              >
            </div>
            <div class="col-4 col-md-2">
              <v-btn
                @click="uploadLink"
                :disabled="!isEditable"
                elevation="2"
                class="w-100 btn-primary"
                >{{ $t("player.image.upload_video") }}
              </v-btn>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div>
            <h4 class="card-title text-primary">
              {{ $t("player.image.manage_video") }}
            </h4>
          </div>
          <v-row>
            <v-col
              v-for="(item, idx) in videos"
              :key="item.id"
              class="d-flex child-flex"
              cols="4"
            >
              <ImageMenuWrapper
                :hideIcon="!isEditable"
                @onClick="(e) => onContextMenu(e, idx, 'videos')"
              >
                <div class="embed-responsive embed-responsive-4by3">
                  <iframe
                    v-if="item.account_media_name === null"
                    class="embed-responsive-item"
                    :src="item.account_media_url"
                  ></iframe>
                  <template v-else>
                    <video class="embed-responsive-item" controls>
                      <source :src="item.account_media_url" />
                    </video>
                  </template>

                  <v-menu
                    v-model="item.isShowMenu"
                    :position-x="item.x"
                    :position-y="item.y"
                    absolute
                    offset-y
                    style="max-width: 600px"
                  >
                    <v-list>
                      <v-list-item class="cursor-pointer">
                        <v-list-item-title
                          @click="() => deleteMedia(item.id, 'getVideo')"
                          >{{ $t("normal.delete_list") }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </ImageMenuWrapper>
            </v-col>
          </v-row>
          <TableFooter
            :perPage.sync="videoPerPage"
            :lastPage="videoLastPage"
            :currentPage.sync="videoPage"
            :total="videoTotal"
            :pageSizes="[9]"
          />
        </div>
      </div>
      <v-btn
        elevation="2"
        class="btn-primary float-right mb-5"
        v-on:click="nextTab()"
      >
        {{ $t("normal.next") }} <v-icon right> fa-arrow-right </v-icon>
      </v-btn>
      <v-btn
        elevation="2"
        class="btn-primary float-left mb-5"
        v-on:click="backTab()"
      >
        <v-icon left> fa-arrow-left </v-icon> {{ $t("normal.previous") }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import TableFooter from "../../components/TableFooter.vue";
import { appAxios } from "../../helpers/app-axios";
import ImageMenuWrapper from "../ImageMenuWrapper.vue";
import { replaceYoutubeLink } from "../../helpers/replaceYoutube";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    TableFooter,
    ImageMenuWrapper,
  },
  name: "player-tabs7",
  mounted() {
    this.$data.accountId = this.$route.params.id;
    if (this.$route.path.includes("player")) {
      this.$data.isPlayerRoute = true;
    }
    this.$data.imageDropzoneOptions = this.getOptions("images");
    this.$data.videoDropzoneOptions = this.getOptions("videos");
    this.getImage();
    this.getVideo();
    //
  },
  methods: {
    async onUploadSuccess(file, refName) {
      this.$refs[refName].removeFile(file);
      this[refName]();
    },
    nextTab() {
      // this.$emit("update:tabs", "tabs-8");
      this.$router.push({ path: "", query: { tab: 7 } });
      //console.log(this.tabs);
    },
    backTab() {
      this.$router.push({ path: "", query: { tab: 5 } });
      //console.log(this.tabs);
    },
    onContextMenu(event, idx, type) {
      event.preventDefault();
      this.$data[type][idx].isShowMenu = !this.$data[type][idx].isShowMenu;
      this.$data[type][idx].x = event.clientX;
      this.$data[type][idx].y = event.clientY;
    },
    async download(url, name) {
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      document.body.appendChild(link);

      // link.target = "_blank";
      link.setAttribute("target", "_blank");

      link.click();
      document.body.removeChild(link);
    },
    async deleteMedia(fileId, functionName) {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.$t("short_language"))).then(
        async (result) => {
          if (result.value) {
            appAxios
              .post(`account/${this.accountId}/medias/${fileId}/delete`)
              .then(() => {
                this[functionName]();
              });
          }
        }
      );
    },
    async getImage() {
      return appAxios
        .get(
          `account/${this.$data.accountId}/medias/${
            this.$data.isPlayerRoute ? "players" : "staffs"
          }/images`,
          {
            params: {
              page: this.$data.imagePage,
              perPage: this.$data.imagePerPage,
            },
          }
        )
        .then((res) => {
          const data = res.data?.data?.media;
          this.$data.images = data.data.map((ele) => ({
            ...ele,
            isShowMenu: false,
            x: 0,
            y: 0,
          }));
          this.$data.imageTotal = data.total;
          this.$data.imageLastPage = data.lastPage;
        });
    },
    async getVideo() {
      return appAxios
        .get(
          `account/${this.$data.accountId}/medias/${
            this.$data.isPlayerRoute ? "players" : "staffs"
          }/videos`,
          {
            params: {
              page: this.$data.videoPage,
              perPage: this.$data.videoPerPage,
            },
          }
        )
        .then((res) => {
          const data = res.data?.data?.media;
          this.$data.videos = data.data.map((ele) => ({
            ...ele,
            isShowMenu: false,
            x: 0,
            y: 0,
          }));
          this.$data.videoTotal = data.total;
          this.$data.videoLastPage = data.lastPage;
        });
    },
    getOptions(type) {
      const token = localStorage.getItem(process.env.VUE_APP_TOKEN_KEY_ADMIN);
      return {
        url: `/api/v1/account/${this.$data.accountId}/medias/${
          this.$data.isPlayerRoute ? "players" : "staffs"
        }/${type}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        acceptedFiles:
          type === "videos"
            ? "video/mp4,video/flv,video/flv,video/avi,video/wnv,video/mkv"
            : "image/jpg,image/jpeg,image/png",
        thumbnailWidth: 200,
        addRemoveLinks: true,
      };
    },
    uploadLink() {
      if (!this.$data.videoLink) return;
      const replacedLink = replaceYoutubeLink(this.$data.videoLink);
      appAxios
        .post(`account/${this.$data.accountId}/medias/video-link`, {
          account_media_video_url: replacedLink,
        })
        .then(() => {
          this.$data.videoLink = null;
          this.getVideo();
        });
    },
  },
  watch: {
    imagePage() {
      this.getImage();
    },
    imagePerPage() {
      this.getImage();
    },
    videoPage() {
      this.getVideo();
    },
    videoPerPage() {
      this.getVideo();
    },
  },
  computed: {
    isEditable() {
      if (this.$data.isPlayerRoute) {
        return this.$store.state.permission?.permissions?.athlete_edit;
      }
      return this.$store.state.permission?.permissions?.staff_edit;
    },
  },
  data() {
    return {
      showMenu: false,
      accountId: null,
      isPlayerRoute: false,
      images: [],
      imagePage: 1,
      imageTotal: 0,
      imagePerPage: 9,
      imageLastPage: null,
      videos: [],
      videoPage: 1,
      videoPerPage: 9,
      videoTotal: 0,
      videoLastPage: null,
      imageDropzoneOptions: undefined,
      videoDropzoneOptions: undefined,
      videoLink: null,
    };
  },
};
</script>
<style scoped>
.dropzone {
  min-height: 200px;
  border: 2px dashed #1161e0;
  background: #f8f8f8;
  position: relative;
}
</style>
