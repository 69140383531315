import { appAxios } from "../helpers/app-axios";
export const getAllName = "getRegister";

export default {
  state: {
    listRegister: {},
  },
  mutations: {
    [getAllName](state, { res }) {
      state["listRegister"] = res;
    },
    approveUser(state, { res }) {
      console.log('state.approveUser',state,res)
    },
    rejectUser(state, { res }) {
      console.log('state.rejectUser',state,res)

    }
  },
  actions: {
    async [getAllName]({ commit }, params) {
      const query = new URLSearchParams(params);
      return appAxios
        .get(`account-pre-register`, { params: query })
        .then((res) => {
          commit(getAllName, { res: res.data.data });
        });
    },
    async approveUser({ commit }, payload) {
      return appAxios
        .post(
          `account-pre-register/approve`,{
            id:payload.map((p) => +p.id)
          }
        )
        .then((res) => {          
          commit('approveUser', { res: res.data.data });
        });
    },

    async rejectUser({ commit }, payload) {
      console.log('Rejecting user',commit,payload)
      return appAxios
        .post(
          `account-pre-register/reject`,{
            id:payload.id,
            reason:payload.reason
          }
        )
        .then((res) => {          
          commit('rejectUser', { res: res.data.data });
        });
    },
    
  },
};
