export default {
  state: {
    user: {
      name: "นายทดสอบระบบ ทดสอบระบบ",
      posit: "Admin",
    },
  },
  mutations: {
    edit(state, text) {
      state.user.name = text;
    },
    editPosit(state, payload) {
      state.posit = payload;
    }
  },
  setter: {},
  actions: {
    setName({commit}, payload) {
      commit('edit', payload)
    },
    setPosition({commit}, payload) {
      commit('editPosit', payload);
    }
  },
};
