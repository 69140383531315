<template>
  <div class="row justify-content-center">
    <div class="col-md-12 col-12 col-xl-10">
      <div>
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <h4 class="card-title text-primary">
              {{ $t("player.injury.current_injury") }}
            </h4>
            <!-- <v-btn
              @click="onClickRemove"
              elevation="2"
              :disabled="!isEditable"
              class="btn-primary float-right"
            >
              <v-icon left> fa-sign-out-alt </v-icon>
              {{$t('player.injury.remove_injury')}}
            </v-btn>-->
          </div>

          <div class="card-body ml-5">
            <template v-if="currentInjury.length > 0">
              <div
                class="row border-bottom"
                v-for="item in currentInjury"
                :key="item.id"
              >
                <div class="col-6 col-md-2">
                  <h6 class="font-weight-bold">
                    {{ $t("player.injury.injury") }}
                  </h6>
                </div>
                <div class="col-6 col-md-2">
                  <p v-if="language === 'th'">
                    {{ item.account_injury_name_th || "-" }}
                  </p>
                  <p v-else>{{ item.account_injury_name_en || "-" }}</p>
                </div>
                <div class="col-6 col-md-2">
                  <h6 class="font-weight-bold">
                    {{ $t("player.injury.group_injury") }}
                  </h6>
                </div>
                <div class="col-6 col-md-2">
                  <p>
                    {{
                      getInjuryTypesLabel(item.global_config_injury_types)[
                        language
                      ] || "-"
                    }}
                  </p>
                </div>
                <div class="col-6 col-md-2">
                  <h6 class="font-weight-bold">
                    {{ $t("player.injury.level_injury") }}
                  </h6>
                </div>
                <div class="col-6 col-md-2">
                  <p>{{ getLevelLabel(item.account_injury_level) || "-" }}</p>
                </div>
                <div class="col-6 col-md-2">
                  <h6 class="font-weight-bold">
                    {{ $t("player.injury.date_injury") }}
                  </h6>
                </div>
                <div class="col-6 col-md-2">
                  <p>{{ toReadableDay(item.account_injury_date) || "-" }}</p>
                </div>
                <div class="col-6 col-md-2">
                  <h6 class="font-weight-bold">
                    {{ $t("player.injury.duration_injury") }}
                  </h6>
                </div>
                <div class="col-6 col-md-2">
                  <p>
                    {{ item.account_injury_recovery_time || "-" }}
                    {{ getUnitLabel(item.account_injury_recovery_time_unit) }}
                  </p>
                </div>
                <div class="col-6 col-md-2">
                  <h6 class="font-weight-bold">
                    {{ $t("player.injury.doctor") }}
                  </h6>
                </div>
                <div class="col-6 col-md-2">
                  <p v-if="language === 'th'">
                    {{ item.account_injury_doctor_name || "-" }}
                  </p>
                  <p v-else>{{ item.account_injury_doctor_name_en || "-" }}</p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex w-100 justify-content-center">
                {{ $t("player.injury.no_injury_current") }}
              </div>
            </template>
          </div>
        </div>

        <div class="card-body">
          <div class="d-flex justify-content-between">
            <h4 class="card-title text-primary">
              {{ $t("player.injury.history_injury") }}
            </h4>
            <v-btn
              @click="isShowModal = true"
              elevation="2"
              :disabled="!isEditable"
              class="btn-primary float-right"
            >
              <v-icon left> fa-plus </v-icon>
              {{ $t("player.injury.add_injury") }}
            </v-btn>
          </div>
          <v-data-table
            :items="injuries"
            hide-default-footer
            :headers="headderInjury[language]"
            :items-per-page="perPage"
          >
            <template v-slot:no-data>
              {{ $t("player.injury.no_injury") }}
            </template>
            <template v-slot:item="row">
              <tr>
                <td>{{ toReadableDay(row.item.account_injury_date) }}</td>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.account_injury_name_th || "-"
                  }}</label>
                  <label v-else>{{
                    row.item.account_injury_name_en || "-"
                  }}</label>
                </td>
                <td>
                  {{
                    getInjuryTypesLabel(row.item.global_config_injury_types)[
                      language
                    ] || "-"
                  }}
                </td>
                <td>
                  {{ getLevelLabel(row.item.account_injury_level) || "-" }}
                </td>
                <td>{{ row.item.cause[language] || "-" }}</td>
                <td>
                  {{ row.item.account_injury_recovery_time || "-" }}
                  {{ getUnitLabel(row.item.account_injury_recovery_time_unit) }}
                </td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list dark>
                      <v-list-item
                        class="cursor-pointer"
                        @click="() => editInjury(row.index)"
                      >
                        {{ $t("normal.edit") }}
                      </v-list-item>
                      <v-list-item
                        class="cursor-pointer"
                        @click="() => deleteInjury(row.item.id)"
                      >
                        {{ $t("normal.delete") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
            <template v-slot:footer="{}">
              <TableFooter
                :total="total"
                :perPage.sync="perPage"
                :lastPage="lastPage"
                :currentPage.sync="page"
              />
            </template>
          </v-data-table>
        </div>
      </div>
      <v-btn
        elevation="2"
        class="btn-primary float-right mb-5"
        v-on:click="nextTab()"
      >
        {{ $t("normal.next") }} <v-icon right> fa-arrow-right </v-icon>
      </v-btn>
      <v-btn
        elevation="2"
        class="btn-primary float-left mb-5"
        v-on:click="backTab()"
      >
        <v-icon left> fa-arrow-left </v-icon> {{ $t("normal.previous") }}
      </v-btn>
    </div>
    <InjuryModal
      :injury="editInjuryData"
      @commit="commitInury"
      :visible.sync="isShowModal"
    />
  </div>
</template>
<script>
import { appAxios } from "../../helpers/app-axios";
import TableFooter from "../TableFooter.vue";
import InjuryModal from "./InjuryModal.vue";
import { mapState } from "vuex";
import { inJuryTypesState, getAllInjuryTypes } from "../../store/injury_type";
import format from "date-fns/format";
import { languageService } from "../../helpers/language";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  components: {
    TableFooter,
    InjuryModal,
  },
  name: "player-tabs4",
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  mounted() {
    this.$data.accountId = this.$route.params.id;
    this.getInjury();
    this.$store.dispatch(getAllInjuryTypes);
    this.getCurrentInjury();
  },
  methods: {
    save() {},
    nextTab() {
      //console.log(this.tabs);
      this.$router.push({ path: "", query: { tab: 4 } });
    },
    backTab() {
      this.$router.push({ path: "", query: { tab: 2 } });
      //console.log(this.tabs);
    },
    async commitInury(value) {
      if (!this.isEditable) return;
      const body = {
        ...value,
      };
      await this.saveInjury(body);
      this.getInjury();
      this.getCurrentInjury();
    },
    editInjury(idx) {
      this.$data.editInjuryData = this.$data.injuries[idx];
      this.$data.isShowModal = true;
    },
    async deleteInjury(id) {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            appAxios
              .post(`accounts/${this.accountId}/injuries/${id}/delete`)
              .then(() => {
                this.getInjury();
              });
          }
        }
      );
    },
    async getInjury() {
      return appAxios
        .get(`accounts/${this.$data.accountId}/injuries`, {
          params: { page: this.$data.page, perPage: this.$data.perPage },
        })
        .then((res) => {
          const data = res.data?.data;
          this.$data.total = data.total;
          this.$data.perPage = Number(data.perPage);
          this.$data.lastPage = data.lastPage;
          this.$data.injuries = data.data.map((ele) => {
            const injuryCause = this.$data.causes.find(
              (cause) => cause.value === ele.account_injury_cause
            );
            return {
              ...ele,
              cause: injuryCause?.text,
              global_config_injury_types: ele.global_config_injury_types.id,
            };
          });
          // console.log(this.$data.injuries, data.data)
        });
    },
    toReadableDay(date) {
      return format(new Date(date), "dd/M/yyyy");
    },
    getInjuryTypesLabel(injuryId) {
      if (this.injuryTypes.length === 0) return;
      const matchType = this.injuryTypes.find((ele) => ele.id == injuryId);
      // return matchType?.global_config_injury_type_name_th || "";
      return {
        th: matchType?.global_config_injury_type_name_th || "",
        en: matchType?.global_config_injury_type_name_en || "",
      };
    },
    getLevelLabel(level) {
      const matchLevel = this.$data.level.find((ele) => ele.value == level);
      return matchLevel?.text[this.language];
    },
    getUnitLabel(unit) {
      const matchUnit = this.$data.periods.find((ele) => ele.value == unit);
      return matchUnit?.text[this.language];
    },
    async getCurrentInjury() {
      appAxios
        .get(`accounts/${this.$data.accountId}/injuries/current`)
        .then((res) => {
          const data = res.data?.data;
          if (!data) return;
          const lastest_injury = Math.max(
            ...data.map((e) => new Date(e.account_injury_date))
          );

          const checkLastestInjury = data.filter(
            (d) =>
              format(new Date(d.account_injury_date), "dd/MM/yyyy") ===
              format(new Date(lastest_injury), "dd/MM/yyyy")
          );

          this.$data.currentInjury = checkLastestInjury.map((ele) => ({
            ...ele,
            global_config_injury_types: ele.global_config_injury_types.id,
          }));
        });
    },
    async saveInjury(data) {
      return await appAxios
        .post(`accounts/${this.$data.accountId}/injuries`, data)
        .then(() => {});
    },
    onClickRemove() {
      if (this.$data.currentInjury.length === 0) return;

      this.$swal({
        icon: "warning",
        title:
          this.language === "th"
            ? "คุณแน่ใจหรือไม่ว่าต้องการนำรายการนี้ ออกจาก อาการบาดเจ็บปัจจุบัน"
            : "Are you sure remove data from current injury?",
        showCancelButton: true,
        confirmButtonText: this.language === "th" ? "ตกลง" : "Confirm",
        cancelButtonText: this.language === "th" ? "ยกเลิก" : "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          // TODO: call confirm here
          this.removeCurrentInjury();
          this.closeModal();
        }
      });
    },
    async removeCurrentInjury() {
      const changeStatusPromise = this.$data.currentInjury.map((ele) => {
        return appAxios.get(
          `accounts/${this.$data.accountId}/injuries/${ele.id}`
        );
      });
      Promise.all(changeStatusPromise).then(() => {
        this.getCurrentInjury();
        this.getInjury();
      });
    },
  },
  computed: {
    ...mapState({
      injuryTypes: (state) => state.injuryType[inJuryTypesState],
    }),
    isEditable() {
      if (this.$data.isPlayerRoute) {
        return this.$store.state.permission?.permissions?.athlete_edit;
      }
      return this.$store.state.permission?.permissions?.staff_edit;
    },
    isPlayerRoute() {
      return this.$route.path.includes("player");
    },
  },
  data() {
    return {
      isShowModal: false,
      headderInjury: {
        th: [
          {
            text: "วันที่ได้รับบาดเจ็บ",
            value: "account_injury_date",
            sortable: false,
            width: "10%",
          },
          {
            text: "อาการบาดเจ็บ",
            value: "account_injury_name_th",
            sortable: false,
            width: "15%",
          },
          {
            text: "กลุ่มอาการบาดเจ็บ",
            value: "global_config_injury_types",
            sortable: false,
            width: "15%",
          },
          {
            text: "ระดับความบาดเจ็บ",
            value: "account_injury_level",
            sortable: false,
            width: "15%",
          },
          {
            text: "สาเหตุการบาดเจ็บ",
            value: "cause",
            sortable: false,
            width: "15%",
          },
          {
            text: "ระยะเวลาพักฟื้น",
            value: "account_injury_recovery_time",
            sortable: false,
            width: "10%",
          },
          { text: "จัดการ", value: "edit", sortable: false, width: "10%" },
        ],
        en: [
          {
            text: "Date of Injured",
            value: "account_injury_date",
            sortable: false,
            width: "10%",
          },
          {
            text: "Injuries Records",
            value: "account_injury_name_th",
            sortable: false,
            width: "15%",
          },
          {
            text: "Type of Injury ",
            value: "global_config_injury_types",
            sortable: false,
            width: "15%",
          },
          {
            text: "Injury Level",
            value: "account_injury_level",
            sortable: false,
            width: "15%",
          },
          {
            text: "Cause of Injury",
            value: "cause",
            sortable: false,
            width: "15%",
          },
          {
            text: "Recovery Period",
            value: "account_injury_recovery_time",
            sortable: false,
            width: "10%",
          },
          { text: "Action", value: "edit", sortable: false, width: "10%" },
        ],
      },
      periods: [
        { text: { en: "Days", th: "วัน" }, value: "DAYS" },
        { text: { en: "Weeks", th: "สัปดาห์" }, value: "WEEKS" },
        { text: { en: "Months", th: "เดือน" }, value: "MONTHS" },
        { text: { en: "Years", th: "ปี" }, value: "YEARS" },
        { text: { en: "Matches", th: "แมทช์" }, value: "MATCHES" },
        { text: { en: "Seasons", th: "ฤดูกาล" }, value: "SEASONS" },
      ],
      causes: [
        { value: "COMPETITION", text: { en: "Competition", th: "การแข่งขัน" } },
        { value: "TRAINING", text: { en: "Training", th: "การซ้อม" } },
        { value: "OTHERS", text: { en: "Others", th: "อื่นๆ" } },
      ],
      level: [
        { value: "MINOR", text: { en: "Minor", th: "เล็กน้อย" } },
        { value: "MODERATE", text: { en: "Moderate", th: "บาดเจ็บปานกลาง" } },
        { value: "SERIOUSLY", text: { en: "Seriously", th: "บาดเจ็บรุนแรง" } },
      ],
      editInjuryData: null,
      currentInjury: [],
      accountId: null,
      page: 1,
      perPage: 10,
      lastPage: null,
      total: null,
      injuries: [],
    };
  },
  watch: {
    page() {
      this.getInjury();
    },
    perPage() {
      this.getInjury();
    },
    isShowModal(newVal) {
      if (!newVal) {
        this.$data.editInjuryData = null;
      }
    },
  },
};
</script>
