import { appAxios } from "../helpers/app-axios";

const defaultSubCompetition = {
  setting_sub_competition_awards: {
    gold: {},
    silver: {},
    bronze: {},
  },
};

export const sub_competition = {
  namespaced: true,

  state: () => ({
    sub_competitions: [],
    sub_competition: JSON.parse(JSON.stringify(defaultSubCompetition)),
    rounds: [],
    social_contacts: {},
    error: null,
    success: null,
    teams: [],
    all_teams: [],
    selected_teams: {},
    params: {
      page: 1,
      size: 10,
      active: true,
      name: "",
    },
    progress: {
      image: 0,
      video: 0,
    },
    profile_file: null,
    sport_categories: [],
    remark_th: "",
    remark_en: "",
    personalData: {},
    teamData: {},
  }),

  mutations: {
    setTeamData(state, data) {
      if (data.success) {
        console.log('setTeamData',data)
        state.teamData = data.data || {};
      }
    },

    setPersonalData(state, data) {
      if (data.success) {
        // console.log("personalDatam,", data.data.team);

        state.personalData = data.data.team ?? {};
      }
    },
    setSubCompetitions(state, data) {
      if (data.success) {
        state.sub_competitions = data.data.sub_competition;
      }
    },
    setSubCompetition(state, data) {
      if (data.success) {
        state.sub_competition =
          data.data.sub_competition ||
          JSON.parse(JSON.stringify(defaultSubCompetition));
        state.social_contacts =
          data.data?.sub_competition?.setting_sub_competition_social_contacts ||
          {};
      } else {
        state.error = data.message;
      }

      if (
        !state.sub_competition.setting_sub_competition_awards ||
        Object.keys(state.sub_competition.setting_sub_competition_awards)
          .length == 0
      ) {
        state.sub_competition.setting_sub_competition_awards = JSON.parse(
          JSON.stringify(defaultSubCompetition)
        ).setting_sub_competition_awards;
      }
      if (state.sub_competition.global_config_sport_categories) {
        state.sub_competition.global_config_sport_categories = parseInt(
          state.sub_competition.global_config_sport_categories
        );
      }
    },
    saveData(state, data) {
      state.competition = data.data.competition;
    },
    clear(state) {
      state.sub_competitions = [];
      state.sub_competition = JSON.parse(JSON.stringify(defaultSubCompetition));
      state.rounds = [];
      state.social_contacts = {};
      state.profile_file = null;
    },
    updateCompetitionSocialContacts(state, data) {
      if (data.success) {
        state.social_contacts = data.data?.social_contacts || {};
      } else {
        state.error = data.message;
      }
    },
    clearSocialContacts(state) {
      state.social_contacts = {};
    },
    updateAvailableTeams(state, data) {
      state.teams = data.data.teams;
    },
    updateSubCompetitionTeams(state, data) {
      state.selected_teams = data.data.teams;
    },
    updateAllSubCompetitionTeams(state, data) {
      state.all_teams = data.data.teams;
    },
    clearMessages(state) {
      state.success = null;
      state.error = null;
    },
    success(state, message) {
      state.success = message;
    },
    error(state, error) {
      if (!error) {
        state.error = null;
      } else if (typeof error === "string") {
        state.error = error;
      } else if (typeof error.data.message === "string") {
        if (error.data.message.includes("setting_sub_competition_type")) {
          state.error = "กรุณาระบุประเภทกีฬา";
        } else {
          state.error = error.data.message;
        }
      } else {
        let message = error.data.message.reduce((msg, data) => {
          if (msg) {
            return msg;
          }
          if (data.includes("setting_sub_competition_name_th")) {
            msg = "กรุณาระบุชื่อรายการแข่งขันย่อย (ภาษาไทย)";
          } else if (data.includes("setting_sub_competition_name_en")) {
            msg = "กรุณาระบุชื่อรายการแข่งขันย่อย (ภาษาอังกฤษ)";
          } else if (data.includes("setting_sub_competition_year")) {
            msg = "กรุณาเลือกปีการแข่งขัน";
          } else if (data.includes("setting_sub_competition_start_at")) {
            msg = "กรุณาเลือกวันที่เริ่มการแข่งขัน";
          } else if (data.includes("setting_sub_competition_end_at")) {
            msg = "กรุณาเลือกวันที่สิ้นสุดการแข่งขัน";
          } else if (data.includes("setting_sub_competition_hostname")) {
            msg = "กรุณาระบุเจ้าภาพการแข่งขัน";
          }
          return msg;
        }, null);
        if (message) {
          state.error = message;
        } else {
          state.error = null;
        }
      }
    },
    updateSportCategories(state, data) {
      state.sport_categories = data.data.map((d) => {
        return {
          ...d,
          value: parseInt(d.id),
          th: d.global_config_sport_category_name_th,
          en: d.global_config_sport_category_name_en,
        };
      });
    },
    setRemarkTH(state, data) {
      state.remark_th = data;
    },
    setRemarkEN(state, data) {
      state.remark_en = data;
    },
  },

  actions: {
    async getDetailByTeamNoCompetition({ commit }, { teamId }) {
      await appAxios.get(`setting/team-form/${teamId}`).then((res) => {
        commit("setTeamData", res.data ?? {});
      });
    },
    async getDetailByTeam({ commit }, { teamId ,competitionId}) {
      console.log('competitionId',competitionId)
      await appAxios.get(`setting/team-form/${teamId}/competition/${competitionId}`).then((res) => {
        commit("setTeamData", res.data ?? {});
      });
    },
    async getSelfTeam({ commit }) {
      await appAxios.get(`team/self`).then((res) => {
        commit("setPersonalData", res.data);
      });
    },
    async getSubCompetitions({ commit, state }, { competitionId }) {
      const params = new URLSearchParams(state.params);
      await appAxios
        .get(`competitions/${competitionId}/sub-competitions`, {
          params,
        })
        .then((response) => {
          commit("setSubCompetitions", response.data);
        });
    },

    async getSubCompetition({ commit }, { cid, sid }) {
      await appAxios
        .get(`competitions/${cid}/sub-competitions/${sid}`)
        .then((response) => {
          commit("setSubCompetition", response.data);
        });
    },
    async deleteItem({ commit, dispatch }, data) {
      let url = `competitions/${data.cid}/sub-competitions/${data.sid}/delete`;
      await appAxios
        .post(url)
        .then((response) => {
          if (response.data.success) {
            dispatch("getSubCompetitions", { competitionId: data.cid });
          } else {
            commit("error", response.data.message);
          }
        })
        .catch((error) => {
          commit("error", error);
        });
    },
    async updateSocialContacts({ state }, data) {
      state.social_contacts = data;
    },
    setProfileFile({ state }, file) {
      state.profile_file = file;
    },
    clearSocialContacts({ commit }) {
      commit("clearSocialContacts");
    },
    clear({ commit }) {
      commit("clear");
    },
    async getTeams({ commit }, { id, type }) {
      await appAxios
        .get(
          `sub-competitions/${id}/setting-team-in-sub-competition/choose/teams/${type.toLowerCase()}`
        )
        .then((response) => {
          commit("updateAvailableTeams", response.data);
        })
        .catch(() => {
          // console.log('getTeams Error: ', error.data.message)
        });
    },
    clearTeamInSubCompetition({ state }) {
      state.selected_teams = {};
    },
    async getTeamsInSubCompetition({ commit }, { sid, data }) {
      const params = new URLSearchParams(data);
      await appAxios
        .get(`sub-competitions/${sid}/setting-team-in-sub-competition`, {
          params,
        })
        .then((response) => {
          commit("updateSubCompetitionTeams", response.data);
        })
        .catch(() => {
          // console.log('getTeamsInSubCompetition Error: ', error.data.message)
        });
    },
    async getAllTeamsInSubCompetition({ commit }, { sid }) {
      const params = new URLSearchParams({
        page: 0,
        size: 10000,
      });
      await appAxios
        .get(`sub-competitions/${sid}/setting-team-in-sub-competition`, {
          params,
        })
        .then((response) => {
          commit("updateAllSubCompetitionTeams", response.data);
        })
        .catch(() => {
          // console.log('getTeamsInSubCompetition Error: ', error.data.message)
        });
    },
    async saveData({ state, commit, dispatch }, data) {
      let payload = JSON.parse(JSON.stringify(state.sub_competition));
      commit("error", null);
      commit("success", null);

      delete payload.created_at;
      delete payload.created_by;
      delete payload.deleted;
      delete payload.id;
      delete payload.setting_competition_id;
      delete payload.setting_sub_competition_social_contacts;
      delete payload.updated_at;
      delete payload.updated_by;
      delete payload.setting_sub_competition_media_profile_icon;

      let url = data.sid
        ? `competitions/${data.cid}/sub-competitions/${data.sid}/update`
        : `competitions/${data.cid}/sub-competitions/create`;
      await appAxios
        .post(url, payload)
        .then(async (response) => {
          commit("saveData", response.data);
          if (state.profile_file != null) {
            const formData = new FormData();
            formData.append(
              "file",
              state.profile_file,
              state.profile_file.name
            );

            await dispatch("uploadImageProfile", {
              cid: data.cid,
              sid: response.data.data.sub_competition.id,
              formData,
            });
          }
          const publics = {
            setting_sub_competition_published:
              state.sub_competition.setting_sub_competition_published,
          };
          await appAxios
            .post(
              `competitions/${data.cid}/sub-competitions/${response.data.data.sub_competition.id}/published/update`,
              publics
            )
            .then((response) => {
              console.log(response);
            })
            .catch(() => {
              // console.log('updateSocialContacts Error: ', error.data.message)
            });

          await appAxios
            .post(
              `sub-competitions/${response.data.data.sub_competition.id}/social-contacts`,
              state.social_contacts
            )
            .then((response) => {
              commit("updateCompetitionSocialContacts", response.data);
            })
            .catch(() => {
              // console.log('updateSocialContacts Error: ', error.data.message)
            });
          commit(
            "success",
            response.data.data.sub_competition.setting_competition_id
          );
        })
        .catch((error) => {
          commit("error", error);
        });
    },
    async updateCompetitionTeams({ state, commit, dispatch }, teamIds) {
      await appAxios
        .post(
          `sub-competitions/${state.sub_competition.id}/setting-team-in-sub-competition/create`,
          {
            team_id: teamIds,
          }
        )
        .then(() => {
          dispatch("getTeamsInSubCompetition", {
            sid: state.sub_competition.id,
          });
          dispatch("getTeams", { id: state.sub_competition.id });
        })
        .catch((error) => {
          commit("error", error);
        });
    },
    async deleteCompetitionTeams({ state, commit }, teamIds) {
      commit("clearMessages");
      await appAxios
        .post(
          `sub-competitions/${state.sub_competition.id}/setting-team-in-sub-competition/multi/delete`,
          {
            setting_team_in_sub_competition_id: teamIds,
          }
        )
        .then(() => {
          // dispatch("getTeamsInSubCompetition", {
          //   sid: state.sub_competition.id,
          // });
          // dispatch("getTeams", { id: state.sub_competition.id });
          commit("success", "ลบข้อมูลที่เลือกแล้ว");
        })
        .catch((error) => {
          commit("error", error);
        });
    },

    async uploadMedia({ state, commit }, data) {
      let formData = data.formData;
      commit("success", null);

      let url = `competitions/${data.cid}/sub-competitions/${data.sid}/medias/${
        data.type == "image" ? "images" : "videos"
      }`;
      await appAxios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function(progressEvent) {
            state.progress[data.type] = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
        .then(() => {
          state.progress[data.type] = 0;
          commit("success", "อัพโหลดสำเร็จ");
        })
        .catch((error) => {
          commit("error", error);
        });
    },

    async uploadLink({ commit }, data) {
      commit("success", null);
      await appAxios
        .post(
          `competitions/${data.cid}/sub-competitions/${data.sid}/medias/videos-url`,
          {
            setting_sub_competition_video_url: data.link,
          }
        )
        .then(() => {
          commit("success", "อัพโหลดสำเร็จ");
        })
        .catch((error) => {
          commit("error", error);
        });
    },
    async uploadImageProfile({ commit, state }, data) {
      let formData = data.formData;
      commit("success", null);
      commit("error", null);

      let url = `competitions/${data.cid}/sub-competitions/${data.sid}/medias/profile`;
      await appAxios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function(progressEvent) {
            state.progress = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
        .then(() => {
          commit("success", "PROFILE_UPLOADED");
        })
        .catch(() => {
          commit("error", "การอัพโหลดไม่สำเร็จ");
        });
    },
    getSportCategories({ commit }) {
      appAxios
        .get(`setting/global/sport-categories/all`)
        .then((response) => {
          commit("updateSportCategories", response.data);
        })
        .catch((error) => {
          commit("error", error);
        });
    },
    async deleteMedia({ commit }, data) {
      let url = `competitions/${data.cid}/sub-competitions/${data.sid}/medias/${data.mediaId}/delete`;
      await appAxios
        .post(url)
        .then(() => {
          commit("success", "ลบภาพสำเร็จแล้ว");
        })
        .catch((error) => {
          commit("error", error);
        });
    },
    setRemarkTH({ commit }, payload) {
      commit("setRemarkTH", payload);
    },
    setRemarkEN({ commit }, payload) {
      commit("setRemarkEN", payload);
    },
    setTeamData({ state }) {
      state.teamData = {}
    }
  },
};
