<template>
  <v-app>
		<div class="content app-content">
			<div class="content-header">
				<div class="content-header-left">
					<div class="row d-flex align-items-center justify-space-between">
						<div class="col-12 col-md-8 d-flex align-items-center">
							<v-icon class="content-header-title float-left mb-0" small>
								fa-chevron-left
							</v-icon>
							<router-link to="/competition" custom>
								<h5 class="content-header-title float-left mb-0 ml-1">
									{{ $t('competition.all') }}
								</h5>
							</router-link>
							<template v-if="competition && competition.id">
								<h5 class="content-header-title float-left mb-0 ml-1">
									/
								</h5>
								<router-link :to="`/competition/${competition.id}`" custom>
									<h5 class="content-header-title float-left mb-0 ml-1">
										{{ competitionName }}
									</h5>
								</router-link>
							</template>
							<template v-else>
								<h5 class="content-header-title float-left mb-0 ml-1">
									/
								</h5>
								
									<h5 class="content-header-title float-left mb-0 ml-1">
										เพิ่มรายการแข่งขัน
									</h5>
							</template>
						</div>
						<div class="col-12 col-md-4 d-flex justify-content-end">
							<v-btn color='primary' @click="onSaveData">
								{{ $t('normal.save') }}
							</v-btn>
						</div>
					</div>
				</div>
			</div>
			<div class="bg-white mt-2 rounded pt-4">
				<add-competition-form></add-competition-form>
			</div>
		</div>
	</v-app>
</template>

<script>
import AddCompetitionForm from '@/components/Competition/AddCompetitionForm.vue'
import { mapActions, mapState } from 'vuex'

export default {
	name: 'AddUpdateCompetition',

	components: {
		AddCompetitionForm
	},
	methods: {
		...mapActions('competition', ['clearForm', 'saveData']),

		async onSaveData() {

			let result = await this.saveData()
			if (result.success) {
				this.$router.go(-1)
			}
		}
	},
	computed: {
		...mapState('competition', ['competition']),
		competitionName() {
			return this.competition.setting_competition_informations['setting_competition_information_name_'+this.$t('short_language')] || ''
		},
	}
}
</script>

<style scoped>
	.center {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 50%;
	}

	.content {
		background: #f8f8f8;
	}

	.v-input--selection-controls {
		margin-top: 0 !important;
		padding-top: 0 !important;
	}

	.active-radio .v-icon {
		color: red;
	}

	@media (min-width: 960px) {
		.tab1-border-left {
			border-left: 1px solid #ebe9f1 !important;
		}
	}
</style>
