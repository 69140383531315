<template>
  <div class="row">
    <div class="col-md-12 col-12">
      <div class="card">
        <div class="card-body">
          <!-- <div class="card-body ml-4">
            <div class="card-header">
              <h4 class="card-title text-primary">ตรวจสอบเอกสารสำคัญ</h4>
            </div>
            <div class="row ml-4">
              <div class="col-3">
                <v-checkbox v-model="ex1" label="ภาพเอกสารบัตรประชาชน" color="success" hide-details ></v-checkbox>
              </div>
              <div class="col-3">
                <v-checkbox v-model="ex2" label="ภาพเอกสารพาสปอร์ต" color="success" hide-details></v-checkbox>
              </div>
              <div class="col-3">
                <v-checkbox v-model="ex3" label="ภาพเอกสารข้อมูลทะเบียนบ้าน" color="success" hide-details></v-checkbox>
              </div>
              <div class="col-3">
                <v-checkbox v-model="ex4" label="ภาพบัตรประจำตัวนักกีฬา" color="success" hide-details></v-checkbox>
              </div>
            </div>
          </div> -->
        </div>
        <div v-if="isEditable" class="card-body">
          <div class="card-header">
            <h4 class="card-title text-primary">
              {{ $t("team.attach_file") }}
            </h4>
          </div>
          <vue-dropzone
            v-if="documentDropzoneOptions"
            :options="documentDropzoneOptions"
            id="picture"
            ref="getDocument"
            :useCustomSlot="true"
            @vdropzone-success="(file) => onUploadSuccess(file, 'getDocument')"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">
                {{ $t("player.document.drag_document") }}
              </h3>
              <div class="subtitle">({{ $t("stadium.sub_drag_image") }})</div>
              <div class="subtitle">
                ({{ $t("player.document.second_sub_document") }})
              </div>
            </div>
          </vue-dropzone>
        </div>
        <div class="card-body">
          <div class="card-header">
            <h4 class="card-title text-primary">
              {{ $t("player.document.manage_document") }}
            </h4>
          </div>
          <v-row>
            <v-col
              v-for="(item, idx) in documents"
              :key="idx"
              class="
                d-flex
                justify-content-center
                align-items-center
                child-flex
              "
              md="2"
              cols="4"
              style="flex-direction: column"
            >
              <v-menu
                v-model="item.showMenu"
                :position-x="item.x"
                :position-y="item.y"
                absolute
                offset-y
                style="max-width: 600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="blue darken-1"
                    aspect-ratio="1"
                    style="font-size: 150px"
                  >
                    fa-file
                  </v-icon>
                  <label class="form-label" for="basic-addon-name">{{
                    item.setting_team_media_file_name
                  }}</label>
                </template>

                <v-list>
                  <v-list-item class="cursor-pointer">
                    <v-list-item-title
                      @click="
                        () =>
                          download(
                            item.setting_team_media_url,
                            item.setting_team_media_name
                          )
                      "
                      >{{ $t("image.size_document") }}</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item class="cursor-pointer">
                    <!-- @click="() => deleteMedia(item.id, 'getDocument')" -->
                    <v-list-item-title
                      @click="() => deleteMedia(item.id, 'getDocument')"
                      >{{ $t("normal.delete_list") }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <TableFooter
            :total="imageTotal"
            :perPage.sync="imagePerPage"
            :lastPage="imageLastPage"
            :currentPage.sync="imagePage"
          />
        </div>
        <div class="card-body">
          <div class="card-header">
            <h4 class="card-title text-primary">
              {{ $t("player.document.other_records_th") }}
            </h4>
          </div>
          <form ref="formRef" class="needs-validation" novalidate="">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <vue-editor
                    :editorToolbar="customToolbar"
                    v-model="
                      teamDocumentRemarkForm.setting_team_information_remark
                    "
                    :disabled="!isEditable"
                  ></vue-editor>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="card-body">
          <div class="card-header">
            <h4 class="card-title text-primary">
              {{ $t("player.document.other_records_en") }}
            </h4>
          </div>
          <form ref="formRef" class="needs-validation" novalidate="">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <vue-editor
                    :editorToolbar="customToolbar"
                    v-model="
                      teamDocumentRemarkForm.setting_team_information_remark_en
                    "
                    :disabled="!isEditable"
                  ></vue-editor>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <v-btn
        elevation="2"
        class="btn-primary float-left mb-5"
        v-on:click="backTab()"
      >
        <v-icon left> fa-arrow-left </v-icon>{{ $t("normal.previous") }}</v-btn
      >
    </div>
  </div>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import TableFooter from "../../components/TableFooter.vue";
import { appAxios } from "../../helpers/app-axios";
import { VueEditor } from "vue2-editor";
import { mapActions } from "vuex";
import MasterDataHelper from "@/helpers/master_data_helper.js";


export default {
  components: {
    vueDropzone: vue2Dropzone,
    VueEditor,
    TableFooter,
  },
  name: "team-tab6",
  methods: {
    ...mapActions("sub_competition", ["setRemarkTH", "setRemarkEN"]),

    async nextTab() {
      if (!(await this.save())) return;

      // const teamId = await this.save(true);
      // if (!teamId) return;
      return this.$router.push("/team");
    },
    async save() {
      // const result = this.$refs.formRef.validate();
      // if (!result) return;
      // console.log('test')
      // const teamId = await this.save();
      await this.saveTeamDocumentRemark();
      return true;
    },
    async saveTeamDocumentRemark() {
      const body = this.$data.teamDocumentRemarkForm;
      appAxios
        .post(`team/${this.$data.teamId}/medias/remark`, body)
        .then((res) => res);
    },
    async onUploadSuccess(file, refName) {
      // this.$data.file_name = file;
      this.$refs[refName].removeFile(file);
      // this[refName]();
      const body = new FormData();
      body.append("file", file);
      body.append("setting_team_media_file_name", file.name);
      appAxios
        .post(`/team/${this.$data.teamId}/medias/documents/`, body)
        .then(() => {
          this.getDocument();
        });
    },
    async deleteMedia(fileId, functionName) {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.$t("short_language"))).then(
        async (result) => {
          if (result.value) {
            appAxios
              .post(`team/${this.$data.teamId}/medias/${fileId}/delete`)
              .then(() => {
                this[functionName]();
              });
          }
        }
      );
    },
    async getDocument() {
      return appAxios
        .get(`team/${this.$data.teamId}/medias/documents`, {
          params: {
            page: this.$data.imagePage,
            perPage: this.$data.imagePerPage,
          },
        })
        .then((res) => {
          const data = res.data?.data?.team_media;
          this.$data.documents = data.data;
          this.$data.imageTotal = data.total;
          this.$data.imageLastPage = data.lastPage;
        });
    },
    async download(url, name) {
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      document.body.appendChild(link);

      // link.target = "_blank";
      link.setAttribute("target", "_blank");

      link.click();
      document.body.removeChild(link);
    },
    getOptions() {
      // const formData = new FormData();
      // console.log('file_name',this.$data.file_name)
      // formData.append('setting_team_media_file_name', this.$data.file_name[]);
      // formData.append('file', this.$data.file_name);
      return {
        url: `/api/v1/team/shirt-uniform/onhold`,
        // formData,
        acceptedFiles: "application/pdf,image/jpeg,image/jpg,image/png",
        thumbnailWidth: 200,
        addRemoveLinks: true,
      };
    },
    parseRemarkForm(data) {
      this.$data.teamDocumentRemarkForm = {
        setting_team_information_remark:
          data.setting_team_information_remark ?? "",
        setting_team_information_remark_en:
          data.setting_team_information_remark_en ?? "",
      };
    },

    test: function () {
      alert("test");
    },
    getRemark() {
      appAxios.get(`team/${this.$data.teamId}/medias/remark`).then((res) => {
        const data = res.data.data.team_media;
        this.parseRemarkForm(data);
      });
    },
    backTab() {
      this.$router.push({ path: "", query: { tab: 4 } });
    },
  },
  mounted() {
    const teamId = this.$route.params.id;
    this.$data.teamId = teamId;
    this.$data.documentDropzoneOptions = this.getOptions();
    this.getDocument();
    this.getRemark();

    // appAxios.get(`team/${teamId}`).then((res) => {
    //   const data = res.data.data.team;
    //   this.parseRemarkForm(data)
    // });
  },
  computed: {
    isEditable() {
      return this.$store.state.permission.permissions.team_edit;
    },
  },
  data() {
    return {
      customToolbar: [
        [{ size: [] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link"],
      ],
      documentDropzoneOptions: undefined,
      showMenu: false,
      documents: [],
      imagePage: 1,
      imageTotal: 0,
      imagePerPage: 10,
      imageLastPage: null,
      teamId: null,
      setting_team_media_file_name: "bobo",
      file_name: null,
      teamDocumentRemarkForm: {
        setting_team_information_remark: null,
        setting_team_information_remark_en: null,
      },
      /////
      // dropzoneOptions:{
      //   url: 'https://httpbin.org/post',
      //   thumbnailWidth: 200,
      //   addRemoveLinks: true,
      // },
      // contentThai:"<h1>Some initial content</h1>",
      // ex1:true,
      // ex2:false,
      // ex3:false,
      // ex4:true,
      // ytURL : "https://www.youtube.com/embed/F6dniaSqd1Q"
    };
  },
  watch: {
    imagePerPage() {
      this.getDocument();
    },
    imagePage() {
      this.getDocument();
    },
    "teamDocumentRemarkForm.setting_team_information_remark"() {
      this.setRemarkTH(
        this.$data.teamDocumentRemarkForm.setting_team_information_remark
      );
      // console.log('teamDocumentRemarkForm',this.$data.teamDocumentRemarkForm.setting_team_information_remark);
    },
    "teamDocumentRemarkForm.setting_team_information_remark_en"() {
      this.setRemarkEN(
        this.$data.teamDocumentRemarkForm.setting_team_information_remark_en
      );

      // console.log('teamDocumentRemarkForm',this.$data.teamDocumentRemarkForm.setting_team_information_remark_en);
    },
  },
};
</script>
<style scoped>
.dropzone {
  min-height: 200px;
  border: 2px dashed #1161e0;
  background: #f8f8f8;
  position: relative;
}
</style>
