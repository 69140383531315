<template>
  <v-app>
    <div class="content app-content h-100">
      <div class="container">
        <h2>{{ $t("menu.register") }}</h2>
        <div class="d-flex justify-between mb-5 ">
          <div class="bg-primary px-2 rounded-lg text-white">
            {{ selectPerson.length }}
          </div>
          <!-- <div class="mx-5"><v-icon>$complete</v-icon>อนุมัติรายการที่เลือก</div> -->
          <div
            class="mx-5 d-flex "
            @click="approveUser"
            :class="{ 'text-success cursor-pointer': selectPerson.length > 0 }"
          >
            <v-icon
              :class="{
                'text-success cursor-pointer': selectPerson.length > 0,
              }"
              >$complete</v-icon
            ><span class="m-auto ml-3">อนุมัติรายการที่เลือก</span>
          </div>

          <div
            class="d-flex"
            @click="onModalRejectComment"
            :class="{ 'text-danger cursor-pointer': selectPerson.length > 0 }"
          >
            <v-icon
              :class="{ 'text-danger cursor-pointer': selectPerson.length > 0 }"
              >$close</v-icon
            ><span class="m-auto ml-3">ไม่อนุมัติรายการที่เลือก</span>
          </div>
        </div>
        <v-data-table
          v-model="selectPerson"
          hide-default-footer
          :headers="headers[language]"
          :items="accounts.data"
          item-key="id"
          :items-per-page="pageSize"
          show-select
        >
          <template
            v-slot:item.data-table-select="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :value="item.status === 'WATING' && isSelected"
              :readonly="item.status !== 'WATING'"
              :disabled="item.status !== 'WATING'"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.created_at`]="{item}">
            {{showDate(item.created_at)}}
            
          </template>
          <template
            v-slot:[`item.status`]="{
              item,
            }"
          >
            <div
              v-if="item.status === `APPROVED`"
              class="text-primary font-weight-bold"
            >
              อนุมัติแล้ว
            </div>
            <div
              v-else-if="item.status === `REJECTED`"
              class="text-danger font-weight-bold"
            >
              ไม่อนุมัติ
            </div>
            <div v-else class="text-warning font-weight-bold">รอการอนุมัติ</div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>fa-ellipsis-v</v-icon>
                </v-btn>
              </template>
              <v-list dark>
                <v-list-item
                  class="cursor-pointer"
                  @click="removeSingleAccount(item.id)"
                  v-if="isEditable"
                >
                  <v-icon x-small>fa-trash</v-icon>
                  <span class="ml-3">
                    {{ $t("normal.delete") }}
                  </span>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:top="{}">
            <div
              class="d-flex justify-content-end align-items-center py-4 px-4"
            >
              {{ $t("normal.search") }}
              <div class="mx-3 search-box">
                <v-text-field
                  v-model="searchName"
                  dense
                  outlined
                  hide-details="true"
                  :placeholder="
                    $t('filter_player_staff.search_placeholder_player')
                  "
                ></v-text-field>
              </div>
            </div>
          </template>
          <template v-slot:footer="{}">
            <TableFooter
              :perPage.sync="pageSize"
              :currentPage.sync="currentPage"
              :lastPage="accounts.lastPage"
              :total="accounts.total"
            />
          </template>
        </v-data-table>
      </div>
    </div>
    <RejectModal :dialog.sync="showRejectModal" @commit="onCommitReject"/>
  </v-app>
</template>

<script>
import TableFooter from "../../components/TableFooter.vue";
import MasterDataHelper from "@/helpers/master_data_helper.js";
import { appAxios } from "../../helpers/app-axios";
import { languageService } from "../../helpers/language";
import RejectModal from "@/components/RejectModal.vue";
import { format } from "date-fns";

export default {
  components: {
    TableFooter,
    RejectModal,
  },
  mounted: function() {
    this.getAll()
  },
  data: function() {
    return {
      language: "th",
      searching: false,
      showRejectModal: false,
      commentFailed:null,

      headers: {
        th: [
          {
            text: this.$t("register.email", "th"),
            value: "email",
            sortable: false,
            width: "25%",
          },
          {
            text: this.$t("register.register_date", "th"),
            value: "created_at",
            sortable: false,
            width: "25%",
          },
          {
            text: this.$t("register.status_approved", "th"),
            value: "status",
            sortable: false,
            width: "25%",
          },

          {
            text: this.$t("player.table.action", "th"),
            value: "action",
            sortable: false,
            width: "10%",
          },
        ],
        en: [
          {
            text: this.$t("register.email", "en"),
            value: "email",
            sortable: false,
            width: "10%",
          },
          {
            text: this.$t("register.register_date", "en"),
            value: "created_at",
            sortable: false,
            width: "25%",
          },
          {
            text: this.$t("register.status_approved", "en"),
            value: "status",
            sortable: false,
            width: "15%",
          },

          {
            text: this.$t("player.table.action", "en"),
            value: "action",
            sortable: false,
            width: "10%",
          },
        ],
      },

      selectPerson: [],
      pageSizes: [10, 20, 50, 100],
      range: [0, 0],
      pageSize: 10,
      currentPage: 1,
      searchName: "",
    };
  },
  computed: {
    accounts() {
      const data = {
        currentPage: this.$store.state.register?.listRegister?.currentPage,
        data: this.$store.state.register?.listRegister?.data,
        lastPage: this.$store.state.register?.listRegister?.lastPage,
        perPage: this.$store.state.register?.listRegister?.perPage,
        total: this.$store.state.register?.listRegister?.total,
      };
      return data || {};
    },
    currentItem() {
      if (!this.accounts.currentPage) return "0 - 0";
      const currentItem =
        Number(this.accounts.currentPage - 1) * Number(this.accounts.perPage) +
        1;
      if (this.accounts.currentPage !== this.accounts.lastPage) {
        return `${currentItem} - ${currentItem +
          Number(this.accounts.perPage - 1)}`;
      } else {
        return `${currentItem} - ${Number(this.accounts.total)}`;
      }
    },

    params() {
      const params = {
        size: this.pageSize,
        page: this.currentPage,
        name: this.searchName,
      };

      return params;
    },
    isEditable() {
      return this.$store.state.permission?.permissions?.athlete_edit;
    },
  },
  methods: {

    async onCommitReject(value){
      const objData = {
        id:this.selectPerson.map((p) => +p.id),
        reason:value
      }
      await this.$store.dispatch("rejectUser", objData)
      await this.getAll()
    },
    onModalRejectComment() {
      this.$data.showRejectModal = true;
    },

    async approveUser(){
      await this.$store.dispatch("approveUser", this.selectPerson)
      await this.getAll()
      // console.log('approveUser',this.selectPerson)
    },
    getAll() {
      this.$store.dispatch("getRegister", this.params);
      // this.$store.dispatch("getPlayers", this.params);
    },
    search() {
      this.$data.searching = true;
      this.currentPage = 1;
      this.getAll();

    },
    showDate(date) {
      const formatDate = format(new Date(date), "dd/MM/yyyy");
      return formatDate;
      // return date
    },
    async removeSingleAccount(id) {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            this.removeAccount(id).then(() => {
              this.getAll();
            });
          }
        }
      );
    },
    async removeAccount(id) {
      // console.log('remove',id)
      return appAxios.post(`account-pre-register/${id}/delete`).then((res) => {
        res;
      });
    },

  },

  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  watch: {
    currentPage: function() {
      this.$data.searching = false;
      this.getAll();

    },
    pageSize: function () {
      this.getAll();
    },
    searchName: function () {
      this.getAll();
    },

  },
};
</script>

<style lang="scss" scoped>
.content {
  background: #f8f8f8;
}

.select-amount {
  padding: 0 10px;
  background: #1e73fd;
  color: white;
}

.search-box {
  width: 360px;
}

.page-size-select {
  width: 85px;
}
.image-upload,
.uploadImage {
  cursor: pointer;
}
.image-upload .selectFile {
  display: none;
}
.greenLabel {
  width: 10px;
  height: 10px;
  background: #28c76f;
  border-radius: 20px;
}
.redLabel {
  width: 10px;
  height: 10px;
  background: #ea5455;
  border-radius: 20px;
}
</style>
