import { BehaviorSubject } from 'rxjs';

const subject = new BehaviorSubject();

export const languageService = {
    getCurrent: () => subject.asObservable(),
    setCurrent: language => {
        subject.next(language);
        localStorage.setItem("language", language);
    },
    clear: () => {
        subject.next();
        localStorage.removeItem("language")
    }
};