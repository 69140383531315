<template>
  <v-dialog @input="closeModal" :value="dialog" max-width="60vw">
    <v-card>
      <div class="float-right">
        <v-card-actions>
          <!-- <v-btn color="primary" text @click="closeModal" mx-2>
            {{ $t("normal.cancel") }}
          </v-btn> -->
          <v-btn color="primary" @click="handleCommit" mx-2>
            {{ $t("normal.save") }}
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-title>
        <span class="header text-primary">สร้างข้อมูลทีม</span>
      </v-card-title>
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <div class="m-md-2 ">
          <div class="image-upload" @change="onChangeImage">
            <label for="file-input">
              <v-icon class="uploadImage">$imageIcon</v-icon>
            </label>

            <input
            
              class="selectFile"
              id="file-input"
              type="file"
              accept="image/*"
            />
          </div>
          <img
            v-if="itemImage.imageUrl"
            :src="itemImage.imageUrl"
            class="
              profileImage
              d-flex
              justify-content-center
              center
              p-1
              rounded-pill
            "
          />

          <img
            v-else
            class="
              profileImage
              d-flex
              justify-content-center
              center
              p-1
              rounded-pill
            "
            src="@/assets/images/img_avatar.png"
            alt="Alternative Text"
          />

          
        </div>
          </div>
          <div class="col-8 ">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="global_config_sport_category_name_th">
                    ชื่อทีม (ภาษาไทย) <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    type="text"
                    dense
                    outlined
                    v-model="form.setting_team_information_name_th"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="global_config_sport_category_name_th">
                    ชื่อทีม (ภาษาอังกฤษ) <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    type="text"
                    dense
                    outlined
                    v-model="form.setting_team_information_name_en"

                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="global_config_sport_category_name_th">
                    ชื่อผู้จัดการทีม <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    type="text"
                    dense
                    outlined
                    v-model="form.setting_team_information_manager_name"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="global_config_sport_category_name_th">
                    เบอร์โทรศัพท์ <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    type="text"
                    dense
                    outlined
                    v-model="form.setting_team_social_contact_phone"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="global_config_sport_category_name_th">
                    สถานที่ติดต่อ <span class="red--text">*</span>
                  </label>
                  <v-text-field
                    type="text"
                    dense
                    outlined
                    v-model="form.setting_team_region_address_detail"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "CreateTeam",
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      itemImage: {
        image: null,
        preview: null,
        imageUrl: null,
      },
      form:{},
      typeCompetitionForm: {},
      typeCompetitionSearchCompete: null,
      typeCompetitionList: [],
    };
  },
  mounted: function() {
    // this.getTeamSelf()
    // this.getSelfTeam()
  },
  computed: {
    ...mapState("sub_competition", ["personalData"]),

  },
  watch: {
    dialog() {
      if(Object.keys(this.personalData).length > 0){
        this.$data.form = {
          setting_team_information_name_th: this.personalData.setting_team_informations.setting_team_information_name_th,
          setting_team_information_name_en: this.personalData.setting_team_informations.setting_team_information_name_en,
          setting_team_information_manager_name: this.personalData.setting_team_informations.setting_team_information_manager_name,
          setting_team_social_contact_phone: this.personalData.setting_team_social_contacts.setting_team_social_contact_phone,
          setting_team_region_address_detail: this.personalData.setting_team_regions.setting_team_region_address_detail,
        }

        this.$data.itemImage.imageUrl = this.personalData.setting_team_informations.medias.setting_team_media_profile_url
      }
    }},
  components: {},
  methods: {
    // ...mapActions("sub_competition", ["getSelfTeam"]),

    onChangeImage(e) {
      const file = e.target.files[0];
      this.$data.itemImage.preview = e;
      this.$data.itemImage.image = file;
      this.$data.itemImage.imageUrl = URL.createObjectURL(file);
    },
    closeModal() {
      this.$emit("update:dialog", false);
    },
    handleCommit(){
      this.$emit("commit",{form:this.form,image:this.itemImage})
      this.closeModal()

    }
  },
};
</script>

<style lang="scss" scoped>
.image-upload .uploadImage {
  position: absolute;
  right: 20%;
  cursor: pointer;
}
.image-upload .selectFile {
  display: none;
}
.profileImage {
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none !important;
}
</style>
