<template>
  <div class="d-flex justify-content-between px-4 py-3">
    <div class="d-flex align-items-center">
      <span> {{ currentItem }} {{ $t('normal.from') }} {{ total }} {{ $t('normal.item') }} </span>
    </div>
    <div class="d-flex">
      <v-pagination
        :value="currentPage"
        @input="changeIndex"
        :length="lastPage"
        :total-visible="7"
        circle
      ></v-pagination>
      <div class="page-size-select" v-if="pageSizes.length > 1">
        <v-select
          :value="perPage"
          @change="changePerPage"
          dense
          outlined
          :items="pageSizes"
          hide-details
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 10,
    },
    lastPage: {
      type: Number,
      default: 1,
    },
    pageSizes: {
      default: () => [10, 20, 50, 100]
    }
  },
  methods: {
    changeIndex(value) {
      this.$emit("update:currentPage", value);
    },
    changePerPage(value) {
      this.$emit("update:perPage", value);
    },
  },
  computed: {
    currentItem() {
      if (!this.currentPage) return "0 - 0";
      const currentItem =
        (Number(this.currentPage - 1) * Number(this.perPage)) + 1;
      if (this.currentPage !== this.lastPage) {
        return `${currentItem} - ${currentItem + Number(this.perPage - 1)}`;
      } else {
        return `${currentItem} - ${Number(this.total)}`;
      }
    },
  },
};
</script>

<style scoped>
.page-size-select {
  width: 85px;
}
</style>
