<template>
  <div class="mb-5" id="education1">
    <h4 class="text-primary mb-5">
      {{ $t("player.personal_info.education_info") }}
    </h4>

    <div
      class="row col-md-12 m-0 p-0"
      v-for="(detail, i) in dataEducation.education"
      :key="i"
    >
      <div class="col-md-2 mx-0 px-0 bold text-primary py-1">
        {{ detail.year[language] }}
      </div>
      <div class="col-md-4 px-0 py-1">{{ detail.name[language] }}</div>
      <div class="col-md-6 py-1 px-0">{{ detail.details[language] }}</div>
    </div>
    <div
      class="row col-md-12 m-0 p-0 d-flex justify-content-center"
      v-if="!dataEducation.education || dataEducation.education.length === 0"
    >
      <label>{{ $t("normal.no_data") }}</label>
    </div>
  </div>
</template>

<script>
import { languageService } from "../../helpers/language";

export default {
  name: "EducationInformation",
  props: ["education"],
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  mounted(){
            this.$emit('heightSize',document.querySelector('#education1').offsetHeight)

  },
  data() {
    return {
      language: "th",
      dataEducation: {},
    };
  },
  watch: {
    education() {
      this.dataEducation = {
        education: this.education?.map((edu) => {
          return {
            year: {
              th:+edu?.account_education_end_at + 543,
              en:edu?.account_education_end_at
            },
            name: {
              th: edu?.global_config_educations
                ?.global_config_education_name_th,
              en: edu?.global_config_educations
                ?.global_config_education_name_en,
            },
            details: {
                th:edu?.account_education_faculty
              ? edu.account_education_faculty +
                "," +
                edu?.account_education_name_th
              : "" + edu?.account_education_name_th,
              en:edu?.account_education_faculty_en
              ? edu.account_education_faculty_en +
                "," +
                edu?.account_education_name_en
              : "" + edu?.account_education_name_en,
            }
          };
        }),
      };
    },
  },
};
</script>