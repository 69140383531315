export default class MasterDataHelper {
  static sweetAlertConfig(language) {
    if (language === 'th') {
      return {
      icon: 'warning',
      iconColor: '#FF5A43',
      title: "คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้",
      // title: $t("staff.s÷÷taff_name", language),
      text: "(หากกดยืนยันแล้วจะไม่สามารถกู้คืนข้อมูลได้อีก)",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF5A43",
      confirmButtonText: "ยืนยัน",
      cancelButtonColor: "#C8C8C8",
      cancelButtonText: "ยกเลิก",
    }
    } else {
      return {
        icon: 'warning',
        iconColor: '#FF5A43',
        title: "Are you sure you want to delete this item?",
        // title: $t("staff.s÷÷taff_name", language),
        text: "Once deleted permanently. They cannot be recovered.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FF5A43",
        confirmButtonText: "Confirm",
        cancelButtonColor: "#C8C8C8",
        cancelButtonText: "Cancel",
      }
    }

  }
}