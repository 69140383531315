<template>
  <div id="personal1" class="mb-8" >
    <h4 class="text-primary mb-5">
      {{ $t("player.personal_info.personal_info") }}
    </h4>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-md-11">
        <div class="row">
          <div class="row col-md-6 m-0 p-0">
            <div class="col-md-5 mx-0 px-0 bold text-primary py-1">
              {{ $t("player.personal_info.age") }}
            </div>
            <div class="col-md-7 mx-0 px-0 py-1">{{ dataPersonal.age }}</div>
          </div>
          <div class="row col-md-6 m-0 p-0">
            <div class="col-md-5 mx-0 px-0 bold text-primary py-1">
              {{ $t("player.personal_info.gender") }}
            </div>
            <div class="col-md-7 mx-0 px-0 py-1">
              {{ dataPersonal.gender[language] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-md-11">
        <div class="row">
          <div class="row col-md-6 m-0 p-0">
            <div class="col-md-5 mx-0 px-0 bold text-primary py-1">
              {{ $t("player.personal_info.nationality") }}
            </div>
            <div class="col-md-7 mx-0 px-0 py-1">
              {{ dataPersonal.nationality[language] }}
            </div>
          </div>
          <div class="row col-md-6 m-0 p-0">
            <div class="col-md-5 mx-0 px-0 bold text-primary py-1">
              {{ $t("player.personal_info.religion") }}
            </div>
            <div class="col-md-7 mx-0 px-0 py-1">
              {{ dataPersonal.religion[language] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-md-11">
        <div class="row">
          <div class="row col-md-6 m-0 p-0">
            <div class="col-md-5 mx-0 px-0 bold text-primary py-1">
              {{ $t("player.athlete_info.height") }}
            </div>
            <div class="col-md-7 mx-0 px-0 py-1">
              {{ dataPersonal.height }}
            </div>
          </div>
          <div class="row col-md-6 m-0 p-0">
            <div class="col-md-5 mx-0 px-0 bold text-primary py-1">
              {{ $t("player.athlete_info.weight") }}
            </div>
            <div class="col-md-7 mx-0 px-0 py-1">
              {{ dataPersonal.weight }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-md-11">
        <div class="row">
          <div class="row col-md-6 m-0 p-0">
            <div class="col-md-5 mx-0 px-0 bold text-primary py-1">
              {{ $t("player.athlete_info.dominant_foot") }}
            </div>
            <div class="col-md-7 mx-0 px-0 py-1">
              {{ dataPersonal.footskill[language] }}
            </div>
          </div>
          <div class="row col-md-6 m-0 p-0">
            <div class="col-md-5 mx-0 px-0 bold text-primary py-1">
              {{ $t("player.athlete_info.dominant_hand") }}
            </div>
            <div class="col-md-7 mx-0 px-0 py-1">
              {{ dataPersonal.handskill[language] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { languageService } from "../../helpers/language";

export default {
  name: "personalInformation",
  props: ["personal"],
  data() {
    return {
      language: "th",
      dataPersonal: {},
    };
  },
  mounted(){
    this.$emit('heightSize',165)
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  methods: {
    religion() {
      switch (
        this.personal?.account_information?.account_information_religion
      ) {
        case "พุทธ":
          return {
            th: "พุทธ",
            en: "Buddhism",
          };

        case "คริสต์":
          return {
            th: "คริสต์",
            en: "Christianity",
          };

        case "อิสลาม":
          return {
            th: "อิสลาม",
            en: "Islam",
          };
        case "ฮินดู":
          return {
            th: "ฮินดู",
            en: "Hinduism",
          };
        case "อื่น ๆ":
          return {
            th: "อื่น ๆ",
            en: "Other",
          };
        default:
          return {
            th: "",
            en: "",
          };
      }
    },
    feetSkill(){
      switch(this.personal?.account_information?.account_information_feet_skill){
        case "เท้าซ้าย":
          return {
            th: "เท้าซ้าย",
            en: "Left Footed",
          };
        case "เท้าขวา":
          return {
            th: "เท้าขวา",
            en: "Right Footed",
          };
        case "ถนัดทั้งสองเท้า":
          return {
            th: "ถนัดทั้งสองเท้า",
            en: "Ambidextrous",
          };
        default:
          return {
            th: "",
            en: "",
          };
      }
    },
    handSkill(){
      switch(this.personal?.account_information?.account_information_hand_skill){
        case "มือซ้าย":
          return {
            th: "มือซ้าย",
            en: "Left Handed",
          };
        case "มือขวา":
          return {
            th: "มือขวา",
            en: "Right Handed",
          };
        case "ถนัดทั้งสองมือ":
          return {
            th: "ถนัดทั้งสองมือ",
            en: "Ambidextrous",
          };
        default:
          return {
            th: "",
            en: "",
          };
      }
    },
    genderList(){
      switch(this.personal?.account_information?.account_information_gender){
        case 0:
          return {
            th: "ชาย",
            en: "Male",
          };
        case 1:
          return {
            th: "หญิง",
            en: "Female",
          };
        case 2:
          return {
            th: "ทรานส์เจนเดอร์",
            en: "Trangender",
          };
        default:
          return {
            th: "",
            en: "",
          };
      }
    }
  },
  watch: {
    personal() {
      this.dataPersonal = {
        age:
          this.personal?.account_banner?.account_informations
            ?.account_information_age || "-",
        gender:this.genderList(),
        nationality: {
          th:
            this.personal?.account_information
              ?.account_information_nationality || "-",
          en:
            this.personal?.account_information
              ?.account_information_nationality_en || "-",
        },

        religion: this.religion(),
        height:
          this.personal?.account_banner?.account_informations
            ?.account_information_height || "-",
        weight:
          this.personal?.account_banner?.account_informations
            ?.account_information_weight || "-",
        footskill:this.feetSkill(),
        handskill:this.handSkill()
      };

    },

  },
};
</script>