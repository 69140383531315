<template>
  <v-app>
    <div class="content app-content">
      <div class="container">
        <h2>{{ $t("competition.all") }} 1 {{this.currentPage}}</h2>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="competitionData"
          :single-select="singleSelect"
          :page.sync="currentPage"
          :items-per-page="pageSize"
                    class="elevation-1 row-pointer"
          hide-default-footer
          @page-count="pageCount = $event"
          @click:row="onRowClick"
        >
          <!-- <template v-slot:[`item.name`]="{ item }">
              <v-img
                :src="item.setting_competition_media_profile_icon"
                contain
                width="40"
                class="ma-2"
              ></v-img>
              <div>{{ item }}</div>
              <div>{{ item.name_en }}</div>
            </template> -->

          <template v-slot:top="{}">
            <div
              class="d-flex justify-content-end align-items-center py-4 px-4"
            >
              {{ $t("normal.search") }}
              <div class="mx-3 search-box">
                <v-text-field
                  v-model="params.name"
                  dense
                  outlined
                  hide-details="true"
                  :placeholder="$t('competition.search_placeholder')"
                  @keyup.enter="getCompetitions"
                ></v-text-field>
              </div>
              <!-- <div>
                  <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-icon x-small>fa-eye</v-icon>
                        <span class="mx-3">{{ $t("normal.display") }}</span>
                        <v-icon x-small>fa-chevron-down</v-icon>
                      </div>
                    </template>
                    <div class="p-1 bg-white">
                      <div class="mb-4">{{ $t("normal.display") }}</div>
                      <v-form>
                        <div>{{ $t("competition.level") }}</div>
                        <v-select
                          dense
                          outlined
                          hide-details="true"
                          v-model="filter.level"
                          :items="levels"
                        ></v-select>
                        <div>{{ $t("competition.team_type") }}</div>
                        <v-select
                          dense
                          outlined
                          hide-details="true"
                          v-model="filter.type"
                          :items="types"
                        ></v-select>
                      </v-form>
                    </div>
                  </v-menu>
                </div> -->
            </div>
          </template>
          <template v-slot:footer="{}">
            <TableFooter
            :perPage.sync="pageSize"
            :currentPage.sync="currentPage"
              :lastPage="competitions.lastPage"
              :total="competitions.total"
            />
          </template>
        </v-data-table>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TableFooter from "../../../components/TableFooter.vue";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  name: "Competition",

  components: {
    TableFooter,
  },
  computed: {
    ...mapState("competition", ["competitions", "params"]),
    canDeleteSelected() {
      return this.selected.length > 0;
    },

    competitionData() {
      return [
        {
          name: "Competitions",
        },
        {
          name: "Competitions",
        },
      ];
    },
    listActions() {
      return [
        {
          label: this.$t("normal.delete_select"),
          icon: "fa-trash",
          action: "onDeleteCompetitions",
        },
      ];
    },
    headers() {
      return [
        {
          text: "ชื่อรายการแข่งขัน",
          value:
            "setting_competition_informations.setting_competition_information_name_" +
            this.$t("short_language"),
          sortable: false,
        },
        {
          text: this.$t("competition.sub"),
          value: "count_sub_competition",
          sortable: false,
        },
        {
          text: this.$t("competition.level"),
          value: "setting_competition_level",
          sortable: false,
        },
        {
          text: this.$t("competition.team_type"),
          value:
            "global_config_standard_types.global_config_standard_type_name_" +
            this.$t("short_language"),
          sortable: false,
        },
        {
          text: this.$t("competition.association_or_organizer"),
          value:
            "setting_competition_informations.setting_competition_information_organizer_" +
            this.$t("short_language"),
          sortable: false,
        },
      ];
    },
    actions() {
      return [
        { text: this.$t("normal.edit_list"), icon: "fa-pen", key: "edit" },
        {
          text: this.$t("normal.delete_list"),
          icon: "fa-trash",
          key: "delete",
        },
      ];
    },
    levels() {
      return [
        { text: this.$t("competition_level.all"), value: "" },
        { text: this.$t("competition_level.district"), value: "DISTRICT" },
        { text: this.$t("competition_level.zone"), value: "ZONE" },
        {
          text: this.$t("competition_level.continental"),
          value: "CONTINENTAL",
        },
        {
          text: this.$t("competition_level.international"),
          value: "INTERNATIONAL",
        },
        { text: this.$t("competition_level.regional"), value: "REGIONAL" },
        { text: this.$t("competition_level.global"), value: "GLOBAL" },
      ];
    },
    types() {
      return [
        { text: this.$t("competition_team_type.all"), value: "" },
        {
          text: this.$t("competition_team_type.national_team"),
          value: "National",
        },
        { text: this.$t("competition_team_type.club"), value: "Club" },
      ];
    },
  },

  data() {
    return {
      selected: [],
      singleSelect: false,
      pageSize: 10,
      currentPage: 1,

      pageSizes: [10, 20, 50, 100],
      range: [10, 20],
      dialogConfirmDelete: false,
      itemDeleting: null,
      filter: {
        level: "",
        type: "",
      },
    };
  },

  mounted() {
    this.getCompetitions();
  },

  methods: {
    ...mapActions("competition", [
      "getCompetitions",
      "clearCompetition",
      "deleteCompetition",
    ]),

    onAddCompetition() {
      this.clearCompetition();
      this.$router.push({ name: "competition-add" });
    },
    onRowClick(row) {
      this.$router.push({ name: "competition-detail", params: { id: row.id } });
    },
    async onClick(action, item) {
      if (action === "edit") {
        this.clearCompetition();
        this.$router.push({
          name: "competition-update",
          params: { id: item.id },
        });
      } else if (action === "delete") {
        this.itemDeleting = item;
        await this.$swal(
          MasterDataHelper.sweetAlertConfig(this.$t("short_language"))
        ).then(async (result) => {
          if (result.value) {
            this.deleteCompetition(this.itemDeleting.id);
          }
        });
      }
    },
    confirmDeleteItem() {
      this.dialogConfirmDelete = false;
      if (this.selected.length > 0) {
        const ids = this.selected.map((select) => select.id);
        const filtered = this.competitions.data?.filter((data) => {
          return !ids.includes(data.id);
        });
        this.$set(this.competitions, "data", filtered);
      } else {
        this.deleteCompetition(this.itemDeleting.id);
      }
    },
    handleClick(action) {
      this[action]();
    },
    async onDeleteCompetitions() {
      await this.$swal(
        MasterDataHelper.sweetAlertConfig(this.$t("short_language"))
      ).then(async (result) => {
        if (result.value) {
          if (this.selected.length > 0) {
            const ids = this.selected.map((select) => select.id);
            const filtered = this.competitions.data?.filter((data) => {
              return !ids.includes(data.id);
            });
            this.$set(this.competitions, "data", filtered);
          }
        }
      });
    },
    competitionLevel(level) {
      switch (level) {
        case "DISTRICT":
          return this.$t("competition_level.district");
        case "ZONE":
          return this.$t("competition_level.zone");
        case "REGIONAL":
          return this.$t("competition_level.regional");
        case "INTERNATIONAL":
          return this.$t("competition_level.national");
        case "CONTINENTAL":
          return this.$t("competition_level.continental");
        case "GLOBAL":
          return this.$t("competition_level.global");
      }
    },
  },

  watch: {
    "params"(){
      console.log('params',this.params);
    },
    currentPage: function () {
      console.log('watch.params.page',this.params);
      this.getCompetitions();
    },
    pageSize: function () {
      this.currentPage = 1;
      this.getCompetitions();
    },
    "params.name"() {
      this.getCompetitions();
    },
  },
};
</script>

<style scoped>
.content {
  background: #f8f8f8;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.select-amount {
  padding: 0 10px;
  background: #1e73fd;
  color: white;
}

.search-box {
  width: 360px;
}
</style>
