<template>
  <v-dialog
    :value="visible"
    @input="closeModal"
    width="900px"
    v-bind:hide-overlay="false"
    style="margin-top: auto"
  >
    <v-card height="">
      <div class="dialog-wrapper">
        <div class="dialog-header">
          <div class="dialog-header-title">{{ $t("team.team_shirt") }}</div>
          <div class="dialog-actions">
            <v-btn color="primary" plain class="btn-close" @click="closeModal">
              {{ $t("normal.cancel") }}
            </v-btn>
            <v-btn color="primary" class="m-l-5px" @click="commit">
              {{ $t("normal.save") }}
            </v-btn>
          </div>
        </div>
        <div class="dialog-content">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name">{{
                  $t("normal.select_year")
                }}</label>
                <v-select
                  item-text="year"
                  item-value="year"
                  :items="years"
                  v-model="form.uniformYear"
                  dense
                  outlined
                >
                </v-select>
              </div>
            </div>
          </div>
          <hr class="" style="width: auto" />
          <div class="row">
            <div class="col-4" v-for="(item, idx) of templates" :key="idx">
              <label class="form-label" for="basic-addon-name">{{
                item.label
              }}</label>
              <template v-if="item.imageSrc">
                <div class="position-relative">
                  <div class="remove-btn" @click="removeImage(item.type)">
                    X
                  </div>
                  <img
                    :src="item.imageSrc"
                    width="100%"
                    height="auto"
                    alt="Alternative Text"
                    class="d-flex justify-content-center"
                    style="border-radius: 10px"
                  />
                </div>
              </template>
              <template v-else>
                <vue-dropzone
                  v-if="dropzoneOptions"
                  id="picture"
                  :options="dropzoneOptions"
                  :useCustomSlot="true"
                  @vdropzone-success="
                    (file) => onUploadSuccess(file, item.type)
                  "
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      {{ $t("player.image.drag_image") }}
                    </h3>
                    <div class="subtitle">
                      {{ `${$t("player.image.sub_drag_image")}` }}
                    </div>
                  </div>
                </vue-dropzone>
              </template>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { appAxios } from "../../helpers/app-axios";
import { languageService } from "../../helpers/language";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: String,
    },
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  beforeDestroy() {
    console.log("destroy team");
    this.subscription.unsubscribe();
  },
  methods: {
    closeModal(e) {
      this.$emit("closeModal", e);
    },
    commit() {
      this.$emit("commit", this.form);
    },
    onUploadSuccess(file, key) {
      this.$set(this.form, key, file);
    },
    removeImage(type) {
      this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then((result) => {
        if (result.value) {
          this.$set(this.$data.form, type, null);
        }
      });
    },
    getImage(teamId, year) {
      if (!teamId || !year) return;
      appAxios.get(`team/${teamId}/uniforms/${year}`).then((res) => {
        const data = res.data?.data?.uniform;
        if (!data) return;
        this.$set(this.$data.form, "HOME", null);
        this.$set(this.$data.form, "AWAY", null);
        this.$set(this.$data.form, "SPARE", null);
        data.forEach((ele) => {
          this.$set(
            this.$data.form,
            ele.setting_team_uniform_type,
            ele.setting_team_uniform_image_url
          );
        });
      });
    },
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(Array(year - 1995 + 1).keys()).map((ele) => 1995 + ele);
    },
    homeShirtImageUrl() {
      if (!this.$data.form?.HOME) return null;
      if (!(this.$data.form?.HOME instanceof File))
        return this.$data.form?.HOME;
      return URL.createObjectURL(this.$data.form?.HOME);
    },
    awayShirtImageUrl() {
      if (!this.$data.form?.AWAY) return null;
      if (!(this.$data.form?.AWAY instanceof File))
        return this.$data.form?.AWAY;
      return URL.createObjectURL(this.$data.form?.AWAY);
    },
    spareShirtImageUrl() {
      if (!this.$data.form?.SPARE) return null;
      if (!(this.$data.form?.SPARE instanceof File))
        return this.$data.form?.SPARE;
      return URL.createObjectURL(this.$data.form?.SPARE);
    },
    templates() {
      return [
        {
          label: this.$t("team.home_team_uniform"),
          imageSrc: this.homeShirtImageUrl,
          type: "HOME",
        },
        {
          label: this.$t("team.away_team_uniform"),
          imageSrc: this.awayShirtImageUrl,
          type: "AWAY",
        },
        {
          label: this.$t("team.spare_team_uniform"),
          imageSrc: this.spareShirtImageUrl,
          type: "SPARE",
        },
      ];
    },
  },
  data() {
    return {
      dropzoneOptions: {
        url: `/api/v1/team/shirt-uniform/onhold`,
        thumbnailWidth: 200,
        addRemoveLinks: true,
      },
      form: {
        uniformYear: new Date().getFullYear(),
      },
      language: "th",
    };
  },
  watch: {
    "form.uniformYear"(newValue) {
      this.getImage(this.teamId, newValue);
    },
    teamId(newValue) {
      if (!newValue) return;
      this.getImage(newValue, this.form.uniformYear);
    },
  },
};
</script>

<style lang="scss" scope>
.remove-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  border-radius: 50%;
  background: white;
  width: 25px;
  height: 25px;
  text-align: center;
}
</style>
