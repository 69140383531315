export default {
  state: {
    isShowErrorSnackbar: false,
    errorText: null
  },
  mutations: {
    setErrorText(state, value) {
      state.errorText = value;
    },
    setShowErrorSnackbar(state, value) {
      state.isShowErrorSnackbar = value;
    },
  },
  actions: {
    showErrorSnackbar({ commit }, text) {
      commit('setErrorText', text)
      commit('setShowErrorSnackbar', true);
    },
    closeErrorSnackbar({ commit }) {
      commit("setShowErrorSnackbar", false);
    }
  },
};
