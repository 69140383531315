<template>
  <div></div>
</template>

<script>
import jwtDecode from 'jwt-decode';
import { SAVE_PERMISSION, SAVE_ROLE } from '../../store/permission';

export default {
  mounted() {
    const token = this.$route.query?.token;
    const decodedToken = jwtDecode(token);
    const permission = decodedToken?.account_role?.permission_menu;
    const role = decodedToken?.account_role?.permission_code;
    this.$store.dispatch('setName', decodedToken?.name);
    this.$store.dispatch('setPosition', decodedToken?.account_role.permission_name_th);
    this.$store.dispatch(SAVE_ROLE, role);
    if (permission) {
      this.$store.dispatch(SAVE_PERMISSION, permission);
    }
    // const existToken = localStorage.getItem(process.env.VUE_APP_TOKEN_KEY_ADMIN);
    if (token) {
      localStorage.setItem(process.env.VUE_APP_TOKEN_KEY_ADMIN, token);
      this.$router.push('/');
    } else {
      window.location.href = process.env.VUE_APP_SSO_PATH;
    }
  }
}
</script>
