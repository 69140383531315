<template>
  <div class="d-flex">
<div class="rounded h-100 px-4 py-4 d-flex align-items-center justify-content-center" :style="{ backgroundColor }">
      <v-icon :style="{ color: iconColor, width: '30px', height: '30px' }">{{icon}}</v-icon>
    </div>
    <div class="ml-2 d-flex flex-column justify-content-center">
      <div>
        <span class="h6">{{ numbers }}</span>
        <span class="fs-6"> {{ unit }}</span>
        <span class="ml-1" v-if="extra" :style="{ color: iconColor }">{{
          extra
        }}</span>
      </div>
      <div class="labelCard">{{ label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SummaryPerson",
  props: [
    "icon",
    "backgroundColor",
    "iconColor",
    "amount",
    "label",
    "extra",
    "unit",
  ],
  computed: {
    numbers: function() {
      return Number(this.amount).toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.labelCard{
  font-size: 10px;
}
</style>>

