<template>
  <div class="detailHeader">
    <div class="headerSet py-8 h-100 show-image position-relative">
    <img
				v-if="banner.image"
				:src="banner.image"
				alt="Alternative Text"
				class="imgPlayer"
			/>
			<img
				v-else
				src="@/assets/images/cv_staff.png"
				class="mr-3 mt-3 iconPlayer"
			/>
      <div class="nameCV">
				<h2>{{ language==='th'?banner.firstName:banner.firstNameEn }}</h2>
        <h2>{{ language==='th'?banner.lastName:banner.lastNameEn  }}</h2>
        <h5>{{ language==='th'?banner.fullNameEn:banner.fullNameTH  }}</h5>
				<div
					class=" d-inline fontTeam"
					v-for="(posit, m) in banner.position"
					:key="m"
				>
					<label class="font-small-1 mr-2">{{ posit.name[language] }}</label>
				</div>
				<div v-for="(posits, m) in banner.team" :key="m + 1" class="col">
					<div class="row">
						<img
							v-if="posits.icon"
							:src="posits.icon"
							width="25px"
							height="30px"
							class="mr-3 mt-3"
						/>
						<img
							v-else
							src="@/assets/images/iconTeam.png"
							width="25px"
							height="30px"
							class="mr-3 mt-3"
						/>

						<div class="mt-2">
							<div class="font-small-1" v-if="m === 0">{{ $t("player.team") }}</div>
							<div class="font-small-1"  v-else>{{ $t("filter_player_staff.persent_team") }}</div>

							<div class="font-small-1" v-if="posits.name">
								<span v-if="posits.name">{{ posits.name[language] }}</span><span v-if="posits.shirt">#{{ posits.shirt }}</span>
								{{ checkPosition(posits.position) }}
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { languageService } from "../../helpers/language";
import {
	playerPositionState,
} from "../../store/team_players_histories";
export default {
  name: "headerStaff",
  props: ["banner"],
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  data() {
    return {
      language: "th",
    };
  },
  computed: {
		...mapState({
			playerPosition: (state) =>
				state.teamPlayersHistories[playerPositionState],
		}),
	},
  methods: {
		checkPosition(position) {
			const find = this.playerPosition.find((te) => te.id === position);
			return find?.global_config_position_name_th || "";
		},
	},
};
</script>


<style scoped>
.detailHeader {
  position: unset;
  top: 0;
  color: white;
  height: 300px;
  width: -webkit-fill-available;
}

.headerSet {
  display: flex;
}

.show-image {
  background-image: url("~@/assets/images/top_header.png"),
    url("~@/assets/images/header_staff.png"),
    url("~@/assets/images/header_bg.png");
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: cover, cover, cover;
}
.nameCV {
  width: 21vw;
  position: absolute;
  right: 4%;
}
h2,
h3,
h4,
h5,
h6,
.fontTeam label {
  color: white;
}
.iconAvatar {
  color: white;
  font-size: 6px;
}
.imgPlayer {
  height: 90%;
  width: 20%;
  bottom: 0;
  left: 25%;
  position: absolute;
}
.iconPlayer {
  height: 80%;
  width: 20%;
  left: 25%;
  bottom: 0;
  position: absolute;
}
@media (max-width: 1370px) {
  .imgPlayer {
    height: auto;
    width: 25%;
  }
  .progressList {
    top: 10px;
  }
}
.progressList {
  position: absolute;
  right: 6%;
  width: 15vw;
}
</style>
