import { appAxios } from "../helpers/app-axios";

// change here
const modelNameSingular = 'injuryType'
const modelNamePlural = 'injuryTypes'
export const inJuryTypesState = 'injuryTypes'
export const getAllInjuryTypes = 'getAllInjuryTypes'

// non change
const getAllName = `get${modelNamePlural.charAt(0).toUpperCase() + modelNamePlural.slice(1)}`
// const getOneName = `get${modelNameSingular.charAt(0).toUpperCase() + modelNameSingular.slice(1)}`
const createName = `create${modelNameSingular.charAt(0).toUpperCase() + modelNameSingular.slice(1)}`
const updateName = `update${modelNameSingular.charAt(0).toUpperCase() + modelNameSingular.slice(1)}`
const deleteName = `delete${modelNameSingular.charAt(0).toUpperCase() + modelNameSingular.slice(1)}`

export default {
  state: {
    [modelNameSingular]: {},
    [modelNamePlural]: [],
    [inJuryTypesState]: [],
    isFethingAllTypes: false
  },
  mutations: {
    [getAllName](state, { res }) {
      state[modelNamePlural] = res
    },
    [createName]() {
    },
    [updateName]() {
    },
    [deleteName]() {
    },
    [getAllInjuryTypes](state, payload) {
      state[inJuryTypesState] = payload;
    },
    setIsFetchingAllTypes(state, status) {
      state.isFethingAllTypes = status;
    }
  },
  actions: {
    [getAllName]({ commit }, params) {
      const query = new URLSearchParams(params)
      return appAxios.get(`setting/global/injury-types`, { params: query })
        .then((res) => {
          commit(getAllName, { res: res.data.data })
        })
    },
    [createName]({ commit }, payload) {
      return appAxios.post(`setting/global/injury-types`, payload)
        .then(() => {
          commit(createName, { res: payload })
        })
    },
    [updateName]({ commit }, { index, payload }) {
      return appAxios.post(`setting/global/injury-types`, payload)
        .then((res) => {
          commit(updateName, { res: res.data.data, index: index })
        })
    },
    [deleteName]({ commit }, id) {
      return appAxios.post(`setting/global/injury-types/${id}/delete`)
        .then((res) => {
          commit(deleteName, { res: res.data.data })
        })
    },
    async [getAllInjuryTypes]({commit, state}) {
      if (state[inJuryTypesState].length > 0 || state.isFethingAllTypes) return;
      commit('setIsFetchingAllTypes', true);
      appAxios.get(`setting/global/injury-types/all`, { params: { active: true } }).then(res => {
        commit(getAllInjuryTypes, res.data?.data);
        console.log('getAllInjuryTypes', res.data?.data);
        commit('setIsFetchingAllTypes', false);
      })
    }
  }
}
