import axios from "axios";

const tokenKey = process.env.VUE_APP_TOKEN_KEY_ADMIN;

const reqInterceptor = (req) => {
  const token = localStorage.getItem(tokenKey);
  req.headers.Authorization = `Bearer ${token}`;
  return req;
};

const errorInterceptor = (store) => (err) => {
  switch (err.response.status) {
    case 401:
      localStorage.removeItem(tokenKey);
      window.location.href = process.env.VUE_APP_SSO_PATH;
      break;
    default:
      break;
  }
  if (!err.response?.config.url.includes('competition')) {
    store.dispatch('showErrorSnackbar', err.response.data.message);
  }
  throw err.response;
};

const appAxios = axios.create({
  baseURL: "/api/v1",
});

export const setAxios = (store) => {
  appAxios.interceptors.request.use(reqInterceptor);
  appAxios.interceptors.response.use((res) => res, errorInterceptor(store));
}

export { appAxios };
