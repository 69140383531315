import { appAxios } from "../helpers/app-axios";

// change here
// const modelNameSingular = 'skill'
const modelNamePlural = 'skills'

// non change
const getAllName = `get${modelNamePlural.charAt(0).toUpperCase() + modelNamePlural.slice(1)}`

export default {
  state: {
    [modelNamePlural]: []
  },
  mutations: {
    [getAllName](state, { res }) {
      state[modelNamePlural] = res
    },
    saveSkill(state, { res }) {
      state[modelNamePlural] = res
    },
  },
  actions: {
    async [getAllName]({ commit }) {
      appAxios.get('setting/global/skill')
        .then((res) => {
          commit(getAllName, { res: res.data.data })
        })
    },
    async saveSkill({ commit }, payload) {
      appAxios.post('setting/global/skill', payload)
        .then((res) => {
          commit('saveSkill', { res: res.data.data })
        })
    },
  }
}