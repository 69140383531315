<template>
  <v-app>
    <div class="content app-content">
      <div class="content-header">
        <div class="content-header-left">
          <div class="row d-flex align-items-center justify-space-between">
            <div class="col-12 col-md-8 d-flex align-items-center">
              <v-icon class="content-header-title float-left mb-0" small>
                fa-chevron-left
              </v-icon>
              <router-link to="/competition" custom>
                <h5 class="content-header-title float-left mb-0 ml-2">
                  {{ $t("competition.all") }}
                </h5>
              </router-link>
              <h5 class="content-header-title float-left mb-0 ml-2">/</h5>
              <router-link
                :to="{
                  name: 'competition-detail',
                  params: { id: competitionId },
                }"
                custom
              >
                <h5 class="content-header-title float-left mb-0 ml-2">
                  {{
                    competition.setting_competition_informations[
                      "setting_competition_information_name_" +
                        $t("short_language")
                    ]
                  }}
                </h5>
              </router-link>
              <template>
                <h5 class="content-header-title float-left mb-0 ml-2">/</h5>
                <h5 class="content-header-title float-left mb-0 ml-2">
                  สมัครแข่งขัน
                </h5>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="container" v-if="!isAdmin">
        <div class="bg-white px-5 py-5">
          <div class="d-flex justify-content-between">
            <div class="my-auto text-primary">
              รายชื่อนักกีฬา, บุคลากร - {{ nameTitle }}
            </div>
            <div>
              <v-btn color="primary" text mx-2>
                {{ $t("normal.cancel") }}
              </v-btn>
              <v-btn color="primary" @click="handleCommit" mx-2>
                {{ $t("normal.save") }}
              </v-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-3 mt-10 text-end">สีเสื้อทีม:</div>
            <div class="col-3">
              <label>สีเสื้อชุดที่ 1 *</label>
              <v-text-field
                dense
                outlined
                hide-details
                v-model="uniform.shirt_color_1"
              ></v-text-field>
            </div>
            <div class="col-3">
              <label>สีเสื้อชุดที่ 2 *</label>
              <v-text-field
                dense
                outlined
                hide-details
                v-model="uniform.shirt_color_2"
              ></v-text-field>
            </div>
          </div>
          <div class="row">
            <div class="col-3 mt-10 text-end">สีกางเกงทีม :</div>
            <div class="col-3">
              <label>สีกางเกงชุดที่ 1 *</label>
              <v-text-field
                dense
                outlined
                hide-details
                v-model="uniform.shorts_color_1"
              ></v-text-field>
            </div>
            <div class="col-3">
              <label>สีกางเกงชุดที่ 2*</label>
              <v-text-field
                dense
                outlined
                hide-details
                v-model="uniform.shorts_color_2"
              ></v-text-field>
            </div>
          </div>

          <hr />
          <div class="row" v-for="(itemStaff, idxStaff) in staff" :key="idxStaff">
            <div class="col-3 mt-10 text-end">
              <span v-if="idxStaff === 0">บุคลากร :</span>
            </div>
            <div class="col-3">
              <label>ประเภทบุคลากร * </label>
              <!-- {{this.positionStaff}} -->
              <v-select
                dense
                outlined
                :items="positionStaff"
                item-value="id"
                item-text="global_config_position_name_th"
                v-model="itemStaff.global_config_position"
              ></v-select>
            </div>
            <div class="col-3">
              <label>ชื่อ - นามสกุล *</label>
              <v-select
                dense
                outlined
                :items="listStaff"
                item-value="value"
                item-text="label"
                v-model="itemStaff.account_id"
              ></v-select>
            </div>
            <div class="col-1 mt-7">
              <div v-if="idxStaff < staff.length - 1">
                 <v-btn
                color="red"
               
                @click="deleteList(idxStaff)"
                ><v-icon small class="pa-3">fa-trash</v-icon></v-btn
              >
              </div>
             <div v-else @click="addList">
               <v-btn color="primary" 
                ><v-icon small class="pa-3" 
                  >fa-plus</v-icon
                ></v-btn
              >
             </div>
             
            </div>
          </div>
          <hr />
          <div>
            <div>
              นักกีฬา :
            </div>
            <div class="row" v-for="(item, idx) in player" :key="idx">
              <div class="col-2">
                <div class="m-md-2">
                  <div
                    class="image-upload"
                    @change="onChangeImage($event, idx)"
                  >
                    <label :for="'file-input-' + idx">
                      <v-icon class="uploadImage">$imageIcon</v-icon>
                    </label>

                    <input
                      class="selectFile"
                      :id="'file-input-' + idx"
                      type="file"
                      accept="image/*"
                    />
                  </div>
                  <img
                    v-if="item.itemImage.imageUrl"
                    :src="item.itemImage.imageUrl"
                    class="
              profileImage
              d-flex
              justify-content-center
              center
              p-1
              rounded-pill
            "
                  />

                  <img
                    v-else
                    class="
              profileImage
              d-flex
              justify-content-center
              center
              p-1
              rounded-pill
            "
                    src="@/assets/images/img_avatar.png"
                    alt="Alternative Text"
                  />
                </div>
              </div>
              <div class="col-9">
                <div class="row">
                  <div class="col-4">
                    <label>ชื่อจริง *</label>
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      v-model="item.account_information_firstname_th"
                    ></v-text-field>
                  </div>
                  <div class="col-4">
                    <label>นามสกุล *</label>
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      v-model="item.account_information_lastname_th"
                    ></v-text-field>
                  </div>
                  <div class="col-4">
                    <label>เลขบัตรประชาชน * </label>
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      v-model="item.account_information_citizen_id"
                    ></v-text-field>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("player.personal_info.dob")
                      }}</label>
                      <v-menu
                        ref="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            :value="birthDayFormats[idx]"
                            dense
                            outlined
                            hide-details
                            @click:clear="item.account_information_dob = null"
                            :placeholder="$t('player.personal_info.dob')"
                            append-icon="fa-calendar"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          min="1950-01-01"
                          locale="us-us"
                          v-model="item.account_information_dob"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                  <div class="col-4">
                    <label>{{ $t("player.personal_info.age") }}</label>
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      disabled
                      :value="age[idx]"
                    ></v-text-field>
                  </div>

                  <div class="col-4">
                    <label>ส่วนสูง *</label>
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      v-model="item.account_information_height"
                    ></v-text-field>
                  </div>
                  <div class="col-4">
                    <label>น้ำหนัก *</label>
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      v-model="item.account_information_weight"
                    ></v-text-field>
                  </div>
                  <div class="col-4">
                    <label>เบอร์เสื้อชุด 1 *</label>
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      v-model="item.team_player_history_shirt_number"
                    ></v-text-field>
                  </div>
                  <div class="col-4">
                    <label>เบอร์เสื้อชุด 2 *</label>
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      v-model="item.team_player_history_shirt_number_2"
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="col-1">
                <v-btn
                  color="red"
                  :style="`height:-webkit-fill-available`"
                  @click="deleteListPlayer(idx)"
                  ><v-icon small class="pa-3">fa-trash</v-icon></v-btn
                >
              </div>
            </div>
            <div class="w-100 text-center my-5">
              <v-btn color="primary" class="text-center" @click="addPlayer"
                ><v-icon small class="pa-3">fa-plus</v-icon> เพิ่มนักกีฬา</v-btn
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else class="container">
        <ListTeam :uniform="uniform" :player="player" />
      </div>
    </div>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import { differenceInYears, format } from "date-fns";
import { appAxios } from "../../../helpers/app-axios";
import ListTeam from "../../../components/Competition/Sub/ListTeam.vue";
export default {
  name: "AddPlayerInTeam",
  components: {
    ListTeam,
  },
  mounted() {
    this.competitionId = this.$route.params.cid;
    this.subCompetitionId = this.$route.params.sid;
    // this.subTeamID =this.$route.params.list
    this.subTeamID = this.$route.params.list.includes("-")
      ? this.$route.params.list.split("-")[1]
      : this.$route.params.list;

    this.getPositionStaff();
    if (!this.isAdmin) {
      this.getAllStaff();
    }
    this.getSubTeam();
  },
  computed: {
    ...mapState("competition", ["competition"]),
    ...mapState("sub_competition", [
      "sub_competitions",
      "personalData",
      "teamData",
    ]),

    isAdmin() {
      return this.$store.state.permission.role !== "B_TEAM_ADMIN";
    },
    nameTitle() {
      const findSubTeam = this.teamData.sub_team_information.find(
        (t) => t.id === this.$route.params.list
      );
      return (
        findSubTeam.setting_sub_competition_id.setting_sub_competition_type_id
          .setting_sub_competition_type_name_th +
        " " +
        findSubTeam.setting_sub_competition_id
          .setting_sub_competition_sub_type_id
          .setting_sub_competition_sub_type_name_th
      );
    },
    // isOpenMenu(idx) {
    //   // Return true if the date picker has a value, else false
    //   return !!this.player[idx].account_information_dob;
    // },
    age() {
      return this.player.map((player) => {
        if (!player.account_information_dob) {
          return 0;
        }
        return differenceInYears(
          new Date(),
          new Date(player.account_information_dob)
        );
      });
    },
    birthDayFormats() {
      return this.player.map((player) => {
        if (!player.account_information_dob) {
          return null;
        }
        return format(new Date(player.account_information_dob), "dd/MM/yy");
      });
    },
  },
  data() {
    return {
      positionStaff: [],
      listStaff: [],
      uniform: {},
      staff: [
        {
          account_id: null,
          global_config_position: null
          // name:null,
        },
      ],
      player: [
        {
          account_information_firstname_th: null,
          account_information_lastname_th: null,
          account_information_citizen_id: null,
          team_player_history_shirt_number: null,
          team_player_history_shirt_number_2: null,
          account_information_height: null,
          account_information_weight: null,
          account_information_dob: null,
          team_player_history_profile_image_path: null,
          itemImage: {
            preview: null,
            image: null,
            imageUrl: null,
          },
        },
      ],
    };
  },
  methods: {
    async getSubTeam() {
      await appAxios
        .get(
          `team/${this.personalData.id ??
            this.$route.params.list.split("-")[0]}/sub-team/${this.subTeamID}`
        )
        .then((res) => {
          const response = res.data.data.team;
          this.uniform = {
            shirt_color_1: response.shirt_color_1,
            shorts_color_1: response.shorts_color_1,
            shirt_color_2: response.shirt_color_2,
            shorts_color_2: response.shorts_color_2,
            status:response.status
          };
        });

      await appAxios
        .get(
          `team/${this.personalData.id ??
            this.$route.params.list.split("-")[0]}/staffs/sub-team/${
            this.subTeamID
          }`
        )
        .then((res) => {
          const listData = res.data.data.staffs.data.map((s) => {
            return {
              account_id: s.accounts.account_id,
              // name:s.accounts.account_informations.account_information_firstname_th + ' ' + s.accounts.account_informations.account_information_lastname_th,
              global_config_position: s?.global_config_positions?.id,
            };
          });
          this.staff =
            listData.length === 0 && !this.isAdmin
              ? [
                  {
                    account_id: null,
                    global_config_position: null,
                    // name:null
                  },
                ]
              : listData;

              console.log('listData: ' , listData,this.staff)

        });
      await appAxios
        .get(
          `team/${this.personalData.id ??
            this.$route.params.list.split("-")[0]}/players/sub-team/${
            this.subTeamID
          }`
        )
        .then((res) => {
          const listDataPlayer = res.data.data.team_player_history.data.map(
            (s) => {
              return {
                account_information_firstname_th:
                  s.accounts.account_informations
                    .account_information_firstname_th,
                account_information_lastname_th:
                  s.accounts.account_informations
                    .account_information_lastname_th,
                account_information_citizen_id:
                  s.accounts.account_informations
                    .account_information_citizen_id,
                team_player_history_shirt_number:
                  s.team_player_history_shirt_number,
                team_player_history_shirt_number_2:
                  s.team_player_history_shirt_number_2,
                account_information_height:
                  s.accounts.account_informations.account_information_height,
                account_information_weight:
                  s.accounts.account_informations.account_information_weight,
                account_information_dob:
                  s.accounts.account_informations.account_information_dob,
                team_player_history_profile_image_path: s.team_player_history_profile_image_path,
                itemImage: { preview: null, image: null, imageUrl: s.team_player_history_profile_image_url },
              };
            }
          );

          this.player =
            listDataPlayer.length === 0 && !this.isAdmin
              ? [
                  {
                    account_information_firstname_th: null,
                    account_information_lastname_th: null,
                    account_information_citizen_id: null,
                    team_player_history_shirt_number: null,
                    team_player_history_shirt_number_2: null,
                    account_information_height: null,
                    account_information_weight: null,
                    account_information_dob: null,
                    team_player_history_profile_image_path: null,
                    itemImage: {
                      preview: null,
                      image: null,
                      imageUrl: null,
                    },
                  },
                ]
              : listDataPlayer;

        });
    },
    deleteListPlayer(itemPlayer) {
      this.player = this.player.filter((item, index) => index !== itemPlayer);
    },
    deleteList(itemToDelete) {
      this.staff = this.staff.filter((item, index) => index !== itemToDelete);
    },

    async saveUniform() {
      await appAxios.post(
        `team/${this.personalData.id}/sub-team/${this.subTeamID}/update`,
        this.uniform
      );
      //getTeamSelf
      // return appAxios.post
    },
    async saveStaff() {
      const objData = {
        staffs: this.staff.map((s) => {
          return {
            account_id: +s.account_id,
            global_config_position: +s.global_config_position,
          };
        }),
      };
      // console.log("save staff", objData);
      //teamID,subTeamID
      await appAxios.post(
        `team/${this.personalData.id}/sub-team/${this.subTeamID}/staffs`,
        objData
      );
    },
    async savePlayer() {
      const playerDataPromises = this.player.map(async (player) => {
        if (player.itemImage.image) {
          const formData = new FormData();
          formData.append("file", player.itemImage.image);

          // Upload profile image for the player
          const response = await appAxios.post(
            `team/${this.personalData.id}/sub-team/${this.subTeamID}/medias/profile`,
            formData
          );

          console.log('Uploading profile image',response)
          // Return the file URL from the response
          return response.data.data.file_path;
        } else {
          return null; // Return null if no image to upload
        }
      });

      // console.log('playerDataPromises',playerDataPromises,this.player)

      // Wait for all player data promises to resolve
      const playerDataFileUrls = await Promise.all(playerDataPromises);

      // Combine player data with their respective file URLs
      const playerDataWithFileUrls = this.player.map((player, index) => {
        delete player['itemImage'];
        return {
          ...player,
          account_information_height: +player.account_information_height,
                    account_information_weight: +player.account_information_weight,
          team_player_history_shirt_number_2: +player.team_player_history_shirt_number_2,
          team_player_history_shirt_number: +player.team_player_history_shirt_number,
          team_player_history_profile_image_path: playerDataFileUrls[index] ?? player.team_player_history_profile_image_path, // Assign file URL to the player data
        };
      });



      // console.log("image", objData);
      await appAxios.post(
        `team/${this.personalData.id}/sub-team/${this.subTeamID}/players`,
        {
          players: playerDataWithFileUrls,
        }
      );
    },
    async handleCommit() {
      await this.saveUniform();
      await this.saveStaff();
      await this.savePlayer();

      this.$router.push({
        name: "sub-competition-detail",
        params: { cid: this.competitionId, sid: 'staff', tab: "1" },
      });
    },
    async getPositionStaff() {
      await appAxios.get("global/position/staff/all").then((res) => {
        this.positionStaff = res.data.data.position;
      });
    },
    async getAllStaff() {
      await appAxios.get("account/personels/staff").then((res) => {
        this.listStaff = res.data.data.account.data.map((res) => {
          return {
            label:
              res.account_informations.account_information_firstname_th +
              " " +
              res.account_informations.account_information_lastname_th,
            value: res.account_id,
          };
        });
      });
    },
    addList() {
      
      // this.staff.push({
      //   account_id: null,
      //   global_config_position: null
      // });
      this.staff = [
        ...this.staff,{
          account_id: null,
        global_config_position: null
        }
      ]
      console.log('addList',this.staff)

    },
    onChangeImage(e, idx) {
      const file = e.target.files[0];
      console.log('onChangeImage',file,idx)
      // this.$data.item.preview = e;
      // this.$data.item.image = file;
      // this.$data.item.imageUrl = URL.createObjectURL(file);
      this.player[idx].itemImage.preview = e;
      this.player[idx].itemImage.image = file;
      this.player[idx].itemImage.imageUrl = URL.createObjectURL(file);

      console.log("this.player.onChangeImage", this.player);
    },
    addPlayer() {
      this.player.push({
        account_information_firstname_th: null,
        account_information_lastname_th: null,
        account_information_citizen_id: null,
        team_player_history_shirt_number: null,
        team_player_history_shirt_number_2: null,
        account_information_height: null,
        account_information_weight: null,
        account_information_dob: null,
        team_player_history_profile_image_path: null,
        itemImage: {
          preview: null,
          image: null,
          imageUrl: null,
        },
      });
    },
  },
};
</script>
<style scoped>
.content {
  background: #f8f8f8;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.no-data {
  cursor: default !important;
}
.image-upload .uploadImage {
  position: absolute;
  right: 20%;
  cursor: pointer;
}
.image-upload .selectFile {
  display: none;
}

.profileImage {
  height: auto;
  width: -webkit-fill-available;
  object-fit: cover;
}
.textWord {
  word-break: break-word;
}
</style>
