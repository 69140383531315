import { render, staticRenderFns } from "./PlayerTabs.vue?vue&type=template&id=3ea05be1&scoped=true&"
import script from "./PlayerTabs.vue?vue&type=script&lang=js&"
export * from "./PlayerTabs.vue?vue&type=script&lang=js&"
import style0 from "./PlayerTabs.vue?vue&type=style&index=0&id=3ea05be1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ea05be1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VIcon,VTab,VTabItem,VTabs,VTabsItems,VToolbar})
