var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"}),(_vm.isEditable)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title text-primary"},[_vm._v(" "+_vm._s(_vm.$t("team.attach_file"))+" ")])]),(_vm.documentDropzoneOptions)?_c('vue-dropzone',{ref:"getDocument",attrs:{"options":_vm.documentDropzoneOptions,"id":"picture","useCustomSlot":true},on:{"vdropzone-success":function (file) { return _vm.onUploadSuccess(file, 'getDocument'); }}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('h3',{staticClass:"dropzone-custom-title"},[_vm._v(" "+_vm._s(_vm.$t("player.document.drag_document"))+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v("("+_vm._s(_vm.$t("stadium.sub_drag_image"))+")")]),_c('div',{staticClass:"subtitle"},[_vm._v(" ("+_vm._s(_vm.$t("player.document.second_sub_document"))+") ")])])]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title text-primary"},[_vm._v(" "+_vm._s(_vm.$t("player.document.manage_document"))+" ")])]),_c('v-row',_vm._l((_vm.documents),function(item,idx){return _c('v-col',{key:idx,staticClass:"\n              d-flex\n              justify-content-center\n              align-items-center\n              child-flex\n            ",staticStyle:{"flex-direction":"column"},attrs:{"md":"2","cols":"4"}},[_c('v-menu',{staticStyle:{"max-width":"600px"},attrs:{"position-x":item.x,"position-y":item.y,"absolute":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"font-size":"150px"},attrs:{"color":"blue darken-1","aspect-ratio":"1"}},'v-icon',attrs,false),on),[_vm._v(" fa-file ")]),_c('label',{staticClass:"form-label",attrs:{"for":"basic-addon-name"}},[_vm._v(_vm._s(item.setting_team_media_file_name))])]}}],null,true),model:{value:(item.showMenu),callback:function ($$v) {_vm.$set(item, "showMenu", $$v)},expression:"item.showMenu"}},[_c('v-list',[_c('v-list-item',{staticClass:"cursor-pointer"},[_c('v-list-item-title',{on:{"click":function () { return _vm.download(
                          item.setting_team_media_url,
                          item.setting_team_media_name
                        ); }}},[_vm._v(_vm._s(_vm.$t("image.size_document")))])],1),_c('v-list-item',{staticClass:"cursor-pointer"},[_c('v-list-item-title',{on:{"click":function () { return _vm.deleteMedia(item.id, 'getDocument'); }}},[_vm._v(_vm._s(_vm.$t("normal.delete_list")))])],1)],1)],1)],1)}),1),_c('TableFooter',{attrs:{"total":_vm.imageTotal,"perPage":_vm.imagePerPage,"lastPage":_vm.imageLastPage,"currentPage":_vm.imagePage},on:{"update:perPage":function($event){_vm.imagePerPage=$event},"update:per-page":function($event){_vm.imagePerPage=$event},"update:currentPage":function($event){_vm.imagePage=$event},"update:current-page":function($event){_vm.imagePage=$event}}})],1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title text-primary"},[_vm._v(" "+_vm._s(_vm.$t("player.document.other_records_th"))+" ")])]),_c('form',{ref:"formRef",staticClass:"needs-validation",attrs:{"novalidate":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar,"disabled":!_vm.isEditable},model:{value:(
                    _vm.teamDocumentRemarkForm.setting_team_information_remark
                  ),callback:function ($$v) {_vm.$set(_vm.teamDocumentRemarkForm, "setting_team_information_remark", $$v)},expression:"\n                    teamDocumentRemarkForm.setting_team_information_remark\n                  "}})],1)])])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title text-primary"},[_vm._v(" "+_vm._s(_vm.$t("player.document.other_records_en"))+" ")])]),_c('form',{ref:"formRef",staticClass:"needs-validation",attrs:{"novalidate":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar,"disabled":!_vm.isEditable},model:{value:(
                    _vm.teamDocumentRemarkForm.setting_team_information_remark_en
                  ),callback:function ($$v) {_vm.$set(_vm.teamDocumentRemarkForm, "setting_team_information_remark_en", $$v)},expression:"\n                    teamDocumentRemarkForm.setting_team_information_remark_en\n                  "}})],1)])])])])]),_c('v-btn',{staticClass:"btn-primary float-left mb-5",attrs:{"elevation":"2"},on:{"click":function($event){return _vm.backTab()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fa-arrow-left ")]),_vm._v(_vm._s(_vm.$t("normal.previous")))],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }