var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-light menu-accordion menu-shadow",attrs:{"id":"navbar","data-scroll-to-active":"true"}},[_c('Navbar'),_c('div',{staticClass:"shadow-bottom"}),_c('div',{staticClass:"main-menu-content"},[_c('ul',{staticClass:"navigation navigation-main",attrs:{"id":"main-menu-navigation","data-menu":"menu-navigation"}},[(_vm.isAdmin)?_c('div',[_c('div',{staticClass:"d-flex align-items-center homePage",attrs:{"target":"_blank"},on:{"click":_vm.toAnotherPage}},[_c('v-icon',[_vm._v("$home")]),_c('span',{staticClass:"font-weight-bolder",attrs:{"data-i18n":"Email"}},[_vm._v(_vm._s(_vm.$t("menu.home")))])],1)]):_vm._e(),(_vm.checkPermission('/'))?_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('li',{class:[_vm.$route.name === 'Dashboard' && 'active'],attrs:{"role":"link"},on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('v-icon',{attrs:{"dark":_vm.$route.name === 'Dashboard'?true:false}},[_vm._v("$fileText")]),_c('span',{staticClass:"menu-title text-truncate font-weight-bolder",attrs:{"data-i18n":"Chat"}},[_vm._v(_vm._s(!_vm.isAdmin ? "หน้าแรก":_vm.$t("menu.report")))])],1)])]}}],null,false,3359774239)}):_vm._e(),(_vm.checkPermission('/player'))?_c('router-link',{attrs:{"to":"/player","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active' , _vm.$route.name === 'AddPlayer' && 'active' , _vm.$route.name === 'EditPlayer' && 'active'],attrs:{"role":"link"},on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('v-icon',{attrs:{"dark":isActive}},[_vm._v("$user")]),_c('span',{staticClass:"menu-title text-truncate font-weight-bolder",attrs:{"data-i18n":"Chat"}},[_vm._v(_vm._s(_vm.$t("menu.player")))])],1)])]}}],null,false,1421652186)}):_vm._e(),(_vm.checkPermission('/staff'))?_c('router-link',{attrs:{"to":"/staff","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active' , _vm.$route.name === 'AddStaff' && 'active' , _vm.$route.name === 'EditStaff' && 'active'],attrs:{"role":"link"},on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('v-icon',{attrs:{"dark":isActive}},[_vm._v("$users")]),_c('span',{staticClass:"menu-title text-truncate font-weight-bolder",attrs:{"data-i18n":"Chat"}},[_vm._v(_vm._s(_vm.$t("menu.staff")))])],1)])]}}],null,false,3994037820)}):_vm._e(),(_vm.checkPermission('/team'))?_c('router-link',{attrs:{"to":"/team","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active' , _vm.$route.name === 'AddTeam' && 'active'],attrs:{"role":"link"},on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('v-icon',{attrs:{"dark":isActive}},[_vm._v("$teamIcon")]),_c('span',{staticClass:"menu-title text-truncate font-weight-bolder",attrs:{"data-i18n":"Chat"}},[_vm._v(_vm._s(_vm.$t("menu.team")))])],1)])]}}],null,false,737739739)}):_vm._e(),_c('router-link',{attrs:{"to":"/competition","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && 'active' , _vm.$route.name === 'competition' && 'active'],attrs:{"role":"link"},on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('v-icon',{attrs:{"dark":isActive}},[_vm._v("$trophy")]),_c('span',{staticClass:"menu-title text-truncate font-weight-bolder",attrs:{"data-i18n":"Chat"}},[_vm._v(_vm._s(_vm.$t("menu.competition")))])],1)])]}}])}),(_vm.checkPermission('/register'))?_c('router-link',{attrs:{"to":"/register","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'active'],attrs:{"role":"link"},on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[(isExactActive)?_c('IconImage',{attrs:{"src":"assets/images/icons/register_white.svg","width":"20","height":"20"}}):_vm._e(),(!isExactActive)?_c('IconImage',{attrs:{"src":"assets/images/icons/register.svg","width":"20","height":"20"}}):_vm._e(),_c('span',{staticClass:"menu-title text-truncate font-weight-bolder",attrs:{"data-i18n":"Chat"}},[_vm._v(_vm._s(_vm.$t("menu.register")))])],1)])]}}],null,false,2455298068)}):_vm._e(),(_vm.checkPermission('/master_data'))?_c('div',{staticClass:"app-divider"},[_c('div',{staticClass:"border-bottom d-flex"},[_c('v-icon',{staticStyle:{"color":"#B9B9C3"},attrs:{"small":""}},[_vm._v("$setting")]),_c('small',{staticClass:"menu-title text-truncate ml-25",attrs:{"data-i18n":"Setting"}},[_vm._v(_vm._s(_vm.$t("menu.setting")))])],1)]):_vm._e(),(_vm.checkPermission('/master_data/relation'))?_c('router-link',{attrs:{"to":"/master_data/relation","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'active'],attrs:{"role":"link"},on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[(isExactActive)?_c('IconImage',{attrs:{"src":"assets/images/icons/relation_white.svg","width":"20","height":"20"}}):_vm._e(),(!isExactActive)?_c('IconImage',{attrs:{"src":"assets/images/icons/relation.svg","width":"20","height":"20"}}):_vm._e(),_c('span',{staticClass:"menu-title text-truncate font-weight-bolder",attrs:{"data-i18n":"Chat"}},[_vm._v(_vm._s(_vm.$t("menu.setting_relationship")))])],1)])]}}],null,false,3269522128)}):_vm._e(),(_vm.checkPermission('/master_data/education'))?_c('router-link',{attrs:{"to":"/master_data/education","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'active'],attrs:{"role":"link"},on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[(isExactActive)?_c('IconImage',{attrs:{"src":"assets/images/icons/education_white.svg","width":"20","height":"20"}}):_vm._e(),(!isExactActive)?_c('IconImage',{attrs:{"src":"assets/images/icons/education.svg","width":"20","height":"20"}}):_vm._e(),_c('span',{staticClass:"menu-title text-truncate font-weight-bolder",attrs:{"data-i18n":"Chat"}},[_vm._v(_vm._s(_vm.$t("menu.setting_education")))])],1)])]}}],null,false,2317894462)}):_vm._e(),(_vm.checkPermission('/master_data'))?_c('router-link',{attrs:{"to":"#player","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item has-sub",attrs:{"data-x":isExactActive}},[_c('a',{class:'d-flex align-items-center sub-active-' + _vm.subIsActive(['/master_data/player_position', '/master_data/sport_category', '/master_data/injury_type', '/master_data/skill', '/master_data/physical_fitness']),attrs:{"href":"#"}},[(_vm.subIsActive(['/master_data/player_position', '/master_data/sport_category', '/master_data/injury_type', '/master_data/skill', '/master_data/physical_fitness']))?_c('IconImage',{attrs:{"src":"assets/images/icons/player_white.svg","width":"20","height":"20"}}):_vm._e(),(!_vm.subIsActive(['/master_data/player_position', '/master_data/sport_category', '/master_data/injury_type', '/master_data/skill', '/master_data/physical_fitness']))?_c('IconImage',{attrs:{"src":"assets/images/icons/player.svg","width":"20","height":"20"}}):_vm._e(),_c('span',{staticClass:"menu-title text-truncate font-weight-bolder",attrs:{"data-i18n":"Invoice"}},[_vm._v(_vm._s(_vm.$t("menu.setting_player")))])],1),_c('ul',{staticClass:"menu-content"},[(_vm.checkPermission('/master_data/player_position'))?_c('router-link',{attrs:{"to":"/master_data/player_position","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'active'],on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"menu-item text-truncate",attrs:{"data-i18n":"List"}},[_vm._v(_vm._s(_vm.$t("menu.position_player")))])])])]}}],null,true)}):_vm._e(),(_vm.checkPermission('/master_data/sport_category'))?_c('router-link',{attrs:{"to":"/master_data/sport_category","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'active'],on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"menu-item text-truncate",attrs:{"data-i18n":"List"}},[_vm._v(_vm._s(_vm.$t("menu.type_sport")))])])])]}}],null,true)}):_vm._e(),(_vm.checkPermission('/master_data/injury_type'))?_c('router-link',{attrs:{"to":"/master_data/injury_type","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'active'],on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"menu-item text-truncate",attrs:{"data-i18n":"List"}},[_vm._v(_vm._s(_vm.$t("menu.injury")))])])])]}}],null,true)}):_vm._e(),(_vm.checkPermission('/master_data/skill'))?_c('router-link',{attrs:{"to":"/master_data/skill","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'active'],on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"menu-item text-truncate",attrs:{"data-i18n":"List"}},[_vm._v(_vm._s(_vm.$t("menu.player_skill")))])])])]}}],null,true)}):_vm._e(),(_vm.checkPermission('/master_data/physical_fitness'))?_c('router-link',{attrs:{"to":"/master_data/physical_fitness","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'active'],on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"menu-item text-truncate",attrs:{"data-i18n":"List"}},[_vm._v(_vm._s(_vm.$t("menu.player_status")))])])])]}}],null,true)}):_vm._e()],1)])]}}],null,false,1921471725)}):_vm._e(),(_vm.checkPermission('/master_data'))?_c('router-link',{attrs:{"to":"#staff","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item has-sub",attrs:{"data-x":isExactActive}},[_c('a',{class:'d-flex align-items-center sub-active-' + _vm.subIsActive(['/master_data/staff_position', '/master_data/department']),attrs:{"href":"#"}},[(_vm.subIsActive(['/master_data/staff_position', '/master_data/department']))?_c('IconImage',{attrs:{"className":"icon-image","src":"assets/images/icons/staff_white.svg","width":"20","height":"20"}}):_vm._e(),(!_vm.subIsActive(['/master_data/staff_position', '/master_data/department']))?_c('IconImage',{attrs:{"className":"icon-image","src":"assets/images/icons/staff.svg","width":"20","height":"20"}}):_vm._e(),_c('span',{staticClass:"menu-title text-truncate font-weight-bolder",attrs:{"data-i18n":"Invoice"}},[_vm._v(_vm._s(_vm.$t("menu.setting_staff")))])],1),_c('ul',{staticClass:"menu-content"},[(_vm.checkPermission('/master_data/staff_position'))?_c('router-link',{attrs:{"to":"/master_data/staff_position","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'active'],on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"menu-item text-truncate",attrs:{"data-i18n":"List"}},[_vm._v(_vm._s(_vm.$t("menu.position_staff")))])])])]}}],null,true)}):_vm._e(),(_vm.checkPermission('/master_data/department'))?_c('router-link',{attrs:{"to":"/master_data/department","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'active'],on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"menu-item text-truncate",attrs:{"data-i18n":"List"}},[_vm._v(_vm._s(_vm.$t("menu.department_staff")))])])])]}}],null,true)}):_vm._e()],1)])]}}],null,false,2421458796)}):_vm._e(),(_vm.checkPermission('/master_data'))?_c('router-link',{attrs:{"to":"#award","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item has-sub",attrs:{"data-x":isExactActive}},[_c('a',{class:'d-flex align-items-center sub-active-' + _vm.subIsActive(['/master_data/award']),attrs:{"href":"#"}},[(_vm.subIsActive(['/master_data/award']))?_c('IconImage',{attrs:{"className":"icon-image","src":"assets/images/icons/award_white.svg","width":"20","height":"20"}}):_vm._e(),(!_vm.subIsActive(['/master_data/award']))?_c('IconImage',{attrs:{"className":"icon-image","src":"assets/images/icons/award.svg","width":"20","height":"20"}}):_vm._e(),_c('span',{staticClass:"menu-title text-truncate font-weight-bolder",attrs:{"data-i18n":"Invoice"}},[_vm._v(_vm._s(_vm.$t("menu.setting_competition")))])],1),_c('ul',{staticClass:"menu-content"},[(_vm.checkPermission('/master_data/award'))?_c('router-link',{attrs:{"to":"/master_data/award","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{class:[isExactActive && 'active'],on:{"click":navigate}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"menu-item text-truncate",attrs:{"data-i18n":"List"}},[_vm._v(_vm._s(_vm.$t("menu.setting_award")))])])])]}}],null,true)}):_vm._e()],1)])]}}],null,false,2922099523)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }