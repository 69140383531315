<template>
  <v-dialog @input="closeModal" :value="dialog" max-width="700px">
    <v-card class="px-5 py-2">
      <div class="d-flex justify-content-between">
        <div class="my-auto text-primary">เหตุผลการปฏิเสธ</div>
        <div>
          <v-btn color="primary" text @click="closeModal" mx-2>
            {{ $t("normal.cancel") }}
          </v-btn>
          <v-btn color="primary" @click="handleCommit" mx-2>
            {{ $t("normal.save") }}
          </v-btn>
        </div>
      </div>
      <div class="form-group">
        <label for="global_config_position_name_th">
            เหตุผลการปฏิเสธ </label>
        <v-textarea  auto-grow outlined rows="7" v-model="commentFailed"></v-textarea>
      </div>

      <!-- <v-textarea class="mt-5" auto-grow outlined rows="7"></v-textarea> -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RejectModal",
  props: ["dialog"],
  data(){
    return{
      commentFailed:""
    }
  },
  methods: {
    closeModal() {
      this.$emit("update:dialog", false);
    },
    handleCommit(){
      this.$emit("commit",this.commentFailed)
      this.closeModal()
    }
  },
};
</script>
<style></style>