<template>
  <div>
    <div class="row pr-10">
      <div class="col-6">
        <svg-map
          :map="Thailand"
          :location-class="getLocationClass"
          @mousemove="moveOnLocation"
          @mouseover="pointLocation"
          @mouseout="unpointLocation"
        />
        <div :style="tooltipStyle" v-if="pointedLocation" class="nameLocation">
          {{ pointedLocation }}
        </div>
      </div>
      <div class="col-6">
        <v-row class="mb-3">
          <div>{{ $t("report.age") }}</div>
        </v-row>
        <v-row>
          <v-text-field
            dense
            outlined
            :value="range[0]"
            class="mt-0 pt-0 pr-10"
            hide-details
            type="number"
            @change="$set(range, 0, $event)"
            style="width: 60px"
          ></v-text-field>
          <!--@change="$set(range, 0, $event)"-->
          <v-text-field
            dense
            outlined
            :value="range[1]"
            class="mt-0 pt-0 pl-10"
            type="number"
            style="width: 60px"
            @change="$set(range, 1, $event)"
          ></v-text-field>
        </v-row>
        <v-row class="mt-0">
          <v-range-slider
            v-model="range"
            :max="100"
            :min="0"
            hide-details
            class="align-center"
            @mouseup="searchAge"
            @mousedown="changeAge"
          >
          </v-range-slider>
        </v-row>

        <v-row class="mb-3">{{ $t("report.gender") }}</v-row>
        <v-row class="mb-3">
          <v-select
            dense
            :items="genderList"
            item-value="value"
            :item-text="'text_' + language"
            v-model="gender"
            outlined
            hide-details="true"
            clearable
          ></v-select>
        </v-row>
        <v-row class="mb-3">{{ $t("report.position") }}</v-row>
        <v-row>
          <v-select
            dense
            :items="positionsList"
            item-value="id"
            :item-text="'global_config_position_name_' + language"
            v-model="position"
            outlined
            hide-details="true"
            clearable
          ></v-select
        ></v-row>
      </div>
    </div>
    <div class="mapColor">
      <div class="colored level1">
        <div class="mt-5 text-center font-small-2">0</div>
      </div>
      <div class="colored level2">
        <div class="mt-5 text-center font-small-2">1-10</div>
      </div>
      <div class="colored level3">
        <div class="mt-5 text-center font-small-2">11-15</div>
      </div>
      <div class="colored level4">
        <div class="mt-5 text-center font-small-2">16-20</div>
      </div>
      <div class="colored level5">
        <div class="mt-5 text-center font-small-2">20+</div>
      </div>
    </div>
  </div>
</template>

<script>
import { SvgMap } from "vue-svg-map";
import Thailand from "@svg-maps/thailand";
import { getMapPlayer } from "../../store/dashboard";
import { appAxios } from "../../helpers/app-axios";
import {
  provincesState,
  getProvinces,
} from "../../store/global_config_province";
import { mapState } from "vuex";
// import { mapState } from "vuex";
export default {
  components: { SvgMap },
  props: {
    language: {
      type: String,
      default: "th",
    },
  },
  mounted() {
    this.$store.dispatch(getMapPlayer);
    this.$data.thailandList = this.$store.state.dashboard.mapPlayerState;
    this.getPosition();
    this.$store.dispatch(getProvinces);
  },
  data: function () {
    return {
      Thailand,
      pointedLocation: null,
      tooltipStyle: null,
      genderList: [
        {
          value: 0,
          text_en: this.$t("normal.male", "en"),
          text_th: this.$t("normal.male", "th"),
        },
        {
          value: 1,
          text_en: this.$t("normal.female", "en"),
          text_th: this.$t("normal.female", "th"),
        },
        {
          value: 2,
          text_en: this.$t("normal.transgender", "en"),
          text_th: this.$t("normal.transgender", "th"),
        },
      ],
      checkSlider: false,
      gender: null,
      positionsList: [],
      position: null,
      range: [0, 0],
      thailandList: null,
      paramsMap: null,
    };
  },
  watch: {
    gender(newVal) {
      if (newVal === null) {
        delete this.paramsMap["gender"];
      } else {
        this.paramsMap = { ...this.paramsMap, gender: newVal };
      }
      this.$store.dispatch(getMapPlayer, this.paramsMap);
    },
    position(newVal) {
      if (newVal === null) {
        delete this.paramsMap["position_id"];
      } else {
        this.paramsMap = { ...this.paramsMap, position_id: +newVal };
      }
      this.$store.dispatch(getMapPlayer, this.paramsMap);
    },
    range(newVal) {

        if (newVal[1] === 0 || newVal[1] === "0") {
          delete this.paramsMap["age_min"];
          delete this.paramsMap["age_max"];
        } else {
          this.paramsMap = { ...this.paramsMap, age_min: newVal[0] };
          this.paramsMap = { ...this.paramsMap, age_max: newVal[1] };
        }if (!this.checkSlider) {
        this.$store.dispatch(getMapPlayer, this.paramsMap);
      }
    },
  },
  computed: {
    ...mapState({
      provinces: (state) => state.globalConfigProvince[provincesState],
    }),
  },
  methods: {
    changeAge() {
      this.checkSlider = true;
    },
    searchAge() {
      this.$store.dispatch(getMapPlayer, this.paramsMap);
      this.checkSlider = false;
    },
    async getPosition() {
      return appAxios.get(`setting/global/positions/player/all`).then((res) => {
        const data = res.data?.data;
        if (!data) return;
        this.$data.positionsList = data;
      });
    },
    moveOnLocation(event) {
      this.tooltipStyle = {
        display: "block",
        top: `${event.clientY + 10}px`,
        left: `${event.clientX - 100}px`,
      };
    },
    getLocationName(node) {
      return node && node.attributes.id.value;
    },
    pointLocation(event) {
      if (
        this.thailandList &&
        this.$store.state.dashboard.mapPlayerState.length !== 0
      ) {
        const compareThailand = this.$store.state.dashboard.mapPlayerState.find(
          (elm) => {
            return elm.text === event.target.id;
          }
        );

        // console.log();
        if (compareThailand?.value !== 0) {
          this.pointedLocation =
            this.language === "th"
              ? compareThailand?.name_th + " " + compareThailand?.value + " คน"
              : compareThailand?.name_en +
                " " +
                compareThailand?.value +
                " people";
        } else {
          this.pointedLocation =
            this.language === "th"
              ? compareThailand.name_th
              : compareThailand?.name_en;
        }
      }
    },
    unpointLocation() {
      this.pointedLocation = null;
      this.tooltipStyle = { display: "none" };
    },
    getLocationClass(location) {
      if (this.$store.state.dashboard.mapPlayerState) {
        const compareThailand = this.$store.state.dashboard.mapPlayerState.find(
          (elm) => {
            return elm.text === location.id;
          }
        );
        if (compareThailand !== undefined) {
          if (compareThailand.value === 0) {
            return `svg-map__location svg-map__location--heat0`;
          } else if (compareThailand.value > 0 && compareThailand.value <= 10) {
            return `svg-map__location svg-map__location--heat1`;
          } else if (
            compareThailand.value > 10 &&
            compareThailand.value <= 15
          ) {
            return `svg-map__location svg-map__location--heat2`;
          } else if (
            compareThailand.value > 15 &&
            compareThailand.value <= 20
          ) {
            return `svg-map__location svg-map__location--heat3`;
          } else if (compareThailand.value > 20) {
            return `svg-map__location svg-map__location--heat4`;
          }
        } else {
          return `svg-map__location svg-map__location--heat0`;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nameLocation {
  position: fixed;
  width: 200px;
  padding: 10px;
  border: 1px solid darkgrey;
  background-color: white;
  z-index: 2;
  text-align: center;
}
@media (min-width: 1420px) {
  .mapColor {
    transform: translate3d(205px, -50px, 0);
  }
}

@media (min-width: 1350px) and (max-width: 1419px) {
  .mapColor {
    transform: translate3d(170px, -25px, 0);
  }
}
@media (min-width: 1300px) and (max-width: 1349px) {
  .mapColor {
    transform: translate3d(150px, -25px, 0);
  }
}
@media (min-width: 960px) and (max-width: 1299px) {
  .mapColor {
    transform: translate3d(55px, 25px, 0);
  }
}
@media (min-width: 650px) and (max-width: 959px) {
  .mapColor {
    transform: translate3d(305px, -150px, 0);
  }
}
@media (min-width: 500px) and (max-width: 649px) {
  .mapColor {
    transform: translate3d(105px, 0px, 0);
  }
}
.colored {
  display: inline-block;
  width: 50px;
  height: 15px;
}
.level1 {
  background-color: #f4f4f4;
}
.level2 {
  background-color: #daeffc;
}
.level3 {
  background-color: #8ccdf7;
}
.level4 {
  background-color: #4e9fce;
}
.level5 {
  background-color: #00557c;
}
</style>
