<template>
  <div class="row justify-content-center">
    <div class="col-md-12 col-12 col-xl-10">
      <div>
        <div class="card-body">
          <div>
            <h4 class="card-title text-primary">
              {{ $t("player.honors.nation_honor") }}
            </h4>
          </div>
          <div v-if="isEditable" class="row">
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                :search-input.sync="nationSearchCompete"
                v-model="nationForm.compete"
                :items="nationCompeteList"
                item-value="text.th"
                item-text="text.th"
                :placeholder="$t('player.honors.competition_select_th')"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                :search-input.sync="nationSearchCompeteEN"
                v-model="nationForm.compete"
                :items="nationCompeteList"
                item-value="text.en"
                item-text="text.en"
                :placeholder="$t('player.honors.competition_select_en')"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                v-model="nationForm.team"
                :search-input.sync="nationSearchTeam"
                :items="nationTeamList"
                item-value="text.th"
                item-text="text.th"
                :placeholder="$t('player.honors.team_select_th')"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                v-model="nationForm.team"
                :search-input.sync="nationSearchTeamEN"
                :items="nationTeamList"
                item-value="text.en"
                item-text="text.en"
                :placeholder="$t('player.honors.team_select_en')"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                v-model="nationForm.award"
                :search-input.sync="nationSearchAward"
                :items="nationAwardList"
                :placeholder="$t('player.honors.award_name')"
                :item-text="language === 'th' ? `text.th` : `text.en`"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-autocomplete
                hide-details
                dense
                outlined
                v-model="nationForm.year"
                :items="yearsList"
                :placeholder="$t('player.honors.year_honor')"
              ></v-autocomplete>
            </div>
            <div class="col-2 d-flex align-items-center">
              <v-btn
                @click="
                  () => onSubmit('nation', 'nationForm', 'getNationHonor')
                "
                color="primary"
                class="mr-2"
                ><v-icon> fa-plus </v-icon></v-btn
              >
              <v-btn @click="() => onClear('nationForm')" color="red" dark
                ><v-icon> fa-trash </v-icon></v-btn
              >
            </div>
          </div>
          <v-data-table
            :items="nationHonor"
            hide-default-footer
            :headers="headderCountry[language]"
            :items-per-page="nationPerPage"
          >
            <template v-slot:item="row">
              <tr>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.setting_sub_competition_name_th || "-"
                  }}</label>
                  <label v-else>{{
                    row.item.setting_sub_competition_name_en || "-"
                  }}</label>
                </td>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.setting_team_name_th || "-"
                  }}</label>
                  <label v-else>{{
                    row.item.setting_team_name_en || "-"
                  }}</label>
                </td>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.honor_name || "-"
                  }}</label>
                  <label v-else>{{ row.item.honor_name_en || "-" }}</label>
                </td>
                <td>{{ row.item.honor_year || "-" }}</td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list dark>
                      <v-list-item
                        @click="
                          () =>
                            onDelete('nation', row.item.id, 'getNationHonor')
                        "
                        v-if="isEditable"
                        class="cursor-pointer"
                      >
                        {{ $t("normal.delete_list") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
            <template v-slot:footer="{}">
              <TableFooter
                :perPage.sync="nationPerPage"
                :currentPage.sync="nationPage"
                :lastPage="nationLastPage"
                :total="nationTotal"
              />
            </template>
          </v-data-table>
        </div>

        <div class="card-body">
          <div>
            <h4 class="card-title text-primary">
              {{ $t("player.honors.team_honor") }}
            </h4>
          </div>
          <div v-if="isEditable" class="row">
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                v-model="clubForm.compete"
                :search-input.sync="clubSearchCompete"
                :items="clubCompeteList"
                item-value="text.th"
                item-text="text.th"
                :placeholder="$t('player.honors.competition_select_th')"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                v-model="clubForm.compete"
                :search-input.sync="clubSearchCompeteEN"
                :items="clubCompeteList"
                item-value="text.en"
                item-text="text.en"
                :placeholder="$t('player.honors.competition_select_en')"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                v-model="clubForm.team"
                :search-input.sync="clubSearchTeam"
                :items="clubTeamList"
                :placeholder="$t('player.honors.team_select_th')"
                item-value="text.th"
                item-text="text.th"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                v-model="clubForm.team"
                :search-input.sync="clubSearchTeamEN"
                :items="clubTeamList"
                item-value="text.en"
                item-text="text.en"
                :placeholder="$t('player.honors.team_select_en')"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                v-model="clubForm.award"
                :search-input.sync="clubSearchAward"
                :items="clubAwardList"
                :placeholder="$t('player.honors.award_name')"
                :item-text="language === 'th' ? `text.th` : `text.en`"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-autocomplete
                hide-details
                dense
                outlined
                v-model="clubForm.year"
                :items="yearsList"
                :placeholder="$t('player.honors.year_honor')"
              ></v-autocomplete>
            </div>
            <div class="col-2 d-flex align-items-center">
              <v-btn
                @click="() => onSubmit('club', 'clubForm', 'getClubHonor')"
                color="primary"
                class="mr-2"
                ><v-icon> fa-plus </v-icon></v-btn
              >
              <v-btn @click="() => onClear('clubForm')" color="red" dark
                ><v-icon> fa-trash </v-icon></v-btn
              >
            </div>
          </div>
          <v-data-table
            hide-default-footer
            :items="clubHonor"
            :headers="headderCountry[language]"
            :items-per-page="clubPerPage"
          >
            <template v-slot:item="row">
              <tr>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.setting_sub_competition_name_th
                  }}</label>
                  <label v-else>{{
                    row.item.setting_sub_competition_name_en
                  }}</label>
                </td>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.setting_team_name_th
                  }}</label>
                  <label v-else>{{ row.item.setting_team_name_en }}</label>
                </td>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.honor_name
                  }}</label>
                  <label v-else>{{ row.item.honor_name_en }}</label>
                </td>
                <td>{{ row.item.honor_year }}</td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list dark>
                      <v-list-item
                        @click="
                          () => onDelete('club', row.item.id, 'getClubHonor')
                        "
                        v-if="isEditable"
                        class="cursor-pointer"
                      >
                        {{ $t("normal.delete_list") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
            <template v-slot:footer="{}">
              <TableFooter
                :perPage.sync="clubPerPage"
                :currentPage.sync="clubPage"
                :lastPage="clubLastPage"
                :total="clubTotal"
              />
            </template>
          </v-data-table>
        </div>

        <div class="card-body">
          <div>
            <h4 class="card-title text-primary">
              {{ $t("player.honors.personal_honor") }}
            </h4>
          </div>
          <div v-if="isEditable" class="row">
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                v-model="personalForm.compete"
                :search-input.sync="personalSearchCompete"
                :items="personalCompeteList"
                item-value="text.th"
                item-text="text.th"
                :placeholder="$t('player.honors.competition_select_th')"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                v-model="personalForm.compete"
                :search-input.sync="personalSearchCompeteEN"
                :items="personalCompeteList"
                item-value="text.en"
                item-text="text.en"
                :placeholder="$t('player.honors.competition_select_en')"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                v-model="personalForm.team"
                :items="personalTeamList"
                item-value="text.th"
                item-text="text.th"
                :placeholder="$t('player.honors.team_select_th')"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                v-model="personalForm.team"
                :items="personalTeamList"
                item-value="text.en"
                item-text="text.en"
                :placeholder="$t('player.honors.team_select_en')"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-combobox
                hide-details
                dense
                outlined
                v-model="personalForm.award"
                :search-input.sync="personalSearchAward"
                :items="personalAwardList"
                :item-text="language === 'th' ? `text.th` : `text.en`"
                :placeholder="$t('player.honors.award_name')"
              ></v-combobox>
            </div>
            <div class="col-3">
              <v-autocomplete
                hide-details
                dense
                outlined
                v-model="personalForm.year"
                :search-input.sync="personalSearchTeam"
                :items="yearsList"
                :placeholder="$t('player.honors.year_honor')"
              ></v-autocomplete>
            </div>
            <div class="col-2 d-flex align-items-center">
              <v-btn
                @click="
                  () => onSubmit('personal', 'personalForm', 'getPersonalHonor')
                "
                color="primary"
                class="mr-2"
                ><v-icon> fa-plus </v-icon></v-btn
              >
              <v-btn @click="() => onClear('personalForm')" color="red" dark
                ><v-icon> fa-trash </v-icon></v-btn
              >
            </div>
          </div>
          <v-data-table
            hide-default-footer
            :items="personalHonor"
            :headers="headderCountry[language]"
            :items-per-page="personalPerPage"
          >
            <template v-slot:item="row">
              <tr>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.setting_sub_competition_name_th
                  }}</label>
                  <label v-else>{{
                    row.item.setting_sub_competition_name_en
                  }}</label>
                </td>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.setting_team_name_th
                  }}</label>
                  <label v-else>{{ row.item.setting_team_name_en }}</label>
                </td>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.honor_name
                  }}</label>
                  <label v-else>{{ row.item.honor_name_en }}</label>
                </td>
                <td>{{ row.item.honor_year }}</td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list dark>
                      <v-list-item
                        @click="
                          () =>
                            onDelete(
                              'personal',
                              row.item.id,
                              'getPersonalHonor'
                            )
                        "
                        v-if="isEditable"
                        class="cursor-pointer"
                      >
                        {{ $t("normal.delete_list") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
            <template v-slot:footer="{}">
              <TableFooter
                :perPage.sync="personalPerPage"
                :currentPage.sync="personalPage"
                :lastPage="personalLastPage"
                :total="personalTotal"
              />
            </template>
          </v-data-table>
        </div>
      </div>
      <v-btn
        elevation="2"
        class="btn-primary float-right mb-5"
        v-on:click="nextTab()"
      >
        {{ $t("normal.next") }} <v-icon right> fa-arrow-right </v-icon>
      </v-btn>
      <v-btn
        elevation="2"
        class="btn-primary float-left mb-5"
        v-on:click="backTab()"
      >
        <v-icon left> fa-arrow-left </v-icon> {{ $t("normal.previous") }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { appAxios } from "../../helpers/app-axios";
import TableFooter from "../TableFooter.vue";
import { languageService } from "../../helpers/language";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  components: {
    TableFooter,
  },
  name: "player-tabs3",
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  methods: {
    test: function () {
      alert("test");
    },
    nextTab() {
      //console.log(this.tabs);
      this.$router.push({ path: "", query: { tab: 3 } });
    },
    backTab() {
      this.$router.push({ path: "", query: { tab: 1 } });
      //console.log(this.tabs);
    },
    getCompete(name, ...list) {
      appAxios
        .get(`competitions/find-all/sub-competition`, { params: { name } })
        .then((res) => {
          const data = res.data.data.sub_competition;
          if (!data) return;
          const options = data.map((ele) => ({
            text: {
              th: ele.setting_sub_competition_name_th,
              en: ele.setting_sub_competition_name_en,
            },
            // text: ele.setting_sub_competition_name_th,
            value: {
              th: ele.setting_sub_competition_name_th,
              en: ele.setting_sub_competition_name_en,
            },
          }));
          list.forEach((ele) => {
            this[ele] = options;
          });
          // this[ele] = data.data;
        });
    },

    getTeamPersonal(name, ...list) {
      const param = {
        name: "",
        team_national: false,
        team_club: false,
      };
      appAxios.get("team/choose", { params: param }).then((res) => {
        const data = res.data.data.team;

        if (!data) return;
        const options = data.map((ele) => ({
          text: {
            th: ele.setting_team_informations?.setting_team_information_name_th,
            en: ele.setting_team_informations?.setting_team_information_name_en,
          },
          value: {
            th: ele.setting_team_informations?.setting_team_information_name_th,
            en: ele.setting_team_informations?.setting_team_information_name_en,
          },
        }));
        list.forEach((ele) => {
          this[ele] = options;
        });
      });
    },
    getTeamNation(name, ...list) {
      const param = {
        name: "",
        team_national: true,
        team_club: false,
      };
      appAxios.get("team/choose", { params: param }).then((res) => {
        const data = res.data.data.team;

        if (!data) return;
        const options = data.map((ele) => ({
          text: {
            th: ele.setting_team_informations?.setting_team_information_name_th,
            en: ele.setting_team_informations?.setting_team_information_name_en,
          },
          value: {
            th: ele.setting_team_informations?.setting_team_information_name_th,
            en: ele.setting_team_informations?.setting_team_information_name_en,
          },
        }));
        list.forEach((ele) => {
          this[ele] = options;
        });
      });
    },
    getTeamClub(name, ...list) {
      const param = {
        name: "",
        team_national: false,
        team_club: true,
      };
      appAxios.get("team/choose", { params: param }).then((res) => {
        const data = res.data.data.team;

        if (!data) return;
        const options = data.map((ele) => ({
          text: {
            th: ele.setting_team_informations?.setting_team_information_name_th,
            en: ele.setting_team_informations?.setting_team_information_name_en,
          },
          value: {
            th: ele.setting_team_informations?.setting_team_information_name_th,
            en: ele.setting_team_informations?.setting_team_information_name_en,
          },
        }));
        list.forEach((ele) => {
          this[ele] = options;
        });
      });
    },
    getAward(name, ...list) {
      appAxios.get("setting/global/award", { params: { name } }).then((res) => {
        const data = res.data.data;
        if (!data) return;
        const options = data.data.map((ele) => ({
          // text: ele.global_config_award_name_th,
          // value: ele.global_config_award_name_th,
          text: {
            th: ele.global_config_award_name_th,
            en: ele.global_config_award_name_en,
          },
          value: {
            th: ele.global_config_award_name_th,
            en: ele.global_config_award_name_en,
          },
        }));
        list.forEach((ele) => {
          this[ele] = options;
        });
      });
    },
    onSubmit(type, form, getFn) {
      // console.log(this[form].compete, `${type}SearchCompete`, this[`${type}SearchCompete`], this[`${type}SearchTeam`], type, getFn);
      const body = {
        setting_sub_competition_name_en: this[form].compete?.value?.en || "",
        setting_sub_competition_name_th:
          this[form].compete?.value?.th || this[`${type}SearchCompete`],
        setting_team_name_en:
          this[form].team?.value?.en || this[`${type}SearchTeam`],
        setting_team_name_th:
          this[form].team?.value?.th || this[`${type}SearchTeam`],
        honor_name_en:
          this[form].award?.value?.en || this[`${type}SearchAward`],
        honor_name: this[form].award?.value?.th || this[`${type}SearchAward`],

        honor_year: this[form].year,
      };
      appAxios
        .post(`account/${this.accountId}/honor/${type}`, body)
        .then(() => {
          this[form] = {};
          this[getFn]();
        });
    },
    async  onDelete(type, id, getFn) {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
      appAxios
        .post(`account/${this.accountId}/honor/${type}/${id}/delete`)
        .then(() => {
          this[getFn]();
        });}
        }
      );
    },
    onClear(form) {
      this[form] = {};
    },
    getNationHonor() {
      appAxios
        .get(`account/${this.accountId}/honor/nation`, {
          params: this.nationParams,
        })
        .then((res) => {
          const data = res.data.data;
          if (!data) return;
          this.nationTotal = data.total;
          this.$data.nationHonor = data.data;
          this.nationLastPage = data.lastPage;
        });
    },
    getClubHonor() {
      appAxios
        .get(`account/${this.accountId}/honor/club`, {
          params: this.clubParams,
        })
        .then((res) => {
          const data = res.data.data;
          if (!data) return;
          this.clubTotal = data.total;
          this.clubHonor = data.data;
          this.clubLastPage = data.lastPage;
        });
    },
    getPersonalHonor() {
      appAxios
        .get(`account/${this.accountId}/honor/personal`, {
          params: this.personalParams,
        })
        .then((res) => {
          const data = res.data.data;
          if (!data) return;
          this.personalTotal = data.total;
          this.personalHonor = data.data;
          this.personalLastPage = data.lastPage;
        });
    },
  },
  computed: {
    params() {
      const params = {
        size: this.pageSize,
        page: this.currentPage,
      };
      return params;
    },
    fitColor: function () {
      if (this.fit.value < 50) {
        return "red";
      } else {
        return "teal";
      }
    },
    yearsList() {
      const currentYear = new Date().getFullYear();
      const yearAmount = currentYear - 1950;
      return Array.from(Array(yearAmount).keys())
        .map((ele) => currentYear - ele)
        .sort((a, b) => b - a);
    },
    accountId() {
      return Number(this.$route.params?.id);
    },
    isEditable() {
      if (this.isPlayerRoute) {
        return this.$store.state.permission?.permissions?.athlete_edit;
      }
      return this.$store.state.permission?.permissions?.staff_edit;
    },
    nationParams() {
      return {
        page: this.nationPage,
        perPage: this.nationPerPage,
      };
    },
    clubParams() {
      return {
        page: this.clubPage,
        perPage: this.clubPerPage,
      };
    },
    personalParams() {
      return {
        page: this.personalPage,
        perPage: this.personalPerPage,
      };
    },
    requiredRules() {
      return [(v) => !!v || "required"];
    },
  },
  data() {
    return {
      headderCountry: {
        th: [
          {
            text: "ชื่อรายการแข่งขัน",
            value: "name",
            sortable: false,
            width: "30%",
          },
          {
            text: "ทีมชาติ/สโมสร",
            value: "team",
            sortable: false,
            width: "30%",
          },
          { text: "รางวัล", value: "medal", sortable: false, width: "30%" },
          {
            text: "ปีที่ได้รับรางวัล",
            value: "year",
            sortable: false,
            width: "30%",
          },
          { text: "จัดการ", value: "edit", sortable: false, width: "30%" },
        ],
        en: [
          {
            text: "Name of Competition",
            value: "name",
            sortable: false,
            width: "30%",
          },
          {
            text: "National Team/ Club",
            value: "team",
            sortable: false,
            width: "30%",
          },
          { text: "Award", value: "medal", sortable: false, width: "30%" },
          { text: "Year", value: "year", sortable: false, width: "30%" },
          { text: "Action", value: "edit", sortable: false, width: "30%" },
        ],
      },
      nationForm: {},
      nationCompeteList: [],
      nationCompeteListEN: [],
      nationTeamList: [],
      nationSearchCompete: null,
      nationSearchCompeteEN: null,
      nationSearchTeam: null,
      nationSearchTeamEN: null,
      nationAwardList: [],
      nationSearchAward: null,
      nationHonor: [],
      nationTotal: 0,
      nationPage: 1,
      nationPerPage: 10,
      nationLastPage: 1,
      clubForm: {},
      clubCompeteList: [],
      clubTeamList: [],
      clubSearchCompete: null,
      clubSearchCompeteEN: null,
      clubSearchTeam: null,
      clubSearchTeamEN: null,
      clubAwardList: [],
      clubSearchAward: null,
      clubHonor: [],
      clubTotal: 0,
      clubPage: 1,
      clubPerPage: 10,
      clubLastPage: 1,
      personalSearchCompete: null,
      personalSearchCompeteEN: null,
      personalForm: {},
      personalCompeteList: [],
      personalTeamList: [],
      personalSearchTeam: null,
      personalAwardList: [],
      personalSearchAward: null,
      personalHonor: [],
      personalTotal: 0,
      personalPage: 1,
      personalPerPage: 10,
      personalLastPage: 1,
    };
  },
  watch: {
    nationPerPage() {
      this.getNationHonor();
    },
    nationPage() {
      this.getNationHonor();
    },
    clubPage() {
      this.getClubHonor();
    },
    clubPerPage() {
      this.getClubHonor();
    },
    personalPage() {
      this.getPersonalHonor();
    },
    personalPerPage() {
      this.getPersonalHonor();
    },
    nationSearchCompete(newVal) {
      console.log("compete", newVal, this.nationCompeteList);
    },
    nationSearchTeam(newVal) {
      this.getTeamNation(newVal, "nationTeamList");
    },
    nationSearchAward(newVal) {
      this.getAward(newVal, "nationAwardList");
    },
    clubSearchTeam(newVal) {
      this.getTeamClub(newVal, "clubTeamList");
    },
    clubSearchAward(newVal) {
      this.getAward(newVal, "clubAwardList");
    },
    personalSearchTeam(newVal) {
      this.getTeamPersonal(newVal, "personalTeamList");
    },
    personalSearchAward(newVal) {
      this.getAward(newVal, "personalAwardList");
    },
  },
  mounted() {
    this.getNationHonor();
    this.getClubHonor();
    this.getPersonalHonor();
    this.getCompete(
      null,
      "nationCompeteList",
      "clubCompeteList",
      "personalCompeteList"
    );
    this.getTeamNation(null, "nationTeamList");
    this.getTeamClub(null, "clubTeamList");
    this.getTeamPersonal(null, "personalTeamList");

    this.getAward(
      null,
      "nationAwardList",
      "clubAwardList",
      "personalAwardList"
    );
  },
};
</script>
