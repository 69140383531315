import { appAxios } from '../helpers/app-axios';

//const modelNameSingular = 'player'
const modelNamePlural = 'players'
const modelNamePluralData = 'playerdata'

const getData = `get${modelNamePlural.charAt(0).toUpperCase() + modelNamePlural.slice(1)}`

const updatePlayerData = `update${modelNamePluralData.charAt(0).toUpperCase() + modelNamePluralData.slice(1)}`

const getSearch = `getSearch${modelNamePlural.charAt(0).toUpperCase() + modelNamePlural.slice(1)}`

const getPlayerData = `get${modelNamePluralData.charAt(0).toUpperCase() + modelNamePluralData.slice(1)}`

const resetPlayerData = `reset${modelNamePluralData.charAt(0).toUpperCase() + modelNamePluralData.slice(1)}`

const insertPlayerData = `insert${modelNamePluralData.charAt(0).toUpperCase() + modelNamePluralData.slice(1)}`

//const UpdateContactPlayerData = `updateContact${modelNamePluralData.charAt(0).toUpperCase() + modelNamePluralData.slice(1)}`


//const getDataModel = `getSearch${modelNamePlural.charAt(0).toUpperCase() + modelNamePlural.slice(1)}`

const DefaultPlayerData = () => {
  return {
    account_id: null,
    account_informations: {
      id: null,
      account_information_code_personnel: "",
      account_information_citizen_id: "",
      account_information_passport_id: "",
      account_information_type_personnel: "THAI",
      account_information_firstname_th: "",
      account_information_lastname_th: "",
      account_information_firstname_en: "",
      account_information_lastname_en: "",
      account_information_nickname: "",
      account_information_blood_type: null,
      account_information_dob: "2010-01-01",
      account_information_religion: "",
      account_information_race: "",
      account_information_nationality: "",
      account_information_phone: null,
      account_information_gender: null,
      account_information_height: null,
      account_information_weight: null,
      account_information_biography_th: null,
      account_information_biography_en: null,
      account_information_hand_skill: null,
      account_information_feet_skill: null,
      deleted: false
    },
    account_region_citizen: {
      id: null,
      account_region_country_id: "",
      account_region_address_detail: "",
      account_region_province_id: "",
      account_region_district_id: "",
      account_region_subdistrict_id: "",
      account_region_zipcode: "",
      deleted: false
    },
    account_region_current: {
      id: null,
      account_region_country_id: "",
      account_region_address_detail: "",
      account_region_province_id: "",
      account_region_district_id: "",
      account_region_subdistrict_id: "",
      account_region_zipcode: "",
      deleted: false
    },
    social_contacts: {
      account_social_contact_facebook: "string",
      account_social_contact_line: "string",
      account_social_contact_twitter: "string",
      account_social_contact_instagram: "string",
      account_social_contact_youtube: "string",
      account_social_contact_website: "string",
      account_social_contact_fax: "string"
    },
    account_specifications: null,
    account_email: "",
    account_email_verified: true,
    account_email_verified_token: null,
    account_email_verified_expires: null,
    account_password_verified_token: null,
    account_type: null,
    account_subscription_login_at: null,
    account_subscription_logout_at: null,
    account_published: false,
    deleted: false,
    educations: [],
    relations: []
  }
}


export default {
  state: {
    [modelNamePlural]: [],
    [modelNamePluralData]: {
    }
  },
  mutations: {
    [getData](state, data) {
      state[modelNamePlural] = data
    },
    [getSearch](state, data) {
      state[modelNamePlural] = data
    },
    [updatePlayerData](state, data) {
      state[modelNamePluralData][data.key][data.value.key] = data.value.value
    },
    [getPlayerData](state, data) {
      state[modelNamePluralData] = data
    },
    [resetPlayerData](state) {
      state[modelNamePluralData] = DefaultPlayerData()
    },
    [insertPlayerData]() {

    }
  },
  actions: {
    async [getData]({ commit }, payload) {
      console.log('payload', payload.params);
      if ((payload?.params.gender === 0 ||
        payload?.params.gender ||
        payload?.params.national ||
        payload?.params.team_club ||
        payload?.params.age_min ||
        payload?.params.age_max ||
        payload?.params.city_name) && payload.searching) {
        payload.params['page'] = 1
      }
      const params = new URLSearchParams(payload?.params);
      await appAxios.get('account/personels/players', { params }).then(response => {
        commit(getData, response.data)
      })
    },
    async [getSearch]({ commit }, payload) {
      await appAxios.get('account/personels/players', payload).then(response => {
        commit(getSearch, response.data)
      })
    },
    async [updatePlayerData]({ commit }, payload) {
      commit(updatePlayerData, payload)
    },
    async [getPlayerData]({ commit }, payload) {
      await appAxios.get('account/' + payload).then(response => {
        commit(getPlayerData, response.data.data.account)
      })
    },
    [resetPlayerData]({ commit }) {
      commit(resetPlayerData)
    },
    async [insertPlayerData]({ commit }, payload) {
      await appAxios.post('account/personnels', payload.account_informations).then(response => {
        commit(insertPlayerData, response.data)
      }).catch(() => {
        // console.log(error.response)
      })
    }
  }
}