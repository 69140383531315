<template>
<v-app id="player">
 <div class="app-content content ">
		<div class="content-overlay"></div>
			<PlayerTabs/>
  </div>
</v-app>
</template>

<script>
import PlayerTabs from '../../components/Player/PlayerTabs';

export default {
  name: 'AddPlayer',
  components:{
    PlayerTabs
  },
  mounted() {
      
  }
}
</script>

<style >

</style>
