<template>
  <div :style="styleColor">
    <div class="label-value">
      <div class="value">
        {{value}}%
      </div>
      <div class="label">
        {{label}}
      </div>
    </div>
    <div class="bar" >
      <div class="bar-color" :style="barColorStyle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhysicalProgressBar',
  props: ['label', 'value'],
  computed: {
    styleColor() {
      return { color: this.mapColor[this.value] }
    },
    barColorStyle() {
      return { background: this.mapColor[this.value], width: this.value+'%' }
    },
  },
  data () {
    return {
      mapColor: {
        10: '#FC5A5A',
        20: '#FC5A5A',
        30: '#FC5A5A',
        40: '#FF974A',
        50: '#FF974A',
        60: '#FF974A',
        70: '#E5CF00',
        80: '#E5CF00',
        90: '#3DD598',
        100: '#3DD598',
      }
    }
  }
}
</script>

<style scoped>
.label-value {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  margin-bottom: 5px;
}
.label {
  font-weight: bold;
}
.bar {
  height: 6px;
  width: 100%;
  background: #F3F6F8;
  position: relative;
}
.bar-color {
  height: 6px;
  position: absolute;
}
</style>