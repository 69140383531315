export const SAVE_PERMISSION = 'SAVE_PERMISSION';
export const SAVE_ROLE = 'SAVE_ROLE';

export default {
  state: {
    permissions: null,
    role: null
  },
  mutations: {
    setPermission(state, permission) {
      state.permissions = permission;
    },
    setRole(state, role) {
      state.role = role;
    }
  },
  actions: {
    async [SAVE_PERMISSION]({commit}, permission) {
      commit('setPermission', permission);
    },
    async [SAVE_ROLE]({commit}, role) {
      commit('setRole', role)
    }
  }
}

//"team": false,
// "staff": false,
// "report": false,
// "athlete": false,
// "setting": false,
// "stadium": false,
// "team_edit": false,
// "staff_edit": false,
// "competition": false,
// "athlete_edit": false,
// "setting_edit": false,
// "stadium_edit": false,
// "competition_edit": false,
// "application_management": false,
// "application_management_edit": false
