<template>
  <v-card class="pa-8 mt-8">
    <v-row>
      <v-col class="d-flex child-flex">
        <span class="text-primary">{{ $t("image.upload_image") }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex child-flex">
        <upload-media accept="image/*" type="image">
          <template v-slot:title>
            {{ $t("image.drag_image") }}
          </template>
          <template v-slot:condition>
            ({{ $t("image.sub_drag_image") }})
          </template>
        </upload-media>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex child-flex">
        <span class="text-primary">{{ $t("image.manage_image") }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(item, idx) in images" :key="idx" class="d-flex" cols="4">
        <ImageMenuWrapper @onClick="(e) => onContextMenu(e, idx, 'images')">
          <v-img
            :src="item.setting_sub_competition_media_url"
            class="grey lighten-2 rounded-lg child-flex"
            :aspect-ratio="3 / 2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </ImageMenuWrapper>
        <v-menu
          v-model="item.isShowMenu"
          :position-x="item.x"
          :position-y="item.y"
          absolute
          offset-y
          style="max-width: 600px"
        >
          <!-- <template v-slot:activator="{ on, attrs }">
                <v-icon class="pa-2" small v-bind="attrs" v-on="on"
                  >fa-ellipsis-v</v-icon
                >
              </template> -->
          <v-list>
            <v-list-item class="cursor-pointer">
              <v-list-item-title
                @click="
                  () =>
                    download(
                      item.setting_sub_competition_media_url,
                      item.setting_sub_competition_media_name
                    )
                "
                >{{ $t("image.download_image") }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item class="cursor-pointer">
              <v-list-item-title
                @click="
                  () =>
                    deleteMedia({
                      cid: competitionId,
                      sid: subCompetitionId,
                      mediaId: item.id,
                    })
                "
                >{{ $t("normal.delete_list") }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TableFooter
          :perPage.sync="image.perPage"
          :currentPage.sync="image.currentPage"
          :lastPage="image.lastPage"
          :total="image.total"
          :pageSizes="pageSizes"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex child-flex">
        <span class="text-primary">{{ $t("image.upload_video") }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex child-flex">
        <upload-media accept="video/mp4,video/x-m4v,video/*" type="video">
          <template v-slot:title>
            {{ $t("image.drag_video") }}
          </template>
          <template v-slot:condition>
            ({{ $t("image.sub_drag_video") }})
          </template>
        </upload-media>
      </v-col>
    </v-row>
    <div v-if="isEditable" class="card-body">
      <div>
        <h4 class="card-title text-primary">
          {{ $t("image.add_link_video") }}
        </h4>
      </div>
      <div class="row">
        <div class="col-md-10 col-sm-8 col-12">
          <v-text-field
            prepend-inner-icon="fa-link"
            hide-details
            dense
            outlined
            v-model="videoLink"
          ></v-text-field>
          <small
            >{{
              $t("image.example_youtube")
            }}
            http://www.youtube.com/chanatip/Ym3HHa798y7</small
          >
        </div>
        <div class="col-4 col-md-2">
          <v-btn
            :disabled="!isEditable"
            elevation="2"
            class="w-100 btn-primary"
            @click="submitLink"
            >{{ $t("image.upload_video") }}</v-btn
          >
        </div>
      </div>
    </div>
    <v-row>
      <v-col class="d-flex child-flex">
        <span class="text-primary">{{ $t("image.manage_video") }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(item, idx) in videos"
        :key="idx"
        class="d-flex child-flex"
        cols="4"
      >
        <ImageMenuWrapper
          :hideIcon="!isEditable"
          @onClick="(e) => onContextMenu(e, idx, 'videos')"
        >
          <div class="embed-responsive embed-responsive-4by3">
            <iframe
              v-if="item.setting_sub_competition_media_url === null"
              class="embed-responsive-item"
              :src="item.setting_sub_competition_media_url"
            ></iframe>
            <template v-else>
              <video class="embed-responsive-item" controls>
                <source :src="item.setting_sub_competition_media_url" />
              </video>
            </template>
            <v-menu
              v-model="item.isShowMenu"
              :position-x="item.x"
              :position-y="item.y"
              absolute
              offset-y
              style="max-width: 600px"
            >
              <v-list>
                <v-list-item class="cursor-pointer">
                  <v-list-item-title
                    @click="() => deleteMedia({
                      cid: competitionId,
                      sid: subCompetitionId,
                      mediaId: item.id,
                    })"
                    >{{ $t("normal.delete_list") }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </ImageMenuWrapper>
      </v-col>
    </v-row>
    <!-- <v-row>
			<v-col class="d-flex child-flex">
				<span class='text-primary'>จัดการรูปภาพ</span>
			</v-col>
		</v-row>
		<v-row>
			<v-col
				v-for="item in video.data"
				:key="item.id"
				class="d-flex child-flex"
				cols="4"
			>
				<v-img
					:src="item.setting_sub_competition_media_url"
					class="grey lighten-2 rounded-lg"
					:aspect-ratio="3/2"
				>
					<template v-slot:placeholder>
						<v-row
							class="fill-height ma-0"
							align="center"
							justify="center"
						>
							<v-progress-circular
								indeterminate
								color="grey lighten-5"
							></v-progress-circular>
						</v-row>
					</template>
				</v-img>
			</v-col>
		</v-row> -->
    <v-row>
      <v-col>
        <TableFooter
          :perPage.sync="video.perPage"
          :currentPage.sync="video.currentPage"
          :lastPage="video.lastPage"
          :total="video.total"
          :pageSizes="pageSizes"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import TableFooter from "@/components/TableFooter.vue";
import { mapActions, mapState } from "vuex";
import UploadMedia from "../../UploadMedia.vue";
import ImageMenuWrapper from "@/components/ImageMenuWrapper.vue";
import { replaceYoutubeLink } from "@/helpers/replaceYoutube";

export default {
  components: {
    TableFooter,
    UploadMedia,
    ImageMenuWrapper,
  },

  data() {
    return {
      showMenu: false,
      images: [],
      videos: [],
      competitionId: null,
      subCompetitionId: null,
      dragover: false,
      uploadedFiles: [],
      videoLink: null,
      pageSizes: [9, 18, 45, 90],
    };
  },

  mounted() {
    this.competitionId = this.$route.params.cid;
    this.subCompetitionId = this.$route.params.sid;
    this.getGallery({ cid: this.competitionId, sid: this.subCompetitionId });
  },

  computed: {
    ...mapState("gallery", ["params", "image", "video"]),
    ...mapState("sub_competition", ["success"]),

    isEditable() {
      return true;
      // if (this.$data.isPlayerRoute) {
      //   return this.$store.state.permission?.permissions?.athlete_edit;
      // }
      // return this.$store.state.permission?.permissions?.staff_edit;
    },
  },

  methods: {
    ...mapActions("gallery", ["getGallery"]),
    ...mapActions("sub_competition", ["uploadLink", "deleteMedia"]),

    closeDialog() {
      this.uploadedFiles = [];
      this.$emit("update:dialog", false);
    },
    onContextMenu(event, idx, type) {
      console.log("event", event.clientX, event.clientY, idx, type);
      event.preventDefault();
      this.$data[type][idx].isShowMenu = !this.$data[type][idx].isShowMenu;
      this.$data[type][idx].x = event.clientX;
      this.$data[type][idx].y = event.clientY;
    },
    removeFile(fileName) {
      const index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName
      );
      // If file is in uploaded files remove it
      if (index > -1) this.uploadedFiles.splice(index, 1);
    },

    submitLink() {
      if (this.videoLink) {
        this.uploadLink({
          cid: this.competitionId,
          sid: this.subCompetitionId,
          link: replaceYoutubeLink(this.videoLink),
        });
      }
    },
    async download(url, name) {
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      link.target = "_blank";
      link.click();
    },
  },

  watch: {
    success(val) {
      if (val) {
        this.getGallery({
          cid: this.competitionId,
          sid: this.subCompetitionId,
        });
      }
    },
    image(val) {
      this.$data.images = val.data.map((ele) => ({
        ...ele,
        isShowMenu: false,
        x: 0,
        y: 0,
      }));
    },
    video(val) {
      this.$data.videos = val.data.map((ele) => ({
        ...ele,
        isShowMenu: false,
        x: 0,
        y: 0,
      }));
    },
  },
};
</script>

<style scoped>
.drop-zone {
  border: 2px dashed #1161e0;
  border-radius: 10px;
  margin: 4px 12px;
}
</style>