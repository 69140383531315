import homeIcon from "./icons/home.vue";
import fileTextIcon from "./icons/file-text.vue";
import settingIcon from "./icons/settings.vue";
import userIcon from "./icons/user.vue";
import usersIcon from "./icons/users.vue";
import calendarCompetitionIcon from "./icons/calendar-competition.vue"
import trophyIcon from "./icons/trophy.vue";
import associationIcon from "./icons/association.vue";
import expandIcon from './icons/expandIcon.vue';
import condensed from './icons/condensed.vue'
import downloadIcon from './icons/download.vue'
import printIcon from './icons/print.vue'
import selectImageIcon from './icons/select-image.vue'
import importIcon from './icons/import.vue'
import alert from './icons/alert.vue'
import editHistoryTeam from './icons/editHistoryTeam'
import stadiumIcon from './icons/stadium.vue'
import teamIcon from './icons/team.vue'
import thaiIcon from './icons/thai.vue'
import englishIcon from './icons/english.vue'


export const IconsValues = {
  home: { component: homeIcon },
  fileText: { component: fileTextIcon },
  setting: { component: settingIcon },
  user: { component: userIcon },
  users: { component: usersIcon },
  calendarCompetition: { component: calendarCompetitionIcon },
  trophy: { component: trophyIcon },
  association: { component: associationIcon },
  expandIcon: { component: expandIcon },
  condenseIcon: { component: condensed },
  download: { component: downloadIcon },
  print: { component: printIcon },
  imageIcon: { component: selectImageIcon },
  importIcon: { component: importIcon },
  alertIcon: { component: alert },
  editHistoryIcon: { component: editHistoryTeam },
  stadiumIcon: { component: stadiumIcon },
  teamIcon: { component: teamIcon },
  thaiIcon: { component: thaiIcon },
  englishIcon: { component: englishIcon },
};
