import { appAxios } from "../helpers/app-axios";

// change here
const modelNameSingular = 'education'
const modelNamePlural = 'educations'

// non change
const getAllName = `get${modelNamePlural.charAt(0).toUpperCase() + modelNamePlural.slice(1)}`
// const getOneName = `get${modelNameSingular.charAt(0).toUpperCase() + modelNameSingular.slice(1)}`
const createName = `create${modelNameSingular.charAt(0).toUpperCase() + modelNameSingular.slice(1)}`
const updateName = `update${modelNameSingular.charAt(0).toUpperCase() + modelNameSingular.slice(1)}`
const deleteName = `delete${modelNameSingular.charAt(0).toUpperCase() + modelNameSingular.slice(1)}`

export default {
  state: {
    [modelNameSingular]: {},
    [modelNamePlural]: [],
  },
  mutations: {
    [getAllName](state, { res }) {
      state[modelNamePlural] = res
    },
    [createName](state, { res }) {
      state[modelNamePlural].data.push(res)
    },
    [updateName](state, { index, res }) {
      state[modelNamePlural].data[index] = res
      state[modelNamePlural].data = Object.assign([], state[modelNamePlural].data)
    },
    [deleteName]() {
    }
  },
  actions: {
    [getAllName]({ commit }, params) {
      const query = new URLSearchParams(params)
      return appAxios.get(`setting/global/education`, { params: query })
        .then((res) => {
          commit(getAllName, { res: res.data.data.global_config_educations })
        })
    },
    [createName]({ commit }, payload) {
      return appAxios.post(`setting/global/education`, payload)
        .then(() => {
          commit(createName, { res: payload })
        })
    },
    [updateName]({ commit }, { index, payload }) {
      return appAxios.post(`setting/global/education`, payload)
        .then((res) => {
          commit(updateName, { res: res.data.data.global_config_educations, index: index })
        })
    },
    [deleteName]({ commit }, id) {
      return appAxios.post(`setting/global/education/${id}/delete`)
        .then((res) => {
          commit(deleteName, { res: res.data.data })
        })
    }
  }
}