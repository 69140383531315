<template>
  <div class="row no-gutters p-md-3">
    <div class="col-md-4 col-xl-3 col-12">
      <div class="p-2">
        <div class="m-md-2">
          <img
            class="d-flex justify-content-center center p-1"
            src="@/assets/images/stadium.png"
            width="50%"
            height="50%"
            alt="Alternative Text"
          />

          <div class="mb-2">
            <h4 class="text-center">{{ stadiumName[language] }}</h4>
          </div>
        </div>

        <div class="border-top py-10 px-3" style="overflow-x: auto">
          <div class="text-center h4 my-3">
            {{ $t("contact_information.contact") }}
            <v-icon
              @click="onClickStadiumSocialContact"
              style="font-size: 1rem"
              class="ml-2 cursor-pointer"
              >fa-edit</v-icon
            >
          </div>
          <div class="row">
            <div class="col-4">{{ $t("contact_information.phone") }}</div>
            <div class="col-8">
              {{
                stadiumSocialContactForm.setting_stadium_social_contact_phone ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">E-mail</div>
            <div class="col-8">
              {{
                stadiumSocialContactForm.setting_stadium_social_contact_email ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Facebook</div>
            <div class="col-8">
              {{
                stadiumSocialContactForm.setting_stadium_social_contact_facebook ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">LINE id</div>
            <div class="col-8">
              {{
                stadiumSocialContactForm.setting_stadium_social_contact_line ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Instagram</div>
            <div class="col-8">
              {{
                stadiumSocialContactForm.setting_stadium_social_contact_instagram ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Youtube</div>
            <div class="col-8">
              {{
                stadiumSocialContactForm.setting_stadium_social_contact_youtube ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">twitter</div>
            <div class="col-8">
              {{
                stadiumSocialContactForm.setting_stadium_social_contact_twitter ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Website</div>
            <div class="col-8">
              {{
                stadiumSocialContactForm.setting_stadium_social_contact_website ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">Fax</div>
            <div class="col-8">
              {{
                stadiumSocialContactForm.setting_stadium_social_contact_fax ||
                "-"
              }}
            </div>
          </div>
        </div>
        <div class="border-bottom border-top py-3 ">
          <div class="text-center my-3 font-weight-bolder">
            {{ $t("public.status") }}
          </div>
          <div class="row">
            <div class="col-6">
              <checkbox
                :checked="stadiumForm.active"
                :disabled="!isEditable"
                v-model="stadiumForm.active"
              >
                <span
                  v-if="stadiumForm.active"
                  class="input-box"
                  slot="input-box"
                >
                  <v-icon>fa-check</v-icon>
                </span>
                <span v-if="stadiumForm.active">{{ $t("public.public") }}</span>
                <span v-else>{{ $t("public.no_public") }}</span>
              </checkbox>
            </div>
            <div class="col-6">
              <div>
                <label>
                  {{ $t("public.create_date") }}
                </label>
              </div>
              <div>
                <label class="text-primary">
                  {{ stadiumForm.createdAt }}
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div>
                <label>
                  {{ $t("public.create_by") }}
                </label>
              </div>
              <div>
                <label style="word-break: break-all">
                  {{ stadiumForm.updatedBy }}
                </label>
              </div>
            </div>
            <div class="col-6">
              <div>
                <label>
                  {{ $t("public.update_date") }}
                </label>
              </div>
              <div>
                <label class="text-primary">
                  {{ stadiumForm.updatedAt }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8 col-12 tab1-border-left">
      <!-- ข้อมูลสนามกีฬา -->
      <v-form ref="formRef">
        <div>
          <div class="border-bottom p-2">
            <div>
              <h4 class="text-primary">{{ $t("stadium.stadium") }}</h4>
            </div>
            <div>
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("stadium.stadium_th")
                    }}</label>
                    <v-text-field
                      :rules="ruleForm"
                      v-model="stadiumForm.setting_stadium_name_th"
                      :readonly="!isEditable"
                      dense
                      outlined
                      :placeholder="$t('stadium.stadium_th')"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("stadium.stadium_en")
                    }}</label>
                    <v-text-field
                      v-model="stadiumForm.setting_stadium_name_en"
                      :readonly="!isEditable"
                      :rules="ruleForm"
                      dense
                      outlined
                      :placeholder="$t('stadium.stadium_en')"
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("stadium.owner_th")
                    }}</label>
                    <v-text-field
                      :rules="ruleForm"
                      v-model="stadiumForm.setting_stadium_owner_name_th"
                      :readonly="!isEditable"
                      dense
                      outlined
                      :placeholder="$t('stadium.owner_th')"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("stadium.owner_en")
                    }}</label>
                    <v-text-field
                      v-model="stadiumForm.setting_stadium_owner_name_en"
                      :readonly="!isEditable"
                      dense
                      outlined
                      :placeholder="$t('stadium.owner_en')"
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("stadium.capacity")
                    }}</label>
                    <v-text-field
                      type="number"
                      :rules="ruleForm"
                      v-model="stadiumForm.setting_stadium_capacity"
                      :readonly="!isEditable"
                      dense
                      outlined
                      hide-details
                      :placeholder="$t('stadium.capacity')"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("stadium.type_lawn")
                    }}</label>
                    <v-select
                      v-model="stadiumForm.pitch_detail_grass"
                      :readonly="!isEditable"
                      :items="grass"
                      :item-text="'text_' + language"
                      :placeholder="$t('normal.please_select')"
                      dense
                      outlined
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("stadium.width")
                    }}</label>
                    <v-text-field
                      v-model="stadiumForm.pitch_detail_width"
                      :readonly="!isEditable"
                      dense
                      outlined
                      hide-details
                      :placeholder="$t('stadium.width')"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">{{
                      $t("stadium.length")
                    }}</label>
                    <v-text-field
                      v-model="stadiumForm.pitch_detail_length"
                      :readonly="!isEditable"
                      dense
                      outlined
                      hide-details
                      :placeholder="$t('stadium.length')"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- รูปสนามกีฬา -->
          <div class="p-2 border-bottom">
            <div>
              <h4 class="text-primary">{{ $t("stadium.stdium_image") }}</h4>
            </div>
            <div class="row">
              <div class="col-12 article">
                <label class="form-label" for="basic-addon-name">{{
                  $t("stadium.stdium_image")
                }}</label>
                <v-icon
                  v-if="isEditable"
                  @click="openCreateModal1"
                  style="font-size: 2rem"
                  class="ml-2 cursor-pointer"
                  >fa-edit</v-icon
                >
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="position-relative">
                  <!-- setting_stadium_map -->
                  <img
                    width="100%"
                    height="600"
                    :src="
                      imageSrc
                        ? imageSrc
                        : 'https://lightmax.co.th/extensions/themes/gae_lightmax301/staticfiles/images/default/490x490.gif'
                    "
                    alt="Alternative Text"
                    class="d-flex justify-content-center"
                    style="border-radius: 10px"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- ที่อยู่สนามกีฬา -->
          <div class="p-2 border-bottom">
            <div>
              <h4 class="text-primary">{{ $t("stadium.address_stadium") }}</h4>
            </div>
            <div>
              <form class="needs-validation" novalidate="">
                <div class="row">
                  <div class="col-sm-12 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("stadium.link_address")
                      }}</label>
                      <v-text-field
                        v-model="stadiumForm.setting_stadium_map"
                        :readonly="!isEditable"
                        dense
                        outlined
                        hide-details
                        placeholder="ลิงก์ที่อยู่"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("address.address_no_th")
                      }}</label>
                      <v-text-field
                        v-model="
                          stadiumForm.setting_stadium_regions
                            .setting_stadium_region_address_detail
                        "
                        :readonly="!isEditable"
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('address.address_no_th')"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name"
                        >{{$t('address.address_no_en')}}</label
                      >
                      <v-text-field
                        v-model="
                          stadiumForm.setting_stadium_regions
                            .setting_stadium_region_address_detail_en
                        "
                        :readonly="!isEditable"
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('address.address_no_en')"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("address.country")
                      }}</label>
                      <v-autocomplete
                        v-model="
                          stadiumForm.setting_stadium_regions
                            .setting_stadium_region_country_id
                        "
                        :readonly="!isEditable"
                        :placeholder="$t('normal.please_select')"
                        :items="countries"
                        :item-text="'country_native1_name_'+language"
                        item-value="country_id"
                        :loading="isCountriesFetching"
                        dense
                        outlined
                        hide-details
                      ></v-autocomplete>
                      <!-- <v-select  v-model="stadiumForm.setting_stadium_region_country_id" :items="country" :placeholder="$t('normal.please_select')" dense outlined></v-select> -->
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("address.province")
                      }}</label>
                      <v-autocomplete
                        v-model="
                          stadiumForm.setting_stadium_regions
                            .setting_stadium_region_province_id
                        "
                        :readonly="!isEditable"
                        :items="provinces"
						:item-text="'province_name_'+language"
                        item-value="id"
                        :loading="isProvincesFetching"
                        :disabled="
                          stadiumForm.setting_stadium_regions
                            .setting_stadium_region_country_id !== '188'
                        "
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('normal.please_select')"
                      ></v-autocomplete>
                      <!-- <v-select  v-model="stadiumForm.setting_stadium_region_province_id" :items="country" :placeholder="$t('normal.please_select')" dense outlined></v-select> -->
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-3 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("address.district")
                      }}</label>
                      <v-autocomplete
                        v-model="
                          stadiumForm.setting_stadium_regions
                            .setting_stadium_region_district_id
                        "
                        :readonly="!isEditable"
                        :items="districts"
                        item-value="id"
						:item-text="'district_name_'+language"
                        :loading="isDistrictFetching"
                        :disabled="
                          !stadiumForm.setting_stadium_regions
                            .setting_stadium_region_province_id
                        "
                        dense
                        outlined
                        hide-details
                        :placeholder="$t('normal.please_select')"
                      ></v-autocomplete>
                      <!-- <v-select  v-model="stadiumForm.setting_stadium_region_district_id" :items="country" :placeholder="$t('normal.please_select')" dense outlined></v-select> -->
                    </div>
                  </div>
                  <div class="col-sm-3 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("address.sub_district")
                      }}</label>
                      <v-autocomplete
                        v-model="
                          stadiumForm.setting_stadium_regions
                            .setting_stadium_region_subdistrict_id
                        "
                        :readonly="!isEditable"
                        :disabled="
                          !stadiumForm.setting_stadium_regions
                            .setting_stadium_region_district_id
                        "
                        :items="subdistricts"
                        :loading="isSubdistrictFetching"
						:item-text="'sub_district_name_'+language"
                        item-value="id"
                        dense
                        outlined
                        hide-details
						:placeholder="$t('normal.please_select')"
                      ></v-autocomplete>
                      <!-- <v-select  v-model="stadiumForm.setting_stadium_region_subdistrict_id" :items="country" :placeholder="$t('normal.please_select')" dense outlined></v-select> -->
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name">{{
                        $t("address.zip_code")
                      }}</label>
                      <v-text-field
                        :disabled="
                          !stadiumForm.setting_stadium_regions
                            .setting_stadium_region_zipcode
                        "
                        :readonly="!isEditable"
                        v-model="
                          stadiumForm.setting_stadium_regions
                            .setting_stadium_region_zipcode
                        "
                        dense
                        outlined
                        hide-details
						:placeholder="$t('address.zip_code')"
                      ></v-text-field>
                      <!-- <v-text-field  v-model="stadiumForm.setting_stadium_region_zipcode" dense outlined hide-details placeholder="รหัสไปรษณีย์"></v-text-field> -->
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- ประวัติ-->
          <div class="p-2">
            <div>
              <h4 class="text-primary">{{ $t("stadium.history") }}</h4>
            </div>
            <div>
              <form class="needs-validation" novalidate="">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name"
                        >{{ $t("stadium.history_th") }}
                      </label>
                      <vue-editor
                        v-model="stadiumForm.setting_stadium_history_th"
                        :editorToolbar="customToolbar"
                        :disabled="!isEditable"
                      ></vue-editor>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name"
                        >{{ $t("stadium.history_en") }}
                      </label>
                      <vue-editor
                        v-model="stadiumForm.setting_stadium_history_en"
                        :editorToolbar="customToolbar"
                        :disabled="!isEditable"
                      ></vue-editor>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </v-form>
    </div>
    <StadiumSocialContactModal
      :socialContact="stadiumSocialContactForm"
      @commit="onCommitSocialContact"
      :dialog.sync="showStadiumSocialContactModal"
    />

    <!-- dialog -->
    <UploadModal
      :visible="this.dialog1"
      @closeModal="closeModal1"
      @submit="onSubmitUpload"
    >
    </UploadModal>
    <!-- //dialog -->
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
// import { mapActions,mapGetters } from "vuex";
import { appAxios } from "../../helpers/app-axios";
import {
  countriesState,
  getCountries,
} from "../../store/global_config_country";
import {
  provincesState,
  getProvinces,
} from "../../store/global_config_province";
import { getDistrict } from "../../store/global_config_district";
import { getSubdistrict } from "../../store/global_config_subdistrict";
import { mapState } from "vuex";
import StadiumSocialContactModal from "./StadiumSocialContactModal.vue";
import UploadModal from "./UploadModal.vue";
import format from "date-fns/format";
import { languageService } from "../../helpers/language";

export default {
  components: {
    VueEditor,
    StadiumSocialContactModal,
    UploadModal,
  },
  name: "stadium-tab1",
  props: {
    tab: String,
  },
  mounted() {
    const isAddRoute = this.$route.path.includes("add");
    if (!isAddRoute) {
      const stadiumId = this.$route.params.id;

      this.$data.stadiumId = stadiumId;
      appAxios.get(`stadium/${stadiumId}`).then((res) => {
        const data = res.data.data.stadium;
        this.parseStadiumForm(data);
      });
      appAxios.get(`stadium/${stadiumId}/social-contact`).then((res) => {
        const data = res.data?.data?.social_contacts;
        this.parseStadiumSocialContact(data);
      });
    }
    this.$store.dispatch(getCountries);
    this.$store.dispatch(getProvinces);

    // const address = this.address;
    if (
      this.stadiumForm.setting_stadium_regions
        .setting_stadium_region_province_id
    ) {
      this.$store.dispatch(
        getDistrict,
        this.stadiumForm.setting_stadium_regions
          .setting_stadium_region_province_id
      );
    }
    if (
      this.stadiumForm.setting_stadium_regions
        .setting_stadium_region_district_id
    ) {
      this.$store.dispatch(
        getSubdistrict,
        this.stadiumForm.setting_stadium_regions
          .setting_stadium_region_district_id
      );
    }
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  data() {
    return {
      language: "th",
      // showRelationModal: false,
      customToolbar: [
        [{ size: [] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["link"],
      ],
      id: "",
      action: "",
      showStadiumSocialContactModal: false,
      dialog1: false,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 200,
        addRemoveLinks: true,
      },
      grass: [
        {
          value: 0,
          text_th: "หญ้าจริง",
          text_en: "Grass",
        },
        {
          value: 1,
          text_th: "หญ้าเทียม",
          text_en: "Artificial Grass",
        },
        {
          value: 2,
          text_th: "อื่นๆ",
          text_en: "Other",
        },
      ],
      dataImage: null,
      stadiumId: null,
      stadiumForm: {
        setting_stadium_name_th: null,
        setting_stadium_name_en: null,
        setting_stadium_owner_name_th: null,
        setting_stadium_owner_name_en: null,
        setting_stadium_capacity: null,
        pitch_detail_grass: null,
        pitch_detail_width: null,
        pitch_detail_length: null,
        setting_stadium_map: null,
        setting_stadium_regions: {
          setting_stadium_region_country_id: "188",
          setting_stadium_region_address_detail: null,
          setting_stadium_region_address_detail_en: null,
          setting_stadium_region_province_id: null,
          setting_stadium_region_district_id: null,
          setting_stadium_region_subdistrict_id: null,
          setting_stadium_region_zipcode: null,
        },
        setting_stadium_history_th: null,
        setting_stadium_history_en: null,
        active: false,
      },
      stadiumSocialContactForm: {
        // id: null,
        setting_stadium_social_contact_phone: "",
        setting_stadium_social_contact_email: "",
        setting_stadium_social_contact_facebook: "",
        setting_stadium_social_contact_line: "",
        setting_stadium_social_contact_instagram: "",
        setting_stadium_social_contact_twitter: "",
        setting_stadium_social_contact_youtube: "",
        setting_stadium_social_contact_website: "",
        setting_stadium_social_contact_fax: "",
      },
      imageStadiumForm: null,
    };
  },
  methods: {
    // ...mapActions("stadiums", [
    //   "initData",
    //   "initDataByid",
    // ]),
    async save() {
      if (!this.isEditable) return;
      const result = this.$refs.formRef.validate();
      if (!result) return;
      const stadiumId = await this.saveStadium();
      await this.saveStadiumSocialContact(stadiumId);
      await this.savePublicStadium(stadiumId);
      if (this.$data.dataImage) {
        await this.saveImageStadium(stadiumId);
      }
      this.$data.stadiumForm.stadiumId = stadiumId;
      return this.$router.push({
        path: `/stadium/edit/${stadiumId}`,
        query: { tab: 0 },
      });
    },
    parseStadiumForm(data) {
      // console.log('data',data.setting_stadium_regions.setting_stadium_region_address_detail)
      this.$data.stadiumForm = {
        stadiumId: data.id,
        setting_stadium_name_th: data.setting_stadium_name_th,
        setting_stadium_name_en: data.setting_stadium_name_en,
        setting_stadium_owner_name_th:
          data.setting_stadium_owners.setting_stadium_owner_name_th,
        setting_stadium_owner_name_en:
          data.setting_stadium_owners.setting_stadium_owner_name_en,
        setting_stadium_capacity: data.setting_stadium_capacity,
        pitch_detail_grass: data.pitch_details?.pitch_detail_grass,
        pitch_detail_width: data.pitch_details?.pitch_detail_width,
        pitch_detail_length: data.pitch_details?.pitch_detail_length,
        setting_stadium_map: data.setting_stadium_map,
        setting_stadium_regions: {
          setting_stadium_region_country_id:
            data.setting_stadium_regions?.setting_stadium_region_country_id,
          setting_stadium_region_address_detail:
            data.setting_stadium_regions?.setting_stadium_region_address_detail,
          setting_stadium_region_address_detail_en:
            data.setting_stadium_regions
              ?.setting_stadium_region_address_detail_en,
          setting_stadium_region_province_id:
            data.setting_stadium_regions?.setting_stadium_region_province_id,
          setting_stadium_region_district_id:
            data.setting_stadium_regions?.setting_stadium_region_district_id,
          setting_stadium_region_subdistrict_id:
            data.setting_stadium_regions?.setting_stadium_region_subdistrict_id,
          setting_stadium_region_zipcode:
            data.setting_stadium_regions?.setting_stadium_region_zipcode,
        },
        setting_stadium_history_th: data.setting_stadium_history_th,
        setting_stadium_history_en: data.setting_stadium_history_en,
        active: data.setting_stadium_published,
        createdAt: format(new Date(data.created_at), "dd/MM/yyyy"),
        updatedAt: format(new Date(data.updated_at), "dd/MM/yyyy"),
        updatedBy: data.updated_by,
      };
      this.$data.dataImage =
        data.setting_stadium_owners?.setting_stadium_owner_media_url;
    },
    parseStadiumSocialContact(data) {
      if (!data) return;
      this.$data.stadiumSocialContactForm = data;
    },
    async savePublicStadium(stadiumId) {
      const publics = {
        setting_stadium_published:
          this.$data.stadiumForm.active === null
            ? false
            : this.$data.stadiumForm.active,
      };
      return appAxios
        .post(`stadium/${stadiumId}/published/update`, publics)
        .then((res) => {
          res;
        });
    },
    async saveStadium() {
      const body = {
        ...this.$data.stadiumForm,
      };
      delete body.createdAt;
      delete body.updatedBy;
      delete body.updatedAt;

      // save stadium
      if (body.stadiumId) {
        const stadiumId = body.stadiumId;
        delete body.stadiumId;
        await this.savePublicStadium(stadiumId);
        return await appAxios
          .post(`stadium/${stadiumId}/update`, body)
          .then((res) => {
            return res.data?.data?.stadium?.id;
          });
      } else {
        return await appAxios.post("stadium", body).then((res) => {
          return res.data?.data?.stadium?.id;
        });
      }
    },
    async saveStadiumSocialContact(stadiumId) {
      const body = this.$data.stadiumSocialContactForm;
      appAxios
        .post(`stadium/${stadiumId}/social-contact`, body)
        .then((res) => res);
    },
    async saveImageStadium(stadiumId) {
      if (!(this.dataImage instanceof File)) return;
      let formData = new FormData();
      formData.append("file", this.$data.dataImage);
      appAxios
        .post(`stadium/${stadiumId}/upload/images`, formData)
        .then((res) => res);
    },
    onCommitSocialContact(value) {
      this.$data.stadiumSocialContactForm = value;
      this.closeModal();
    },
    onClickStadiumSocialContact(e) {
      e.preventDefault();
      this.$data.showStadiumSocialContactModal = true;
    },
    closeModal() {
      this.$data.showStadiumSocialContactModal = false;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.closeModal1();
    },
    openCreateModal1(e) {
      e.preventDefault();
      this.dialog1 = true;
    },
    closeModal1() {
      this.dialog1 = false;
    },
    onSubmitUpload(image) {
      console.log(image);
      this.$data.dataImage = image;
      this.closeModal1();
    },
  },
  computed: {
    ...mapState({
      isCountriesFetching: (state) => state.globalConfigCountry.isFetching,
      countries: (state) => state.globalConfigCountry[countriesState],
      isProvincesFetching: (state) => state.globalConfigProvince.isFetching,
      provinces: (state) => state.globalConfigProvince[provincesState],
      isDistrictFetching(state) {
        state.globalConfigDistrict.isFetching; // to let vue know when should trigger state change
        return state.globalConfigDistrict[
          this.stadiumForm.setting_stadium_regions
            .setting_stadium_region_province_id
        ]?.isFetching;
      },
      districts(state) {
        state.globalConfigDistrict.isFetching;
        return state.globalConfigDistrict[
          this.stadiumForm.setting_stadium_regions
            .setting_stadium_region_province_id
          // this.address.province_id
        ]?.data;
      },
      isSubdistrictFetching(state) {
        state.globalConfigSubdistrict.isFetching;
        return state.globalConfigSubdistrict[
          this.stadiumForm.setting_stadium_regions
            .setting_stadium_region_district_id
        ]?.isFetching;
      },
      subdistricts(state) {
        state.globalConfigSubdistrict.isFetching;
        return state.globalConfigSubdistrict[
          this.stadiumForm.setting_stadium_regions
            .setting_stadium_region_district_id
        ]?.data;
      },
      isEditable: (state) => state.permission.permissions.stadium_edit,
    }),
    ruleForm() {
      const rules = [];
      rules.push((v) => !!v || `required`);
      // }
      return rules;
    },
    imageSrc() {
      if (!this.dataImage) return null;
      if (!(this.dataImage instanceof File)) return this.dataImage;
      const url = URL.createObjectURL(this.dataImage);
      return url;
    },
    stadiumName() {
      return (
        {
          th: this.stadiumForm.setting_stadium_name_th,
          en: this.stadiumForm.setting_stadium_name_en,
        } || { th: "ชื่อสนามกีฬา", en: "Stadium Name" }
      );
    },
  },
  watch: {
    "stadiumForm.setting_stadium_regions.setting_stadium_region_country_id"() {
      if (
        this.stadiumForm.setting_stadium_regions
          .setting_stadium_region_country_id !== "188"
      ) {
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_province_id =
          null;
      } else {
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_province_id =
          null;
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_district_id =
          null;
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_subdistrict_id =
          null;
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_zipcode =
          null;
      }
    },
    "stadiumForm.setting_stadium_regions.setting_stadium_region_province_id"(
      newVal,
      old
    ) {
      console.log("setting_stadium_region_province_id", newVal, old);
      if (!newVal) {
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_district_id =
          null;
        return;
      } else if (old) {
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_district_id =
          null;
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_subdistrict_id =
          null;
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_zipcode =
          null;
      }
      this.$store.dispatch(getDistrict, newVal);
    },
    "stadiumForm.setting_stadium_regions.setting_stadium_region_district_id"(
      newVal,
      old
    ) {
      console.log(newVal, old);
      if (!newVal) {
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_subdistrict_id =
          null;
        return;
      } else if (old) {
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_subdistrict_id =
          null;
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_zipcode =
          null;
      }
      this.$store.dispatch(getSubdistrict, newVal);
    },
    "stadiumForm.setting_stadium_regions.setting_stadium_region_subdistrict_id"(
      newVal
    ) {
      if (!newVal) {
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_zipcode =
          null;
        return;
      }
      const matchSubdistrict = this.$store.state.globalConfigSubdistrict[
        this.stadiumForm.setting_stadium_regions
          .setting_stadium_region_district_id
      ]?.data.find((ele) => ele.id == newVal);
      if (matchSubdistrict) {
        this.stadiumForm.setting_stadium_regions.setting_stadium_region_zipcode =
          matchSubdistrict.zipcode;
      }
    },
  },
};
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.active-radio .v-icon {
  color: red;
}
.article {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

div.required label:after {
  content: " *";
  color: red;
}

@media (min-width: 960px) {
  .tab1-border-left {
    border-left: 1px solid #ebe9f1 !important;
  }
}
</style>
