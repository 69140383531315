<template>
  <div class="master-data-table">
    <div
      class="master-data-table-wrapper"
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 30px 5px 30px;
      "
    >
      <h4 class="card-title text-primary">{{ $t('team.outstanding_matches') }}</h4>
    </div>
    <div class="master-data-table-wrapper" style="padding-top: 15px">
      <v-data-table
        :items="DataMajorCompetition"
        :headers="headers"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        :items-per-page="dataTeamStaffsHistoreisMajor.perPage"
        :no-data-text="$t('normal.no_data')"
      >
        <template v-slot:item="row">
          <tr>
            <td>{{ row.item.date }}</td>
            <!--  <td>  <img src="@/assets/images/logoTrans.png" width="20"> {{ row.item.competitor }} </td>-->
            <td class="d-flex justify-content-start pt-3">
              <v-img
                v-if="
                  row.item.imageCompetitor || row.item.imageCompetitor !== ''
                "
                :src="row.item.imageCompetitor"
                max-width="30"
                max-height="30"
                contain
                class="mr-3"
              />
              <label>{{ row.item.nameCompetitor }}</label>
            </td>
            <td>{{ row.item.score }}</td>
            <td
              :class="
                row.item.resultsCompetitor[language] === 'ชนะ' || row.item.resultsCompetitor[language] === 'Win'
                  ? 'text-success'
                  : row.item.resultsCompetitor[language] === 'เสมอ' || row.item.resultsCompetitor[language] === 'Draw'
                  ? 'text-body'
                  : 'text-danger'
              "
            >
              {{ row.item.resultsCompetitor[language] }}
            </td>
            <td>{{ row.item.competition[language] }}</td>
            <td>{{ row.item.round[language] }}</td>
            <td>
              <div
                class="d-flex"
                :class="
                  row.item.remark === null
                    ? 'justify-content-end'
                    : 'justify-content-between'
                "
              >
                {{ row.item.remark[language] }}
                <v-icon @click="editHistoryMajor(row.item.id, 'major')"
                  >$editHistoryIcon</v-icon
                >
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="" style="background: #ffffff">
        <TableFooter
          :perPage.sync="dataTeamStaffsHistoreisMajor.perPage"
          :currentPage.sync="currentPageMajor"
          :lastPage="dataTeamStaffsHistoreisMajor.lastPage"
          :total="dataTeamStaffsHistoreisMajor.total"
        />
      </div>
    </div>
    <!-- // -->
    <div
      class="master-data-table-wrapper"
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 30px 5px 30px;
      "
    >
      <h4 class="card-title text-primary">
        {{ $t("team.all_competition_history") }}
      </h4>
      <div
        class=""
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <!-- display: flex; justify-content: space-between; align-items: center; -->
        <label class="form-label mx-2" for="basic-addon-name">{{
          $t("normal.year")
        }}</label>
        <div class="form-group">
          <v-autocomplete
            clearable
            :items="yearsList"
            v-model="selected.year"
            :label="$t('normal.please_select')"
            dense
            solo
            flat
            class="form-control"
          ></v-autocomplete>
        </div>
        <label class="form-label mx-3" for="basic-addon-name">{{
          $t("team.browse_by_list")
        }}</label>
        <div class="form-group">
          <v-autocomplete
            :items="listMatch"
            clearable
            :item-text="'setting_sub_competition_name_' + language"
            item-value="id"
            v-model="selected.match"
            :label="$t('normal.please_select')"
            dense
            solo
            flat
            class="form-control"
          ></v-autocomplete>
        </div>
      </div>
    </div>
    <div class="master-data-table-wrapper" style="padding-top: 15px">
      <!--<div class="master-data-table-search">
				<div class="input-group">
						<IconImage
						className="icon-search"
						src="assets/images/icons/search.svg"
						width="21"
						height="21"
					></IconImage>
					<input
						type="text"
						@change="getFindSearchName"
						v-model="searchNameList"
						class="form-control"
						placeholder="ค้นหาการแข่งขัน"
					/>
						<div class="input-group-append">
						<v-menu offset-y rounded="lg" content-class="filter-dropdown" left>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon v-bind="attrs" v-on="on" outlined>
									<IconImage
										className="icon-filter"
										src="assets/images/icons/filter.svg"
										width="22"
										height="19"
									></IconImage>
								</v-btn>
							</template>
						<div class="p-1 bg-white">
								<div class="text-primary">ตัวกรอง</div>
								<v-form>
									<div>ช่วงอายุ</div>
									<v-range-slider
										v-model="range"
										:max="100"
										:min="0"
										hide-details
										class="align-center"
									>
										<template v-slot:prepend>
											<v-text-field
												dense
												outlined
												:value="range[0]"
												class="mt-0 pt-0"
												hide-details
												single-line
												type="number"
												style="width: 60px"
												@change="$set(range, 0, $event)"
											></v-text-field>
										</template>
										<template v-slot:append>
											<v-text-field
												dense
												outlined
												:value="range[1]"
												class="mt-0 pt-0"
												hide-details
												single-line
												type="number"
												style="width: 60px"
												@change="$set(range, 1, $event)"
											></v-text-field>
										</template>
									</v-range-slider>
									<div>เพศ</div>
									<v-select dense outlined hide-details="true"></v-select>
									<div>สถานะทีมชาติ</div>
									<v-select dense outlined hide-details="true"></v-select>
									<div>ต้นสังกัดปัจจุบัน</div>
									<v-select dense outlined hide-details="true"></v-select>
									<div>จังหวัด</div>
									<v-select dense outlined hide-details="true"></v-select>
									<v-btn color="primary" class="mt-3">ค้นหา</v-btn>
								</v-form>
							</div>
						</v-menu>
					</div>
				</div>
			</div>-->
      <v-data-table
        :items="DataCompetititon"
        :headers="headers"
        item-key="id"
        :search="searchNameList"
        :page.sync="page"
        :hide-default-footer="true"
        class="elevation-1"
        :items-per-page="pageSizeList"
        :no-data-text="$t('normal.no_data')"
      >
        <template v-slot:item="row">
          <tr>
            <td>{{ row.item.date }}</td>
            <!--  <td>  <img src="@/assets/images/logoTrans.png" width="20"> {{ row.item.competitor }} </td>-->
            <td class="d-flex justify-content-start pt-3">
              <v-img
                v-if="row.item.imageCompetitor"
                :src="row.item.imageCompetitor"
                max-width="30"
                max-height="30"
                contain
                class="mr-3"
              />
              <label>{{ row.item.nameCompetitor }}</label>
            </td>
            <td>{{ row.item.score }}</td>
            <td
              :class="
                row.item.resultsCompetitor[language] === 'ชนะ' || row.item.resultsCompetitor[language] === 'Win'
                  ? 'text-success'
                  : row.item.resultsCompetitor[language] === 'เสมอ' || row.item.resultsCompetitor[language] === 'Draw'
                  ? 'text-body'
                  : 'text-danger'
              "
            >
              {{ row.item.resultsCompetitor[language] }}
            </td>
            <td>{{ row.item.competition[language] }}</td>
            <td>{{ row.item.round[language] }}</td>
            <td>
              <div
                class="d-flex"
                :class="
                  row.item.remark === null
                    ? 'justify-content-end'
                    : 'justify-content-between'
                "
              >
                <div>{{ row.item.remark[language] }}</div>
                <v-icon @click="editHistoryMajor(row.item.id, 'history')"
                  >$editHistoryIcon</v-icon
                >
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="" style="background: #ffffff">
        <TableFooter
          :perPage.sync="pageSizeList"
          :currentPage.sync="currentPageList"
          :lastPage="dataTeamStaffsHistoreisList.lastPage"
          :total="dataTeamStaffsHistoreisList.total"
        />
      </div>
    </div>
    <v-btn
      elevation="2"
      class="btn-primary float-right mb-5"
      v-on:click="nextTab()"
    >
      {{ $t("normal.next") }} <v-icon right> fa-arrow-right </v-icon>
    </v-btn>
    <v-btn
      elevation="2"
      class="btn-primary float-left mb-5"
      v-on:click="backTab()"
    >
      <v-icon left> fa-arrow-left </v-icon> {{ $t("normal.previous") }}
    </v-btn>
    <EditRemark
      :dialog.sync="isEditRemark"
      :type="typeMatch"
      :data.sync="editRemark"
      @commit="onCommitVersusMatch"
    />
  </div>
</template>
<script>
// import IconImage from "@/components/IconImage.vue";
import TableFooter from "../../components/TableFooter.vue";
import EditRemark from "../../components/Team/EditRemark";
import format from "date-fns/format";
import { appAxios } from "../../helpers/app-axios";
import { languageService } from "../../helpers/language";

export default {
  components: {
    // IconImage,
    TableFooter,
    EditRemark,
  },
  name: "team-tab4",
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  beforeDestroy() {
    console.log("destroy team");
    this.subscription.unsubscribe();
  },
  mounted() {
    this.$data.teamId = this.$route.params.id;
    this.getMajorCompetition();
    this.getHistryCompetition();
    this.getCompete();
    // this.getDataTeamStaffsHistoriesMajor()
    // this.getDataTeamStaffsHistoriesList()
  },
  updated() {
    console.log("listMatch :: ", this.listMatch);
  },
  methods: {
    onCommitVersusMatch() {
      this.getMajorCompetition();
      this.getHistryCompetition();
    },
    test: function () {
      alert("test");
    },
    nextTab() {
      this.$router.push({ path: "", query: { tab: 4 } });
    },
    backTab() {
      this.$router.push({ path: "", query: { tab: 2 } });
    },
    changeCounts: function (counts) {
      this.itemsPerPage = counts;
    },
    testConsole_3: function (counts) {
      this.itemsPerPage = counts;
    },
    testConsole_4: function (counts) {
      this.itemsPerPage = counts;
    },
    editHistoryMajor(detail, e) {
      appAxios
        .get(`team/${this.$data.teamId}/match/versus-match/${detail}`)
        .then((res) => {
          this.$data.editRemark = this.getDataVersusMatch(
            res.data.data.versus_match
          );
        });

      this.$data.isEditRemark = true;
      this.$data.typeMatch = e;
    },
    getHistryCompetition() {
      appAxios
        .get(`team/${this.$data.teamId}/match/history`, {
          params: this.params,
        })
        .then((res) => {
          const Competiton = this.parseVersusMatch(
            res.data.data.versus_match?.data
          );
          if (this.searchNameList) {
            const searching = Competiton.filter((p) => {
              return p.competition.toLowerCase().includes(this.searchNameList);
            });
            this.$data.DataCompetititon = searching;
          } else {
            this.$data.DataCompetititon = Competiton;
          }
          this.$data.dataTeamStaffsHistoreisList = {
            lastPage: res.data?.data?.versus_match?.lastPage,
            total: res.data?.data?.versus_match?.total,
            currentPage: res.data?.data?.versus_match?.lastPage,
            perPage: res.data?.data?.versus_match?.perPage,
          };
        });
    },
    getMajorCompetition() {
      appAxios.get(`team/${this.$data.teamId}/match/important`).then((res) => {
        const majorCompetiton = this.parseVersusMatch(
          res.data.data.versus_match?.data
        );
        this.$data.DataMajorCompetition = majorCompetiton;
        this.$data.dataTeamStaffsHistoreisMajor = {
          lastPage: res.data?.data?.versus_match?.lastPage,
          total: res.data?.data?.versus_match?.total,
          currentPage: res.data?.data?.versus_match?.lastPage,
          perPage: res.data?.data?.versus_match?.perPage,
        };
      });
    },
    getDataVersusMatch(match) {
      let team = {};
      let competitor = {};
      if (
        this.$data.teamId ===
        match?.versus_match_team_detail_one_id?.versus_match_team_id
      ) {
        team = match?.versus_match_team_detail_one_id;
        competitor = match?.versus_match_team_detail_two_id;
      } else {
        team = match?.versus_match_team_detail_two_id;
        competitor = match?.versus_match_team_detail_one_id;
      }
      let result = +team?.versus_match_score - +competitor?.versus_match_score;

      const objData = {
        id: match?.id,
        date: format(new Date(match?.versus_match_date), "dd/MM/yyyy"),
        versus_match_id: null || team?.id,
        imageCompetitor: competitor?.versus_match_team_image,
        imageTeam: team?.versus_match_team_image,

        nameCompetitor: competitor?.versus_match_team_name,
        nameTeam: team?.versus_match_team_name,

        score:
          (team?.versus_match_score || 0) +
          "-" +
          (competitor?.versus_match_score || 0),
        resultsCompetitor: result === 0 ? {en:"Draw",th:"เสมอ"} : result > 0 ? {en:"Win",th:"ชนะ"} : {en:"Lose",th:"แพ้"},
        resultsTeam: result === 0 ? {en:"Draw",th:"เสมอ"} : result < 0 ? {en:"Win",th:"ชนะ"}  : {en:"Lose",th:"แพ้"},

        // competition:
        //   match?.setting_sub_competition?.setting_sub_competition_name_th,
        competition:{
          en:match?.setting_sub_competition?.setting_sub_competition_name_en,
          th:match?.setting_sub_competition?.setting_sub_competition_name_th,
        },
        // round: match?.competition_sections.competition_section_name_th,
        round:{
          en:match?.competition_sections.competition_section_name_en,
          th:match?.competition_sections.competition_section_name_th
        },
        // remark: team?.versus_match_team_detail_remark,
        remark:team?.versus_match_team_detail_remark,
        remark_en:team?.versus_match_team_detail_remark_en,
        importantMatch: team?.versus_match_team_detail_important,
      };
      return objData;
    },
    parseVersusMatch(dataMatch) {
      const dataCompetitions = dataMatch.map((match) => {
        return this.getDataVersusMatch(match);
      });
      return dataCompetitions;
    },
    getFindSearchName() {
      const searching = this.$data.dataSearchTeam.filter((p) => {
        return p.competition.toLowerCase().includes(this.searchNameList);
      });
      this.$data.DataCompetititon = searching; // this.getHistryCompetition();
    },
    getCompete() {
      appAxios.get(`competitions/find-all/sub-competition`).then((res) => {
        const data = res.data.data.sub_competition;
        if (!data) return;
        // const sub = data.map((com) => {
        //   return {
        //     setting_sub_competition_name_th: com.setting_sub_competition_name_th,
        // 	setting_sub_competition_name_en: com.setting_sub_competition_name_en,
        //     value: com.id,
        //   };
        // });
        // console.log('listMatch ==> ', sub)
        // this.listMatch = sub;
        console.log("listMatch ==> ", data);
        this.listMatch = data;
      });
    },
  },
  computed: {
    fitColor: function () {
      // console.log(this.fit.value);
      if (this.fit.value < 50) {
        return "red";
      } else {
        return "teal";
      }
    },
    users: {
      set: function (val) {
        this.$store.state.user.user.name = val;
      },
      get: function () {
        return this.$store.state.user.user.name;
      },
    },
    yearsList() {
      const currentYear = new Date().getFullYear();
      const yearAmount = currentYear - 1950;
      return Array.from(Array(yearAmount).keys())
        .map((ele) => currentYear - ele)
        .sort((a, b) => b - a);
    },
    params() {
      const params = {
        size: this.pageSizeList,
        page: this.currentPageList,
        setting_sub_competition_year: this.selected.year || 0,
        setting_competitions: +this.selected.match || 0,
      };
      return params;
    },
  },
  data() {
    return {
      selected: {},
      sub_competition: [],
      editRemark: {},
      isEditRemark: false,
      isEditImportantMatch: false,
      typeMatch: "",
      pageSizeMajor: 10,
      currentPageMajor: 1,
      searchNameMajor: "",
      dataTeamStaffsHistoreisMajor: {},
      teamId: null,
      pageSizeList: 10,
      currentPageList: 1,
      searchNameList: "",
      dataTeamStaffsHistoreisList: {},
      //
      page: 1,
      pageCount: 0,
      pageCounts: [10, 20, 50, 100],
      itemsPerPage: 10,
      min: 1,
      max: 90,
      range: [20, 70],
      headers: [
        {
          text: this.$t("team.competition_date", this.language),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("team.rival", this.language),
          value: "name",
          sortable: false,
        },
        {
          text: this.$t("team.score", this.language),
          value: "position",
          sortable: false,
        },
        {
          text: this.$t("team.result", this.language),
          value: "status",
          sortable: false,
        },
        {
          text: this.$t("competition.all_competition", this.language),
          value: "abb",
          sortable: false,
        },
        {
          text: this.$t("team.round_of_competition", this.language),
          value: "abb",
          sortable: false,
        },
        {
          text: this.$t("normal.remark", this.language),
          value: "abb",
          sortable: false,
        },
      ],
      listMatch: [],
      search: "",
      DataMajorCompetition: [],
      DataCompetititon: [],
      dataSearchTeam: [],
      language: "th",
    };
  },
  watch: {
    "selected.year"() {
      this.getHistryCompetition();
    },
    "selected.match"() {
      this.getHistryCompetition();
    },

    //
    // currentPageMajor: function() {
    // 	this.getDataTeamStaffsHistoriesMajor();
    // },
    // pageSizeMajor: function() {
    // 	this.getDataTeamStaffsHistoriesMajor();
    // },
    // searchNameMajor: function() {
    // 	this.getDataTeamStaffsHistoriesMajor();
    // },
    // currentPageList: function() {
    // 	this.getDataTeamStaffsHistoriesList();
    // },
    // pageSizeList: function() {
    // 	this.getDataTeamStaffsHistoriesList();
    // },
    // searchNameList: function() {
    // 	this.getDataTeamStaffsHistoriesList();
    // },
    currentPageMajor: function () {
      this.getVersusMatch();
    },
    pageSizeMajor: function () {
      this.getVersusMatch();
    },
    searchNameMajor: function () {
      this.getVersusMatch();
    },
    currentPageList: function () {
      this.getHistryCompetition();
    },
    pageSizeList: function () {
      this.getHistryCompetition();
    },
    searchNameList: function () {
      this.getHistryCompetition();
    },
    language(newVal) {
      this.headers = [
        {
          text: this.$t("team.competition_date", newVal),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("team.rival", newVal),
          value: "name",
          sortable: false,
        },
        {
          text: this.$t("team.score", newVal),
          value: "position",
          sortable: false,
        },
        {
          text: this.$t("team.result", newVal),
          value: "status",
          sortable: false,
        },
        {
          text: this.$t("competition.all", newVal),
          value: "abb",
          sortable: false,
        },
        {
          text: this.$t("team.round_of_competition", newVal),
          value: "abb",
          sortable: false,
        },
        {
          text: this.$t("normal.remark", newVal),
          value: "abb",
          sortable: false,
        },
      ];
    },
  },
};
</script>
