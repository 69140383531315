<template>
  <v-app id="curriculumVitae">
    <div class="content app-content h-100">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <side-menu @commit="getParams" />
          </div>
          <div class="col-md-8">
            <!--<div class="card paper mb-10" v-for="(item,t) in this.sumPage" :key="t">-->
                        <div class="card paper mb-10">

              <header-player v-if="accountType === 'player' " :banner="banner" />
              <header-staff v-else :banner="banner" />
              <div class="p-2">
                <personal
                  v-if="params.information"
                  :personal="personal"
                  @heightSize="handlePageSize"
                />
                <contact
                  v-if="params.social_contact"
                  :contact="contact"
                  @heightSize="handlePageSize"
                />
                <address-info
                  v-if="params.social_region"
                  :addressInfo="addressInfo"
                  @heightSize="handlePageSize"
                />
                <education
                  v-if="params.education"
                  :education="education"
                  @heightSize="handlePageSize"
                />
                <nation-history
                  v-if="params.team_national_history"
                  :nationHistory="nationHistory"
                  :type="accountType"
                  @heightSize="handlePageSize"
                />
                <club-history
                  v-if="params.team_club_history"
                  :clubHistory="clubHistory"
                  :type="accountType"
                  @heightSize="handlePageSize"
                />
                <honor-nation
                  v-if="params.honor_national"
                  :honorNation="honorNation"
                  @heightSize="handlePageSize"
                />
                <honor-club
                  v-if="params.honor_club"
                  :honorClub="honorClub"
                  @heightSize="handlePageSize"
                />
                <honor-personal
                  v-if="params.honor_personal"
                  :honorPersonal="honorPersonal"
                  @heightSize="handlePageSize"
                />
              </div>
            </div>
          </div>
        </div>

        <!--<div class="shadow-lg p-3 mb-5 bg-white rounded">
					<Tab11Content />
				</div>-->
      </div>
    </div>
  </v-app>
</template>

<script>
import HeaderPlayer from "../../components/CurriculumVitae/headerPlayer.vue";
import SideMenu from "../../components/CurriculumVitae/SideMenu.vue";
import { appAxios } from "../../helpers/app-axios";
import Personal from "@/components/CurriculumVitae/Personal";
import Contact from "@/components/CurriculumVitae/Contact";
import Education from "../../components/CurriculumVitae/Education.vue";
import AddressInfo from "@/components/CurriculumVitae/AddressInfo";
import NationHistory from "../../components/CurriculumVitae/NationHistory.vue";
import ClubHistory from "../../components/CurriculumVitae/ClubHistory.vue";
import HonorClub from "../../components/CurriculumVitae/HonorClub.vue";
import HonorNation from "@/components/CurriculumVitae/HonorNation";
import HonorPersonal from "@/components/CurriculumVitae/HonorPersonal";
import HeaderStaff from "../../components/CurriculumVitae/headerStaff.vue";
export default {
  components: {
    SideMenu,
    HeaderPlayer,
    Personal,
    Contact,
    Education,
    AddressInfo,
    NationHistory,
    ClubHistory,
    HonorClub,
    HonorNation,
    HonorPersonal,
    HeaderStaff,
  },
  data() {
    return {
      accountType: "",
      heightBanner: 300,
      checkPage: [],
      sumPage: 1,
      banner: {},
      personal: {},
      contact: {},
      addressInfo: {},
      education: {},
      nationHistory: {},
      clubHistory: {},
      honorNation: {},
      honorClub: {},
      honorPersonal: {},
      params: {},
    };
  },

  methods: {
    handlePageSize(value) {
      this.heightBanner = this.heightBanner + value;
      if(this.checkPage.length >= 9)return
      if (this.checkPage.length < 9) {
        if (this.heightBanner < 1050) {
          this.checkPage.push(this.sumPage);
        } else {
          this.checkPage.push(this.sumPage + 1);
          this.sumPage = this.sumPage + 1;
          this.heightBanner = 0;
        }
        console.log('handlePageSize',this.heightBanner,value);
      }
    },
    async getParams(value) {
      this.params = value;
      const player = this.$route.path.includes("player") ? "player" : "staff";
      this.accountType = player;
      appAxios
        .get(`/account/${Number(this.$route.params?.id)}/${player}/report`, {
          params: value,
        })
        .then((res) => {
          this.formatBanner(res.data.data.account.account_banner);
          this.personal = res.data.data.account;
          this.contact = res.data.data.account.account_social_contact;
          this.addressInfo = res.data.data.account.account_region;
          this.education = res.data.data.account.account_education;
          this.nationHistory =
            res.data.data.account.account_team_history_national;
          this.clubHistory = res.data.data.account.account_team_history_club;
          this.honorNation = res.data.data.account.account_honor_national;
          this.honorClub = res.data.data.account.account_honor_club;
          this.honorPersonal = res.data.data.account.account_honor_personal;
        });
    },
    formatBanner(data) {
      this.banner = {
        image: data?.account_informations?.account_media_preview_url,
        firstName: data?.account_informations?.account_information_firstname_th,
        lastName: data?.account_informations?.account_information_lastname_th,
        firstNameEn:
          data?.account_informations?.account_information_firstname_en,
        lastNameEn: data?.account_informations?.account_information_lastname_en,
        fullNameEn:
          (data?.account_informations?.account_information_firstname_en || "") +
          " " +
          (data?.account_informations?.account_information_lastname_en || ""),
        fullNameTH:
          (data?.account_informations?.account_information_firstname_th || "") +
          " " +
          (data?.account_informations?.account_information_lastname_th || ""),
        position: data?.positions?.map((posi) => {
          return {
            name: {
              th: posi?.global_config_position_name_th,
              en: posi?.global_config_position_name_en,
            },
            abbreviation: posi?.global_config_position_abbreviation,
          };
        }),
        team: [
          {
            icon: data?.team_national?.setting_team_media_profile_url,
            name: {
              th: data?.team_national?.setting_team_information_name_th,
              en: data?.team_national?.setting_team_information_name_en,
            },
            shirt: data?.team_national?.team_player_history_shirt_number,
            position: data?.team_national?.global_config_positions,
          },
          {
            icon: data?.team_club?.setting_team_media_profile_url,
            name: {
              th: data?.team_club?.setting_team_information_name_th,
              en: data?.team_club?.setting_team_information_name_en,
            },
            shirt: data?.team_club?.team_player_history_shirt_number,
            position: data?.team_club?.global_config_positions,
          },
        ],
        techicalSkill:
          data?.technical_skill?.length !== 0 && data?.technical_skill !== null
            ? data?.technical_skill?.map((skill) => {
                return {
                  name: {
                    th: skill?.global_config_skill_name_th,
                    en: skill?.global_config_skill_name_en,
                  },
                  percent: skill?.account_technical_skill_percentage,
                };
              })
            : [
                {
                  name: {
                    th: "ความคล่องตัว",
                    en: "",
                  },
                  percent: 0,
                },
                {
                  name: {
                    th: "ความเร็ว",
                    en: "",
                  },
                  percent: 0,
                },
                {
                  name: {
                    th: "ความแม่นยํา",
                    en: "",
                  },
                  percent: 0,
                },
                {
                  name: {
                    th: "ความทนทาน",
                    en: "",
                  },
                  percent: 0,
                },
                {
                  name: {
                    th: "ความเเข็งแรง",
                    en: "",
                  },
                  percent: 0,
                },
              ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.paper {
  size: 21cm 29.7cm;
  // max-height: 29.7cm;
}
</style>
