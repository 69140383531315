<template>
	<v-dialog
		:value="dialog"
		@input="closeModal"
		width="800px"
		v-bind:hide-overlay="false"
		style="margin-top:auto;"
	>
		<v-card>
			<div class="dialog-wrapper">
				<div class="dialog-header">
					<div class="dialog-header-title">{{ header[language] }}</div>
					<div class="dialog-actions">
						<v-btn color="primary" plain class="btn-close" @click="closeModal">
							{{ $t("normal.cancel") }}
						</v-btn>
						<v-btn
							color="primary"
							:disabled="!isEditable"
							class="m-l-5px"
							@click="submit"
						>
							{{ $t("normal.save") }}
						</v-btn>
					</div>
				</div>
				<div class="dialog-content mx-10 mt-10">
					<div class="mx-15 d-flex">
						<div class="col-5 text-center d-flex">
							<div>
								<v-img
									:src="data.imageTeam"
									width="100"
									height="50"
									contain
								/>
							</div>
							<div>
								<p class="mb-0 pt-0">{{ data.nameTeam }}</p>
								<p
									:class="
										data.resultsCompetitor[language] === 'ชนะ' || data.resultsCompetitor[language] === 'Win'
											? 'text-success'
											: data.resultsCompetitor[language] === 'แพ้' || data.resultsCompetitor[language] === 'Lose'
											? 'text-danger'
											: 'text-body'
									"
								>
									{{ data.resultsCompetitor[language] }}
								</p>
							</div>
						</div>
						<div class="col-2 text-center">{{ data.score }}</div>
						<div class="col-5 text-center d-flex">
							<div>
								<v-img
									:src="data.imageCompetitor"
									width="100"
									height="50"
									contain
								/>
							</div>
							<div>
								<p class="mb-0 pt-0">{{ data.nameCompetitor }}</p>
								<p
									:class="
										data.resultsTeam[language] === 'ชนะ' || data.resultsTeam[language] === 'Win'
											? 'text-success'
											: data.resultsTeam[language] === 'แพ้' || data.resultsTeam[language] === 'Lose'
											? 'text-danger'
											: 'text-body'
									"
								>
									{{ data.resultsTeam[language] }}
								</p>
							</div>
						</div>
					</div>

					<div>
						<label>{{ $t("team.team_remark_th") }}</label>
						<v-textarea
							auto-grow
							outlined
							rows="5"
							v-model="data.remark"
						></v-textarea>
					</div>
					<div>
						<label>{{ $t("team.team_remark_en") }}</label>
						<v-textarea
							auto-grow
							outlined
							rows="5"
							v-model="data.remark_en"
						></v-textarea>
					</div>
					<div class="form-group">
						<v-checkbox
							v-model="data.importantMatch"
							:label="$t('team.important_team')"
							:readonly="!isEditable"
							color="success"
							class=""
							hide-details
						>
						</v-checkbox>
					</div>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { appAxios } from "../../helpers/app-axios";
import { languageService } from "../../helpers/language"

export default {
	props: {
		dialog: {
			default: false,
			type: Boolean,
		},
		type: {
			default: "",
			type: String,
		},
		data: null,
	},
	created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
	methods: {
		closeModal() {
			this.$emit("update:dialog", false);
		},
		submit() {
			const updateRemark = {
				versus_match_team_detail_remark: this.data.remark,
				versus_match_team_detail_remark_en: this.data.remark_en,
				versus_match_team_detail_important: this.data.importantMatch,
			};
			console.log("submit", updateRemark,this.data);
			appAxios
				.post(
					`/team/${this.$route.params.id}/match/${
						this.data.id
					}/team-detail/${this.data.versus_match_id}/update`,
					updateRemark
				)
				.then(() => this.closeModal());
			this.$emit("commit", "success");
            // this.closeModal()
			// const body =
		},
	},
	computed: {
		isEditable() {
			return this.$store.state.permission.permissions.team_edit;
		},
		header() {
			return this.type === "major"
				?
				{
					en:"Outstanding Matches",
					th:"ประวัติการแข่งขันที่สำคัญ"
				}
				: {
					en:"All Competition History",
					th:"ประวัติแยกตามรายการแข่งขัน"
				}
		},
	},
	data(){
		return{
			language: "th",
		}
	}
};
</script>
