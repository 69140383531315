import jwtDecode from 'jwt-decode';
import { SAVE_PERMISSION, SAVE_ROLE } from '../../store/permission';
import store from '../../store';

export default (to, from, next) => {
  const token = localStorage.getItem(process.env.VUE_APP_TOKEN_KEY_ADMIN);
  if (token) {
    const decodedToken = jwtDecode(token);
    const permission = decodedToken?.account_role?.permission_menu;
    const role = decodedToken?.account_role?.permission_code;
    store.dispatch('setName', decodedToken?.name);
    store.dispatch('setPosition', decodedToken?.account_role.permission_name_th);
    store.dispatch(SAVE_ROLE, role);
    if (permission) {
      store.dispatch(SAVE_PERMISSION, permission);
    }
  }
  if (token || to.name === 'saveToken') {
    next()
  } else {
    window.location.href = process.env.VUE_APP_SSO_PATH;
  }
}