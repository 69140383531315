<template>
	<div class="row no-gutters p-md-3">
		<div class="col-md-4 col-xl-3 col-12">
			<div class="p-0">
				<div class="m-md-2">
					<!-- <div class=""> -->
					<v-img
						src="@/assets/images/logoasso.png"
						class="profileImage d-flex justify-content-center center p-1"
						contain
					/>

					<div class="mb-2">
						<h4 class="text-center">
							{{
								associationForm.global_config_association_information_name_th
							}}
						</h4>
					</div>
				</div>
				<div class="border-top py-10 px-3" style="overflow-x:auto">
					<!-- <div class="border-top m-md-2 py-2"> -->
					<div class="text-center h4 my-3">
						ช่องทางการติดต่อ
						<v-icon
							@click="onClickAsscoationSocialContact"
							style="font-size: 1rem"
							class="ml-2 cursor-pointer"
							>fa-edit</v-icon
						>
					</div>
					<div class="row">
						<div class="col-4">โทรศัพท์</div>
						<div class="col-8">
							{{
								AssociationSocialContactForm.global_config_association_social_contact_phone ||
									"-"
							}}
						</div>
					</div>
					<div class="row">
						<div class="col-4">E-mail</div>
						<div class="col-8">
							{{
								AssociationSocialContactForm.global_config_association_social_contact_email ||
									"-"
							}}
						</div>
					</div>
					<div class="row">
						<div class="col-4">Facebook</div>
						<div class="col-8">
							{{
								AssociationSocialContactForm.global_config_association_social_contact_facebook ||
									"-"
							}}
						</div>
					</div>
					<div class="row">
						<div class="col-4">LINE id</div>
						<div class="col-8">
							{{
								AssociationSocialContactForm.global_config_association_social_contact_line ||
									"-"
							}}
						</div>
					</div>
					<div class="row">
						<div class="col-4">Instagram</div>
						<div class="col-8">
							{{
								AssociationSocialContactForm.global_config_association_social_contact_instagram ||
									"-"
							}}
						</div>
					</div>
					<div class="row">
						<div class="col-4">Youtube</div>
						<div class="col-8">
							{{
								AssociationSocialContactForm.global_config_association_social_contact_youtube ||
									"-"
							}}
						</div>
					</div>
					<div class="row">
						<div class="col-4">twitter</div>
						<div class="col-8">
							{{
								AssociationSocialContactForm.global_config_association_social_contact_twitter ||
									"-"
							}}
						</div>
					</div>
					<div class="row">
						<div class="col-4">Website</div>
						<div class="col-8">
							{{
								AssociationSocialContactForm.global_config_association_social_contact_website ||
									"-"
							}}
						</div>
					</div>
					<div class="row">
						<div class="col-4">Fax</div>
						<div class="col-8">
							{{
								AssociationSocialContactForm.global_config_association_social_contact_fax ||
									"-"
							}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-8 col-12 tab1-border-left">
			<!-- ข้อมูลสนามกีฬา -->
			<v-form ref="formRef">
				<div>
					<div class="border-bottom p-2">
						<div>
							<h4 class="text-primary">ข้อมูลองค์กร</h4>
						</div>
						<div>
							<div class="row">
								<div class="col-sm-6 col-12">
									<div class="form-group">
										<label class="form-label" for="basic-addon-name"
											>ชื่อองค์กร(ภาษาไทย)</label
										>
										<v-text-field
											:rules="ruleForm"
											:readonly="!isEditable"
											v-model="
												associationForm.global_config_association_information_name_th
											"
											dense
											outlined
											placeholder="การกีฬาแห่งประเทศไทย"
										></v-text-field>
									</div>
								</div>
								<div class="col-sm-6 col-12">
									<div class="form-group">
										<label class="form-label" for="basic-addon-name"
											>ชื่อองค์กร(ภาษาอังกฤษ)</label
										>
										<v-text-field
											:rules="ruleForm"
											:readonly="!isEditable"
											v-model="
												associationForm.global_config_association_information_name_en
											"
											dense
											outlined
											placeholder="Sports Authority of Thailand"
										></v-text-field>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6 col-12">
									<div class="form-group">
										<label class="form-label" for="basic-addon-name"
											>ชื่อย่อ (ภาษาไทย)</label
										>
										<v-text-field
											:rules="ruleForm"
											:readonly="!isEditable"
											v-model="
												associationForm.global_config_association_information_abbreviation_th
											"
											dense
											outlined
											placeholder="กกท."
										></v-text-field>
									</div>
								</div>
								<div class="col-sm-6 col-12">
									<div class="form-group">
										<label class="form-label" for="basic-addon-name"
											>ชื่อย่อ (ภาษาอังกฤษ)</label
										>
										<v-text-field
											:rules="ruleForm"
											:readonly="!isEditable"
											v-model="
												associationForm.global_config_association_information_abbreviation_en
											"
											dense
											outlined
											placeholder="SAT."
										></v-text-field>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- ที่อยู่องค์กร -->
					<div class="p-2 border-bottom">
						<div>
							<h4 class="text-primary">ที่อยู่องค์กร</h4>
						</div>
						<div>
							<form class="needs-validation" novalidate="">
								<div class="row">
									<div class="col-sm-12 col-12">
										<div class="form-group">
											<label class="form-label" for="basic-addon-name"
												>ที่อยู่ (ภาษาไทย)</label
											>
											<v-text-field
												:readonly="!isEditable"
												v-model="
													associationForm.global_config_association_regions
														.global_config_association_region_address_detail
												"
												dense
												outlined
												hide-details
												placeholder="11/12 หมู่บ้านสุขใจ ซอบ11"
											></v-text-field>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-12 col-12">
										<div class="form-group">
											<label class="form-label" for="basic-addon-name"
												>ที่อยู่ (ภาษาอังกฤษ)</label
											>
											<v-text-field
												:readonly="!isEditable"
												v-model="
													associationForm.global_config_association_regions
														.global_config_association_region_address_detail_en
												"
												dense
												outlined
												hide-details
												placeholder="11/12 หมู่บ้านสุขใจ ซอบ11"
											></v-text-field>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-12 col-12">
										<div class="form-group">
											<label class="form-label" for="basic-addon-name"
												>ที่อยู่(ภาษาอังกฤษ)</label
											>
											<v-text-field
												:readonly="!isEditable"
												v-model="
													associationForm.global_config_association_regions
														.global_config_association_region_address_detail_en
												"
												dense
												outlined
												hide-details
												placeholder="11/12 หมู่บ้านสุขใจ ซอบ11"
											></v-text-field>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-6 col-12">
										<div class="form-group">
											<label class="form-label" for="basic-addon-name"
												>ประเทศ</label
											>
											<v-autocomplete
												:readonly="!isEditable"
												v-model="
													associationForm.global_config_association_regions
														.global_config_association_region_country_id
												"
												:placeholder="$t('normal.please_select')"
												:items="countries"
												item-text="country_native1_name_th"
												item-value="country_id"
												:loading="isCountriesFetching"
												dense
												outlined
												hide-details
											></v-autocomplete>
										</div>
									</div>
									<div class="col-sm-6 col-12">
										<div class="form-group">
											<label class="form-label" for="basic-addon-name"
												>จังหวัด</label
											>
											<v-autocomplete
												:readonly="!isEditable"
												v-model="
													associationForm.global_config_association_regions
														.global_config_association_region_province_id
												"
												:items="provinces"
												item-text="province_name_th"
												item-value="id"
												:loading="isProvincesFetching"
												:disabled="
													associationForm.global_config_association_regions
														.global_config_association_region_country_id !==
														'188'
												"
												dense
												outlined
												hide-details
												:placeholder="$t('normal.please_select')"
											></v-autocomplete>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-3 col-12">
										<div class="form-group">
											<label class="form-label" for="basic-addon-name"
												>อำเภอ</label
											>
											<v-autocomplete
												:readonly="!isEditable"
												v-model="
													associationForm.global_config_association_regions
														.global_config_association_region_district_id
												"
												:items="districts"
												item-value="id"
												item-text="district_name_th"
												:loading="isDistrictFetching"
												:disabled="
													!associationForm.global_config_association_regions
														.global_config_association_region_province_id
												"
												dense
												outlined
												hide-details
												:placeholder="$t('normal.please_select')"
											></v-autocomplete>
										</div>
									</div>
									<div class="col-sm-3 col-12">
										<div class="form-group">
											<label class="form-label" for="basic-addon-name"
												>ตำบล</label
											>
											<v-autocomplete
												:readonly="!isEditable"
												v-model="
													associationForm.global_config_association_regions
														.global_config_association_region_subdistrict_id
												"
												:disabled="
													!associationForm.global_config_association_regions
														.global_config_association_region_district_id
												"
												:items="subdistricts"
												:loading="isSubdistrictFetching"
												item-text="sub_district_name_th"
												item-value="id"
												dense
												outlined
												hide-details
												:placeholder="$t('normal.please_select')"
											></v-autocomplete>
										</div>
									</div>
									<div class="col-sm-6 col-12">
										<div class="form-group">
											<label class="form-label" for="basic-addon-name"
												>รหัสไปรษณีย์</label
											>
											<v-text-field
												:readonly="!isEditable"
												:disabled="
													!associationForm.global_config_association_regions
														.global_config_association_region_zipcode
												"
												v-model="
													associationForm.global_config_association_regions
														.global_config_association_region_zipcode
												"
												dense
												outlined
												hide-details
												placeholder="10000"
											></v-text-field>
											<!-- <v-text-field  v-model="associationForm.global_config_association_region_zipcode" dense outlined hide-details placeholder="รหัสไปรษณีย์"></v-text-field> -->
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
					<!-- ประวัติ-->
					<div class="p-2">
						<div>
							<h4 class="text-primary">ประวัติ</h4>
						</div>
						<div>
							<form class="needs-validation" novalidate="">
								<div class="row">
									<div class="col-12">
										<div class="form-group">
											<label class="form-label" for="basic-addon-name"
												>ประวัติ (ภาษาไทย)
											</label>
											<vue-editor
												v-model="
													associationForm.global_config_association_information_history_th
												"
												:editorToolbar="customToolbar"
												:disabled="!isEditable"
											></vue-editor>
										</div>
									</div>
									<div class="col-12">
										<div class="form-group">
											<label class="form-label" for="basic-addon-name"
												>ประวัติ (ภาษาอังกฤษ)
											</label>
											<vue-editor
												v-model="
													associationForm.global_config_association_information_history_en
												"
												:editorToolbar="customToolbar"
												:disabled="!isEditable"
											></vue-editor>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</v-form>
		</div>
		<AssociationSocialContactModal
			:associationContact="AssociationSocialContactForm"
			@commit="onCommitAssociationContact"
			:dialog.sync="showAssociationSocialContactModal"
		/>
	</div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { mapState } from "vuex";
import { appAxios } from "../../helpers/app-axios";
import {
	countriesState,
	getCountries,
} from "../../store/global_config_country";
import {
	provincesState,
	getProvinces,
} from "../../store/global_config_province";
import { getDistrict } from "../../store/global_config_district";
import { getSubdistrict } from "../../store/global_config_subdistrict";

import AssociationSocialContactModal from "./AssociationSocialContactModal";

export default {
	components: {
		VueEditor,
		AssociationSocialContactModal,
	},
	name: "association-tab",
	props: {
		tab: String,
	},
	mounted() {
		appAxios.get(`/association`).then((res) => {
			const data = res.data.data.association;
			if (data.length > 0) {
				this.parseAssociationForm(data, true);
			}
			return data;
		});
		this.$store.dispatch(getCountries);
		this.$store.dispatch(getProvinces);

		if (
			this.associationForm.global_config_association_regions
				.global_config_association_region_province_id
		) {
			this.$store.dispatch(
				getDistrict,
				this.associationForm.global_config_association_regions
					.global_config_association_region_province_id
			);
		}

		if (
			this.associationForm.global_config_association_regions
				.global_config_association_region_district_id
		) {
			this.$store.dispatch(
				getSubdistrict,
				this.associationForm.global_config_association_regions
					.global_config_association_region_district_id
			);
		}
	},
	data() {
		return {
			customToolbar: [
				[{ size: [] }],
				["bold", "italic", "underline", "strike"],
				[
					{ align: "" },
					{ align: "center" },
					{ align: "right" },
					{ align: "justify" },
				],
				["blockquote", "code-block"],
				[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
				[{ indent: "-1" }, { indent: "+1" }],
				[{ color: [] }, { background: [] }],
				["link"],
			],
			id: "",
			action: "",
			showAssociationSocialContactModal: false,
			dialog1: false,
			associationId: null,
			associationForm: {
				global_config_association_information_name_th: null,
				global_config_association_information_name_en: null,
				global_config_association_information_abbreviation_th: null,
				global_config_association_information_abbreviation_en: null,
				global_config_association_regions: {
					global_config_association_region_country_id: "188",
					global_config_association_region_address_detail: null,
					global_config_association_region_address_detail_en:null,
					global_config_association_region_province_id: null,
					global_config_association_region_district_id: null,
					global_config_association_region_subdistrict_id: null,
					global_config_association_region_zipcode: null,
				},
				global_config_association_information_history_th: null,
				global_config_association_information_history_en: null,
			},
			AssociationSocialContactForm: {
				// id: null,
				global_config_association_social_contact_phone: null,
				global_config_association_social_contact_email: null,
				global_config_association_social_contact_facebook: null,
				global_config_association_social_contact_line: null,
				global_config_association_social_contact_instagram: null,
				global_config_association_social_contact_twitter: null,
				global_config_association_social_contact_youtube: null,
				global_config_association_social_contact_website: null,
				global_config_association_social_contact_fax: null,
			},
		};
	},
	methods: {
		async save() {
			if (!this.isEditable) return;
			const result = this.$refs.formRef.validate();
			if (!result) return;
			const associationId = await this.saveAssociation();

			await this.saveAssociationSocialContact(associationId);
			appAxios.get(`/association`).then((res) => {
				const data = res.data.data.association;
				if (data.length > 0) {
					this.parseAssociationForm(data, false);
				}
				return data;
			});
			// this.getAssociationSocialContact(associationId);
		},
		async saveAssociation() {
			const body = {
				...this.$data.associationForm,
			};
			const dataAssociation = await appAxios.get(`/association`).then((res) => {
				const data = res.data.data.association;
				if (data.length > 0) {
					this.parseAssociationForm(data, false);
				}
				return data;
			});
			if (dataAssociation[0]?.id) {
				delete body.associationId;
				return await appAxios
					.post(`association/${dataAssociation[0].id}/update`, body)
					.then((res) => {
						return res.data?.data?.association.id;
					});
			} else if (dataAssociation.length === 0) {
				delete body.global_config_association_published;
				return await appAxios.post("association/create", body).then((res) => {
					return res.data?.data?.association.id;
				});
			}
		},
		async saveAssociationSocialContact(associationId) {
			const body = this.$data.AssociationSocialContactForm;
			delete body.socialContactId;
			appAxios
				.post(
					`association-social-contact/${associationId}/social-contacts`,
					body
				)
				.then((res) => res);
		},
		onCommitAssociationContact(value) {
			this.$data.AssociationSocialContactForm = value;
			this.closeModal();
		},
		onClickAsscoationSocialContact(e) {
			e.preventDefault();
			this.$data.showAssociationSocialContactModal = true;
		},
		closeModal() {
			this.$data.showAssociationSocialContactModal = false;
		},
		parseAssociationForm(data, checkSocial) {
			this.$data.associationForm = {
				associationId: data[0].id,
				global_config_association_information_name_th:
					data[0].global_config_association_informations
						.global_config_association_information_name_th,
				global_config_association_information_name_en:
					data[0].global_config_association_informations
						.global_config_association_information_name_en,
				global_config_association_information_abbreviation_th:
					data[0].global_config_association_informations
						.global_config_association_information_abbreviation_th,
				global_config_association_information_abbreviation_en:
					data[0].global_config_association_informations
						.global_config_association_information_abbreviation_en,
				global_config_association_regions: {
					global_config_association_region_country_id: "188",
					global_config_association_region_address_detail:
						data[0].global_config_association_regions
							?.global_config_association_region_address_detail,
					global_config_association_region_address_detail_en:
						data[0].global_config_association_regions
							?.global_config_association_region_address_detail_en,
					global_config_association_region_province_id:
						data[0].global_config_association_regions
							?.global_config_association_region_province_id?.id,
					global_config_association_region_district_id:
						data[0].global_config_association_regions
							?.global_config_association_region_district_id?.id,
					global_config_association_region_subdistrict_id:
						data[0].global_config_association_regions
							?.global_config_association_region_subdistrict_id?.id,
					global_config_association_region_zipcode:
						data[0].global_config_association_regions
							?.global_config_association_region_zipcode,
				},
				global_config_association_information_history_th:
					data[0].global_config_association_informations
						.global_config_association_information_history_th,
				global_config_association_information_history_en:
					data[0].global_config_association_informations
						.global_config_association_information_history_en,
				global_config_association_published: true,
			};
			if (checkSocial) {
				this.getAssociationSocialContact(
					this.$data.associationForm.associationId
				);
			}
		},
		getAssociationSocialContact(data) {
			appAxios
				.get(`association-social-contact/${data}/social-contact`)
				.then((res) => {
					const results = res.data.data.social_contacts;
					this.parseAssociationSocialContact(results);
				});
		},

		parseAssociationSocialContact(data) {
			this.$data.AssociationSocialContactForm = {
				socialContactId: data?.id,
				global_config_association_social_contact_phone:
					data?.global_config_association_social_contact_phone,
				global_config_association_social_contact_email:
					data?.global_config_association_social_contact_email,
				global_config_association_social_contact_facebook:
					data?.global_config_association_social_contact_facebook,
				global_config_association_social_contact_line:
					data?.global_config_association_social_contact_line,
				global_config_association_social_contact_instagram:
					data?.global_config_association_social_contact_instagram,
				global_config_association_social_contact_twitter:
					data?.global_config_association_social_contact_twitter,
				global_config_association_social_contact_youtube:
					data?.global_config_association_social_contact_youtube,
				global_config_association_social_contact_website:
					data?.global_config_association_social_contact_website,
				global_config_association_social_contact_fax:
					data?.global_config_association_social_contact_fax,
			};
		},
	},
	computed: {
		...mapState({
			isCountriesFetching: (state) => state.globalConfigCountry.isFetching,
			countries: (state) => state.globalConfigCountry[countriesState],
			isProvincesFetching: (state) => state.globalConfigProvince.isFetching,
			provinces: (state) => state.globalConfigProvince[provincesState],
			isDistrictFetching(state) {
				state.globalConfigDistrict.isFetching; // to let vue know when should trigger state change
				return state.globalConfigDistrict[
					this.associationForm.global_config_association_regions
						.global_config_association_region_province_id
				]?.isFetching;
			},
			districts(state) {
				state.globalConfigDistrict.isFetching;
				return state.globalConfigDistrict[
					this.associationForm.global_config_association_regions
						.global_config_association_region_province_id
					// this.address.province_id
				]?.data;
			},
			isSubdistrictFetching(state) {
				state.globalConfigSubdistrict.isFetching;
				return state.globalConfigSubdistrict[
					this.associationForm.global_config_association_regions
						.global_config_association_region_district_id
				]?.isFetching;
			},
			subdistricts(state) {
				state.globalConfigSubdistrict.isFetching;
				return state.globalConfigSubdistrict[
					this.associationForm.global_config_association_regions
						.global_config_association_region_district_id
				]?.data;
			},
			isEditable: (state) =>
				state.permission.permissions.application_management_edit,
		}),
		ruleForm() {
			const rules = [];
			// if (
			//   this.$data.accountForm.account_information_type_personnel === "THAI"
			// ) {
			rules.push((v) => !!v || `required`);
			// }
			return rules;
		},
	},
	watch: {
		"associationForm.global_config_association_regions.global_config_association_region_country_id"() {
			if (
				this.associationForm.global_config_association_regions
					.global_config_association_region_country_id !== "188"
			) {
				this.associationForm.global_config_association_regions.global_config_association_region_province_id = null;
			} else {
				this.associationForm.global_config_association_regions.global_config_association_region_province_id = null;
				this.associationForm.global_config_association_regions.global_config_association_region_district_id = null;
				this.associationForm.global_config_association_regions.global_config_association_region_subdistrict_id = null;
				this.associationForm.global_config_association_regions.global_config_association_region_zipcode = null;
			}
		},
		"associationForm.global_config_association_regions.global_config_association_region_province_id"(
			newVal,
			old
		) {
			if (!newVal) {
				this.associationForm.global_config_association_regions.global_config_association_region_district_id = null;
				return;
			} else if (old) {
				this.associationForm.global_config_association_regions.global_config_association_region_district_id = null;
				this.associationForm.global_config_association_regions.global_config_association_region_subdistrict_id = null;
				this.associationForm.global_config_association_regions.global_config_association_region_zipcode = null;
			}
			this.$store.dispatch(getDistrict, newVal);
		},
		"associationForm.global_config_association_regions.global_config_association_region_district_id"(
			newVal,
			old
		) {
			if (!newVal) {
				this.associationForm.global_config_association_regions.global_config_association_region_subdistrict_id = null;
				return;
			} else if (old) {
				this.associationForm.global_config_association_regions.global_config_association_region_subdistrict_id = null;
				this.associationForm.global_config_association_regions.global_config_association_region_zipcode = null;
			}
			this.$store.dispatch(getSubdistrict, newVal);
		},
		"associationForm.global_config_association_regions.global_config_association_region_subdistrict_id"(
			newVal
		) {
			if (!newVal) {
				this.associationForm.global_config_association_regions.global_config_association_region_zipcode = null;
				return;
			}
			const matchSubdistrict = this.$store.state.globalConfigSubdistrict[
				this.associationForm.global_config_association_regions
					.global_config_association_region_district_id
			]?.data.find((ele) => ele.id == newVal);
			if (matchSubdistrict) {
				this.associationForm.global_config_association_regions.global_config_association_region_zipcode =
					matchSubdistrict.zipcode;
			}
		},
	},
};
</script>

<style scoped>
.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}

.v-input--selection-controls {
	margin-top: 0 !important;
	padding-top: 0 !important;
}

.active-radio .v-icon {
	color: red;
}
.article {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}
.download_image {
	float: right;
	margin: 0 0 0 0;
	bottom: 23px;
}

div.required label:after {
	content: " *";
	color: red;
}

@media (min-width: 960px) {
	.tab1-border-left {
		border-left: 1px solid #ebe9f1 !important;
	}
}
.profileImage {
	height: 200px;
	width: 200px;
	object-fit: cover;
}
</style>
