<template>
    <div>
    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
    <footer class="footer footer-static footer-light">
        <p class="clearfix mb-0"><span class="float-md-left d-block d-md-inline-block mt-25">COPYRIGHT &copy; 2021<a class="ml-25" href="https://da.co.th/" target="_blank">DA</a><span class="d-none d-sm-inline-block">, All rights Reserved</span></span></p>
    </footer>
    </div>
</template>
<script>

export default {

}
</script>

<style>

</style>