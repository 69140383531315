<template>
  <div class="row justify-content-center">
    <div class="col-md-12 col-12 col-xl-10">
      <div>
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <h4 class="card-title text-primary">
              {{ $t("player.penalty.current_penalty") }}
            </h4>
            <v-btn
              @click="isShowModal = true"
              elevation="2"
              class="btn-primary"
            >
              <v-icon left> fa-plus </v-icon>
              {{ $t("player.penalty.add_penalty_button") }}
            </v-btn>
          </div>
          <v-data-table
            hide-default-footer
            :items="currentPenalties"
            :headers="headderPenalty[language]"
            :items-per-page="currentPerPage"
          >
            <template v-slot:item="row">
              <tr>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.account_penalty_title_th || "-"
                  }}</label
                  ><label v-else>{{
                    row.item.account_penalty_title_en || "-"
                  }}</label>
                </td>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.account_penalty_competition_name || "-"
                  }}</label
                  ><label v-else>{{
                    row.item.account_penalty_competition_name_en || "-"
                  }}</label>
                </td>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.account_penalty_competition_match || "-"
                  }}</label
                  ><label v-else>{{
                    row.item.account_penalty_competition_match_en || "-"
                  }}</label>
                </td>
                <td>{{ row.item.date || "-" }}</td>
                <td>
                  {{ row.item.duration.number || "-" }}
                  {{ getUnit(row.item.duration.unit)[language] }}
                </td>
                <td>{{ row.item.appeal[language] || "-" }}</td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list dark>
                      <v-list-item
                        class="cursor-pointer"
                        @click="() => onEditPenalty(row.item)"
                      >
                        {{ $t("normal.edit") }}
                      </v-list-item>
                      <v-list-item
                        class="cursor-pointer"
                        @click="() => deletePenalty(row.item.id)"
                      >
                        {{ $t("normal.delete") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
            <template v-slot:footer="{}">
              <TableFooter
                :total="currentTotal"
                :perPage.sync="currentPerPage"
                :lastPage="currentLastPage"
                :currentPage.sync="currentPage"
              />
            </template>
          </v-data-table>
        </div>

        <div class="card-body">
          <div>
            <h4 class="card-title text-primary">
              {{ $t("player.penalty.history_penalty") }}
            </h4>
          </div>
          <v-data-table
            hide-default-footer
            :items="penalties"
            :headers="headderPenalty[language]"
            :items-per-page="penaltiesPerPage"
          >
            <template v-slot:item="row">
              <tr>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.account_penalty_title_th || "-"
                  }}</label
                  ><label v-else>{{
                    row.item.account_penalty_title_en || "-"
                  }}</label>
                </td>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.account_penalty_competition_name || "-"
                  }}</label
                  ><label v-else>{{
                    row.item.account_penalty_competition_name_en || "-"
                  }}</label>
                </td>
                <td>
                  <label v-if="language === 'th'">{{
                    row.item.account_penalty_competition_match || "-"
                  }}</label
                  ><label v-else>{{
                    row.item.account_penalty_competition_match_en || "-"
                  }}</label>
                </td>
                <td>{{ row.item.date || "-" }}</td>
                <td>
                  {{ row.item.duration.number || "-" }}
                  {{ getUnit(row.item.duration.unit)[language] }}
                </td>
                <td>{{ row.item.appeal[language] || "-" }}</td>
                <td>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list dark>
                      <v-list-item
                        class="cursor-pointer"
                        @click="() => onEditPenalty(row.item)"
                      >
                        {{ $t("normal.edit") }}
                      </v-list-item>
                      <v-list-item
                        class="cursor-pointer"
                        @click="() => deletePenalty(row.item.id)"
                      >
                        {{ $t("normal.delete_list") }}
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <!-- <v-icon>
                                                          fa-ellipsis-v
                                                    </v-icon> -->
                </td>
              </tr>
            </template>
            <template v-slot:footer="{}">
              <TableFooter
                :perPage.sync="penaltiesPerPage"
                :currentPage.sync="penaltiesPage"
                :lastPage="penaltiesLastPage"
                :total="penaltiesTotal"
              />
            </template>
          </v-data-table>
        </div>
      </div>
      <v-btn
        elevation="2"
        class="btn-primary float-right mb-5"
        v-on:click="nextTab()"
      >
        {{ $t("normal.next") }} <v-icon right> fa-arrow-right </v-icon>
      </v-btn>
      <v-btn
        elevation="2"
        class="btn-primary float-left mb-5"
        v-on:click="backTab()"
      >
        <v-icon left> fa-arrow-left </v-icon> {{ $t("normal.previous") }}
      </v-btn>
    </div>
    <template v-if="isShowModal">
      <PenaltyModal
        :penalty.sync="editPenalty"
        @save="getData"
        :visible.sync="isShowModal"
      />
    </template>
  </div>
</template>
<script>
import { appAxios } from "../../helpers/app-axios";
import TableFooter from "../TableFooter.vue";
import PenaltyModal from "./PenaltyModal.vue";
import { format } from "date-fns";
import { languageService } from "../../helpers/language";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  components: {
    TableFooter,
    PenaltyModal,
  },
  name: "player-tabs6",
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  mounted() {
    this.getData();
  },
  methods: {
    test: function () {
      alert("test");
    },
    nextTab() {
      this.$router.push({ path: "", query: { tab: 6 } });
      //console.log(this.tabs);
    },
    backTab() {
      this.$router.push({ path: "", query: { tab: 4 } });
      //console.log(this.tabs);
    },
    getShowDate(date) {
      if (!date) return null;
      return format(new Date(date), "dd/MM/yyyy");
    },
    getUnit(key) {
      switch (key) {
        case "DAYS":
          return { en: "Days", th: "วัน" };
        case "WEEKS":
          return { en: "Weeks", th: "สัปดาห์" };
        case "MONTHS":
          return { en: "Months", th: "เดือน" };
        case "YEARS":
          return { en: "Years", th: "ปี" };
        case "MATCHES":
          return { en: "Matches", th: "แมทช์" };
        case "SEASONS":
          return { en: "Seasons", th: "ฤดูกาล" };
        default:
          return { en: "", th: "" };
      }
    },
    getData() {
      this.getCurrentPenalties();
      this.getPenalties();
    },
    formatDuration(data) {
      console.log("data", data);
    },
    getCurrentPenalties() {
      appAxios
        .get(`account/${this.accountId}/penalties/current`, {
          params: this.currentPenlatiesParams,
        })
        .then((res) => {
          const data = res.data?.data;
          if (!data) return;
          this.currentPenalties = data.data.map((ele) => ({
            ...ele,
            date: this.getShowDate(ele.account_penalty_date),
            // duration: `${ele.account_penalty_duration} ${
            //   this.getUnit(ele.account_penalty_duration_unit)[this.language]
            // }`,
            duration: {
              number: ele.account_penalty_duration,
              unit: ele.account_penalty_duration_unit,
            },
            appeal: ele.account_penalty_appeal_begin
              ? {
                  en: "Appealing",
                  th: "มีการยื่นอุทธรณ์",
                }
              : {
                  en: "No Appealing",
                  th: "ไม่มีการยื่นอุทธรณ์",
                },
          }));
          this.currentTotal = data.total;
          this.currentLastPage = data.lastPage;
        });
    },
    getPenalties() {
      appAxios
        .get(`account/${this.accountId}/penalties`, {
          params: this.penaltiesParams,
        })
        .then((res) => {
          const data = res.data?.data;
          if (!data) return;
          this.penalties = data.data.map((ele) => ({
            ...ele,
            date: this.getShowDate(ele.account_penalty_date),
            duration: {
              number: ele.account_penalty_duration,
              unit: ele.account_penalty_duration_unit,
            },
            appeal: ele.account_penalty_appeal_begin
              ? {
                  en: "Appealing",
                  th: "มีการยื่นอุทธรณ์",
                }
              : {
                  en: "No Appealing",
                  th: "ไม่มีการยื่นอุทธรณ์",
                },
          }));
          this.penaltiesTotal = data.total;
          this.penaltiesLastPage = data.lastPage;
        });
    },
    onEditPenalty(penalty) {
      this.editPenalty = penalty;
      this.isShowModal = true;
    },
    async deletePenalty(penaltyId) {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            appAxios
              .post(`account/${this.accountId}/penalties/${penaltyId}/delete`)
              .then(() => {
                this.getData();
              });
          }
        }
      );
    },
  },
  computed: {
    fitColor: function () {
      if (this.fit.value < 50) {
        return "red";
      } else {
        return "teal";
      }
    },
    accountId() {
      return Number(this.$route.params?.id);
    },
    currentPenlatiesParams() {
      return {
        perPage: this.currentPerPage,
        page: this.currentPage,
      };
    },
    penaltiesParams() {
      return {
        perPage: this.penaltiesPerPage,
        page: this.penaltiesPage,
      };
    },
  },
  data() {
    return {
      isShowModal: false,
      headderPenalty: {
        en: [
          { text: "Penalty Name", value: "name", sortable: false },
          { text: "Competition Name", value: "program", sortable: false },
          { text: "Match", value: "round", sortable: false },
          { text: "Date of Penalty", value: "start", sortable: false },
          { text: "Punishment Duration", value: "time", sortable: false },
          { text: "Appealing", value: "appeal", sortable: false },
          { text: "Action", value: "edit", sortable: false },
        ],
        th: [
          { text: "ชื่อบทลงโทษ", value: "name", sortable: false },
          { text: "รายการแข่งขัน", value: "program", sortable: false },
          { text: "รอบการแข่งขัน", value: "round", sortable: false },
          { text: "วันที่โดนบทลงโทษ", value: "start", sortable: false },
          { text: "ระยะเวลาที่โดนลงโทษ", value: "time", sortable: false },
          { text: "การยื่นอุทธรณ์", value: "appeal", sortable: false },
          { text: "จัดการ", value: "edit", sortable: false },
        ],
      },
      penalties: [],
      penaltiesTotal: 0,
      penaltiesPerPage: 10,
      penaltiesPage: 1,
      penaltiesLastPage: 1,
      currentPenalties: [],
      currentTotal: 0,
      currentLastPage: 1,
      currentPerPage: 10,
      currentPage: 1,
      editPenalty: null,
      language: "th",
      //ex3: { label: 'ความพร้อมนักกีฬา', val: this.fit.value, color: 'red' },
    };
  },
  watch: {
    penaltiesPerPage() {
      this.getPenalties();
    },
    penaltiesPage() {
      this.getPenalties();
    },
    currentPerPage() {
      this.getCurrentPenalties();
    },
    currentPage() {
      this.getCurrentPenalties();
    },
  },
};
</script>
