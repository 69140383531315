<template>
  <v-app>
    <div class="content app-content h-100">
      <div class="container">
        <h2>{{ $t("staff.all_staff") }}</h2>

        <div class="d-flex mb-10">
          <div class="rounded px-4 py-4 bg-white d-flex">
            <SummaryPerson
              :class="idx !== amountSummary.length - 1 ? 'mr-4' : ''"
              v-for="(item, idx) in amountSummary"
              :key="idx"
              :amount="item.amount"
              :label="language === 'en' ? item.label_en : item.label_th"
              :backgroundColor="item.backgroundColor"
              :icon="item.icon"
              :iconColor="item.iconColor"
              :extra="item.extra"
              :unit="language === 'en' ? item.unit_en : item.unit_th"
            />
          </div>
          <div class="rounded px-4 py-4 bg-white d-flex ml-2">
            <SummaryPerson
              :class="idx !== ageSummary.length - 1 ? 'mr-4' : ''"
              v-for="(item, idx) in ageSummary"
              :key="idx"
              :amount="item.amount"
              :label="language === 'en' ? item.label_en : item.label_th"
              :backgroundColor="item.backgroundColor"
              :icon="item.icon"
              :iconColor="item.iconColor"
              :extra="item.extra"
            />
          </div>
        </div>

        <div class="d-flex justify-content-between mb-3">
          <div class="d-flex">
            <div
              v-for="(item, idx) in listActions"
              :key="idx"
              class="
                mr-2
                cursor-pointer
                align-items-center
                d-flex
                select-amount
                rounded
              "
              @click="item.function()"
            >
              <div
                class="image-upload"
                v-if="idx === 0"
                @change="onChangeImage"
              >
                <label for="file-input">
                  <v-icon class="uploadImage">{{ item.icon }}</v-icon>
                </label>
                <input class="selectFile" id="file-input" type="file" />
              </div>
              <v-icon dark class="mx-3" v-else>{{ item.icon }}</v-icon>
              <span class="ml-1" v-if="language === 'en'">
                {{ item.label_en }}
              </span>
              <span class="ml-1" v-else>
                {{ item.label_th }}
              </span>
              <span v-if="idx === 0 && dataImport !== null" class="row ml-2">
                <v-img
                  src="@/assets/images/successImport.svg"
                  max-width="20"
                  class="mx-1"
                  @click="onModalSuccess"
                />
                <v-img
                  src="@/assets/images/Icon.svg"
                  max-width="20"
                  class="mx-1"
                  @click="onModalFail"
                />
              </span>
            </div>
            <div class="d-flex align-items-center rounded">
              {{ $t("normal.select") }} {{ selectPerson.length }}
              {{ $t("normal.list") }}
            </div>
          </div>
          <router-link v-if="isEditable" to="/staff/add">
            <v-btn color="primary">+ {{ $t("staff.add_staff") }}</v-btn>
          </router-link>
        </div>

        <v-data-table
          v-model="selectPerson"
          hide-default-footer
          show-select
          :headers="headers[language]"
          :items="accounts.data"
          :items-per-page="pageSize"
          item-key="account_id"
          @click:row="onRowClick"
        >
          <template
            v-slot:[`item.account_informations.account_information_code_personnel`]="{
              item,
            }"
          >
            {{ item.code || "-" }}
          </template>
          <template
            v-slot:[`item.account_informations.account_information_firstname_th`]="{
              item,
            }"
          >
            <div>
              {{
                item.firstname["th"]
                  ? item.firstname["th"] + " " + item.lastname["th"]
                  : "-"
              }}
            </div>
            <div>
              {{
                item.firstname["en"]
                  ? item.firstname["en"] + " " + item.lastname["en"]
                  : "-"
              }}
            </div>
          </template>
          <template v-slot:[`item.position`]="{ item }">
            {{ item.position[language] ? item.position[language] : "-" }}
          </template>
          <template v-slot:[`item.nationStatus`]="{ item }">
            {{
              item.team_national[language] ? item.team_national[language] : "-"
            }}
          </template>
          <template v-slot:[`item.currentTeam`]="{ item }">
            {{ item.team_club[language] ? item.team_club[language] : "-" }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>fa-ellipsis-v</v-icon>
                </v-btn>
              </template>
              <v-list dark>
                <v-list-item :to="'/staff/edit/' + item.account_id">
                  <v-icon x-small>fa-pen</v-icon>
                  <span class="ml-3">
                    {{ $t("normal.edit") }}
                  </span>
                </v-list-item>
                <v-list-item
                  class="cursor-pointer"
                  @click="removeSingleAccount(item.account_id)"
                  v-if="isEditable"
                >
                  <v-icon x-small>fa-trash</v-icon>
                  <span class="ml-3">
                    {{ $t("normal.delete_list") }}
                  </span>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:top="{}">
            <div
              class="d-flex justify-content-end align-items-center py-4 px-4"
            >
              {{ $t("normal.search") }}
              <div class="mx-3 search-box">
                <v-text-field
                  v-model="searchName"
                  dense
                  outlined
                  hide-details="true"
                  :placeholder="
                    $t('filter_player_staff.search_placeholder_staff')
                  "
                ></v-text-field>
              </div>
              <div>
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-icon x-small>fa-eye</v-icon>
                      <span class="mx-3">{{ $t("normal.display") }}</span>
                      <v-icon x-small>fa-chevron-down</v-icon>
                    </div>
                  </template>
                  <div class="p-1 bg-white">
                    <div>{{ $t("normal.filter") }}</div>
                    <v-form>
                      <div>{{ $t("filter_player_staff.age") }}</div>
                      <v-range-slider
                        v-model="range"
                        :max="100"
                        :min="0"
                        hide-details
                        class="align-center"
                      >
                        <template v-slot:prepend>
                          <v-text-field
                            dense
                            outlined
                            :value="range[0]"
                            class="mt-0 pt-0"
                            hide-details
                            type="number"
                            style="width: 60px"
                            @change="$set(range, 0, $event)"
                          ></v-text-field>
                        </template>
                        <template v-slot:append>
                          <v-text-field
                            dense
                            outlined
                            :value="range[1]"
                            class="mt-0 pt-0"
                            hide-details
                            type="number"
                            style="width: 60px"
                            @change="$set(range, 1, $event)"
                          ></v-text-field>
                        </template>
                      </v-range-slider>
                      <div>{{ $t("filter_player_staff.gender") }}</div>
                      <v-select
                        dense
                        :items="genderList"
                        item-value="value"
                        :item-text="'text_' + language"
                        v-model="gender"
                        outlined
                        hide-details="true"
                        clearable
                      ></v-select>
                      <div>{{ $t("filter_player_staff.status_team") }}</div>
                      <v-combobox
                        clearable
                        dense
                        outlined
                        :items="nationTeamList"
                        :search-input="searchNation"
                        :item-text="'text_' + language"
                        v-model="selectedNationTeam"
                        hide-details="true"
                      ></v-combobox>
                      <div>{{ $t("filter_player_staff.persent_team") }}</div>
                      <v-combobox
                        clearable
                        dense
                        outlined
                        :items="clubTeamList"
                        :search-input="searchClub"
                        :item-text="'text_' + language"
                        v-model="selectedClubTeam"
                        hide-details="true"
                      ></v-combobox>
                      <div>{{ $t("filter_player_staff.city") }}</div>
                      <v-select
                        clearable
                        dense
                        v-model="selectedProvince"
                        :items="provinces"
                        :item-text="'province_name_' + language"
                        item-value="id"
                        outlined
                        hide-details="true"
                      ></v-select>
                      <v-btn @click="getAll" color="primary" class="mt-3">{{
                        $t("normal.search")
                      }}</v-btn>
                    </v-form>
                  </div>
                </v-menu>
              </div>
            </div>
          </template>
          <template v-slot:footer="{}">
            <TableFooter
              :perPage.sync="pageSize"
              :currentPage.sync="currentPage"
              :lastPage="accounts.lastPage"
              :total="accounts.total"
            />
          </template>
        </v-data-table>
      </div>
    </div>
    <ImportModal
      :dialog.sync="showNotificationImportSuccessModal"
      :dataImport="dataSuccess"
      postion="staff"
      type="success"
    />
    <ImportModal
      :dialog.sync="showNotificationImportFailModal"
      :dataImport="dataFail"
      postion="staff"
      type="fail"
    />
  </v-app>
</template>

<script>
import SummaryPerson from "@/components/Player/SummaryPerson.vue";
import TableFooter from "../../components/TableFooter.vue";
import { appAxios } from "../../helpers/app-axios";
import { mapState } from "vuex";
import {
  provincesState,
  getProvinces,
} from "../../store/global_config_province";
import ImportModal from "../../components/Player/ImportModal.vue";
import { languageService } from "../../helpers/language";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  components: {
    SummaryPerson,
    TableFooter,
    ImportModal,
  },
  mounted: function () {
    this.getAll();
    this.$store.dispatch(getProvinces);
    this.getNationTeamList();
    this.getClubTeamList();
    this.getSummaryPlayer();
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  computed: {
    currentItem() {
      if (!this.accounts.currentPage) return "0 - 0";
      const currentItem =
        Number(this.accounts.currentPage - 1) * Number(this.accounts.perPage) +
        1;
      if (this.accounts.currentPage !== this.accounts.lastPage) {
        return `${currentItem} - ${
          currentItem + Number(this.accounts.perPage - 1)
        }`;
      } else {
        return `${currentItem} - ${Number(this.accounts.total)}`;
      }
    },
    ...mapState({
      provinces: (state) => state.globalConfigProvince[provincesState],
    }),
    params() {
      const params = {
        size: this.pageSize,
        page: this.currentPage,
        name: this.searchName,
      };
      if (this.range[1] !== 0) {
        params.age_min = this.range[0];
        params.age_max = this.range[1];
      }
      if (this.selectedNationTeam) {
        params.national = this.selectedNationTeam.value;
      }
      if (this.selectedClubTeam) {
        params.team_club = this.selectedClubTeam.value;
      }
      if (this.gender !== null) {
        params.gender = this.gender;
      }
      if (this.selectedProvince) {
        params.city_name = this.selectedProvince;
      }
      return params;
    },
    isEditable() {
      return this.$store.state.permission?.permissions?.staff_edit;
    },
    listActions() {
      const action = [
        // { label: "นำเข้า", icon: "fa-upload", fuction: null },
        // { label: "ดาวน์โหลด", icon: "fa-download", function: null },
      ];
      if (this.isEditable) {
        action.push(
          {
            label_th: this.$t("normal.import", "th"),
            label_en: this.$t("normal.import", "en"),
            icon: "$importIcon",
            function: this.importData,
          },
          {
            label_th: this.$t("normal.delete_select", "th"),
            label_en: this.$t("normal.delete_select", "en"),
            icon: "fa-trash",
            function: this.removeMultiple,
          }
        );
      }
      return action;
    },
  },
  methods: {
    search() {
      this.$data.searching = true;
      this.currentPage = 1;
      this.getAll();
    },
    onModalSuccess() {
      this.$data.showNotificationImportSuccessModal = true;
      this.$data.showNotificationImportFailModal = false;
      if (this.$data.dataImport?.import_success?.count !== null) {
        this.$data.dataSuccess = this.$data.dataImport?.import_success;
      }
    },
    onRowClick(row) {
      this.$router.push({ path: `/staff/edit/${row.account_id}` });
    },
    onModalFail() {
      this.$data.showNotificationImportFailModal = true;
      this.$data.showNotificationImportSuccessModal = false;

      if (this.$data.dataImport?.import_failed?.count !== null) {
        this.$data.dataFail = this.$data.dataImport?.import_failed;
      }
    },
    async getSummaryPlayer() {
      let average = [];
      let count = [];
      appAxios.get("account/count/staffs").then((res) => {
        const test = res.data.data.staff;

        for (const [key, value] of Object.entries(test)) {
          if (key.charAt(0) === "a") {
            average.push(value);
          } else {
            count.push(value);
          }
        }
        const informationPlayer = this.$data.amountSummary.map((p, index) => {
          return {
            ...p,
            amount: count[index * 2],
            extra: "+" + count[index * 2 + 1],
          };
        });
        this.$data.amountSummary = informationPlayer;

        const informationAgePlayer = this.$data.ageSummary.map((p, index) => {
          return {
            ...p,
            amount: average[index],
          };
        });
        this.$data.ageSummary = informationAgePlayer;
      });
    },
    async importData(e) {
      if (!e) return;
      this.file = e.target.files ? e.target.files[0] : null;
      let formData = new FormData();
      formData.append("file", this.file);
      appAxios.post(`account/import/excel/staffs`, formData).then((res) => {
        if (res?.data?.success) {
          this.$data.dataImport = res?.data?.data?.account;
          this.getAll();
        }
      });
    },
    onChangeImage(e) {
      const file = e.target.files[0];
      this.$data.item.preview = e;
      this.$data.item.imageUrl = URL.createObjectURL(file);
      this.importData(e);
    },
    async getAll() {
      if (
        (this.params.gender === 0 ||
          this.params?.gender ||
          this.params?.national ||
          this.params?.team_club ||
          this.params?.age_min ||
          this.params?.age_max ||
          this.params?.city_name) &&
        this.$data.searching
      ) {
        this.params["page"] = 1;
      }
      await appAxios
        .get("account/personels/staff", {
          params: this.params,
        })
        .then((response) => {
          const objData = response.data?.data?.account.data.map((staff) => {
            return {
              account_id: staff.account_id,
              code: staff.account_informations
                .account_information_code_personnel,
              firstname: {
                th:
                  staff?.account_informations
                    ?.account_information_firstname_th || "",
                en:
                  staff?.account_informations
                    ?.account_information_firstname_en || "",
              },
              lastname: {
                th:
                  staff?.account_informations
                    ?.account_information_lastname_th || "",
                en:
                  staff?.account_informations
                    ?.account_information_lastname_en || "",
              },
              position: {
                th: staff?.account_positions?.global_config_position_name_th,
                en: staff?.account_positions?.global_config_position_name_en,
              },
              team_national: {
                th: staff?.team_national?.setting_team_information_name_th,
                en: staff?.team_national?.setting_team_information_name_en,
              },
              team_club: {
                th: staff?.team_club?.setting_team_information_name_th,
                en: staff?.team_club?.setting_team_information_name_en,
              },
            };
          });
          const data = {
            currentPage: response.data?.data?.account.currentPage,
            data: objData,
            lastPage: response.data?.data?.account.lastPage,
            perPage: response.data?.data?.account.perPage,
            total: response.data?.data?.account.total,
          };

          this.$data.accounts = data || [];
        });
    },
    async removeMultiple() {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            await Promise.all(
              this.selectPerson.map((ele) => {
                return this.removeAccount(ele.account_id);
              })
            );
            this.getAll();
            this.getSummaryPlayer();
            this.selectPerson = [];
          }
        }
      );
    },
    async removeSingleAccount(accountId) {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            this.removeAccount(accountId).then(() => {
              this.getAll();
              this.getSummaryPlayer();
            });
          }
        }
      );
      // this.removeAccount(accountId).then(() => {
      // 	this.getAll();
      // 	this.getSummaryPlayer();
      // });
    },
    async removeAccount(accountId) {
      return appAxios.post(`account/${accountId}/delete`).then((res) => res);
    },
    async getNationTeamList() {
      appAxios
        .get("team/choose", {
          params: {
            name: this.searchNation,
            team_national: true,
            team_club: false,
          },
        })
        .then((res) => {
          const data = res.data.data?.team;
          if (!data) return;
          this.nationTeamList = data.map((ele) => ({
            text_th:
              ele?.setting_team_informations?.setting_team_information_name_th,
            text_en:
              ele?.setting_team_informations?.setting_team_information_name_en,
            value: ele?.id,
          }));
        });
    },
    async getClubTeamList() {
      appAxios
        .get("team/choose", {
          params: {
            name: this.searchClub,
            team_national: false,
            team_club: true,
          },
        })
        .then((res) => {
          const data = res.data.data?.team;
          if (!data) return;
          this.clubTeamList = data.map((ele) => ({
            text_th:
              ele.setting_team_informations?.setting_team_information_name_th,
            text_en:
              ele.setting_team_informations?.setting_team_information_name_en,
            value: ele.id,
          }));
        });
    },
  },
  data: function () {
    return {
      language: "th",
      searching: false,
      showNotificationImportSuccessModal: false,
      showNotificationImportFailModal: false,
      dataImport: null,
      dataSuccess: null,
      dataFail: null,
      item: {
        preview: null,
        imageUrl: null,
      },
      genderList: [
        {
          value: 0,
          text_en: this.$t("normal.male", "en"),
          text_th: this.$t("normal.male", "th"),
        },
        {
          value: 1,
          text_en: this.$t("normal.female", "en"),
          text_th: this.$t("normal.female", "th"),
        },
        {
          value: 2,
          text_en: this.$t("normal.transgender", "en"),
          text_th: this.$t("normal.transgender", "th"),
        },
      ],
      amountSummary: [
        {
          amount: null,
          label_th: this.$t("staff.all_staff", "th"),
          label_en: this.$t("staff.all_staff", "en"),
          backgroundColor: "rgba(40, 199, 111, 0.12)",
          icon: "fa-arrow-up",
          iconColor: "#6FCF97",
          extra: null,
          unit: "คน",
        },
        {
          amount: null,
          label_th: this.$t("staff.men_staff", "th"),
          label_en: this.$t("staff.men_staff", "en"),
          backgroundColor: "rgba(0, 98, 255, 0.12)",
          icon: "fa-arrow-up",
          iconColor: "#56CCF2",
          extra: null,
          unit: "คน",
        },
        {
          amount: null,
          label_th: this.$t("staff.women_staff", "th"),
          label_en: this.$t("staff.women_staff", "en"),
          backgroundColor: "rgba(234, 84, 85, 0.12)",
          icon: "fa-arrow-up",
          iconColor: "#EB5757",
          extra: null,
          unit: "คน",
        },
        {
          amount: null,
          label_th: this.$t("staff.transgender_staff", "th"),
          label_en: this.$t("staff.transgender_staff", "en"),
          backgroundColor: "rgba(189, 0, 255, 0.12)",
          icon: "fa-arrow-up",
          iconColor: "#EB5757",
          extra: null,
          unit: "คน",
        },
      ],
      ageSummary: [
        {
          amount: null,
          label_th: this.$t("staff.age_men_staff", "th"),
          label_en: this.$t("staff.age_men_staff", "en"),
          backgroundColor: "rgba(0, 98, 255, 0.12)",
          icon: "fa-arrow-up",
          iconColor: "#56CCF2",
          extra: null,
          unit: "ปี",
        },
        {
          amount: null,
          label_th: this.$t("staff.age_women_staff", "th"),
          label_en: this.$t("staff.age_women_staff", "en"),
          backgroundColor: "rgba(234, 84, 85, 0.12)",
          icon: "fa-arrow-up",
          iconColor: "#EB5757",
          extra: null,
          unit: "ปี",
        },
        {
          amount: null,
          label_th: this.$t("staff.age_transgender_staff", "th"),
          label_en: this.$t("staff.age_transgender_staff", "en"),
          backgroundColor: "rgba(189, 0, 255, 0.12)",
          icon: "fa-arrow-up",
          iconColor: "#EB5757",
          extra: "",
          unit: "ปี",
        },
      ],
      headers: {
        th: [
          {
            text: this.$t("player.table.athlete_id", "th"),
            value: "account_informations.account_information_code_personnel",
            sortable: false,
            width: "10%",
          },
          {
            text: this.$t("player.table.name", "th"),
            value: "account_informations.account_information_firstname_th",
            sortable: false,
            width: "25%",
          },
          {
            text: this.$t("player.table.position", "th"),
            value: "position",
            sortable: false,
            width: "15%",
          },
          {
            text: this.$t("player.table.nation", "th"),
            value: "nationStatus",
            sortable: false,
            width: "20%",
          },
          {
            text: this.$t("player.table.current_team", "th"),
            value: "currentTeam",
            sortable: false,
            width: "20%",
          },
          {
            text: this.$t("player.table.action", "th"),
            value: "action",
            sortable: false,
            width: "10%",
          },
        ],
        en: [
          {
            text: this.$t("player.table.athlete_id", "en"),
            value: "account_informations.account_information_code_personnel",
            sortable: false,
            width: "10%",
          },
          {
            text: this.$t("player.table.name", "en"),
            value: "account_informations.account_information_firstname_th",
            sortable: false,
            width: "25%",
          },
          {
            text: this.$t("player.table.position", "en"),
            value: "position",
            sortable: false,
            width: "15%",
          },
          {
            text: this.$t("player.table.nation", "en"),
            value: "nationStatus",
            sortable: false,
            width: "20%",
          },
          {
            text: this.$t("player.table.current_team", "en"),
            value: "currentTeam",
            sortable: false,
            width: "20%",
          },
          {
            text: this.$t("player.table.action", "en"),
            value: "action",
            sortable: false,
            width: "10%",
          },
        ],
      },
      searchNation: undefined,
      nationTeamList: [],
      searchClub: undefined,
      clubTeamList: [],
      selectedNationTeam: null,
      selectedClubTeam: null,

      accounts: [],
      selectPerson: [],
      pageSizes: [10, 20, 50, 100],
      range: [null, null],
      gender: null,
      selectedProvince: null,
      pageSize: 10,
      currentPage: 1,
      searchName: "",
    };
  },
  watch: {
    currentPage: function () {
      this.$data.searching = false;
      this.getAll();
    },
    pageSize: function () {
      this.getAll();
    },
    searchName: function () {
      this.getAll();
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background: #f8f8f8;
}

.select-amount {
  padding: 0 10px;
  background: #1e73fd;
  color: white;
}

.search-box {
  width: 360px;
}

.page-size-select {
  width: 85px;
}
.image-upload,
.uploadImage {
  cursor: pointer;
}
.image-upload .selectFile {
  display: none;
}
.greenLabel {
  width: 10px;
  height: 10px;
  background: #28c76f;
  border-radius: 20px;
}
.redLabel {
  width: 10px;
  height: 10px;
  background: #ea5455;
  border-radius: 20px;
}
</style>
