<template>
<v-app id="stadium">
 <div class="app-content content ">
        <div class="content-overlay"></div>
          <StadiumTabs/>
  </div>
</v-app>
</template>

<script>
import StadiumTabs from '../../components/Stadium/StadiumTabs';

export default {
  name: 'AddStadium',
  components:{
    StadiumTabs
  },
  mounted() {
      
  },
  
}
</script>

<style>

</style>
