<template>
  <v-app>
		<div class="content app-content">
			<div class="content-header">
				<div class="content-header-left">
					<div class="row d-flex align-items-center justify-space-between">
						<div class="col-12 col-md-8 d-flex align-items-center">
							<v-icon small class="content-header-title float-left mb-0">
								fa-chevron-left
							</v-icon>
							<router-link to="/competition" custom>
								<h5 class="content-header-title float-left mb-0 ml-1">
									{{ $t('competition.all') }}
								</h5>
							</router-link>
							<h5 class="content-header-title float-left mb-0 ml-1">
								/
							</h5>
							<router-link :to="{ name: 'competition-detail', params: { id: competitionId } }" custom>
								<h5 class="content-header-title float-left mb-0 ml-1">
									{{competition.setting_competition_informations['setting_competition_information_name_'+$t('short_language')]}}
								</h5>
							</router-link>
							<template v-if="sub_competition && sub_competition.id">
								<h5 class="content-header-title float-left mb-0 ml-1">
									/
								</h5>
								<router-link :to="{ name: 'sub-competition-detail', params: { cid: competitionId, sid: subCompetitionId, tab: 'teams' } }" custom>
									<h5 class="content-header-title float-left mb-0 ml-1">
										{{ sub_competition['setting_sub_competition_name_'+$t('short_language')] }}
									</h5>
								</router-link>
							</template>
						</div>
						<div class="col-12 col-md-4 mb-1 d-flex justify-content-end">
							<v-btn class="float-right" color="primary" @click="saveData({ cid: competitionId, sid: subCompetitionId })">
								{{ $t('normal.save') }}
							</v-btn>
						</div>
					</div>
				</div>
			</div>
			<AddSubCompetitionForm />
		</div>
	</v-app>
</template>

<script>
import AddSubCompetitionForm from '@/components/Competition/Sub/AddSubCompetitionForm.vue'
import { mapActions, mapState } from 'vuex'

export default {
	name: 'AddUpdateSubCompetition',

	data() {
		return {
			competitionId: null,
			subCompetitionId: null,
		}
	},

	components: {
		AddSubCompetitionForm
	},

	computed: {
		...mapState('competition', ['competition']),
		...mapState('sub_competition', ['sub_competition']),
	},

	mounted() {
		this.competitionId = this.$route.params.cid
		this.subCompetitionId = this.$route.params.sid
		this.clear()
		this.getCompetition(this.competitionId)
	},

	methods: {
		...mapActions('competition', ['getCompetition']),
		...mapActions('sub_competition', ['saveData', 'clear']),
	}
}
</script>
