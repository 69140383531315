<template>
    <v-dialog
      @input="closeModal"
      :value="dialog"
      max-width="700px">
      <v-card>
        <div class="float-right">
          <v-card-actions>
            <v-btn color="primary" text @click="closeModal" mx-2>
              {{ $t('normal.cancel') }}
            </v-btn>
            <v-btn color="primary" @click="handleCommit" mx-2>
              {{ $t('normal.save') }}
            </v-btn>
          </v-card-actions>
        </div>
        <v-card-title>
          <span class="header text-primary">{{ $t('contact_information.contact') }}</span>
        </v-card-title>

        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <label>{{ $t('contact_information.phone') }}</label>
              <v-text-field dense outlined hide-details v-model="form.setting_sub_competition_social_contact_phone"></v-text-field>
            </div>
            <div class="col-6">
              <label>{{ $t('contact_information.email') }}</label>
              <v-text-field dense outlined hide-details v-model="form.setting_sub_competition_social_contact_email"></v-text-field>
            </div>
          </div>
          <div class="mt-4">{{ $t('contact_information.social_media') }}</div>
          <div class="row">
            <div class="col-6">
              <label>{{ $t('contact_information.facebook') }}</label>
              <v-text-field dense outlined hide-details v-model="form.setting_sub_competition_social_contact_facebook"></v-text-field>
            </div>
            <div class="col-6">
              <label>{{ $t('contact_information.line_id') }}</label>
              <v-text-field dense outlined hide-details v-model="form.setting_sub_competition_social_contact_line"></v-text-field>
            </div>
            <div class="col-6">
              <label>{{ $t('contact_information.twitter') }}</label>
              <v-text-field dense outlined hide-details v-model="form.setting_sub_competition_social_contact_twitter"></v-text-field>
            </div>
            <div class="col-6">
              <label>{{ $t('contact_information.instagram') }}</label>
              <v-text-field dense outlined hide-details v-model="form.setting_sub_competition_social_contact_instagram"></v-text-field>
            </div>
            <div class="col-6">
              <label>{{ $t('contact_information.youtube') }}</label>
              <v-text-field dense outlined hide-details v-model="form.setting_sub_competition_social_contact_youtube"></v-text-field>
            </div>
          </div>

          <div class="mt-4">{{ $t('contact_information.contact_more') }}</div>
          <div class="row">
            <div class="col-6">
              <label>{{ $t('contact_information.website') }}</label>
              <v-text-field v-model="form.setting_sub_competition_social_contact_website" dense outlined hide-details></v-text-field>
            </div>
            <div class="col-6">
              <label>{{ $t('contact_information.fax') }}</label>
              <v-text-field v-model="form.setting_sub_competition_social_contact_fax" dense outlined hide-details></v-text-field>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
export default {
	name: 'SubCompetitionSocialContactsModal',
  props: {
		cid: {
			default: '0',
			type: String
		},
		sid: {
			default: '0',
			type: String
		},
    dialog: {
      default: false,
      type: Boolean
    },
    socialContact: null
  },
  methods: {
		...mapActions('sub_competition', ['clearSocialContacts', 'updateSocialContacts']),
    closeModal() {
      this.$emit('update:dialog', false);
    },
    handleCommit() {
			this.updateSocialContacts(this.form);
			this.closeModal()
    }
  },
  watch: {
    dialog() {
      if (this.socialContact) {
        this.$data.form = JSON.parse(JSON.stringify(this.socialContact));
      } else {
        this.$data.form = {
            setting_sub_competition_social_contact_phone: null,
            setting_sub_competition_social_contact_email: null,
            setting_sub_competition_social_contact_facebook: null,
            setting_sub_competition_social_contact_line: null,
            setting_sub_competition_social_contact_instagram: null,
            setting_sub_competition_social_contact_twitter: null,
            setting_sub_competition_social_contact_youtube: null,
            setting_sub_competition_social_contact_website: null,
            setting_sub_competition_social_contact_fax: null,
        }
      }
    }
  },
  data() {
    return {
      form: {}
    }
  }
};
</script>

<style></style>
