<template>
  <v-dialog
    :value="visible"
    @input="closeModal"
    width="900px"
    v-bind:hide-overlay="false"
    style="margin-top:auto;"
  >
    <v-card height="">
      <div class="dialog-wrapper">
        <div class="dialog-header">
          <div class="dialog-header-title">{{$t('stadium.image')}}</div>
          <div class="dialog-actions">
            <v-btn color="primary" plain class="btn-close" @click="closeModal">
              {{$t('normal.cancel')}}
            </v-btn>
            <v-btn color="primary" class="m-l-5px" @click="submit">
              {{$t('normal.save')}}
            </v-btn>
          </div>
        </div>
        <div class="dialog-content">
          <hr class="" style="width: auto;" />
          <div class="row">
            <div class="col-12">
              <div class="">
                <label class="label title" for="basic-addon-name"
                  >{{$t('stadium.stdium_image')}}</label
                >
                <vue-dropzone
                  id="picture"
                  :options="uploadOption"
                  :useCustomSlot="true"
                  ref="imageStadiumForm"
                  @vdropzone-success="
                    (file) => onUploadSuccess(file)
                  "
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      {{$t('stadium.drag_image')}}
                    </h3>
                    <div class="subtitle">{{$t('stadium.sub_drag_image')}}</div>
                  </div>
                </vue-dropzone>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'

export default {
  components: {
    vueDropzone : vue2Dropzone
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", {});
    },
    submit() {
      this.$emit("submit", this.file);
    },
    onUploadSuccess(file) {
      this.file = file;
    }
  },
  data() {
    return {
      uploadOption: {
        url: `/api/v1/team/shirt-uniform/onhold`,
        addRemoveLinks: true,
        maxFiles: 4
      },
      file: null
    };
  },
};
</script>

<style></style>
