var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{staticClass:"content app-content h-100"},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v(_vm._s(_vm.$t("menu.register")))]),_c('div',{staticClass:"d-flex justify-between mb-5 "},[_c('div',{staticClass:"bg-primary px-2 rounded-lg text-white"},[_vm._v(" "+_vm._s(_vm.selectPerson.length)+" ")]),_c('div',{staticClass:"mx-5 d-flex ",class:{ 'text-success cursor-pointer': _vm.selectPerson.length > 0 },on:{"click":_vm.approveUser}},[_c('v-icon',{class:{
              'text-success cursor-pointer': _vm.selectPerson.length > 0,
            }},[_vm._v("$complete")]),_c('span',{staticClass:"m-auto ml-3"},[_vm._v("อนุมัติรายการที่เลือก")])],1),_c('div',{staticClass:"d-flex",class:{ 'text-danger cursor-pointer': _vm.selectPerson.length > 0 },on:{"click":_vm.onModalRejectComment}},[_c('v-icon',{class:{ 'text-danger cursor-pointer': _vm.selectPerson.length > 0 }},[_vm._v("$close")]),_c('span',{staticClass:"m-auto ml-3"},[_vm._v("ไม่อนุมัติรายการที่เลือก")])],1)]),_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers[_vm.language],"items":_vm.accounts.data,"item-key":"id","items-per-page":_vm.pageSize,"show-select":""},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
            var item = ref.item;
            var isSelected = ref.isSelected;
            var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":item.status === 'WATING' && isSelected,"readonly":item.status !== 'WATING',"disabled":item.status !== 'WATING'},on:{"input":function($event){return select($event)}}})]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showDate(item.created_at))+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [(item.status === "APPROVED")?_c('div',{staticClass:"text-primary font-weight-bold"},[_vm._v(" อนุมัติแล้ว ")]):(item.status === "REJECTED")?_c('div',{staticClass:"text-danger font-weight-bold"},[_vm._v(" ไม่อนุมัติ ")]):_c('div',{staticClass:"text-warning font-weight-bold"},[_vm._v("รอการอนุมัติ")])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dark":""}},[(_vm.isEditable)?_c('v-list-item',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.removeSingleAccount(item.id)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-trash")]),_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.$t("normal.delete"))+" ")])],1):_vm._e()],1)],1)]}},{key:"top",fn:function(ref){return [_c('div',{staticClass:"d-flex justify-content-end align-items-center py-4 px-4"},[_vm._v(" "+_vm._s(_vm.$t("normal.search"))+" "),_c('div',{staticClass:"mx-3 search-box"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"true","placeholder":_vm.$t('filter_player_staff.search_placeholder_player')},model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=$$v},expression:"searchName"}})],1)])]}},{key:"footer",fn:function(ref){return [_c('TableFooter',{attrs:{"perPage":_vm.pageSize,"currentPage":_vm.currentPage,"lastPage":_vm.accounts.lastPage,"total":_vm.accounts.total},on:{"update:perPage":function($event){_vm.pageSize=$event},"update:per-page":function($event){_vm.pageSize=$event},"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})]}}],null,true),model:{value:(_vm.selectPerson),callback:function ($$v) {_vm.selectPerson=$$v},expression:"selectPerson"}})],1)]),_c('RejectModal',{attrs:{"dialog":_vm.showRejectModal},on:{"update:dialog":function($event){_vm.showRejectModal=$event},"commit":_vm.onCommitReject}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }