<template>
  <div class="row">
    <div class="col-md-12 col-12">
      <div class="card">
        <div v-if="isEditable" class="card-body">
          <div class="card-header">
            <h4 class="card-title text-primary">
              {{ $t("player.image.upload_image") }}
            </h4>
          </div>
          <vue-dropzone
            v-if="imageDropzoneOptions"
            :options="imageDropzoneOptions"
            id="picture"
            ref="getImage"
            :useCustomSlot="true"
            @vdropzone-success="(file) => onUploadSuccess(file, 'getImage')"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">
                {{ $t("player.image.drag_image") }}
              </h3>
              <div class="subtitle">
                ({{ $t("player.document.sub_document") }})
              </div>
            </div>
          </vue-dropzone>
        </div>
        <div class="card-body">
          <div class="card-header">
            <h4 class="card-title text-primary">
              {{ $t("player.image.manage_image") }}
            </h4>
          </div>
          <v-row>
            <v-col
              v-for="(item, idx) in images"
              :key="idx"
              class="
                d-flex
                justify-content-center
                align-items-center
                child-flex
              "
              md="4"
              cols="6"
            >
              <ImageMenuWrapper
                class="
                  d-flex
                  justify-content-center
                  align-items-center
                  child-flex
                "
                @onClick="(e) => onContextMenu(e, idx, 'images')"
              >
                <v-img
                  :src="item.setting_team_media_url"
                  max-width="500"
                  max-height="250"
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </ImageMenuWrapper>
              <v-menu
                v-model="item.isShowMenu"
                :position-x="item.x"
                :position-y="item.y"
                absolute
                offset-y
                style="max-width: 600px"
              >
                <v-list>
                  <v-list-item class="cursor-pointer">
                    <v-list-item-title
                      @click="
                        () =>
                          download(
                            item.setting_team_media_url,
                            item.setting_team_media_file_name
                          )
                      "
                      >{{ $t("image.size_image") }}</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item class="cursor-pointer">
                    <v-list-item-title
                      @click="() => deleteMedia(item.id, 'getImage')"
                      >{{ $t("normal.delete_list") }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <TableFooter
            :total="imageTotal"
            :perPage.sync="imagePerPage"
            :pageSizes="[9]"
            :lastPage="imageLastPage"
            :currentPage.sync="imagePage"
          />
        </div>
        <div v-if="isEditable" class="card-body">
          <div class="card-header">
            <h4 class="card-title text-primary">
              {{ $t("player.image.upload_video") }}
            </h4>
          </div>
          <vue-dropzone
            v-if="videoDropzoneOptions"
            id="video"
            :options="videoDropzoneOptions"
            :useCustomSlot="true"
            ref="getVideo"
            @vdropzone-success="(file) => onUploadSuccess(file, 'getVideo')"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">
                {{ $t("player.image.drag_video") }}
              </h3>
              <div class="subtitle">
                ({{ $t("player.image.sub_drag_video") }})
              </div>
            </div>
          </vue-dropzone>
        </div>
        <div v-if="isEditable" class="card-body">
          <div>
            <h4 class="card-title text-primary">
              {{ $t("player.image.add_link_video") }}
            </h4>
          </div>
          <div class="row">
            <div class="col-md-10 col-sm-8 col-12">
              <v-text-field
                prepend-inner-icon="fa-link"
                hide-details
                dense
                outlined
                v-model="videoLink"
              ></v-text-field>
              <small
                >{{
                  $t("player.image.example_youtube")
                }}
                http://www.youtube.com/chanatip/Ym3HHa798y7</small
              >
            </div>
            <div class="col-4 col-md-2">
              <v-btn
                @click="uploadLink"
                elevation="2"
                class="w-100 btn-primary"
                >{{ $t("player.image.upload_video") }}</v-btn
              >
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="card-header">
            <h4 class="card-title text-primary">
              {{ $t("player.image.manage_video") }}
            </h4>
          </div>
          <v-row>
            <v-col
              v-for="(item, idx) in videos"
              :key="item.id"
              class="d-flex child-flex"
              cols="4"
            >
              <ImageMenuWrapper
                :hideIcon="!isEditable"
                @onClick="(e) => onContextMenu(e, idx, 'videos')"
              >
                <div class="embed-responsive embed-responsive-4by3">
                  <iframe
                    v-if="item.setting_team_media_name === null"
                    class="embed-responsive-item"
                    :src="item.setting_team_media_url"
                  ></iframe>
                  <template v-else>
                    <video class="embed-responsive-item" controls>
                      <source :src="item.setting_team_media_url" />
                    </video>
                  </template>
                  <v-menu
                    v-model="item.isShowMenu"
                    :position-x="item.x"
                    :position-y="item.y"
                    absolute
                    offset-y
                    style="max-width: 600px"
                  >
                    <v-list>
                      <v-list-item class="cursor-pointer">
                        <v-list-item-title
                          @click="() => deleteMedia(item.id, 'getVideo')"
                          >{{ $t("normal.delete_list") }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </ImageMenuWrapper>
              <!-- <div class="embed-responsive embed-responsive-4by3">
                <iframe
                  class="embed-responsive-item"
                  :src="item.setting_team_media_url"
                ></iframe>
              </div> -->
            </v-col>
          </v-row>
          <TableFooter
            :perPage.sync="videoPerPage"
            :lastPage="videoLastPage"
            :currentPage.sync="videoPage"
            :total="videoTotal"
            :pageSizes="[9]"
          />
        </div>
      </div>
      <v-btn
        elevation="2"
        class="btn-primary float-right mb-5"
        v-on:click="nextTab()"
      >
        {{ $t("normal.next") }} <v-icon right>fa-arrow-right</v-icon></v-btn
      >
      <v-btn
        elevation="2"
        class="btn-primary float-left mb-5"
        v-on:click="backTab()"
      >
        <v-icon left> fa-arrow-left </v-icon>{{ $t("normal.previous") }}</v-btn
      >
    </div>
  </div>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import TableFooter from "../../components/TableFooter.vue";
import { appAxios } from "../../helpers/app-axios";
import ImageMenuWrapper from "../ImageMenuWrapper.vue";
import { replaceYoutubeLink } from "../../helpers/replaceYoutube";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    TableFooter,
    ImageMenuWrapper,
  },
  name: "team-tab5",
  methods: {
    uploadLink() {
      if (!this.$data.videoLink) return;
      const replacedLink = replaceYoutubeLink(this.$data.videoLink);
      appAxios
        .post(`team/${this.$data.teamId}/medias/videos-url`, {
          setting_team_media_video_url: replacedLink,
        })
        .then(() => {
          this.$data.videoLink = null;
          this.getVideo();
        });
    },
    onContextMenu(event, idx, type) {
      event.preventDefault();
      this.$data[type][idx].isShowMenu = !this.$data[type][idx].isShowMenu;
      this.$data[type][idx].x = event.clientX;
      this.$data[type][idx].y = event.clientY;
    },
    async onUploadSuccess(file, refName) {
      // console.log("this.$refs", this.$refs[refName]);
      this.$refs[refName].removeFile(file);
      this[refName]();
    },
    async download(url, name) {
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      link.target = "_blank";
      link.click();
    },
    async deleteMedia(fileId, functionName) {
      await this.$swal(MasterDataHelper.sweetAlertConfig()).then(
        async (result) => {
          if (result.value) {
            appAxios
              .post(`team/${this.teamId}/medias/${fileId}/delete`)
              .then(() => {
                this[functionName]();
              });
          }
        }
      );
    },
    async getImage() {
      return appAxios
        .get(`team/${this.$data.teamId}/medias/images`, {
          params: {
            page: this.$data.imagePage,
            size: this.$data.imagePerPage,
          },
        })
        .then((res) => {
          const data = res.data?.data?.team_media;
          this.$data.images = data.data.map((ele) => ({
            ...ele,
            isShowMenu: false,
            x: 0,
            y: 0,
          }));
          this.$data.imageTotal = data.total;
          this.$data.imageLastPage = data.lastPage;
        });
    },
    async getVideo() {
      return appAxios
        .get(`team/${this.$data.teamId}/medias/videos`, {
          params: {
            page: this.$data.videoPage,
            size: this.$data.videoPerPage,
          },
        })
        .then((res) => {
          const data = res.data?.data?.team_media;
          this.$data.videos = data.data.map((ele) => ({
            ...ele,
            isShowMenu: false,
            x: 0,
            y: 0,
          }));
          this.$data.videoTotal = data.total;
          this.$data.videoLastPage = data.lastPage;
        });
    },
    getOptions(type) {
      const token = localStorage.getItem(process.env.VUE_APP_TOKEN_KEY_ADMIN);
      return {
        url: `/api/v1/team/${this.$data.teamId}/medias/${type}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        acceptedFiles:
          type === "videos"
            ? "video/mp4,video/flv,video/flv,video/avi,video/wnv,video/mkv"
            : "image/jpg,image/jpeg,image/png",
        thumbnailWidth: 200,
        addRemoveLinks: true,
      };
    },
    //
    nextTab() {
      this.$router.push({ path: "", query: { tab: 5 } });
    },
    backTab() {
      this.$router.push({ path: "", query: { tab: 3 } });
    },
  },
  mounted() {
    this.$data.teamId = this.$route.params.id;
    this.$data.imageDropzoneOptions = this.getOptions("images");
    this.$data.videoDropzoneOptions = this.getOptions("videos");
    this.getImage();
    this.getVideo();
    //
  },
  watch: {
    imagePage() {
      this.getImage();
    },
    imagePerPage() {
      this.getImage();
    },
    videoPage() {
      this.getVideo();
    },
    videoPerPage() {
      this.getVideo();
    },
  },
  computed: {
    isEditable() {
      return this.$store.state.permission.permissions.team_edit;
    },
  },
  data() {
    return {
      showMenu: false,
      teamId: null,
      isPlayerRoute: false,
      images: [],
      imagePage: 1,
      imageTotal: 0,
      imagePerPage: 9,
      imageLastPage: null,
      videos: [],
      videoPage: 1,
      videoPerPage: 9,
      videoTotal: 0,
      videoLink: null,
      videoLastPage: null,
      imageDropzoneOptions: undefined,
      videoDropzoneOptions: undefined,
      //
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 200,
        addRemoveLinks: true,
      },
      ytURL: "https://www.youtube.com/embed/QuVtsx2IXxg",
    };
  },
};
</script>
<style scoped>
.dropzone {
  min-height: 200px;
  border: 2px dashed #1161e0;
  background: #f8f8f8;
  position: relative;
}
</style>
