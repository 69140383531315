<template>
  <div class="contentReport row no-gutters p-md-3">
    <div class="row">
      <div class="col-md-6 col-12 order-1">
        <h5 class="card-title text-primary d-flex justify-content-between mb-0">
          <span>{{ $t("report.map") }}</span>
        </h5>
      </div>
      <div class="col-md-6 col-12 order-md-2 order-3">
        <h5 class="card-title text-primary d-flex justify-content-between mb-0">
          <span>{{ $t("report.overall") }}</span>
        </h5>
      </div>
      <div class="col-md-6 col-12 order-md-3 order-2">
        <ThailandMap :language="language" />
      </div>
      <div class="col-md-6 col-12 order-4">
        <pie-chart
          :chart-data="this.$store.state.dashboard.pieStaff[this.language]"
          :style="myStyles"
          :options="pieOptions"
        ></pie-chart>
      </div>
    </div>

    <div class="row tablePlayer">
      <div class="col-md-12 col-12 order-1">
        <fullscreen :fullscreen.sync="fullscreen">
          <h5
            class="card-title text-primary d-flex justify-content-between mb-0"
            :class="{ fullscreenHeader: fullscreen }"
          >
            <span>{{ $t("report.player_nation") }}</span>
            <div>
              <v-icon
                color="#277FFF"
                x-small
                @click="fullScreenTable"
                v-if="!fullscreen"
                >fa-expand-arrows-alt</v-icon
              >
              <v-icon
                color="#277FFF"
                @click="fullScreenTable"
                v-if="fullscreen"
                small
                >fa-compress-arrows-alt</v-icon
              >
            </div>
          </h5>
          <div class="fullscreen-wrapper">
            <TableChart
              :language="language"
              :items="tablePlayerThai.data || []"
              :headers="tablePlayerThai.header"
              :size="fullscreen"
              :expand="expand"
            />
            <div class="" style="background: #ffffff">
              <TableFooter
                :perPage.sync="pageSize"
                :currentPage.sync="currentPage"
                :lastPage="tablePlayerThai.lastPage"
                :total="tablePlayerThai.total"
              />
            </div>
          </div>
        </fullscreen>
      </div>
      <div class="col-md-12 col-12 order-1">
        <fullscreen :fullscreen.sync="fullscreenAllPlayer">
          <h5
            class="card-title text-primary d-flex justify-content-between mb-0"
            :class="{ fullscreenHeader: fullscreenAllPlayer }"
          >
            <span>{{ $t("report.player_team") }}</span>
            <div>
              <v-icon
                color="#277FFF"
                v-if="!fullscreenAllPlayer"
                x-small
                @click="fullScreenTableAllPlayer"
                >fa-expand-arrows-alt</v-icon
              >
              <v-icon
                color="#277FFF"
                v-if="fullscreenAllPlayer"
                @click="fullScreenTableAllPlayer"
                >fa-compress-arrows-alt</v-icon
              >
            </div>
          </h5>
          <div class="fullscreen-wrapper">
            <TableChart
              :language="language"
              :items="tablePlayerAll.data || []"
              :headers="tablePlayerAll.header"
              :size="fullscreenAllPlayer"
              :expand="expand"
            />
            <div class="" style="background: #ffffff">
              <TableFooter
                :perPage.sync="pageSizeClub"
                :currentPage.sync="currentPageClub"
                :lastPage="tablePlayerAll.lastPage"
                :total="tablePlayerAll.total"
              />
            </div>
          </div>
        </fullscreen>
      </div>
    </div>

    <!--<div class="row col-12">
			<div class="col-md-12">
				<h5 class="card-title text-primary d-flex justify-content-between mb-0">
					<span>กราฟเปรียบเทียบบุคลากรแต่ละประเภท แบ่งตามเดือน</span>
					<div
						style="display: flex;justify-content: space-evenly;align-items: center;"
					>
						<span class="mr-2 font-medium-2">
							ปี
						</span>
						<v-select
							dense
							:items="yearList"
							outlined
							hide-details="true"
							clearable
						></v-select>
					</div>
				</h5>
			</div>

			<div class="small col-md-12">
				<line-chart
					:chart-data="datacollection"
					:options="chartOptions"
					:styles="myStyles"
				></line-chart>
			</div>
		</div>-->
  </div>
</template>

<script>
import PieChart from "./chart/PieChart.vue";
// import LineChart from "./chart/LineChart.vue";
import TableChart from "../../components/Dashboard/TableChart";
import ThailandMap from "../../components/Dashboard/ThailandMap";
import VueFullscreen from "vue-fullscreen";
import Vue from "vue";
import { component } from "vue-fullscreen";
import {
  getPieStaffState,
  getTablePlayerAllState,
  getTablePlayerThaiState,
} from "../../store/dashboard";
import TableFooter from "../../components/TableFooter.vue";

import { languageService } from "../../helpers/language";
import { mapActions, mapState } from "vuex";

Vue.use(VueFullscreen);

export default {
  props: {
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PieChart,
    // LineChart,
    TableChart,
    TableFooter,
    ThailandMap,
    fullscreen: component,
  },
  mounted() {
    this.$store.dispatch(getPieStaffState);
    this.$store.dispatch(getTablePlayerAllState, {
      page: 1,
      size: 10,
    });
    // this.getTablePlayerAllState()
    this.$store.dispatch(getTablePlayerThaiState, {
      page: 1,
      size: 10,
    });
    this.getPosition();
    this.fillData();
  },
  computed: {
    ...mapState({
      tablePlayerAll: (state) => state.dashboard.tablePlayerAll,
      tablePlayerThai: (state) => state.dashboard.tablePlayerThai,
    }),
    playerAllData() {
      //   console.log("tablePlayerAll Computed", this.tablePlayerAll);
      return this.tablePlayerAll;
    },
    params() {
      const params = {};
      if (this.range[1] !== 0) {
        params.age_min = this.range[0];
        params.age_max = this.range[1];
      }
      return params;
    },
    paramsClub() {
      return {
        page: this.currentPageClub,
      };
    },
  },
  methods: {
    ...mapActions("dashboard", ["getTablePlayerAllState"]),
    async getPosition() {},

    fillData() {
      this.datacollection = {
        labels: ["ม.ค.", "กพ."],
        datasets: [
          {
            label: "Data One",
            fill: false,
            borderColor: "#2554FF",
            backgroundColor: "#2554FF",
            borderWidth: 3,
            data: [4, 5],
          },
          {
            label: "Data One",
            fill: false,
            borderColor: "#f87979",
            backgroundColor: "#f87979",
            borderWidth: 3,
            data: [15, 10],
          },
        ],
      };
    },

    expandTable() {
      this.expand = !this.expand;
    },
    fullScreenTable() {
      this.fullscreen = !this.fullscreen;
    },
    fullScreenTableAllPlayer() {
      this.fullscreenAllPlayer = !this.fullscreenAllPlayer;
    },
    getColorPieChart() {
      return;
    },
  },
  data: function () {
    return {
      player: [],
      pieStaff: [],
      currentPageClub: 1,
      pageSizeClub: 10,
      pageSize: 10,
      currentPage: 1,
      language: "th",
      fullscreen: false,
      fullscreenAllPlayer: false,
      teleport: false,
      valuePeople: null,
      expand: false,
      yearList: [
        { value: "2563", text: "2563" },
        { value: "2564", text: "2564" },
        { value: "2565", text: "2565" },
      ],

      chartOptions: {
        hoverBorderWidth: 20,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "bottom",
        },
      },
      pieOptions: {
        hoverBorderWidth: 20,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "bottom",
        },
      },
      myStyles: {
        // height: "400 px",
        // maxHight:"100px",
        width: "100%",
        position: "relative",
      },

      datacollection: null,
      datapieChart: null,
    };
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  watch: {
    currentPageClub: function () {
      this.$store.dispatch(getTablePlayerAllState, {
        page: this.currentPageClub,
        size: this.pageSizeClub,
      });
    },
    pageSizeClub: function () {
       this.$store.dispatch(getTablePlayerAllState, {
        page: 1,
        size: this.pageSizeClub,
      });
    },
    currentPage: function () {
      this.$store.dispatch(getTablePlayerThaiState, {
        page: this.currentPage,
        size: this.pageSize,
      });
    },
    pageSize: function () {
       this.$store.dispatch(getTablePlayerThaiState, {
        page: 1,
        size: this.pageSize,
      });
    },
    language() {
      this.$store.dispatch(getPieStaffState);
    },
  },
  beforeDestroy() {
    this.subscription.unsubscribe();
  },
};
</script>

<style scoped lang="scss">
.theme--light .v-icon:focus::after {
  opacity: 0 !important;
}
.tablePlayer .theme--light {
  height: 24px !important;
  width: 24px !important;
}

@media (max-width: 768px) {
  .contentReport {
    margin: 20px;
    padding-left: 20px;
    padding-top: 20px;
  }
  .tablePlayer {
    width: 100%;
  }
}
@media (max-width: 960px) {
  .hideExpand {
    display: none;
  }
}
.fullscreenHeader {
  padding: 30px 100px !important;
  background-color: white;
}
</style>
