import Association from '../views/Association/Association.vue'
import permissionGuard from './guard/permissionGuard';

const association = [
    {
        path: '/association',
        name: 'Association',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter: permissionGuard,
        component: Association
    }
]

export default association;
