<template>
  <v-dialog @input="closeModal" :value="dialog" max-width="700px">
    <v-card>
      <div class="float-right">
        <v-card-actions>
          <v-btn color="primary" text @click="closeModal" mx-2>
            {{ $t('normal.cancel') }}
          </v-btn>
          <v-btn :disabled="!isEditable" color="primary" @click="handleCommit" mx-2>
            {{ $t('normal.save') }}
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-title>
        <span class="header text-primary">{{ $t('contact_information.contact') }}</span>
      </v-card-title>

      <div class="card-body">
        <v-form ref="form">
          <div class="row">
            <div class="col-6">
              <label>{{ $t('contact_information.phone') }}</label>
              <v-text-field
                dense
                outlined
                v-model="form.setting_stadium_social_contact_phone"
              ></v-text-field>
            </div>
            <div class="col-6">
              <label>{{ $t('contact_information.email') }}</label>
              <v-text-field
                :rules="emailRules"
                dense
                outlined
                v-model="form.setting_stadium_social_contact_email"
              ></v-text-field>
            </div>
          </div>
          <div class="mt-4">Social Media</div>
          <div class="row">
            <div class="col-6">
              <label>Facebook</label>
              <v-text-field
                dense
                outlined
                hide-details
                v-model="form.setting_stadium_social_contact_facebook"
              ></v-text-field>
            </div>
            <div class="col-6">
              <label>LINE</label>
              <v-text-field
                dense
                outlined
                hide-details
                v-model="form.setting_stadium_social_contact_line"
              ></v-text-field>
            </div>
            <div class="col-6">
              <label>Twitter</label>
              <v-text-field
                dense
                outlined
                hide-details
                v-model="form.setting_stadium_social_contact_twitter"
              ></v-text-field>
            </div>
            <div class="col-6">
              <label>Instagram</label>
              <v-text-field
                dense
                outlined
                hide-details
                v-model="form.setting_stadium_social_contact_instagram"
              ></v-text-field>
            </div>
            <div class="col-6">
              <label>Youtube</label>
              <v-text-field
                dense
                outlined
                hide-details
                v-model="form.setting_stadium_social_contact_youtube"
              ></v-text-field>
            </div>
          </div>

          <div class="mt-4">{{ $t('contact_information.contact_more') }}</div>
          <div class="row">
            <div class="col-6">
              <label>{{ $t('contact_information.website') }}</label>
              <v-text-field
                v-model="form.setting_stadium_social_contact_website"
                dense
                outlined
                hide-details
              ></v-text-field>
            </div>
            <div class="col-6">
              <label>Fax</label>
              <v-text-field
                v-model="form.setting_stadium_social_contact_fax"
                dense
                outlined
                hide-details
              ></v-text-field>
            </div>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    socialContact: null,
  },
  methods: {
    closeModal() {
      this.$emit("update:dialog", false);
    },
    handleCommit() {
      const validate = this.$refs.form.validate();
      if (!validate) return;
      this.$emit("commit", this.$data.form);
    },
  },
  watch: {
    dialog() {
      if (this.socialContact) {
        this.$data.form = this.socialContact;
      } else {
        this.$data.form = {
          setting_stadium_social_contact_phone: "",
          setting_stadium_social_contact_email: "",
          setting_stadium_social_contact_facebook: "",
          setting_stadium_social_contact_line: "",
          setting_stadium_social_contact_instagram: "",
          setting_stadium_social_contact_twitter: "",
          setting_stadium_social_contact_youtube: "",
          setting_stadium_social_contact_website: "",
          setting_stadium_social_contact_fax: "",
        };
      }
    },
  },
  computed: {
    emailRules() {
      const rules = [];
     const isEmail = (value) => {        // if (!value) return null;
        const emailRegex = new RegExp(
          /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
        );
        if(this.language === 'th'){
          return !value || emailRegex.test(value) || "รูปแบบ email ไม่ถูกต้อง"
        }else{
          return !value || emailRegex.test(value) || "Email is not correct"

        }
      };
      rules.push(isEmail);
      return rules;
    },
    isEditable() {
      return this.$store.state.permission.permissions.stadium_edit
    }
  },
  data() {
    return {
      form: {},
    };
  },
};
</script>

<style></style>
