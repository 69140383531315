<template>
	<v-dialog :value="dialog" max-width="80vh" >
		<v-card>
			<div class="float-right">
				<v-card-actions>
					<v-btn color="primary" text @click="closeModal" mx-2>
						x
					</v-btn>
				</v-card-actions>
			</div>

			<!--success-->
			<div class="card-body text-center" v-if="type === 'success'">
				<v-img
					src="@/assets/images/successImport.svg"
					max-width="70"
					class="mx-auto"
				/>
				<h3 class="text-success my-5">
					การนำเข้า {{ this.count }} ข้อมูล เสร็จสิ้น
				</h3>
				<div class="dataImport">
				<div v-for="(item, i) in this.data" :key="i">
					<label>สร้างบัญชีผู้ใช้งาน account_id  {{item.data.id}} สำเร็จ</label>
				</div>
				</div>
			</div>

						<!--failed-->

			<div class="card-body text-center" v-else>
				<v-img src="@/assets/images/Icon.svg" max-width="70" class="mx-auto" />
				<h3 class="text-danger ml-6 my-5">
					การนำเข้า {{ this.count }} ข้อมูล ล้มเหลว
				</h3>
				<div class="dataImport">
					<div v-for="(item, i) in this.data" :key="i">
						
						<label v-if="item.title === 'passport_id'">
							หมายเลขพาสปอร์ต
							<b>{{ item.data }}</b> มีอยู่ในระบบแล้ว </label
						>
						<label v-else-if="item.title === 'citizen_id'">
							หมายเลขบัตรประชาชน
							<b>{{ item.data }}</b> มีอยู่ในระบบแล้ว </label
						>
						<label v-else>
							หมายเลขบัตร
							<b>{{ item.data }}</b> เกิดข้อผิดพลาด</label
						>
					</div>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "ImportModal",
	props: ["dialog", "dataImport", "type"],

	watch: {
		dataImport() {
			console.log("test", this.dataImport);
			this.data = this.dataImport.data;
			this.count = this.dataImport.count;
		},
	},
	mounted() {
		this.checkValue();
	},
	methods: {
		checkValue() {
			if (this.dataImport === null) {
				this.data = [];
				this.count = 0;
			}
		},
		closeModal() {
			this.$emit("update:dialog", false);
		},
	},
	data() {
		return {
			count: 0,
			data: [],
		};
	},
};
</script>
<style lang="scss" scoped>
.dataImport {
	max-height: 40vh;
	overflow-y: auto;
}

</style>
