<template>
  <v-app>
    <!-- <v-card class="p-1">
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn class="mx-2" color="primary" small>{{
            $t("normal.edit_list")
          }}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="
                d-flex
                flex-column
                justify-center
                align-items-center
                border-right
                pa-2
              "
          cols="4"
        >
          <div :style="`color:#267FFF;font-size:20px`">โรงเรียนชาย ,อายุ 14 ปี</div>
          <div>RBTH Games</div>
          <div>RB00112</div>
        </v-col>
        <v-col
          class="
                d-flex
                align-items-center
                justify-space-around
                text-center
                info-header
              "
        >
          <div>
            <div color="black">{{ $t("competition.abbreviation") }}</div>
            <div class="grey--text">
              RBTH
            </div>
          </div>
          <div>
            <div color="black">{{ $t("competition.level") }}</div>
            <div class="grey--text">
              ระดับภูมิภาค
            </div>
          </div></v-col
        >
      </v-row>
    </v-card> -->
    <v-card class="">
      <div class="py-3 pl-5" :style="`color:#267FFF;font-size:26px`">
        รายชื่อทีมที่สมัคร
      </div>
      <!-- <v-data-table
        :items="this.teamData.data"
        :headers="headers"
        item-key="id"
        :page.sync="page"
        :items-per-page="pageSize"
        v-model="selected"
        class="elevation-1"
        show-select
        @page-count="pageCount = $event"
        :hide-default-footer="true"
        @click:row="onRowClick"

      > -->

      <v-data-table
        v-model="selectPerson"
        :headers="headers"
        :items="listTeam"
        class="elevation-1 row-pointer"
        hide-default-footer
        show-select
        :items-per-page="listTeam.length"
        @page-count="pageCount = $event"
        @click:row="onRowClick"
      >
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            :value="item.status === 'WAITING' && isSelected"
            :readonly="item.status !== 'WAITING'"
            :disabled="item.status !== 'WAITING'"
            @input="select($event)"
          ></v-simple-checkbox>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ showDate(item.created_at) }}
        </template>
        <template
          v-slot:[`item.status`]="{
            item,
          }"
        >
          <div
            v-if="item.status === `APPROVED`"
            class="text-primary font-weight-bold"
          >
            อนุมัติแล้ว
          </div>
          <div
            v-else-if="item.status === `REJECTED`"
            class="text-danger font-weight-bold"
          >
            ไม่อนุมัติ
          </div>
          <div v-else class="text-warning font-weight-bold">รอการอนุมัติ</div>
        </template>
        <template v-slot:top="{}">
          <!-- <v-row class="px-6">
          <v-col class="d-flex justify-end">
            <v-btn color="primary" @click="$emit('openTeamSelect')"
              >+ {{ $t("sub_competition.select_team_join") }}</v-btn
            >
          </v-col>
        </v-row> -->
          <v-row class="px-6 d-flex flex-row justify-space-between">
            <v-col
              cols="auto"
              class="d-flex flex-row justify-center align-items-center"
            >
              <div class="bg-primary px-2 rounded-lg text-white">
                {{ selectPerson.length }}
              </div>
              <div
                class="mx-5 d-flex "
                @click="approveTeam"
                :class="{
                  'text-success cursor-pointer': selectPerson.length > 0,
                }"
              >
                <v-icon
                  :class="{
                    'text-success cursor-pointer': selectPerson.length > 0,
                  }"
                  >$complete</v-icon
                ><span class="m-auto ml-3">อนุมัติรายการที่เลือก</span>
              </div>
              <div
                class="d-flex"
                @click="onModalRejectComment"
                :class="{
                  'text-danger cursor-pointer': selectPerson.length > 0,
                }"
              >
                <v-icon
                  :class="{
                    'text-danger cursor-pointer': selectPerson.length > 0,
                  }"
                  >$close</v-icon
                ><span class="m-auto ml-3">ไม่อนุมัติรายการที่เลือก</span>
              </div>
            </v-col>
            <v-col
              cols="auto"
              class="d-flex flex-row justify-center align-items-center"
            >
              {{ $t("normal.search") }}
              <div class="mx-3 search-box">
                <v-text-field
                  v-model="searchName"
                  dense
                  outlined
                  hide-details="true"
                  :placeholder="$t('sub_competition.search_team_placholder')"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:no-data>
          <div
            class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            py-6
          "
            style="min-height: 400px"
          >
            <v-img
              src="@/assets/images/cup.png"
              max-height="128"
              contain
              class="ma-2"
            ></v-img>
            <h4 class="grey--text text--lighten-1">
              {{ $t("sub_competition.not_exist") }}
            </h4>
            <h5 class="grey--text text--lighten-2">
              {{ $t("sub_competition.please_add_team") }}
            </h5>
          </div>
        </template>
        <template v-slot:[`item.team`]="{ item }">
          <div class="d-flex flex-row align-items-center">
            <v-img
              :src="item.setting_team_media_profile_url"
              max-height="20"
              max-width="20"
              contain
              class="ma-2"
            ></v-img>
            <span class="mx-2">{{
              item["setting_team_information_name_" + $t("short_language")]
            }}</span>
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="pa-2" small v-bind="attrs" v-on="on"
                >fa-ellipsis-v</v-icon
              >
            </template>
            <v-list>
              <v-list-item v-for="(action, index) in actions" :key="index">
                <v-btn text @click="onClick(action.key, item)">
                  <v-icon small class="mr-2">{{ action.icon }}</v-icon
                  >{{ action.text }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <!-- <br> -->
      <!-- <div class="" style="background: #ffffff">
        <TableFooter
          :perPage.sync="pageSize"
          :currentPage.sync="currentPage"
          :lastPage="teamData.lastPage"
          :total="teamData.total"
        />
      </div> -->
    </v-card>
    <RejectModal :dialog.sync="showRejectModal" @commit="onCommitReject" />
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import TableFooter from "../../TableFooter.vue";
import MasterDataHelper from "@/helpers/master_data_helper.js";
import { appAxios } from "../../../helpers/app-axios";
import { format } from "date-fns";
import RejectModal from "@/components/RejectModal.vue";

export default {
  name: "TeamTable",
  components: {
    // TableFooter,
    RejectModal,
  },

  mounted() {
    this.competitionId = this.$route.params.cid;
    this.subCompetitionId = this.$route.params.sid;
    // this.getTeamsInSubCompetition({
    //   sid: this.subCompetitionId,
    //   data: this.params,
    // });
    this.getRugbyTeam();
  },

  computed: {
    ...mapState("sub_competition", ["sub_competition", "selected_teams"]),
    teamData() {
      console.log("selected_teams", this.selected_teams);
      return {
        data: [
          {
            id: 1,
          },
        ],
      };
      // return this.selected_teams || [];
    },
    isAdmin() {
      return this.$store.state.permission.role !== "B_TEAM_ADMIN";
    },
    params() {
      return {
        size: this.pageSize,
        page: this.currentPage,
        name: this.searchName,
      };
    },
    headers() {
      return [
        {
          text: "ชื่อทีม/สโมสร",
          value:
            "setting_team_id.setting_team_informations.setting_team_information_name_th",
        },
        {
          text: "ผู้จัดการทีม",
          value:
            "setting_team_id.setting_team_informations.setting_team_information_manager_name",
        },
        {
          text: "โทรศัพท์มือถือ",
          value:
            "setting_team_id.setting_team_social_contacts.setting_team_social_contact_phone",
        },
        {
          text: "วันที่ลงสมัคร",
          value: "created_at",
        },
        {
          text: "จำนวนนักกีฬา",
          value: "count_players",
        },
        {
          text: "สถานะการอนุมัติ",
          value: "status",
        },
        {
          text: "การชำระค่าสมัคร",
          value: "setting_sub_competition_id.setting_sub_competition_fee",
        },
        { text: "จัดการ", value: "action", sortable: false, width: "10%" },
      ];
    },
    actions() {
      return [
        { text: this.$t("normal.edit_list"), icon: "fa-pen", key: "edit" },
        { text: "ดาวน์โหลดใบสมัคร", icon: "fa-download", key: "download-app" },
        // {
        //   text: "ดาวน์โหลดผังรูปนักกีฬา",
        //   icon: "fa-download",
        //   key: "download-flow",
        // },
        {
          text: this.$t("normal.delete_list"),
          icon: "fa-trash",
          key: "delete",
        },
      ];
    },
    buttonEdit() {
      return [
        { title: this.$t("normal.edit") },
        { title: this.$t("normal.delete_list") },
      ];
    },
  },
  methods: {
    async onClick(action, item) {
      if (action === "edit") {
        this.$router.push({
          name: "sub-competition-list",
          params: {
            cid: this.competitionId,
            sid: this.subCompetitionId,
            tab: "teams",
            list: item.sub_team_id.team_id + "-" + item.sub_team_id.id,
          },
        });
      } else if (action === "delete") {
        this.dialogConfirmDelete = true;
        this.deleteItem(item);
      } else if (action === "download-app") {
        const objData = {
          team_id: item.sub_team_id.team_id,
          sub_team_id: item.sub_team_id.id,
          setting_competition_id: item.sub_team_id.setting_competition_id,
          setting_sub_competition_id:
            item.sub_team_id.setting_sub_competition_id,
        };

        appAxios
          .post(`document/export-applicant`, objData, {
            responseType: "blob",
          })
          .then(async (res) => {
            const stream = await res.data;
            const blob = new Blob([stream], { type: "application/pdf" });            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);

            // Create a link element and simulate a click to download
            const link = document.createElement("a");
            link.href = url;
            link.download = "rugby.pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      }
    },
    async approveTeam() {
      const objData = {
        id: this.selectPerson.map((p) => +p.id),
        status: "APPROVED",
      };
      appAxios
        .post(
          `sub-competitions/${this.$route.params.sid}/setting-team-in-sub-competition/rugby/status`,
          objData
        )
        .then(() => {
          this.getRugbyTeam();
        });
    },
    async onCommitReject(value) {
      const objData = {
        id: this.selectPerson.map((p) => +p.id),
        status: "REJECTED",
        reason: value,
      };
      appAxios
        .post(
          `sub-competitions/${this.$route.params.sid}/setting-team-in-sub-competition/rugby/status`,
          objData
        )
        .then(() => {
          this.getRugbyTeam();
        });
    },
    showDate(date) {
      const formatDate = format(new Date(date), "dd/MM/yyyy");
      return formatDate;
      // return date
    },
    onModalRejectComment() {
      this.$data.showRejectModal = true;
    },
    getRugbyTeam() {
      appAxios
        .get(
          `sub-competitions/${this.subCompetitionId}/setting-team-in-sub-competition/rugby`
        )
        .then((res) => {
          // console.log('res',res.data.data.team)
          this.listTeam = res.data.data.teams;
        });
    },
    onRowClick(row) {
      this.$router.push({
        name: "sub-competition-list",
        params: {
          cid: this.competitionId,
          sid: this.subCompetitionId,
          tab: "teams",
          list: row.sub_team_id.team_id + "-" + row.sub_team_id.id,
        },
      });
    },
    ...mapActions("sub_competition", [
      "deleteCompetitionTeams",
      // "getTeamsInSubCompetition",
    ]),
    async deleteItem(item) {
      this.deletingItem = item;
      await this.$swal(
        MasterDataHelper.sweetAlertConfig(this.$t("short_language"))
      ).then(async (result) => {
        if (result.value) {
          this.deleteCompetitionTeams([this.deletingItem.id]).then(() =>
            this.getRugbyTeam()
          );
        }
      });
    },
    async deleteSelectedItem() {
      await this.$swal(
        MasterDataHelper.sweetAlertConfig(this.$t("short_language"))
      ).then(async (result) => {
        if (result.value) {
          if (this.selected.length > 0) {
            this.deleteCompetitionTeams(this.selected.map((team) => team.id));
          }
        }
      });
    },
    closeDelete() {
      this.dialogConfirmDelete = false;
      this.deletingItem = null;
    },
    confirmDeleteItem() {
      if (this.deletingItem) {
        this.deleteCompetitionTeams([this.deletingItem.id]);
      } else if (this.selected.length > 0) {
        this.deleteCompetitionTeams(this.selected.map((team) => team.id));
      }
      this.dialogConfirmDelete = false;
      this.deletingItem = null;
    },
  },

  data() {
    return {
      selectPerson: [],
      page: 1,
      pageCount: 0,
      pageCounts: [10, 20, 50, 100],
      itemsPerPage: 10,
      range: [10, 20],
      api: "http://localhost:3000/users",
      pageSize: 10,
      currentPage: 1,
      searchName: "",
      selected: [],
      dialogConfirmDelete: false,
      deletingItem: null,
      listTeam: [],
      showRejectModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  background: #f8f8f8;
}

.select-amount {
  padding: 0 10px;
  background: #1e73fd;
  color: white;
}

.search-box {
  width: 360px;
}

.page-size-select {
  width: 85px;
}
</style>
