import { appAxios } from '../helpers/app-axios';

export const accountPlayersState = 'accountPlayers'
export const getAllAccountPlayers = 'getAllAccountPlayers'

export const accountStaffsState = 'accountStaffs'
export const getAllAccountStaffs = 'getAllAccountStaffs'

export const playerPositionState = 'playerPosition'
export const getAllPlayerPosition = 'getAllPlayerPosition'

export const getAllStaffsPosition = 'getAllStaffsPosition'
export const staffsPositionState = 'staffsPosition'

export const getAllDepartment = 'getAllDepartment'
export const departmentState = 'department'

export default {
    state:{
    [accountPlayersState]: [],
    [accountStaffsState]: [],
    [playerPositionState]: [],
    [staffsPositionState]: [],
    [departmentState]: [],
    isFethingAllPlayersPosition: false
      },
      mutations: {
        [getAllAccountStaffs](state, payload) {
          state[accountStaffsState] = payload;
        },
        [getAllAccountPlayers](state, payload) {
          state[accountPlayersState] = payload;
        },
        [getAllPlayerPosition](state, payload) {
          state[playerPositionState] = payload;
        },
        [getAllStaffsPosition](state, payload) {
          state[staffsPositionState] = payload;
        },
        [getAllDepartment](state, payload) {
          console.log('getAllDepartment', payload);
          state[departmentState] = payload;
        },
        setIsFetchingAllPlayersPosition(state, status) {
          state.isFethingAllPlayersPosition = status;
        }
      },
      actions: {
        async [getAllAccountStaffs]({ commit }) {
          await appAxios.get(`account/personels/staff`).then(response => {
            commit(getAllAccountStaffs, response.data?.data?.account?.data);
          }).catch(() => {
            // console.log(error.response)
          })
        },
        async [getAllAccountPlayers]({ commit }) {
          await appAxios.get(`account/personels/players`).then(response => {
            commit(getAllAccountPlayers, response.data?.data?.account?.data);
          }).catch(() => {
            // console.log(error.response)
          })
        },
        async [getAllStaffsPosition]({ commit },) {
          await appAxios.get(`/setting/global/positions/staff`).then(response => {
            commit(getAllStaffsPosition, response.data?.data?.data);
          }).catch(() => {
            //console.log(error.response)
          })
        },
        async [getAllDepartment]({ commit }) {
          await appAxios.get(`/setting/global/departments/all`).then(response => {
            commit(getAllDepartment, response.data?.data);
          }).catch(() => {
            //console.log(error.response)
          })
        },
        async [getAllPlayerPosition]({ commit, state }) {
          if (state[playerPositionState].length > 0 || state.isFethingAllPlayersPosition) return;
          commit('setIsFetchingAllPlayersPosition', true);
          appAxios.get(`setting/global/positions/player`).then(res => {
            commit(getAllPlayerPosition, res.data?.data?.data);
            commit('setIsFetchingAllPlayersPosition', false);
          })
        }
      }
}