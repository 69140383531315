<template>
  <div class="master-data-table">
    <div
      class="master-data-table-wrapper"
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 30px 5px 30px;
      "
    >
      <h4 class="card-title text-primary">
        {{ $t("player.present_athletes") }}({{ teamName }})
      </h4>
      <v-btn
        v-if="isEditable"
        class="float-right primary"
        @click="isPlayerModalVisible = true"
      >
        {{ `+ ${$t("player.add_athlete")}` }}
      </v-btn>
    </div>
    <div class="master-data-table-wrapper" style="padding-top: 15px">
      <div class="master-data-table-search">
        <div class="input-group">
          <IconImage
            className="icon-search"
            src="assets/images/icons/search.svg"
            width="21"
            height="21"
          ></IconImage>
          <input
            type="text"
            v-model="currentPlayerName"
            class="form-control"
            :placeholder="$t('player.find_athletes')"
          />
          <div class="input-group-append">
            <v-menu
              v-model="menuPresent"
              offset-y
              :close-on-content-click="false"
              rounded="lg"
              content-class="filter-dropdown"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" outlined>
                  <IconImage
                    className="icon-filter"
                    src="assets/images/icons/filter.svg"
                    width="22"
                    height="19"
                  ></IconImage>
                </v-btn>
              </template>
              <div class="p-1 bg-white">
                <div class="text-primary">{{ $t("normal.filter") }}</div>
                <v-form>
                  <div>{{ $t("filter_player_staff.age") }}</div>
                  <v-range-slider
                    v-model="currentPlayerAgeRange"
                    :Present="100"
                    :minPresent="0"
                    hide-details
                    class="align-center"
                  >
                    <template v-slot:prepend>
                      <v-text-field
                        dense
                        outlined
                        :value="currentPlayerAgeRange[0]"
                        class="mt-0 pt-0"
                        hide-details
                        type="number"
                        style="width: 60px"
                        @change="$set(currentPlayerAgeRange, 0, $event)"
                      ></v-text-field>
                    </template>
                    <template v-slot:append>
                      <v-text-field
                        dense
                        outlined
                        :value="currentPlayerAgeRange[1]"
                        class="mt-0 pt-0"
                        hide-details
                        type="number"
                        style="width: 60px"
                        @change="$set(currentPlayerAgeRange, 1, $event)"
                      ></v-text-field>
                    </template>
                  </v-range-slider>
                  <div>{{ $t("player.personal_info.nationality") }}</div>
                  <v-select
                    v-model="currentPlayerNation"
                    item-text="text"
                    item-value="value"
                    :items="country"
                    clearable
                    :placeholder="$t('normal.please_select')"
                    dense
                    outlined
                  >
                  </v-select>
                  <div>{{ $t("player.athlete_info.contract_type") }}</div>
                  <v-select
                    v-model="currentPlayerContract"
                    item-text="text"
                    item-value="value"
                    :items="historystatus"
                    clearable
                    :placeholder="$t('normal.please_select')"
                    dense
                    outlined
                  >
                  </v-select>
                  <v-btn
                    @click="getCurrentPlayers"
                    color="primary"
                    class="mt-3"
                    >{{ $t("normal.search") }}</v-btn
                  >
                </v-form>
              </div>
            </v-menu>
          </div>
        </div>
      </div>
      <v-data-table
        :items="currentPlayers"
        :headers="headers"
        item-key="id"
        class="elevation-1"
        :hide-default-footer="true"
        :items-per-page="currentPlayerPerPage"
        :no-data-text="$t('normal.no_data')"
      >
        <template v-slot:item="row">
          <tr>
            <td>
              <v-avatar>
                <img
                  v-if="row.item.profile"
                  :src="row.item.profile"
                  width="20"
                />
                <template v-else>
                  <v-icon> $user </v-icon>
                </template>
              </v-avatar>
              {{ row.item.name[language] }}
            </td>
            <td>
              {{ row.item.nationality[language] }}
            </td>
            <td>
              {{ row.item.age }}
            </td>
            <td>{{ row.item.position }}</td>
            <td>{{ row.item.shirtNumber }}</td>
            <td>{{ row.item.contract[language] }}</td>
            <td>{{ row.item.start }}</td>
            <td>{{ row.item.end }}</td>
            <td>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>
                <v-list dark>
                  <v-list-item
                    class="cursor-pointer"
                    @click="() => editHistory(row.item.id)"
                    ><v-icon x-small>fa-pen</v-icon>
                    <span class="ml-3"
                      >{{ $t("normal.edit") }}
                    </span></v-list-item
                  >
                  <v-list-item
                    class="cursor-pointer"
                    @click="
                      () => deleteHistory(row.item.id, 'getCurrentPlayers')
                    "
                    v-if="isEditable"
                    ><v-icon x-small>fa-trash</v-icon>
                    <span class="ml-3">{{
                      $t("normal.delete_list")
                    }}</span></v-list-item
                  >
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="" style="background: #ffffff">
        <TableFooter
          :perPage.sync="currentPlayerPerPage"
          :currentPage.sync="currentPlayerPage"
          :lastPage="currentPlayerLastPage"
          :total="currentPlayerTotal"
        />
      </div>
    </div>
    <!-- // -->
    <div
      class="master-data-table-wrapper"
      style="
        display: flex;
        justify-content: space-between;
        padding: 5px 30px 5px 30px;
      "
    >
      <h4 class="card-title text-primary">
        {{ $t("player.athlete_info.history") }}({{ teamName }})
      </h4>
    </div>
    <div class="master-data-table-wrapper" style="padding-top: 15px">
      <div class="master-data-table-search">
        <div class="input-group">
          <IconImage
            className="icon-search"
            src="assets/images/icons/search.svg"
            width="21"
            height="21"
          ></IconImage>
          <input
            type="text"
            v-model="pastPlayerName"
            class="form-control"
            :placeholder="$t('player.find_athletes')"
          />
          <div class="input-group-append">
            <v-menu
              v-model="menuPast"
              offset-y
              :close-on-content-click="false"
              rounded="lg"
              content-class="filter-dropdown"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" outlined>
                  <IconImage
                    className="icon-filter"
                    src="assets/images/icons/filter.svg"
                    width="22"
                    height="19"
                  ></IconImage>
                </v-btn>
              </template>
              <div class="p-1 bg-white">
                <div class="text-primary">{{ $t("normal.filter") }}</div>
                <v-form>
                  <div>{{ $t("filter_player_staff.age") }}</div>
                  <v-range-slider
                    v-model="pastPlayerAgeRange"
                    :Present="100"
                    :minPresent="0"
                    hide-details
                    class="align-center"
                  >
                    <template v-slot:prepend>
                      <v-text-field
                        dense
                        outlined
                        :value="pastPlayerAgeRange[0]"
                        class="mt-0 pt-0"
                        hide-details
                        type="number"
                        style="width: 60px"
                        @change="$set(pastPlayerAgeRange, 0, $event)"
                      ></v-text-field>
                    </template>
                    <template v-slot:append>
                      <v-text-field
                        dense
                        outlined
                        :value="pastPlayerAgeRange[1]"
                        class="mt-0 pt-0"
                        hide-details
                        type="number"
                        style="width: 60px"
                        @change="$set(pastPlayerAgeRange, 1, $event)"
                      ></v-text-field>
                    </template>
                  </v-range-slider>
                  <div>{{ $t("player.personal_info.nationality") }}</div>
                  <v-select
                    v-model="pastPlayerNation"
                    item-text="text"
                    item-value="value"
                    :items="country"
                    clearable
                    :placeholder="$t('normal.please_select')"
                    dense
                    outlined
                  >
                  </v-select>
                  <div>{{ $t("player.athlete_info.contract_type") }}</div>
                  <v-select
                    v-model="pastPlayerContract"
                    item-text="text"
                    item-value="value"
                    :items="historystatus"
                    clearable
                    :placeholder="$t('normal.please_select')"
                    dense
                    outlined
                  >
                  </v-select>
                  <v-btn @click="getPastPlayers" color="primary" class="mt-3">{{
                    $t("normal.search")
                  }}</v-btn>
                </v-form>
              </div>
            </v-menu>
          </div>
        </div>
      </div>
      <v-data-table
        :items="pastPlayers"
        :headers="headers"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        :items-per-page="pastPlayerPerPage"
        :no-data-text="$t('normal.no_data')"
      >
        <template v-slot:item="row">
          <tr>
            <td>
              <v-avatar>
                <img
                  v-if="row.item.profile"
                  :src="row.item.profile"
                  width="20"
                />
                <template v-else>
                  <v-icon> $user </v-icon>
                </template>
              </v-avatar>
              {{ row.item.name[language] }}
            </td>
            <td>
              {{ row.item.nationality[language] }}
            </td>
            <td>
              {{ row.item.age }}
            </td>
            <td>{{ row.item.position }}</td>
            <td>{{ row.item.shirtNumber }}</td>
            <td>{{ row.item.contract[language] }}</td>
            <td>{{ row.item.start }}</td>
            <td>{{ row.item.end }}</td>
            <td>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>
                <v-list dark>
                  <v-list-item
                    class="cursor-pointer"
                    @click="() => editHistory(row.item.id)"
                    ><v-icon x-small>fa-pen</v-icon>
                    <span class="ml-3">
                      {{ $t("normal.edit") }}
                    </span></v-list-item
                  >
                  <v-list-item
                    class="cursor-pointer"
                    @click="() => deleteHistory(row.item.id, 'getPastPlayers')"
                    v-if="isEditable"
                    ><v-icon x-small>fa-trash</v-icon>
                    <span class="ml-3">
                      {{ $t("normal.delete_list") }}
                    </span></v-list-item
                  >
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="" style="background: #ffffff">
        <TableFooter
          :perPage.sync="pastPlayerPerPage"
          :currentPage.sync="pastPlayerPage"
          :lastPage="pastPlayerLastPage"
          :total="pastPlayerTotal"
        />
      </div>
    </div>

    <v-btn
      elevation="2"
      class="btn-primary float-right mb-5"
      v-on:click="nextTab()"
    >
      {{ $t("normal.next") }} <v-icon right> fa-arrow-right </v-icon>
    </v-btn>
    <v-btn
      elevation="2"
      class="btn-primary float-left mb-5"
      v-on:click="backTab()"
    >
      <v-icon left> fa-arrow-left </v-icon> {{ $t("normal.previous") }}
    </v-btn>
    <template v-if="isPlayerModalVisible">
      <AccountModal
        :visible.sync="isPlayerModalVisible"
        :teamName="teamName"
        :historyId.sync="historyId"
        accountType="player"
        @save="getHistory"
      />
    </template>
  </div>
</template>
<script>
import IconImage from "@/components/IconImage.vue";
import format from "date-fns/format";
import TableFooter from "../../components/TableFooter.vue";
import { appAxios } from "../../helpers/app-axios";
import {
  playerPositionState,
  getAllPlayerPosition,
} from "../../store/team_players_histories";
import { mapState } from "vuex";
import differenceInYears from "date-fns/differenceInYears";
import AccountModal from "./AccountModal.vue";
import { languageService } from "../../helpers/language";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  components: {
    IconImage,
    TableFooter,
    AccountModal,
  },
  name: "team-tab2",
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  beforeDestroy() {
    console.log("destroy team");
    this.subscription.unsubscribe();
  },

  methods: {
    getHistory() {
      this.getCurrentPlayers();
      this.getPastPlayers();
    },
    getTeamData() {
      appAxios.get(`team/${this.teamId}`).then((res) => {
        const data = res.data.data.team;
        if (!data) return;
        this.teamData = data;
      });
    },
    getCurrentPlayers() {
      appAxios
        .get(`/team/${this.teamId}/players/present`, {
          params: this.currentPlayerParams,
        })
        .then((res) => {
          const data = res.data.data.team_player_history;
          if (!data) return;
          this.currentPlayers = data.data.map((ele) => {
            const firstname =
              ele.accounts.account_informations
                .account_information_firstname_th !== null &&
              ele.accounts.account_informations
                .account_information_firstname_th !== ""
                ? ele.accounts.account_informations
                    .account_information_firstname_th
                : "ไม่ระบุชื่อ";
            const lastname =
              ele.accounts.account_informations
                .account_information_lastname_th !== null &&
              ele.accounts.account_informations
                .account_information_lastname_th !== ""
                ? ele.accounts.account_informations
                    .account_information_lastname_th
                : "ไม่ระบุนามสกุล";
            const firstnameEN =
              ele.accounts.account_informations
                .account_information_firstname_en !== null &&
              ele.accounts.account_informations
                .account_information_firstname_en !== ""
                ? ele.accounts.account_informations
                    .account_information_firstname_en
                : "No Firstname";
            const lastnameEN =
              ele.accounts.account_informations
                .account_information_lastname_en !== null &&
              ele.accounts.account_informations
                .account_information_lastname_en !== ""
                ? ele.accounts.account_informations
                    .account_information_lastname_en
                : "No Lastname ";
            return {
              name: {
                en: firstnameEN + " " + lastnameEN,
                th: firstname + " " + lastname,
              },
              nationality: {
                en: ele.accounts.account_informations
                  .account_information_nationality_en,
                th: ele.accounts.account_informations
                  .account_information_nationality,
              },

              age: this.getAge(
                ele.accounts.account_informations.account_information_dob
              ),
              position: this.getPosition(ele.global_config_positions),
              shirtNumber: ele.team_player_history_shirt_number,
              contract: this.getLoanStatus(ele.team_player_history_loan),
              start: this.showDate(ele.team_player_history_begin_at),
              end: this.showDate(ele.team_player_history_end_at),
              profile: ele.accounts?.medias?.account_media_profile_avatar,
              id: ele.id,
            };
          });
          this.currentPlayerTotal = data.total;
          this.currentPlayerLastPage = data.lastPage;
        });
    },
    getPastPlayers() {
      appAxios
        .get(`/team/${this.teamId}/players/past`, {
          params: this.pastPlayerParams,
        })
        .then((res) => {
          const data = res.data.data.team_player_history;
          if (!data) return;
          this.pastPlayers = data.data.map((ele) => {
            const firstname =
              ele.accounts.account_informations
                .account_information_firstname_th !== null &&
              ele.accounts.account_informations
                .account_information_firstname_th !== ""
                ? ele.accounts.account_informations
                    .account_information_firstname_th
                : "ไม่ระบุชื่อ";
            const lastname =
              ele.accounts.account_informations
                .account_information_lastname_th !== null &&
              ele.accounts.account_informations
                .account_information_lastname_th !== ""
                ? ele.accounts.account_informations
                    .account_information_lastname_th
                : "ไม่ระบุนามสกุล";
            const firstnameEN =
              ele.accounts.account_informations
                .account_information_firstname_en !== null &&
              ele.accounts.account_informations
                .account_information_firstname_en !== ""
                ? ele.accounts.account_informations
                    .account_information_firstname_en
                : "No Firstname";
            const lastnameEN =
              ele.accounts.account_informations
                .account_information_lastname_en !== null &&
              ele.accounts.account_informations
                .account_information_lastname_en !== ""
                ? ele.accounts.account_informations
                    .account_information_lastname_en
                : "No Lastname ";
            return {
              name: {
                en: firstnameEN + " " + lastnameEN,
                th: firstname + " " + lastname,
              },
              nationality: {
                en: ele.accounts.account_informations
                  .account_information_nationality_en,
                th: ele.accounts.account_informations
                  .account_information_nationality,
              },
              age: this.getAge(
                ele.accounts.account_informations.account_information_dob
              ),
              position: this.getPosition(ele.global_config_positions),
              shirtNumber: ele.team_player_history_shirt_number,
              contract: this.getLoanStatus(ele.team_player_history_loan),
              start: this.showDate(ele.team_player_history_begin_at),
              end: this.showDate(ele.team_player_history_end_at),
              profile: ele.accounts?.medias?.account_media_profile_avatar,
              id: ele.id,
            };
          });
          this.pastPlayerTotal = data.total;
          this.pastPlayerLastPage = data.lastPage;
        });
    },
    editHistory(id) {
      this.historyId = id;
      this.isPlayerModalVisible = true;
    },
    async deleteHistory(id, getFn) {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            appAxios
              .post(`team/${this.teamId}/players/${id}/delete`)
              .then(() => {
                this[getFn]();
              });
          }
        }
      );
    },
    nextTab() {
      this.$router.push({ path: "", query: { tab: 2 } });
    },
    backTab() {
      this.$router.push({ path: "", query: { tab: 0 } });
    },
    getAge(birthDate) {
      if (!birthDate) return "-";
      return differenceInYears(new Date(), new Date(birthDate));
    },
    getPosition(e) {
      const dataplayerPosition = this.playerPosition.find(
        (data) => data.id == e
      );
      if (this.language === "en") {
        return dataplayerPosition?.global_config_position_name_en;
      } else {
        return dataplayerPosition?.global_config_position_name_th;
      }
    },
    getLoanStatus(e) {
      return e == false
        ? {
            en: "Permanent",
            th: "ประจำ",
          }
        : {
            en: "Loan Contract",
            th: "ยืมตัว",
          };
    },
    showDate(date) {
      if (!date) return;
      return format(new Date(date), "dd/MM/yyyy");
    },
  },
  computed: {
    //
    ...mapState({
      playerPosition: (state) =>
        state.teamPlayersHistories[playerPositionState],
      isEditable: (state) => state.permission.permissions.team_edit,
    }),
    fitColor: function () {
      // console.log(this.fit.value);
      if (this.fit.value < 50) {
        return "red";
      } else {
        return "teal";
      }
    },
    maxDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
    currentPlayerParams() {
      const params = {
        size: this.currentPlayerPerPage,
        page: this.currentPlayerPage,
        name: this.currentPlayerName,
        type_personnel: this.currentPlayerNation,
        team_player_history_loan: this.currentPlayerContract,
      };
      if (this.currentPlayerAgeRange[1] !== 0) {
        params.age_min = this.currentPlayerAgeRange[0];
        params.age_max = this.currentPlayerAgeRange[1];
      }
      return params;
    },
    pastPlayerParams() {
      const params = {
        size: this.pastPlayerPerPage,
        page: this.pastPlayerPage,
        name: this.pastPlayerName,
        type_personnel: this.pastPlayerNation,
        team_player_history_loan: this.pastPlayerContract,
      };
      if (this.pastPlayerAgeRange[1] !== 0) {
        params.age_min = this.pastPlayerAgeRange[0];
        params.age_max = this.pastPlayerAgeRange[1];
      }
      return params;
    },
    teamId() {
      // console.log(this.$route)
      return this.$route.params.id;
    },
    teamName() {
      if (this.language === "en") {
        return this.teamData?.setting_team_informations
          ?.setting_team_information_name_en;
      } else {
        return this.teamData?.setting_team_informations
          ?.setting_team_information_name_th;
      }
    },
  },

  mounted() {
    this.$data.teamId = this.$route.params.id;
    this.getTeamData();
    this.getHistory();
    this.$store.dispatch(getAllPlayerPosition);
  },

  data() {
    return {
      currentPlayers: [],
      currentPlayerPage: 1,
      currentPlayerPerPage: 10,
      currentPlayerLastPage: 1,
      currentPlayerTotal: 0,
      pastPlayers: [],
      pastPlayerPage: 1,
      pastPlayerPerPage: 10,
      pastPlayerLastPage: 1,
      pastPlayerTotal: 0,

      currentPlayerAgeRange: [0, 0],
      currentPlayerName: null,
      currentPlayerNation: null,
      currentPlayerContract: null,
      pastPlayerAgeRange: [0, 0],
      pastPlayerName: null,
      pastPlayerNation: null,
      pastPlayerContract: null,

      teamData: null,

      headers: [
        {
          text: this.$t("player.athletes_name", this.language),
          value: "name",
          sortable: false,
        },
        {
          text: this.$t("player.personal_info.nationality", this.language),
          value: "nationality",
          sortable: false,
        },
        {
          text: this.$t("player.personal_info.age", this.language),
          value: "status",
          sortable: false,
        },
        {
          text: this.$t("player.athlete_info.position", this.language),
          value: "abb",
          sortable: false,
        },
        {
          text: this.$t("player.athlete_info.shirt_number", this.language),
          value: "abb",
          sortable: false,
        },
        {
          text: this.$t("player.athlete_info.contract_type", this.language),
          value: "edit",
          sortable: false,
        },
        {
          text: this.$t("player.athlete_info.start_date", this.language),
          value: "edit",
          sortable: false,
        },
        {
          text: this.$t("player.athlete_info.end_date", this.language),
          value: "edit",
          sortable: false,
        },
        {
          text: this.$t("normal.manage", this.language),
          value: "edit",
          sortable: false,
        },
      ],
      country: [
        { value: "THAI", text: "ไทย" },
        { value: "FOREIGN", text: "ต่างชาติ" },
      ],
      historystatus: [
        { value: false, text: "ประจำ" },
        { value: true, text: "ยืมตัว" },
      ],
      isPlayerModalVisible: false,
      historyId: null,
      menuPresent: false,
      menuPast: false,
      language: "th",
    };
  },
  watch: {
    selected() {
      // return console.log(this.selected);
    },
    //
    currentPlayerPage: function () {
      this.getCurrentPlayers();
    },
    currentPlayerPerPage: function () {
      this.getCurrentPlayers();
    },
    currentPlayerName() {
      this.getCurrentPlayers();
    },
    pastPlayerPage: function () {
      this.getPastPlayers();
    },
    pastPlayerPerPage: function () {
      this.getPastPlayers();
    },
    pastPlayerName() {
      this.getPastPlayers();
    },
    // isShowTeamPlayersHistoriesModal(newVal) {
    //   console.log("newVal", newVal);
    //   if (!newVal) {
    //     this.$data.dataFormTeamPlayersHistories = null;
    //   }
    // },
    language(newVal) {
      this.headers = [
        {
          text: this.$t("player.athletes_name", newVal),
          value: "name",
          sortable: false,
        },
        {
          text: this.$t("player.personal_info.nationality", newVal),
          value: "nationality",
          sortable: false,
        },
        {
          text: this.$t("player.personal_info.age", newVal),
          value: "status",
          sortable: false,
        },
        {
          text: this.$t("player.athlete_info.position", newVal),
          value: "abb",
          sortable: false,
        },
        {
          text: this.$t("player.athlete_info.shirt_number", newVal),
          value: "abb",
          sortable: false,
        },
        {
          text: this.$t("player.athlete_info.contract_type", newVal),
          value: "edit",
          sortable: false,
        },
        {
          text: this.$t("player.athlete_info.start_date", newVal),
          value: "edit",
          sortable: false,
        },
        {
          text: this.$t("player.athlete_info.end_date", newVal),
          value: "edit",
          sortable: false,
        },
        {
          text: this.$t("normal.manage", newVal),
          value: "edit",
          sortable: false,
        },
      ];
    },
  },
};
</script>
<style lang="css" scoped>
.v-dialog > v-dialog--active {
  margin-top: auto;
}
</style>
