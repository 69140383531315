<template>
<!-- BEGIN: Main Menu-->
    <div id="navbar" class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true">
            <Navbar/>
        <div class="shadow-bottom"></div>
        <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                <!-- <li class=" nav-item"><a class="d-flex align-items-center" href="index.html"><i data-feather="home"></i><span class="menu-title text-truncate" data-i18n="Dashboards">Dashboards</span><span class="badge badge-light-warning badge-pill ml-auto mr-1">2</span></a>
                    <ul class="menu-content">
                        <li><a class="d-flex align-items-center" href="dashboard-analytics.html"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="Analytics">Analytics</span></a>
                        </li>
                        <li class="active"><a class="d-flex align-items-center" href="dashboard-ecommerce.html"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="eCommerce">eCommerce</span></a>
                        </li>
                    </ul>
                </li> -->
                <!-- <li class=" navigation-header"><span data-i18n="Apps &amp; Pages">Apps &amp; Pages</span><i data-feather="more-horizontal"></i>
                </li> -->
               <!-- <a class="d-flex align-items-center" href="/"><i data-feather="home"></i><span class="menu-title text-truncate font-weight-bolder" data-i18n="Email"> หน้าบ้าน</span></a> -->

                 <div v-if="isAdmin">
                        <div class="d-flex align-items-center homePage" @click="toAnotherPage" target="_blank" >
                        <v-icon>$home</v-icon>
                        <span data-i18n="Email" class="font-weight-bolder">{{$t("menu.home")}}</span></div></div>
                        
                <router-link v-if="checkPermission('/')" to="/" v-slot="{ navigate  }" custom
                ><li  role="link" @click="navigate" :class="[$route.name === 'Dashboard' && 'active']"  >
                        <a class="d-flex align-items-center" >

                        <v-icon :dark="$route.name === 'Dashboard'?true:false">$fileText</v-icon>
                        <span class="menu-title text-truncate font-weight-bolder" data-i18n="Chat">{{!isAdmin ? "หน้าแรก":$t("menu.report")}}</span></a></li>
                </router-link>


                
                <router-link v-if="checkPermission('/player')" to="/player" v-slot="{ navigate , isActive }" custom >
                    <li role="link" @click="navigate" :class="[isActive && 'active' , $route.name === 'AddPlayer' && 'active' , $route.name === 'EditPlayer' && 'active']" >
                        <a class="d-flex align-items-center" >
                        <!-- <i data-feather="user"></i> -->
                        <v-icon :dark="isActive">$user</v-icon>
                        <span class="menu-title text-truncate font-weight-bolder" data-i18n="Chat">{{$t("menu.player")}}</span></a></li>
                </router-link>

                <router-link v-if="checkPermission('/staff')" to="/staff" v-slot="{ navigate , isActive }" custom >
                    <li role="link" @click="navigate" :class="[isActive && 'active' , $route.name === 'AddStaff' && 'active' , $route.name === 'EditStaff' && 'active']" >
                        <a class="d-flex align-items-center" >
                        <!-- <i data-feather="users"></i> -->
                        <v-icon :dark="isActive">$users</v-icon>
                        <span class="menu-title text-truncate font-weight-bolder" data-i18n="Chat">{{$t("menu.staff")}}</span></a></li>
                </router-link>
                <router-link v-if="checkPermission('/team')" to="/team" v-slot="{ navigate , isActive }" custom >
                    <li role="link" @click="navigate" :class="[isActive && 'active' , $route.name === 'AddTeam' && 'active']" >
                        <a class="d-flex align-items-center" >
                        <!-- <i data-feather="users"></i> -->
                        <v-icon :dark="isActive">$teamIcon</v-icon>
                        <span class="menu-title text-truncate font-weight-bolder" data-i18n="Chat">{{$t("menu.team")}}</span></a></li>
                </router-link>
                <!-- <router-link v-if="checkPermission('/stadium')" to="/stadium" v-slot="{ navigate , isActive }" custom >
                    <li role="link" @click="navigate" :class="[isActive && 'active' , $route.name === 'AddStadium' && 'active']" >
                        <a class="d-flex align-items-center" >
                        <v-icon :dark="isActive">$stadiumIcon</v-icon>
                        <span class="menu-title text-truncate font-weight-bolder" data-i18n="Chat">{{$t("menu.stadium")}}</span></a></li>
                </router-link> -->
                <router-link to="/competition" v-slot="{ navigate , isActive }" custom >
                    <li role="link" @click="navigate" :class="[isActive && 'active' , $route.name === 'competition' && 'active']" >
                        <a class="d-flex align-items-center" >
                        <!-- <i data-feather="home"></i> -->
                        <v-icon :dark="isActive">$trophy</v-icon>
                        <span class="menu-title text-truncate font-weight-bolder" data-i18n="Chat">{{$t("menu.competition")}}</span></a></li>
                </router-link>

                <!-- <router-link to="/competition-user" v-slot="{ navigate , isActive }" custom >
                    <li role="link" @click="navigate" :class="[isActive && 'active' , $route.name === 'competition-user' && 'active']" >
                        <a class="d-flex align-items-center" >
                        <v-icon :dark="isActive">$trophy</v-icon>
                        <span class="menu-title text-truncate font-weight-bolder" data-i18n="Chat">รายการแข่งขัน user</span></a></li>
                </router-link> -->

                <router-link v-if="checkPermission('/register')" to="/register" v-slot="{ navigate , isExactActive }" custom>
                  <li role="link" @click="navigate" :class="[isExactActive && 'active']" >
                    <a class="d-flex align-items-center">
                      <IconImage src="assets/images/icons/register_white.svg" width="20" height="20" v-if="isExactActive" />
                      <IconImage src="assets/images/icons/register.svg" width="20" height="20" v-if="!isExactActive" />

                      <span class="menu-title text-truncate font-weight-bolder" data-i18n="Chat">{{$t("menu.register")}}</span>
                    </a>
                  </li>
                </router-link>

                <div v-if="checkPermission('/master_data')" class="app-divider">
                  <div class="border-bottom d-flex">
                    <!-- <i data-feather="settings"></i> -->
                    <v-icon small style="color: #B9B9C3;" >$setting</v-icon>
                    <small class="menu-title text-truncate ml-25" data-i18n="Setting">{{$t("menu.setting")}}</small>
                  </div>
                </div>

                <router-link v-if="checkPermission('/master_data/relation')" to="/master_data/relation" v-slot="{ navigate , isExactActive }" custom>
                  <li role="link" @click="navigate" :class="[isExactActive && 'active']" >
                    <a class="d-flex align-items-center">
                      <IconImage src="assets/images/icons/relation_white.svg" width="20" height="20" v-if="isExactActive" />
                      <IconImage src="assets/images/icons/relation.svg" width="20" height="20" v-if="!isExactActive" />
                      <span class="menu-title text-truncate font-weight-bolder" data-i18n="Chat">{{$t("menu.setting_relationship")}}</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkPermission('/master_data/education')" to="/master_data/education" v-slot="{ navigate , isExactActive }" custom>
                  <li role="link" @click="navigate" :class="[isExactActive && 'active']" >
                    <a class="d-flex align-items-center">
                      <IconImage src="assets/images/icons/education_white.svg" width="20" height="20" v-if="isExactActive" />
                      <IconImage src="assets/images/icons/education.svg" width="20" height="20" v-if="!isExactActive" />
                      <span class="menu-title text-truncate font-weight-bolder" data-i18n="Chat">{{$t("menu.setting_education")}}</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkPermission('/master_data')" to="#player" v-slot="{ isExactActive }" custom>
                  <li class="nav-item has-sub" :data-x="isExactActive">
                    <a :class="'d-flex align-items-center sub-active-' + subIsActive(['/master_data/player_position', '/master_data/sport_category', '/master_data/injury_type', '/master_data/skill', '/master_data/physical_fitness'])" href="#">
                      <IconImage src="assets/images/icons/player_white.svg" width="20" height="20" v-if="subIsActive(['/master_data/player_position', '/master_data/sport_category', '/master_data/injury_type', '/master_data/skill', '/master_data/physical_fitness'])" />
                      <IconImage src="assets/images/icons/player.svg" width="20" height="20" v-if="!subIsActive(['/master_data/player_position', '/master_data/sport_category', '/master_data/injury_type', '/master_data/skill', '/master_data/physical_fitness'])" />
                      <span class="menu-title text-truncate font-weight-bolder" data-i18n="Invoice">{{$t("menu.setting_player")}}</span>
                    </a>
                    <ul class="menu-content">
                      <router-link v-if="checkPermission('/master_data/player_position')" to="/master_data/player_position" v-slot="{ navigate, isExactActive }" custom>
                        <li @click="navigate" :class="[isExactActive && 'active']">
                          <a class="d-flex align-items-center">
                            <span class="menu-item text-truncate" data-i18n="List">{{$t("menu.position_player")}}</span>
                          </a>
                        </li>
                      </router-link>
                      <router-link v-if="checkPermission('/master_data/sport_category')" to="/master_data/sport_category" v-slot="{ navigate, isExactActive }" custom>
                        <li @click="navigate" :class="[isExactActive && 'active']">
                          <a class="d-flex align-items-center">
                            <span class="menu-item text-truncate" data-i18n="List">{{$t("menu.type_sport")}}</span>
                          </a>
                        </li>
                      </router-link>
                      <router-link v-if="checkPermission('/master_data/injury_type')" to="/master_data/injury_type" v-slot="{ navigate, isExactActive }" custom>
                        <li @click="navigate" :class="[isExactActive && 'active']">
                          <a class="d-flex align-items-center">
                            <span class="menu-item text-truncate" data-i18n="List">{{$t("menu.injury")}}</span>
                          </a>
                        </li>
                      </router-link>
                      <router-link v-if="checkPermission('/master_data/skill')" to="/master_data/skill" v-slot="{ navigate, isExactActive }" custom>
                        <li @click="navigate" :class="[isExactActive && 'active']">
                          <a class="d-flex align-items-center">
                            <span class="menu-item text-truncate" data-i18n="List">{{$t("menu.player_skill")}}</span>
                          </a>
                        </li>
                      </router-link>
                      <router-link v-if="checkPermission('/master_data/physical_fitness')" to="/master_data/physical_fitness" v-slot="{ navigate, isExactActive }" custom>
                        <li @click="navigate" :class="[isExactActive && 'active']">
                          <a class="d-flex align-items-center">
                            <span class="menu-item text-truncate" data-i18n="List">{{$t("menu.player_status")}}</span>
                          </a>
                        </li>
                      </router-link>
                    </ul>
                  </li>
                </router-link>

                <router-link v-if="checkPermission('/master_data')" to="#staff" v-slot="{ isExactActive }" custom>
                  <li class="nav-item has-sub" :data-x="isExactActive">
                    <a :class="'d-flex align-items-center sub-active-' + subIsActive(['/master_data/staff_position', '/master_data/department'])" href="#">
                      <IconImage className="icon-image" src="assets/images/icons/staff_white.svg" width="20" height="20" v-if="subIsActive(['/master_data/staff_position', '/master_data/department'])" />
                      <IconImage className="icon-image" src="assets/images/icons/staff.svg" width="20" height="20" v-if="!subIsActive(['/master_data/staff_position', '/master_data/department'])" />
                      <span class="menu-title text-truncate font-weight-bolder" data-i18n="Invoice">{{$t("menu.setting_staff")}}</span>
                    </a>
                    <ul class="menu-content">
                      <router-link v-if="checkPermission('/master_data/staff_position')" to="/master_data/staff_position" v-slot="{ navigate, isExactActive }" custom>
                        <li @click="navigate" :class="[isExactActive && 'active']">
                          <a class="d-flex align-items-center">
                            <span class="menu-item text-truncate" data-i18n="List">{{$t("menu.position_staff")}}</span>
                          </a>
                        </li>
                      </router-link>
                      <router-link v-if="checkPermission('/master_data/department')" to="/master_data/department" v-slot="{ navigate, isExactActive }" custom>
                        <li @click="navigate" :class="[isExactActive && 'active']">
                          <a class="d-flex align-items-center">
                            <span class="menu-item text-truncate" data-i18n="List">{{$t("menu.department_staff")}}</span>
                          </a>
                        </li>
                      </router-link>
                    </ul>
                  </li>
                </router-link>
                <router-link v-if="checkPermission('/master_data')" to="#award" v-slot="{ isExactActive }" custom>
                  <li class="nav-item has-sub" :data-x="isExactActive">
                    <a :class="'d-flex align-items-center sub-active-' + subIsActive(['/master_data/award'])" href="#">
                      <IconImage className="icon-image" src="assets/images/icons/award_white.svg" width="20" height="20" v-if="subIsActive(['/master_data/award'])" />
                      <IconImage className="icon-image" src="assets/images/icons/award.svg" width="20" height="20" v-if="!subIsActive(['/master_data/award'])" />
                      <span class="menu-title text-truncate font-weight-bolder" data-i18n="Invoice">{{$t("menu.setting_competition")}}</span>
                    </a>
                    <ul class="menu-content">
                      <router-link v-if="checkPermission('/master_data/award')" to="/master_data/award" v-slot="{ navigate, isExactActive }" custom>
                        <li @click="navigate" :class="[isExactActive && 'active']">
                          <a class="d-flex align-items-center">
                            <span class="menu-item text-truncate" data-i18n="List">{{$t("menu.setting_award")}}</span>
                          </a>
                        </li>
                      </router-link>
                    </ul>
                  </li>
                </router-link>
                <!-- <router-link v-if="checkPermission('/association')" to="/association" v-slot="{ navigate , isActive }" custom>
                  <li role="link" @click="navigate" :class="[isActive && 'active']" >
                    <a class="d-flex align-items-center">
                        <v-icon :dark="isActive">$association</v-icon>
                      <span class="menu-title text-truncate font-weight-bolder" data-i18n="Chat">{{$t("menu.setting_association")}}</span>
                    </a>
                  </li>
                </router-link>-->
            </ul>
        </div>
    </div>
    <!-- END: Main Menu-->
</template>
<script>
import Navbar from './Navbar.vue';
import IconImage from '@/components/IconImage.vue'
import { mapState } from 'vuex';
import { checkPermission } from '../../helpers/checkPermission';

export default {
    name: 'navbar',
    components:{
        Navbar,
        IconImage,
    },
    methods: {
      subIsActive(input) {
        const paths = Array.isArray(input) ? input : [input]
        return paths.some(path => {
          return this.$route.path.indexOf(path) === 0 // current path starts with this path string
        })
      },
      checkPermission(route) {
        return checkPermission(route, this.permission);
      },
      toAnotherPage(){
        const token = localStorage.getItem(process.env.VUE_APP_TOKEN_KEY_ADMIN)
        // window.open(`http://localhost:8081/sso?token=${token}`, '_blank')
					window.open(`${process.env.VUE_APP_API}/sso?token=${token}`, '_blank')
      }
    },
    computed: {
      ...mapState({
        permission: state => state.permission.permissions
      }),
      displayLocale(){
        return this.$i18n.locale
      },
      isAdmin() {
			return this.$store.state.permission.role !== "B_TEAM_ADMIN";
		},
    },
    mounted(){
    }
}
</script>
<style lang="scss">
.main-menu .icon-image {
  margin-right: 1.1rem;
}
.has-sub .menu-content a {
  padding: 10px 15px 10px 50px !important;
}
.has-sub .menu-content .active {
  background: none !important;
  box-shadow: none !important;
}
.has-sub .menu-content a {
  font-size: 14px;
}
.has-sub .menu-content .active a {
  font-weight: bold;
  color: #0066FF !important;
}
.sidebar-group-active:not(.open) > a {
  /* background: none !important; */
  border-radius: 6px;
}
.sub-active-true {
  background: linear-gradient(46.62deg, #0062FF 0%, #4CB1FB 93.64%) !important;
  /* box-shadow: 0 0 10px 1px rgb(115 103 240 / 70%); */
  color: #FFFFFF !important;
  font-weight: 400;
}
.app-divider {
  padding: 10px 15px 10px 15px;
  margin: 0 15px;
  cursor: default;
  color: #B9B9C3;
  i {
    font-size: 1.1em;;
  }
}
.homePage{
  margin:0 15px;
  padding:10px 15px;
  cursor:pointer
}
.homePage svg{
  margin-right: 17.6px;
}
</style>
