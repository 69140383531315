<template>
	<v-card-text
		class='drop-zone'
		@drop.prevent="onDrop($event)"
		@dragover.prevent="dragover = true"
		@dragenter.prevent="dragover = true"
		@dragleave.prevent="dragover = false"
	>
		<v-row class="d-flex flex-column" dense align="center" justify="center">
			<input type="file"
				:name="uploadFieldName"
				:disabled="isSaving"
				@change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
				:accept="accept" class="input-file"
			>
			<h3 class="content-header-title float-left mb-0 ml-2">
				<slot name="title">ลากรูปภาพลงที่นี่หรือคลิกเพื่ออัปโหลด</slot>
			</h3>
			<p>
				<slot name="condition">(รองรับไฟล์ .jpg .jpeg .png)</slot>
			</p>
			<v-progress-linear
				v-model="progress[type]"
				v-if="progress[type]"
				color="blue-grey"
				height="25"
			>
				<template v-slot:default="{ value }">
					<strong>{{ Math.ceil(value) }}%</strong>
				</template>
			</v-progress-linear>
		</v-row>
	</v-card-text>
</template>

<script>
import { mapActions, mapState } from 'vuex';
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
	name: 'UploadMedia',

	props: ['accept', 'type'],

	mounted() {
		this.competitionId = this.$route.params.cid
		this.subCompetitionId = this.$route.params.sid
	},

	methods: {
		...mapActions('sub_competition', ['uploadMedia']),

    onDrop(e) {
      this.dragover = false;

			const formData = new FormData();
			var invalid = [];
			e.dataTransfer.files.forEach(element => {
				let filetype = this.getFileType(element)
				if (filetype != this.type) {
					invalid.push(element)
				}
				formData.append('file', element, element.name)
			});
			if (invalid.length) return;

			this.uploadMedia({ cid: this.competitionId, sid: this.subCompetitionId, formData, type: this.type })
    },

		filesChange(fieldName, fileList) {
			if (!fileList.length) return;

			let invalid = Array
				.from(Array(fileList.length).keys())
				.filter(x => {
					let filetype = this.getFileType(fileList[x])
					return filetype != this.type
				});
			if (invalid.length) return;
			
			const formData = new FormData();
			
			Array
				.from(Array(fileList.length).keys())
				.map(x => {
					formData.append(fieldName, fileList[x], fileList[x].name);
				});

			this.uploadMedia({ cid: this.competitionId, sid: this.subCompetitionId, formData, type: this.type })
		},

		getFileType(file) {
			if(file.type.match('image.*'))
				return 'image';

			if(file.type.match('video.*'))
				return 'video';

			if(file.type.match('audio.*'))
				return 'audio';

			return 'other';
		}
	},

	computed: {
		...mapState('sub_competition', ['progress']),
		
		isInitial() {
			return this.currentStatus === STATUS_INITIAL;
		},
		isSaving() {
			return this.currentStatus === STATUS_SAVING;
		},
		isSuccess() {
			return this.currentStatus === STATUS_SUCCESS;
		},
		isFailed() {
			return this.currentStatus === STATUS_FAILED;
		}
	},

	data() {
		return {
			competitionId: null,
			subCompetitionId: null,
			fileCount: 0,
			medias: [],
			dragover: false,
			uploadFieldName: 'file'
		}
	}
}
</script>

<style scoped>
.input-file {
	opacity: 0; /* invisible but it's there! */
	width: 100%;
	height: 200px;
	position: absolute;
	cursor: pointer;
}
</style>