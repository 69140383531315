import Stadium from '../views/Stadium/Stadium.vue'
import AddStadium from '../views/Stadium/AddStadium.vue'
import permissionGuard from './guard/permissionGuard';

const stadium = [
    {
        path: '/stadium',
        name: 'Stadium',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter: permissionGuard,
        component: Stadium
    },
    {
        path: '/stadium/add/',
        name: 'AddStadium',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter: permissionGuard,
        component: AddStadium
    },
    {
        path: '/stadium/edit/:id',
        name: 'EditStadium',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter: permissionGuard,
        component: AddStadium,
        props: true
    }
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
]

export default stadium;
