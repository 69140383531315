<template>
  <div class="row justify-content-center">
    <div class="col-md-12 col-12 col-xl-10">
      <div>
        <div class="card-body">
          <div>
            <h4 class="card-title text-primary">
              {{ $t("player.document.manage_document") }}
            </h4>
          </div>
          <div class="bg-light">
            <div class="card-body ml-4">
              <div>
                <h4 class="card-title text-primary">
                  {{ $t("player.document.examine_document") }}
                </h4>
              </div>
              <div class="row ml-4">
                <div
                  v-for="(item, idx) in documents"
                  :key="idx"
                  class="col-6 col-md-3 test"
                >
                  <v-checkbox
                    v-model="item.value"
                    readonly
                    color="success"
                    hide-details
                    :ripple="false"
                  >
                    <template v-slot:label>
                      <a
                        href="https://storage.dadevelopmentportal.com/associate-football/Bigdata/players/images/843f8ffd-1a9e-4be2-aed4-28f3f2e06d32.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=associate-football%2F20210828%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210828T084122Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=7aece0821d134e3ec3b20b4efaea1bdf16ab95b09688995e2795677812c3f5d4"
                        download="filename.txt"
                      />
                      <!--@click="() => download(item.link, item.label)"-->
                      <a
                        @click="() => download(item.link, item.label_th)"
                        style="text-decoration: underline; color: #0066ff"
                        v-if="item.value"
                      >
                        <div v-if="language === 'th'">{{ item.label_th }}</div>
                        <div v-else>{{ item.label_en }}</div>
                      </a>
                      <template v-else>
                        <div v-if="language === 'th'">{{ item.label_th }}</div>
                        <div v-else>{{ item.label_en }}</div>
                      </template>
                    </template>
                  </v-checkbox>
                </div>
              </div>
            </div>
            <div class="card-body ml-4">
              <div>
                <h4 class="card-title text-primary">
                  {{ $t("player.document.other_document") }}
                </h4>
              </div>
              <div class="row ml-4">
                <div
                  v-for="item in otherDocs"
                  :key="item.id"
                  class="col-6 col-md-3"
                >
                  <v-checkbox
                    value="checked"
                    readonly
                    color="success"
                    hide-details
                    :ripple="false"
                  >
                    <template v-slot:label>
                      <div
                        @click="
                          () =>
                            download(
                              item.account_media_url,
                              item.account_media_name
                            )
                        "
                        style="text-decoration: underline; color: #0066ff"
                      >
                        <div class="textWord">
                          {{ item.account_media_name }}
                        </div>
                      </div>
                      <v-icon
                        v-if="isEditable"
                        @click="() => deleteMedia(item.id)"
                        color="red"
                        >fa-trash</v-icon
                      >
                    </template>
                  </v-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="isEditable">
          <div
            v-for="(item, idx) in form"
            :key="`form-${idx}`"
            class="card-body"
          >
            <div>
              <h4 class="card-title text-primary">
                {{ item.label[language] }}
              </h4>
            </div>
            <vue-dropzone
              id="picture"
              :options="item.options"
              :useCustomSlot="true"
              :ref="item.key"
              @vdropzone-success="(file) => onUploadSuccess(file, item.key)"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  {{ $t("player.document.drag_document") }}
                </h3>
                <div class="subtitle">
                  ({{ $t("player.document.sub_document") }})
                </div>
                <div class="subtitle">
                  ({{ $t("player.document.second_sub_document") }})
                </div>
              </div>
            </vue-dropzone>
          </div>
        </template>
      </div>

      <v-btn
        elevation="2"
        class="btn-primary float-left mb-5"
        v-on:click="backTab()"
      >
        <v-icon left> fa-arrow-left </v-icon> {{ $t("normal.previous") }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { appAxios } from "../../helpers/app-axios";
import { languageService } from "../../helpers/language";
import MasterDataHelper from "@/helpers/master_data_helper.js";

const playerDocs = (options) => [
  {
    options: options("citizen"),
    key: "getCitizenDoc",
    label: {
      en: "ID Card",
      th: "ภาพเอกสารบัตรประชาชน",
    },
  },
  {
    options: options("passport"),
    key: "getPassportDoc",
    label: {
      en: "Passport Card",
      th: "ภาพเอกสารพาสปอร์ต",
    },
  },
  {
    options: options("house-particulars"),
    key: "getHouseParticularDoc",
    label: {
      en: "Registration House",
      th: "ภาพเอกสารข้อมูลทะเบียนบ้าน",
    },
  },
  {
    options: options("code-personel"),
    key: "getCodePersonelDoc",
    label: {
      en: "Athletes Card",
      th: "ภาพบัตรประจำตัวนักกีฬา",
    },
  },
  {
    options: options("other"),
    key: "getOtherDoc",
    label: {
      en: "Other Document",
      th: "เอกสารอื่น ๆ",
    },
  },
];

const staffDocs = (options) => [
  {
    options: options("citizen"),
    key: "getCitizenDoc",
    label: {
      en: "ID Card",
      th: "ภาพเอกสารบัตรประชาชน",
    },
  },
  {
    options: options("passport"),
    key: "getPassportDoc",
    label: {
      en: "Passport Card",
      th: "ภาพเอกสารพาสปอร์ต",
    },
  },
  {
    options: options("house-particulars"),
    key: "getHouseParticularDoc",
    label: {
      en: "Registration House",
      th: "ภาพเอกสารข้อมูลทะเบียนบ้าน",
    },
  },
  {
    options: options("code-personel"),
    key: "getCodePersonelDoc",
    label: {
      en: "Staff Card",
      th: "ภาพบัตรประจำตัวบุคลากร",
    },
  },
  {
    options: options("other"),
    key: "getOtherDoc",
    label: {
      en: "Other Document",
      th: "เอกสารอื่น ๆ",
    },
  },
];

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  name: "player-tabs8",
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  mounted() {
    const accountId = this.$route.params.id;
    this.$data.accountId = accountId;
    if (this.$route.path.includes("player")) {
      this.$data.isPlayerRoute = true;
      this.$data.form = playerDocs(this.getOptions());
    } else {
      this.$data.form = staffDocs(this.getOptions());
    }
    this.getCitizenDoc();
    this.getPassportDoc();
    this.getHouseParticularDoc();
    this.getCodePersonelDoc();
    this.getOtherDoc();
  },
  methods: {
    nextTab() {
      // console.log(this.$router.push("/player"));
      //this.$vuetify.goTo(0)
      //console.log(this.tabs);
    },
    backTab() {
      this.$router.push({ path: "", query: { tab: 6 } });
      //console.log(this.tabs);
    },
    async getDoc(type) {
      return appAxios.get(
        `account/${this.$data.accountId}/medias/${
          this.$data.isPlayerRoute ? "players" : "staffs"
        }/documents/${type}`
      );
    },
    async getCitizenDoc() {
      return this.getDoc("citizen").then((res) => {
        const citizenIdx = this.$data.documents.findIndex(
          (ele) => ele.key === "citizen"
        );
        const data = res.data?.data?.media;
        if (citizenIdx !== -1 && data) {
          this.$data.documents[citizenIdx].value = true;
          this.$data.documents[citizenIdx].link = data.account_media_url;
        }
      });
    },
    async getPassportDoc() {
      return this.getDoc("passport").then((res) => {
        const passportIdx = this.$data.documents.findIndex(
          (ele) => ele.key === "passport"
        );
        const data = res.data?.data?.media;
        if (passportIdx !== -1 && data) {
          this.$data.documents[passportIdx].value = true;
          this.$data.documents[passportIdx].link = data.account_media_url;
        }
      });
    },
    async getHouseParticularDoc() {
      return this.getDoc("house-particulars").then((res) => {
        const houseIdx = this.$data.documents.findIndex(
          (ele) => ele.key === "house"
        );
        const data = res.data?.data?.media;
        if (houseIdx !== -1 && data) {
          this.$data.documents[houseIdx].value = true;
          this.$data.documents[houseIdx].link = data.account_media_url;
        }
      });
    },
    async getCodePersonelDoc() {
      return this.getDoc("code-personel").then((res) => {
        const codeIdx = this.$data.documents.findIndex(
          (ele) => ele.key === "code"
        );
        const data = res.data?.data?.media;
        if (codeIdx !== -1 && data) {
          this.$data.documents[codeIdx].value = true;
          this.$data.documents[codeIdx].link = data.account_media_url;
        }
      });
    },
    async getOtherDoc() {
      return this.getDoc("other").then((res) => {
        // const citizen = this.$data.documents.find(ele => key === 'citizen');
        const data = res.data?.data?.media;
        if (data) {
          this.$data.otherDocs = data;
        }
      });
    },
    async download(url, name) {
      var link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);

      // link.download = name;
      link.target = "_blank";
      document.body.appendChild(link);

      link.click();
    },
    async deleteMedia(fileId) {
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            appAxios
              .post(`account/${this.accountId}/medias/${fileId}/delete`)
              .then(() => {
                this.getOtherDoc();
              });
          }
        }
      );
    },
    async onUploadSuccess(file, refName) {
      this.$refs[refName][0].removeFile(file);
      this[refName]();
    },
    getOptions() {
      const token = localStorage.getItem(process.env.VUE_APP_TOKEN_KEY_ADMIN);
      return (type) => ({
        url: `/api/v1/account/${this.$data.accountId}/medias/${
          this.$data.isPlayerRoute ? "players" : "staffs"
        }/documents/${type}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        acceptedFiles: "application/pdf,image/jpeg,image/jpg,image/png",
        thumbnailWidth: 200,
        addRemoveLinks: true,
      });
    },
  },
  computed: {
    isEditable() {
      if (this.$data.isPlayerRoute) {
        return this.$store.state.permission?.permissions?.athlete_edit;
      }
      return this.$store.state.permission?.permissions?.staff_edit;
    },
  },
  data() {
    return {
      isPlayerRoute: false,
      accountId: null,
      otherDocs: [],
      documents: [
        {
          label_th: "ภาพเอกสารบัตรประชาชน",
          label_en: "ID Card",
          value: false,
          link: "",
          key: "citizen",
        },
        {
          label_th: "ภาพเอกสารพาสปอร์ต",
          label_en: "Passport Card",
          value: false,
          link: "",
          key: "passport",
        },
        {
          label_th: "ภาพเอกสารข้อมูลทะเบียนบ้าน",
          value: false,
          link: "",
          key: "house",
          label_en: "Registration House",
        },
        {
          label_th: "ภาพบัตรประจำตัว",
          value: false,
          label_en: "Card",
          link: "",
          key: "code",
        },
      ],
      form: [],
    };
  },
};
</script>
<style scoped>
.dropzone {
  min-height: 200px;
  border: 2px dashed #1161e0;
  background: #f8f8f8;
  position: relative;
}
.test {
  display: -webkit-box;
  max-width: 400px;
  height: 109.2px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.625;
}
.textWord {
  word-break: break-all;
}
</style>
