import Competition from "../views/Competition/Home.vue";
import AddUpdate from "../views/Competition/AddUpdate.vue";
import CompetitionDetail from "../views/Competition/CompetitionDetail.vue";
import SubAddUpdate from "../views/Competition/Sub/AddUpdate.vue";
import SubCompetitionDetail from "../views/Competition/Sub/SubCompetitionDetail.vue";
import permissionGuard from "./guard/permissionGuard";
import AddPlayerInTeam from '../views/Competition/Sub/AddPlayerInTeam.vue'
import CompetitionResult from "../views/Competition/Result/CompetitionResult.vue";
import CompetitionUser from "../views/Competition/User/CompeititonUser.vue";
const competition = [
  {
    path: "/competition",
    name: "competition",
    beforeEnter: permissionGuard,
    component: Competition,
  },
  {
    path: "/competition-user",
    name: "competition-user",
    beforeEnter: permissionGuard,
    component: CompetitionUser,
  },
  {
    path: "/competition/add",
    name: "competition-add",
    beforeEnter: permissionGuard,
    component: AddUpdate,
  },
  {
    path: "/competition/:id/update",
    name: "competition-update",
    beforeEnter: permissionGuard,
    component: AddUpdate,
  },
  {
    path: "/competition/:id",
    name: "competition-detail",
    beforeEnter: permissionGuard,
    component: CompetitionDetail,
  },
  {
    path: "/competition/:cid/sub-competition/add",
    name: "add-sub-competition",
    beforeEnter: permissionGuard,
    component: SubAddUpdate,
  },
  {
    path: "/competition/:cid/sub-competition/:sid/tab/:tab",
    name: "sub-competition-detail",
    component: SubCompetitionDetail,
  },
  {
    path: "/competition/:cid/sub-competition/:sid/tab/:tab/:list",
    name: "sub-competition-list",
    component: AddPlayerInTeam,
  },
  {
    path: "/competition/:cid/sub-competition/:sid/update",
    name: "sub-competition-update",
    beforeEnter: permissionGuard,
    component: SubAddUpdate,
  },
  {
    path: "/competition/result-management",
    name: "competition-result",
    beforeEnter: permissionGuard,
    component: CompetitionResult,
  },
];

export default competition;
