<template>
  <v-row>
    <v-col cols="5" style="padding: 0">
      <div class="rounded-lg mb-2" style="border: solid 1px #e2e2ea">
        <v-sheet
          class="rounded-lg"
          color="d-flex justify-content-center"
          style="background-color: #e2e2ea; padding: 6px; margin-bottom: 10px"
          outlined
        >
          <span class="ma-2">{{
            $t("versus_match.player_management.main_player")
          }}</span>
        </v-sheet>

        <div
          class="d-flex justify-content-center align-items-center"
          :class="captainClass"
          outlined
        >
          <div
            v-if="captain.length == 0"
            style="width: 90%; position: absolute"
            class="player-card information"
          >
            <v-avatar color="#267FFF" size="24" style="margin: 6px"
              ><span class="badge">C</span></v-avatar
            >
            <span style="color: #b9b9c3">{{
              $t("versus_match.player_management.drag_captain")
            }}</span>
          </div>
          <draggable
            v-model="captain"
            v-bind="singleOptions"
            style="min-height: 51px; min-width: 100%"
          >
            <template v-for="(player, index) in captain">
              <player-card
                :player="player"
                :key="index"
                :selected="true"
                :captain="true"
              />
            </template>
          </draggable>
        </div>

        <hr style="margin: 6px 0px" />

        <div
          class="d-flex justify-content-center align-items-center"
          :class="viceCaptainClass"
          outlined
        >
          <div
            v-if="vice_captain.length == 0"
            style="width: 90%; position: absolute"
            class="player-card information"
          >
            <v-avatar color="#267FFF" size="24" style="margin: 6px"
              ><span class="badge">VC</span></v-avatar
            >
            <span style="color: #b9b9c3">{{
              $t("versus_match.player_management.drag_vice_captain")
            }}</span>
          </div>
          <draggable
            v-model="vice_captain"
            v-bind="singleOptions"
            style="min-height: 51px; min-width: 100%"
          >
            <template v-for="(player, index) in vice_captain">
              <player-card
                :player="player"
                :key="index"
                :selected="true"
                :vice_captain="true"
              />
            </template>
          </draggable>
        </div>

        <hr style="margin: 6px 0px" />

        <div
          class="d-flex justify-content-center align-items-center"
          :class="lineupClass"
          outlined
        >
          <div
            v-if="lineup.length == 0"
            style="width: 90%; position: absolute"
            class="player-card information"
          >
            <span style="color: #b9b9c3">{{
              $t("versus_match.player_management.drag_main_player")
            }}</span>
          </div>
          <draggable
            v-model="lineup"
            v-bind="{ group: `player_${side}` }"
            style="min-height: 144px; min-width: 100%"
          >
            <template v-for="(player, index) in lineup">
              <player-card :player="player" :key="index" :selected="true" />
            </template>
          </draggable>
        </div>
      </div>

      <div class="rounded-lg mb-2" style="border: solid 1px #e2e2ea">
        <v-sheet
          class="rounded-lg"
          color="d-flex justify-content-center"
          style="background-color: #e2e2ea; padding: 6px; margin-bottom: 10px"
          outlined
        >
          <span class="ma-2">{{
            $t("versus_match.player_management.sub_player")
          }}</span>
        </v-sheet>

        <div
          class="d-flex justify-content-center align-items-center"
          :class="subClass"
          outlined
        >
          <div
            v-if="sub.length == 0"
            style="width: 90%; position: absolute"
            class="player-card information"
          >
            <span style="color: #b9b9c3">{{
              $t("versus_match.player_management.drag_sub_player")
            }}</span>
          </div>
          <draggable
            v-model="sub"
            v-bind="{ group: `player_${side}` }"
            style="min-height: 144px; min-width: 100%"
          >
            <template v-for="(player, index) in sub">
              <player-card :player="player" :key="index" :selected="true" />
            </template>
          </draggable>
        </div>
      </div>
    </v-col>
    <v-col cols="7" style="padding: 0px 8px">
      <div
        class="rounded-md border-top border-bottom d-flex justify-space-around"
        color="grey darken-2"
        style="padding: 6px 0px; margin-bottom: 8px"
        outlined
      >
        <span>{{ $t("menu.player") }}</span>
        <span>{{ $t("player.table.position") }}</span>
      </div>
      <draggable
        v-model="all"
        v-bind="{ group: `player_${side}` }"
        style="min-height: 440px"
      >
        <template v-for="(player, index) in all">
          <player-card :player="player" :key="index" @delete="deletePlayer" />
        </template>
      </draggable>
      <div class="text-primary">
        {{ $t("player.add_player") }}
      </div>
      <div class="d-flex justify-space-between align-end mt-6 mb-2">
        <span>{{ $t("player.athletes_name") }}</span>
        <v-btn color="primary" small @click="addPlayer" :disabled="!team">{{
          $t("versus_match.player_management.add_player")
        }}</v-btn>
      </div>
      <v-text-field
        v-model="player.name"
        dense
        outlined
        hide-details
        placeholder="Name (TH)"
      ></v-text-field>
      <v-text-field
        class="mt-3"
        v-model="player.name_en"
        dense
        outlined
        hide-details
        placeholder="Name (EN)"
      ></v-text-field>
      <v-row class="mt-4">
        <v-col cols="6" class="py-0">
          {{ $t("player.athlete_info.position") }}
        </v-col>
        <v-col cols="6" class="py-0">
          {{ $t("player.athlete_info.shirt_number") }}
        </v-col>
      </v-row>
      <v-row class="mb-6">
        <v-col cols="6" class="py-0">
          <v-select
            v-model="player.position"
            placeholder="Position"
            :value="player.position || ''"
            :items="positions"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field
            type="number"
            v-model="player.number"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
import PlayerCard from "./Player.vue";

export default {
  name: "PlayerManagement",

  props: ["players", "side", "team"],

  components: {
    draggable,
    PlayerCard,
  },

  data() {
    return {
      all: JSON.parse(JSON.stringify(this.players)),
      captain: [],
      vice_captain: [],
      lineup: [],
      sub: [],
      singleOptions: {
        group: {
          name: `player_${this.side}`,
          put: function (to, from) {
            return (
              to.el.children.length < 1 &&
              to.options.group.name == from.options.group.name
            );
          },
        },
      },
      player: {},
    };
  },

  watch: {
    players(val) {
      this.captain = [];
      this.vice_captain = [];
      this.lineup = [];
      this.sub = [];

      let team = [];
      if (this.side == "home") {
        team = this.resultForm.versus_match_team_left;
      } else if (this.side == "away") {
        team = this.resultForm.versus_match_team_right;
      }

      let notPick = JSON.parse(JSON.stringify(val));
      let players =
        team.versus_match_team_detail_player?.filter((tplayer) => {
          if (tplayer.accountId) {
            let player = val.find(
              (player) => player.accountId == tplayer.accountId
            );
            if (player) {
              notPick = notPick.filter((p) => p.accountId != player.accountId);
              this.mapPlayer(tplayer);
            }
            return false;
          } else if (
            tplayer.main != undefined ||
            tplayer.main != null ||
            tplayer.vice_captain ||
            tplayer.captain
          ) {
            this.mapPlayer(tplayer);
            return false;
          }
          return true;
        }) || [];

      this.$set(this, "all", players.concat(notPick));
    },
    captain() {
      this[this.side].captain = this.captain;
    },
    vice_captain() {
      this[this.side].vice_captain = this.vice_captain;
    },
    sub() {
      this[this.side].sub = this.sub;
    },
    lineup() {
      this[this.side].lineup = this.lineup;
    },
    all() {
      // console.log(this.all);
    },
  },

  computed: {
    // ...mapState('data_model', ['positions']),
    ...mapState("match", ["resultForm", "home", "away", "positions"]),

    captainClass() {
      return this.captain.length == 0 ? "open-selection" : "";
    },
    viceCaptainClass() {
      return this.vice_captain.length == 0 ? "open-selection" : "";
    },
    lineupClass() {
      return this.lineup.length == 0 ? "open-selection" : "";
    },
    subClass() {
      return this.sub.length == 0 ? "open-selection" : "";
    },
  },

  methods: {
    addPlayer() {
      console.log("this.player", this.player);
      let newPlayer = {
        accountName: this.player.name,
        accountPosition: {
          global_config_position_abbreviation: this.player.position,
        },
        accountNameEn: this.player.name_en,
        accountShirtNumber: this.player.number,
      };
      this.$set(this.all, this.all.length, newPlayer);
      this.player = {};
    },
    deletePlayer(item) {
      let index = this.all.findIndex((p) => p == item);
      if (index >= 0) {
        this.$delete(this.all, index);
      }
    },
    mapPlayer(player) {
      if (player.captain) {
        this.captain.push(player);
      } else if (player.vice_captain) {
        this.vice_captain.push(player);
      } else if (player.main == "true" || player.main == true) {
        this.lineup.push(player);
      } else {
        this.sub.push(player);
      }
    },
  },
};
</script>

<style scoped>
.open-selection {
  border: 2px dashed #1161e0;
  border-radius: 10px;
  margin: 4px 12px;
}
.player-card.information {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>