<template>
  <div class="mb-5">
    <h4 class="text-primary mb-5">
      {{ $t("player.personal_info.contact_info") }}
    </h4>
    <div
      class="row col-md-12 m-0 p-0 d-flex justify-content-center"
      v-if="!dataContact.nodata"
    >
      <label>{{ $t("normal.no_data") }}</label>
    </div>
    <div class="row" v-if="dataContact.nodata">
      <div class="col-sm-1"></div>
      <div class="col-md-11">
        <div class="row">
          <div class="col-md-2 mx-0 px-0 bold text-primary py-1">
            {{ $t("contact_information.phone2") }}
          </div>
          <div class="col-md-10 pl-6 py-1">{{ dataContact.phone }}</div>
        </div>
      </div>
    </div>
    <div class="row" v-if="dataContact.nodata">
      <div class="col-sm-1"></div>
      <div class="col-md-11">
        <div class="row">
          <div class="col-md-2 mx-0 px-0 bold text-primary py-1">E-mail</div>
          <div class="col-md-10 pl-6 py-1">{{ dataContact.email }}</div>
        </div>
      </div>
    </div>
    <div class="row" v-if="dataContact.nodata">
      <div class="col-sm-1"></div>
      <div class="col-md-11">
        <div class="row">
          <div class="col-md-2 mx-0 px-0 bold text-primary py-1">Facebook</div>
          <div class="col-md-10 pl-6 py-1">{{ dataContact.facebook }}</div>
        </div>
      </div>
    </div>
    <div class="row" v-if="dataContact.nodata">
      <div class="col-sm-1"></div>
      <div class="col-md-11">
        <div class="row">
          <div class="col-md-2 mx-0 px-0 bold text-primary py-1">LINE id</div>
          <div class="col-md-10 pl-6 py-1">{{ dataContact.line }}</div>
        </div>
      </div>
    </div>
    <div class="row" v-if="dataContact.nodata">
      <div class="col-sm-1"></div>
      <div class="col-md-11">
        <div class="row">
          <div class="col-md-2 mx-0 px-0 bold text-primary py-1">Instagram</div>
          <div class="col-md-10 pl-6 py-1">{{ dataContact.instagram }}</div>
        </div>
      </div>
    </div>
    <div class="row" v-if="dataContact.nodata">
      <div class="col-sm-1"></div>
      <div class="col-md-11">
        <div class="row">
          <div class="col-md-2 mx-0 px-0 bold text-primary py-1">Youtube</div>
          <div class="col-md-10 pl-6 py-1">{{ dataContact.youtube }}</div>
        </div>
      </div>
    </div>
    <div class="row" v-if="dataContact.nodata">
      <div class="col-sm-1"></div>
      <div class="col-md-11">
        <div class="row">
          <div class="col-md-2 mx-0 px-0 bold text-primary py-1">twitter</div>
          <div class="col-md-10 pl-6 py-1">{{ dataContact.twitter }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { languageService } from "../../helpers/language";

export default {
  name: "contactInformation",
  props: ["contact"],
  data() {
    return {
      language: "th",
      dataContact: {},
    };
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  mounted() {
    if (!this.dataContact.nodata) {
      this.$emit("heightSize", 70);
    } else {
      this.$emit("heightSize", 270);
    }
  },
  watch: {
    contact() {
      this.dataContact = {
        nodata: this.contact,
        phone: this.contact?.account_information_phone || "-",
        email: this.contact?.account_social_contact_email || "-",
        facebook: this.contact?.account_social_contact_facebook || "-",
        line: this.contact?.account_social_contact_line || "-",
        instagram: this.contact?.account_social_contact_instagram || "-",
        youtube: this.contact?.account_social_contact_youtube || "-",
        twitter: this.contact?.account_social_contact_twitter || "-",
      };
    },
  },
};
</script>