<template>
  <v-app :id="model.nameSingular">
    <div class="app-content content">
      <div class="content-wrapper">
        <div class="content-body content-bg">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="master-data-title">
                {{ $t('setting_player_skill.title') }}
              </div>
            </div>
            <div class="col-12 col-md-6">
              <v-btn
                color="primary"
                class="m-l-5px float-right"
                v-if="isEditable"
                @click="handleSubmit"
              >
                {{ $t('normal.save') }}
              </v-btn>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12">
                  {{ $t('setting_player_skill.name_th') }}
                </div>
                <div class="col-12" v-for="(item, index) in skills" :key="index">
                  <div class="input-label-number">
                    <div class="label-number">{{index + 1}}.</div>
                    <input type="text" class="form-control" :id="'name_th' + index" :readonly="!isEditable" v-model="input[index].global_config_skill_name_th">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12">
                  {{ $t('setting_player_skill.name_en') }}
                </div>
                <div class="col-12" v-for="(item, index) in skills" :key="index">
                  <div class="input-label-number">
                    <div class="label-number">{{index + 1}}.</div>
                    <input type="text" class="form-control" :id="'name_en' + index" :readonly="!isEditable" v-model="input[index].global_config_skill_name_en">
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
// change here
const modelNameSingular = 'skill'
const modelNamePlural = 'skills'

// non change
const getAllName = `get${modelNamePlural.charAt(0).toUpperCase() + modelNamePlural.slice(1)}`

export default {
  name: modelNameSingular,
  components:{
  },
  data() {
    return {
      model: {
        nameSingular: modelNameSingular,
        namePlural: modelNamePlural,
      },
    }
  },
  computed : {
    input() {
      const input = this.$store.state[modelNameSingular][modelNamePlural].map((data) => ({ 
        id: data.id,
        global_config_skill_name_th: data.global_config_skill_name_th,
        global_config_skill_name_en: data.global_config_skill_name_en,
      }))
      return input
    },
    [modelNamePlural]() {
      return this.$store.state[modelNameSingular][modelNamePlural]
    },
    isEditable() {
      return this.$store.state.permission.permissions.setting_edit
    }
  },
  created() {
    this.getAll()
  },
  methods: {
    getAll() {
      this.$store.dispatch(getAllName)
    },
    saveSkill() {
      this.$store.dispatch('saveSkill', this.input)
    },
    handleSubmit(e) {
      e.preventDefault()
      if (!this.isEditable) return;
      this.saveSkill()
    },
  },
}
</script>

<style>
</style>
