<template>
  <v-app>
    <div class="content app-content h-100">
      <div class="container">
        <h2>หน้าแรก</h2>
        <!-- <div>ข้อมูลทีม</div> -->
        <div class="bg-white px-5">
          <div class="d-flex mb-10  justify-content-between align-center">
            <div class="rounded px-4 py-4 text-primary">
              ข้อมูลทีม 
            </div>
            <v-btn color="primary" @click="handleEdit">{{
              $t("normal.edit")
            }}</v-btn>
          </div>
          <div class="row" v-if="Object.keys(this.personalData).length > 0 ">
            <div class="col-4">
              <div class="image-upload">
                <img
                  v-if="
                    Object.keys(this.personalData).length > 0 &&  this.personalData.setting_team_informations.medias
                      .setting_team_media_profile_url
                  "
                  :src="
                    this.personalData.setting_team_informations.medias
                      .setting_team_media_profile_url
                  "
                  class="
            m-auto

              profileImage
              d-flex
              justify-content-center
              center
              p-1
              rounded-pill
            "
                />

                <img
                  v-else
                  class="
                  m-auto
              profileImage
              d-flex
              justify-content-center
              center
              p-1
              rounded-pill
            "
                  src="@/assets/images/img_avatar.png"
                  alt="Alternative Text"
                />
              </div>
            </div>
            <div class="col-8">
              <div class="row">
                <div class="col-6">
                  ชื่อทีม (ภาษาไทย)
                </div>
                <div class="col-6">
                  ชื่อทีม (ภาษาอังกฤษ)
                </div>
                <div class="col-6 pl-7" style="color:#6E6B7B">
                  {{
                    this.personalData.setting_team_informations
                      .setting_team_information_name_th 
                  }}
                </div>
                <div class="col-6 pl-7" style="color:#6E6B7B">
                  {{
                    this.personalData.setting_team_informations
                      .setting_team_information_name_en
                  }}
                </div>
                <div class="col-6">
                  ชื่อผู้จัดการทีม
                </div>
                <div class="col-6">
                  เบอร์โทรศัพท์
                </div>
                <div class="col-6 pl-7" style="color:#6E6B7B">
                  {{
                    this.personalData.setting_team_informations
                      .setting_team_information_manager_name
                  }}
                </div>
                <div class="col-6 pl-7" style="color:#6E6B7B">
                  {{
                    this.personalData.setting_team_social_contacts
                      .setting_team_social_contact_phone
                  }}
                </div>
                <div class="col-12">
                  สถานที่ติดต่อ
                </div>
                <div class="col-12 pl-7" style="color:#6E6B7B">
                  {{
                    this.personalData.setting_team_regions
                      .setting_team_region_address_detail
                  }}
                </div>
              </div>
            </div>
          </div>
          <hr class="mx-5" />
          <div>
            <div class="rounded px-4 py-4 text-primary">
              การสมัครแข่งขัน
            </div>
            <v-data-table
              v-model="selectPerson"
              hide-default-footer
              :headers="headers[language]"
              :items="teamData.sub_team_information"
              item-key="key"
              :items-per-page="pageSize"
            >
            </v-data-table>
          </div>
        </div>
      </div>
      <CreateTeam :dialog.sync="showAddModal" @commit="onCommitCreate" />
    </div>
  </v-app>
</template>

<script>
import CreateTeam from "./CreateTeam.vue";
import { languageService } from "../../helpers/language";
import { mapActions, mapState } from "vuex";
import { appAxios } from "../../helpers/app-axios";

export default {
  components: {
    CreateTeam,
  },
  mounted: function() {
    // this.getTeamSelf()
    this.getSelfTeam();
  },
  computed: {
    ...mapState("sub_competition", ["personalData","teamData"]),
  },

  methods: {
    ...mapActions("sub_competition", ["getSelfTeam","getDetailByTeamNoCompetition"]),

    onChangeImage() {},
    handleEdit() {
      this.showAddModal = true;
    },

    async saveTeam(e) {
      // console.log("e saveTeam", e);
      if (Object.keys(this.personalData).length > 0) {
        // console.log("update",this.personalData);
        return await appAxios
          .post(`team/${this.personalData.id}/rugby-update`, e)
          .then((res) => {
            return res.data?.data?.team?.id;
          });
      } else {
        return await appAxios.post("team/rugby-create", e).then((res) => {
          return res.data?.data?.team?.id;
        });
      }
    },
    async saveTeamProfile(teamId, image) {
      let formData = new FormData();
      formData.append("file", image);
      appAxios
        .post(`team/${teamId}/medias/profile`, formData)
        .then((res) => res);
    },
    async onCommitCreate(e) {
      const teamId = await this.saveTeam(e.form);
      await this.saveTeamProfile(teamId, e.image.image);
      await this.getSelfTeam();
      await window.location.reload();

      // console.log("team", teamId);
    },
  },
  watch: {
    personalData() {
      console.log('personal',this.personalData)
      if (Object.keys(this.personalData).length === 0) {
        this.showAddModal = true;
      }else{
        this.getDetailByTeamNoCompetition({teamId:this.personalData.id})

      }
    },
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  data: function() {
    return {
      language: "th",
      showAddModal: false,
      items: [
        {
          key: 1,
          email: "test@example.com",
          register_date: "01/01/2024",
          status_approved: 0,
        },
        {
          key: 2,

          email: "test1@example.com",
          register_date: "01/01/2024",
          status_approved: 1,
        },
        {
          key: 3,

          email: "test1@example.com",
          register_date: "01/01/2024",
          status_approved: 2,
        },
      ],
      headers: {
        th: [
          {
            text: "ชื่อรายการแข่งขัน",
            value: "setting_competition_id.setting_competition_informations.setting_competition_information_name_th",
            sortable: false,
            width: "25%",
          },
          {
            text: this.$t("competition.type", "th"),
            value: "setting_sub_competition_id.setting_sub_competition_type_id.setting_sub_competition_type_name_th",
            sortable: false,
            width: "25%",
          },
          {
            text: this.$t("competition.gender_type", "th"),
            value: "setting_sub_competition_id.setting_sub_competition_sub_type_id.setting_sub_competition_sub_type_name_th",
            sortable: false,
            width: "25%",
          },
        ],
        en: [
          {
            text: "ชื่อรายการแข่งขัน",
            value: "setting_competition_id.setting_competition_informations.setting_competition_information_name_en",
            sortable: false,
            width: "10%",
          },
          {
            text: this.$t("competition.type", "en"),
            value: "setting_sub_competition_id.setting_sub_competition_type_id.setting_sub_competition_type_name_en",
            sortable: false,
            width: "25%",
          },
          {
            text: this.$t("competition.gender_type", "th"),
            value: "setting_sub_competition_id.setting_sub_competition_sub_type_id.setting_sub_competition_sub_type_name_en",
            sortable: false,
            width: "15%",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  background: #f8f8f8;
}

.select-amount {
  padding: 0 10px;
  background: #1e73fd;
  color: white;
}

.search-box {
  width: 360px;
}

.page-size-select {
  width: 85px;
}
// .image-upload,
// .uploadImage {
//   cursor: pointer;
// }
.image-upload .uploadImage {
  position: absolute;
  right: 20%;
  cursor: pointer;
}
.image-upload .selectFile {
  display: none;
}
.greenLabel {
  width: 10px;
  height: 10px;
  background: #28c76f;
  border-radius: 20px;
}
.redLabel {
  width: 10px;
  height: 10px;
  background: #ea5455;
  border-radius: 20px;
}
.image-upload .selectFile {
  display: none;
}
.profileImage {
  height: 200px;
  width: 200px;
  object-fit: cover;
}
</style>
