import { appAxios } from "../helpers/app-axios";

export const countriesState = "countries";
export const getCountries = "getCountries";

export default {
  state: {
    [countriesState]: [],
    isFetching: false,
  },
  mutations: {
    [getCountries](state, data) {
      if (data) {
        state[countriesState] = data;
      }
    },
    setIsCountriesFetching(state, data) {
      state.isFetching = data;
    },
  },
  actions: {
    async [getCountries]({ commit, state }) {
      if (state[countriesState].length > 0 || state.isFetching) return;
      commit("setIsCountriesFetching", true);
      appAxios
        .get("regions/country")
        .then((response) => {
          commit(getCountries, response?.data?.data?.countries);
        })
        .finally(() => {
          commit("setIsCountriesFetching", false);
        });
    },
  },
};
