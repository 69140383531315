import Player from '../views/Player/Player.vue'
import AddPlayer from '../views/Player/AddPlayer.vue'
import CurriculumVitae from '../views/CurriculumVitae/CurriculumVitae.vue'
//import About from '../views/Home/About.vue'
import permissionGuard from './guard/permissionGuard';

const player = [
    {
    path: '/player',
    name: 'Player',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    beforeEnter: permissionGuard,
    component: Player
    },
    {
        path: '/player/add/',
        name: 'AddPlayer',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter: permissionGuard,
        component: AddPlayer
    },
    {
        path: '/player/edit/:id',
        name: 'EditPlayer',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        beforeEnter: permissionGuard,
        component: AddPlayer,
        props: true
    },
    {
        path: '/player/curriculumvitae/:id',
        name: 'CurriculumVitaePlayer',
        beforeEnter: permissionGuard,
        component: CurriculumVitae,
        props: true
    }
    
    

    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
]

export default player;
