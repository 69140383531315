import { appAxios } from '../helpers/app-axios';
import Vue from 'vue'

const defaultSections = [
    { title: 'รอบชิงชนะเลิศ ', key: 'FIRST_PLACE', data: [], translateKey: 'competition_round.first_place' },
    { title: 'รอบชิงที่ 3', key: 'THIRD_PLACE', data: [], translateKey: 'competition_round.third_place' },
    { title: 'รอบชิงที่ 5', key: 'FIFTH_PLACE', data: [], translateKey: 'competition_round.fifth_place' },
    { title: 'รอบชิงที่ 7', key: 'SEVENTH_PLACE', data: [], translateKey: 'competition_round.seventh_place' },
    { title: 'รอบรองชนะเลิศสายบน', key: 'WINNER_SEMI', data: [], translateKey: 'competition_round.winner_semi' },
    { title: 'รอบรองชนะเลิศสายล่าง', key: 'LOSER_SEMI', data: [], translateKey: 'competition_round.loser_semi' },
    { title: 'รอบก่อนรองชนะเลิศ', key: 'QUARTER_FINAL', data: [], translateKey: 'competition_round.quarter_final' },
    { title: 'รอบชิงที่ 9', key: 'NINTH_PLACE', data: [], translateKey: 'competition_round.ninth_place' },
    { title: 'รอบแบ่งกลุ่ม', key: 'GROUP_STAGE', data: [], translateKey: 'competition_round.group_stage' },
]

export const competition_section = {
    namespaced: true,

    state: () => ({
        sections: JSON.parse(JSON.stringify(defaultSections)),
        group: {},
        matches: {}
    }),

    mutations: {
        error(state, data) {
            state.error = data
        },
        clearSections(state) {
            state.sections = JSON.parse(JSON.stringify(defaultSections))
        },
        updateCompetitionSections(state, data) {
            let section = state.sections.find(section => section.key == data.competition_section_round_name)
            if (section) {
                Vue.set(section, 'info', data)
            }
        },
        updateGroupStage(state, data) {
            data.data.group_stage.map(group => {
                group.competition_group_stage_team_join = group.competition_group_stage_team_join.sort((a, b) => {
                    return b.points - a.points
                }).map((team, index) => {
                    team.rank = index + 1
                    return team
                })
                let section = state.sections.find(section => {
                    return section.info?.id == group.competition_section_id
                })
                if (section) {
                    let index = section.data.findIndex(d => d.id == group.id)
                    if (index >= 0) {
                        section.data.splice(index, 1)
                    }
                    Vue.set(section.data, section.data.length, group)
                }
            })
        },
        updateVersusMatches(state, data) {
            let matches = data.data.versus_match.data.map(match => {
                match.competition_section_id = match.competition_sections
                return match
            })
            matches.map(match => {
                let section = state.sections.find(section => section.info?.id == match.competition_section_id)
                if (section)
                    Vue.set(section.data, section.data.length, match)
            })
        },
        clearMatchGroupData(state, csid) {
            state.sections = state.sections.map(section => {
                if (section.info?.id == csid) {
                    section.data = []
                }
                return section
            })
        }
    },

    actions: {
        getCompetitionSection({ commit, dispatch }, sid) {
            commit('clearSections')
            appAxios.get(`sub-competitions/${sid}/competition-sections`).then(response => {
                response.data.data.competition_section.map(section => {
                    commit('updateCompetitionSections', section)
                    commit('clearMatchGroupData', section.id)
                    console.log('KNOCKOUT', section)
                    if (section.section_type == 'KNOCKOUT') {
                        dispatch('getVersusMatch', { sid, csid: section.id })
                    } else if (section.section_type == 'GROUP') {
                        dispatch('getGroupStage', { sid, csid: section.id })
                    }
                })
            }).catch(error => {
                commit('error', error)
            })
        },
        async createOrUpdateSection({ commit, dispatch }, data) {
            let url = data.csid ?
                `sub-competitions/${data.sid}/competition-sections/${data.csid}/update` :
                `sub-competitions/${data.sid}/competition-sections/create`

            return await appAxios.post(url, data.payload).then(response => {
                dispatch('getCompetitionSection', data.sid)
                return {
                    success: true,
                    competition_section_id: response.data.data.competition_section.id,
                    section_type: response.data.data.competition_section.section_type,
                }
            }).catch(error => {
                commit('error', error)
                return { success: false }
            })
        },
        async deleteSection({ commit, dispatch }, data) {
            return await appAxios.post(
                `sub-competitions/${data.sid}/competition-sections/${data.csid}/delete`
            ).then(response => {
                dispatch('getCompetitionSection', data.sid)
                return { success: true, competition_section_id: response.data.data.competition_section.id }
            }).catch(error => {
                commit('error', error)
                return { success: false }
            })
        },
        async createVersusMatch({ commit, dispatch }, data) {
            let url = data.matchId ?
                `/api/v1/competition-sections/${data.sectionId}/versus-match/${data.matchId}/update` :
                `competition-sections/${data.sectionId}/versus-match/create`

            return await appAxios.post(url, data.payload).then(response => {
                dispatch('getCompetitionSection', data.sectionId)
                return { success: true, competition_section_id: response.data.data.competition_section.id }
            }).catch(error => {
                commit('error', error)
                return { success: false }
            })
        },
        getGroupStage({ commit }, data) {
            appAxios.get(`sub-competitions/${data.sid}/competition-sections/${data.csid}/group-stages`).then(response => {
                commit('updateGroupStage', response.data)
            }).catch(error => {
                commit('error', error)
                return { success: false }
            })
        },
        getVersusMatch({ commit }, data) {
            appAxios.get(`competition-sections/${data.csid}/versus-match`).then(response => {
                console.log('getVersusMatch', response.data)
                commit('updateVersusMatches', response.data)
            }).catch(error => {
                commit('error', error)
                return { success: false }
            })
        },
        createOrUpdateGroup({ commit, dispatch }, data) {
            let url = data.payload.id ?
                `sub-competitions/${data.sid}/competition-sections/${data.csid}/group-stages/${data.payload.id}/update` :
                `sub-competitions/${data.sid}/competition-sections/${data.csid}/group-stages/create`

            let payload = data.payload.id ? {
                competition_group_stage_name: data.payload.competition_group_stage_name,
                competition_group_stage_name_en: data.payload.competition_group_stage_name_en
            } : {
                competition_group_stage_name: data.payload.competition_group_stage_name,
                competition_group_stage_name_en: data.payload.competition_group_stage_name_en,
                competition_group_stage_team_join: data.payload.competition_group_stage_team_join,
            }

            appAxios.post(
                url, payload
            ).then(response => {
                if (response.data.success) {
                    dispatch('getCompetitionSection', data.sid)
                }
            }).catch(error => {
                commit('error', error)
                return { success: false }
            })
        },
        deleteGroup({ commit, dispatch }, data) {
            appAxios.post(
                `sub-competitions/${data.sid}/competition-sections/${data.csid}/group-stages/${data.gid}/delete`
            ).then(response => {
                if (response.data.success) {
                    dispatch('getCompetitionSection', data.sid)
                }
            }).catch(error => {
                commit('error', error)
                return { success: false }
            })
        },
        updateGroupPoints({ commit, dispatch }, data) {
            appAxios.post(
                `sub-competitions/${data.sid}/competition-sections/${data.csid}/group-stages/${data.gid}/update-point`,
                data.payload
            ).then(response => {
                if (response.data.success) {
                    dispatch('getGroupStage', { sid: data.sid, csid: data.csid })
                }
            }).catch(error => {
                commit('error', error)
                return { success: false }
            })
        },
        clearMatchGroupData({ commit }, data) {
            commit('clearMatchGroupData', data)
        }
    },

}