<template>
<v-app id="player">
 <div class="app-content content ">
        <div class="content-overlay"></div>

          <StaffTabs/>
  </div>
</v-app>
</template>

<script>
import StaffTabs from '@/components/Staff/StaffTabs';

export default {
  name: 'AddStaff',
  components:{
    StaffTabs
  },
  mounted() {
      
  }
}
</script>

<style >

</style>
