var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{staticClass:"content app-content"},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v(_vm._s(_vm.$t("competition.all"))+" ")]),(_vm.isAdmin)?_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('div',{staticClass:"d-flex"},[_vm._l((_vm.listActions),function(item,idx){return _c('div',{key:idx,staticClass:"\n              mr-2\n              cursor-pointer\n              align-items-center\n              d-flex\n              select-amount\n              rounded\n            ",on:{"click":function($event){_vm.canDeleteSelected && _vm.handleClick(item.action)}}},[_c('v-icon',{staticClass:"ma-1",attrs:{"dark":"","small":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.$t("normal.items_selected", { count: _vm.selected.length || 0 }))+" ")])],2),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onAddCompetition}},[_vm._v("+ "+_vm._s(_vm.$t("competition.add")))])],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.competitionData,"single-select":_vm.singleSelect,"page":_vm.params.page,"items-per-page":_vm.params.size,"hide-default-footer":"","show-select":_vm.isAdmin},on:{"update:page":function($event){return _vm.$set(_vm.params, "page", $event)},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-2",attrs:{"src":item.setting_competition_media_profile_icon,"contain":"","width":"40"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name_th))]),_c('div',[_vm._v(_vm._s(item.name_en))])]}},{key:"item.count_sub_competition",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.count_sub_competition)+" รายการ")])]}},{key:"item.setting_competition_level",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.competitionLevel(item.setting_competition_level)))])]}},(_vm.isAdmin)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pa-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("fa-ellipsis-v")])]}}],null,true)},[_c('v-list',_vm._l((_vm.actions),function(action,index){return _c('v-list-item',{key:index},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.onClick(action.key, item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(action.icon))]),_vm._v(_vm._s(action.text)+" ")],1)],1)}),1)],1)]}}:null,{key:"top",fn:function(ref){return [_c('div',{staticClass:"d-flex justify-content-end align-items-center py-4 px-4"},[_vm._v(" "+_vm._s(_vm.$t("normal.search"))+" "),_c('div',{staticClass:"mx-3 search-box"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"true","placeholder":_vm.$t('competition.search_placeholder')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getCompetitions($event)}},model:{value:(_vm.params.name),callback:function ($$v) {_vm.$set(_vm.params, "name", $$v)},expression:"params.name"}})],1)])]}},{key:"footer",fn:function(ref){return [_c('TableFooter',{attrs:{"perPage":_vm.pageSizeList,"currentPage":_vm.currentPage,"lastPage":_vm.competitions.lastPage,"total":_vm.competitions.total},on:{"update:perPage":function($event){_vm.pageSizeList=$event},"update:per-page":function($event){_vm.pageSizeList=$event},"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }