<template>
  <div id="address1" class="mb-8">
    <h4 class="text-primary mb-5">
      {{ $t("player.personal_info.address_info") }}
    </h4>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-md-11 mx-0 px-0">
        <div class="w-100 bold text-primary">
          {{ $t("address.current_address") }}
        </div>
        <ul class="w-100">
          <li class="bulletAddress">{{ dataAddress.regionCurrent[language] }}</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-md-11 mx-0 px-0">
        <div class="w-100 bold text-primary">
          {{ $t("address.address_citizen") }}
        </div>
        <ul class="w-100">
          <li class="bulletAddress">{{ dataAddress.regionCitizen[language] }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { languageService } from "../../helpers/language";

export default {
  name: "addressInformation",
  props: ["addressInfo"],
  data() {
    return {
      language: "th",
      dataAddress: {},
    };
  },
  mounted(){
    this.$emit('heightSize',230)
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  watch: {
    addressInfo() {
      this.dataAddress = {
        regionCurrent:
          this.address?.region_current === null
            ? {
                th: "ไม่มีข้อมูล",
                en: "No data",
              }
            : {
                th:
                  (this.addressInfo?.region_current
                    ?.account_region_address_detail ?? "") +
                  " " +
                  (this.addressInfo?.region_current?.sub_district_name_th ??
                    "") +
                  " " +
                  (this.addressInfo?.region_current?.district_name_th ?? "") +
                  " " +
                  (this.addressInfo?.region_current?.province_name_th ?? "") +
                  " " +
                  (this.addressInfo?.region_current?.account_region_zipcode ||
                    "") +
                  " ประเทศ" +
                  (this.addressInfo?.region_current?.country_name_th || ""),
                en:
                  (this.addressInfo?.region_current
                    ?.account_region_address_detail_en ?? "") +
                  " " +
                  (this.addressInfo?.region_current?.sub_district_name_en ??
                    "") +
                  " " +
                  (this.addressInfo?.region_current?.district_name_en ?? "") +
                  " " +
                  (this.addressInfo?.region_current?.province_name_en ?? "") +
                  " " +
                  (this.addressInfo?.region_current?.account_region_zipcode ||
                    "") +
                  " " +
                  (this.addressInfo?.region_current?.country_name_en || ""),
              },
        regionCitizen:
          this.addressInfo?.region_citizen === null
            ? {
                th: "ไม่มีข้อมูล",
                en: "No data",
              }
            : {
                th:
                  (this.addressInfo?.region_citizen
                    ?.account_region_address_detail ?? "") +
                  " " +
                  (this.addressInfo?.region_citizen?.sub_district_name_th ?? "") +
                  " " +
                  (this.addressInfo?.region_citizen?.district_name_th ?? "") +
                  " " +
                  (this.addressInfo?.region_citizen?.province_name_th ?? "") +
                  " " +
                  (this.addressInfo?.region_citizen?.account_region_zipcode ?? "") +
                  " ประเทศ" +
                  (this.addressInfo?.region_citizen?.country_name_th ?? ""),
                en:
                  (this.addressInfo?.region_citizen
                    ?.account_region_address_detail_en ?? "") +
                  " " +
                  (this.addressInfo?.region_citizen?.sub_district_name_en ?? "") +
                  " " +
                  (this.addressInfo?.region_citizen?.district_name_en ?? "") +
                  " " +
                  (this.addressInfo?.region_citizen?.province_name_en ?? "") +
                  " " +
                  (this.addressInfo?.region_citizen?.account_region_zipcode ?? "") +
                  " " +
                  (this.addressInfo?.region_citizen?.country_name_en ?? ""),
              },
      };
      console.log("dataAddress", this.dataAddress);
    },
  },
};
</script>