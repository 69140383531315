<template>
  <div>
    <div class="card">
      <div class="p-2">
        <h4 class="text-primary mb-7 text-center">
          {{ $t("player.personal_info.preview_cv") }}
        </h4>
        <div class="ml-6 mb-10">
          <v-checkbox
            v-for="(option, index) in options"
            :key="index"
            :id="option.value"
            :value="option.value"
            :checked="checkOption"
            v-model="checkOption"
            hide-details
            class="my-3 checkboxOption"
          >
            <template v-slot:label>
              <h6 class="mb-0">{{ option.text[language] }}</h6>
            </template></v-checkbox
          >
        </div>
        <v-btn class="btn-primary mx-auto d-flex" @click="createCV"
          ><div class="px-10">{{ $t("normal.save") }}</div></v-btn
        >
      </div>
    </div>
    <div class="card mt-3 text-center">
      <div class="p-2">
        <div>
          <v-btn outlined color="primary" @click="downloadPDF">
            <v-icon class="mr-5">$download</v-icon
            >{{ $t("normal.download") }}</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { languageService } from "../../helpers/language";
import { appAxios } from "../../helpers/app-axios";

export default {
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  mounted() {
    this.createCV();
    if (this.$route.path.includes("player")) {
      this.$data.isPlayerRouteName = "player";
    } else {
      this.$data.isPlayerRouteName = "staff";
    }
  },
  computed:{
isPlayerRoute() {
      return Number(this.$route.params?.id);
    },
  },
  methods: {
    createCV() {
      this.$emit("commit", this.formatParam());
    },

    downloadPDF() {
// const params = {
//         information: false,
//         social_contact: false,
//         social_region: false,
//         education: false,
//         team_national_history: false,
//         team_club_history: false,
//         honor_national: false,
//         honor_club: false,
//         honor_personal: false,
//       };
//       this.checkOption.map((ch) => {
//         if (ch === "info") {
//           params["information"] = true;
//         } else if (ch === "contact") {
//           params["social_contact"] = true;
//         } else if (ch === "address") {
//           params["social_region"] = true;
//         } else if (ch === "education") {
//           params["education"] = true;
//         } else if (ch === "nationHistory") {
//           params["team_national_history"] = true;
//         } else if (ch === "team") {
//           params["team_club_history"] = true;
//         } else if (ch === "nationAward") {
//           params["honor_national"] = true;
//         } else if (ch === "clubAward") {
//           params["honor_club"] = true;
//         } else if (ch === "award") {
//           params["honor_personal"] = true;
//         }
//       });
      const params = this.formatParam()
            appAxios
        .get(
          `/account/${this.isPlayerRoute}/${this.$data.isPlayerRouteName}/report/pdf/${this.$data.language}`,
          {
            responseType: "blob",
            params,
          }
        )
        .then((res) => {
          var fileURL = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "test2.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
          // window.URL.revokeObjectURL(fileLink.href);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    formatParam() {
      const params = {
        information: false,
        social_contact: false,
        social_region: false,
        education: false,
        team_national_history: false,
        team_club_history: false,
        honor_national: false,
        honor_club: false,
        honor_personal: false,
      };
      this.checkOption.map((ch) => {
        if (ch === "info") {
          params["information"] = true;
        } else if (ch === "contact") {
          params["social_contact"] = true;
        } else if (ch === "address") {
          params["social_region"] = true;
        } else if (ch === "education") {
          params["education"] = true;
        } else if (ch === "nationHistory") {
          params["team_national_history"] = true;
        } else if (ch === "team") {
          params["team_club_history"] = true;
        } else if (ch === "nationAward") {
          params["honor_national"] = true;
        } else if (ch === "clubAward") {
          params["honor_club"] = true;
        } else if (ch === "award") {
          params["honor_personal"] = true;
        }
      });
      return params;
    },
  },
  // watch: {
  //   checkOption() {
  //     this.formatParam(this.checkOption);
  //   },
  // },
  data() {
    return {
      isPlayerRouteName: "",
      language: "th",
      paramsData:{},
      checkOption: [
        "info",
        "contact",
        "address",
        "education",
        "nationHistory",
        "team",
        "nationAward",
        "clubAward",
        "award",
      ],
      options: [
        {
          text: {
            th: "ข้อมูลทั่วไป",
            en: "Information",
          },
          value: "info",
        },
        {
          text: {
            th: "ข้อมูลติดต่อ",
            en: "Contact Information",
          },
          value: "contact",
        },
        {
          text: {
            th: "ที่อยู่",
            en: "Address",
          },
          value: "address",
        },
        {
          text: {
            th: "ข้อมูลด้านการศึกษา",
            en: "Educational Information",
          },
          value: "education",
        },
        {
          text: {
            th: "ประวัติการรับใช้ทีมชาติ",
            en: "National Team ",
          },
          value: "nationHistory",
        },
        {
          text: {
            th: "ทีม/สโมสร",
            en: "Club Career",
          },
          value: "team",
        },
        {
          text: {
            th: "รางวัลระดับทีมชาติ",
            en: "National Team Awards",
          },
          value: "nationAward",
        },
        {
          text: {
            th: "รางวัลระดับสโมสร",
            en: "Club Awards",
          },
          value: "clubAward",
        },
        {
          text: {
            th: "รางวัลระดับเกียรติยศ",
            en: "Personal Awards",
          },
          value: "award",
        },
      ],
    };
  },
};
</script>