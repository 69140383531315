<template>
	<div class="player-card" :class="classSelection">
		<div class='information mx-2'>
			<v-avatar
				v-if="selected && captain"
				color="#267FFF"
				size="24"
				style="margin: 6px;"
			><span class='badge'>C</span></v-avatar>
			<v-avatar
				v-if="selected && vice_captain"
				color="#267FFF"
				size="24"
				style="margin: 6px;"
			><span class='badge'>VC</span></v-avatar>
			<v-avatar
				v-if="selected"
				color="#267FFF"
				size="24"
				style="margin: 6px;"
			>
				<span class='badge'>
					{{ playerPosition }}
				</span>
			</v-avatar>
			<v-avatar size="36px">
				<v-img
					v-if="player.accountImage"
					:src="player.accountImage"
					style="border-radius: 50%"
				></v-img>
				<v-icon v-else>fa-user</v-icon>
			</v-avatar>
			<div class='text-centered ml-1'><div v-if="language==='th'">{{ player.accountName }}</div><div v-else>{{ player.accountNameEn }}</div></div>
			<div class='text-centered ml-1'>{{ player.accountShirtNumber }}</div>
			<div v-if="!selected" class="d-flex flex-row justify-center align-center">
				<div class='position-selection'>
					<v-select
						v-model="player.accountPosition"
						:items="positions"
						dense
						outlined
						background-color="#1161e0"
						dark
					></v-select>
				</div>
				<div v-if="player.accountId == null" class='more-actions'>
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-icon small v-bind="attrs" class='pa-2' v-on="on">fa-ellipsis-v</v-icon>
						</template>
						<v-list>
							<v-list-item
								v-for="(action, index) in actions"
								:key="index"
							>
								<v-btn text @click="onClick(action.key)">
									<v-icon small class="mr-2">{{action.icon}}</v-icon>{{action.text}}
								</v-btn>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { languageService } from "../../../helpers/language";

export default {
	name: 'PlayerCard',
	props: ['player', 'selected', 'captain', 'vice_captain'],
	created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
	data() {
		return {
			language:"th",
			actions: [
				{ text: 'ลบรายการนี้', icon: 'fa-trash', key: 'delete' },
			],
		}
	},

	mounted() {
		if (this.player.accountPosition?.global_config_position_abbreviation) {
			this.player.accountPosition = this.player.accountPosition.global_config_position_abbreviation
		}
	},

	computed: {
		// ...mapState('data_model', ['positions']),
		...mapState('match', ['positions']),

		classSelection() {
			return this.selected ? '' : 'selection'
		},

		playerPosition() {
			return this.player.accountPosition?.global_config_position_abbreviation || this.player.accountPosition || this.positions[0].text
		}
	},

	methods: {
		onClick(action) {
			this.$emit(action, this.player)
		},
	}
}
</script>

<style lang="scss" scoped>
.player-card {
	cursor: pointer;
	padding: 6px;
	margin: 6px 0px;
	width: 100%;

	.badge {
		color: white;
		font-size: 14px;
	}

	.information {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.position-selection {
		width: 80px;
		height: 40px;
	}

	.more-actions {
		max-width: 20px;
	}
}
.player-card.selection {
  border: 1px solid #D0D0D0;
  border-radius: 10px;
  box-shadow: 1px 1px #D0D0D0;
}
</style>