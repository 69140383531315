<template>
  <span class="icon-image" :class="className" :style="style"></span>
</template>

<script>
export default {
  name: 'IconImage',
  props: ['src', 'width', 'height', 'className'],
  computed: {
    style() {
      return `background: url(${this.publicPath + this.src}) no-repeat; width: ${this.width}px; height: ${this.height}px`
    }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style scoped>
.icon-image {
  display: inline-block;
}
</style>