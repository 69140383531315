import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Vueaxios from 'vue-axios'
import vuetify from './plugins/vuetify'

import CheckboxRadio from 'vue-checkbox-radio'
import VueSweetalert2 from 'vue-sweetalert2'
import vSelect from "vue-select"

import 'sweetalert2/dist/sweetalert2.min.css'
import './assets/scss/style.scss'
import 'vue-select/dist/vue-select.css';
import { setAxios } from './helpers/app-axios';

import VueI18n from 'vue-i18n'
import thLanguage from './locale/th.json'
import enLanguage from './locale/en.json'

import { languageService } from './helpers/language';

Vue.component("vSelectSearch", vSelect);
Vue.config.productionTip = false
Vue.use(Vueaxios,axios)
Vue.use(CheckboxRadio)
Vue.use(VueSweetalert2)
Vue.use(VueI18n)

setAxios(store);

const defaultLanguage = {
  locale: 'th', // set locale
  messages : {
    en: {
      ...enLanguage
    },
    th: {
      ...thLanguage
    }
  }
};

if ( localStorage.getItem('language') === 'en' ) {
  defaultLanguage.locale = 'en'
}
languageService.setCurrent(defaultLanguage.locale);

const i18n = new VueI18n(defaultLanguage);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
