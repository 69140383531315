<template>
  <div class="py-2 px-6">
    <template v-for="group in round">
      <v-card class="my-2" :key="group.id">
        <v-data-table
          class="elevation-1"
          :items="group.competition_group_stage_team_join"
          :headers="groupHeaders(group)"
          item-key="id"
          :page.sync="page"
          :items-per-page="pageSize"
          :hide-default-footer="true"
          :hide-default-header="true"
        >
          <template v-slot:header="{ props }">
            <th
              v-for="(head, index) in props.headers"
              :class="head.class"
              class="text-center py-2"
              :key="index"
            >
              <v-img
                v-if="head.value == 'result-againt'"
                :src="head.image"
                max-height="30"
                max-width="30"
                contain
                class="mx-auto"
              ></v-img>
              <span v-else>
                {{ head.text.toUpperCase() }}
              </span>
            </th>
          </template>
          <template v-slot:[`item.team`]="{ item }">
            <div class="d-flex flex-row align-items-center">
              <v-img
                :src="item.team_image"
                max-height="30"
                max-width="30"
                contain
                class="ma-3"
              ></v-img>
              <span>{{
                language === "th" ? item.team_name : item.team_name_en
              }}</span>
            </div>
          </template>
          <template v-slot:[`item.result-againt`]="{ item, header }">
            <div class="d-flex justify-center">
              <span v-if="item.team_id == header.team">-</span>
              <v-sheet
                v-else-if="!hasMatchResult(item, header.team)"
                outlined
                rounded
                width="50"
                height="75"
                class="
                  ma-2
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                  clickable
                "
                @click="
                  $emit('addResult', {
                    csid: group.competition_section_id,
                    groupId: group.id,
                    left_id: item.team_id,
                    right_id: header.team,
                  })
                "
              >
                <div
                  class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                  "
                >
                  <div class="text-decoration-underline text-center">
                    {{ $t("sub_competition.add_score") }}
                  </div>
                  <v-icon x-small class="mt-1">fa-plus</v-icon>
                </div>
              </v-sheet>
              <v-sheet
                v-else
                rounded
                width="75"
                height="50"
                class="
                  ma-2
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                  clickable
                "
                :color="resultClass(item, header.team, '--bg')"
                @click="onUpdateResult(item, header.team, group)"
              >
                <div
                  class="
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                  "
                >
                  <div class="score--text text-decoration-underline">
                    {{ matchResult(item, header.team) }}
                  </div>
                  <div>
                    {{ matchResultText(item, header.team) }}
                  </div>
                </div>
              </v-sheet>
            </div>
          </template>
          <template v-slot:[`item.points`]="{ item }">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <v-text-field
                v-model="item.points"
                dense
                outlined
                hide-details
                class="centered-input"
                style="width: 50px"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:top="{}">
            <div
              class="d-flex justify-space-between px-6 pt-2 align-items-center"
            >
              <span class="text-primary" style="font-size: 14px">{{
                groupName(group)
              }}</span>
              <div class="d-flex align-center">
                <v-icon x-small class="ma-2 pa-2" @click="onDeleteGroup(group)"
                  >fa-trash</v-icon
                >
                <v-btn
                  color="primary"
                  class="text-decoration-underline"
                  style="font-size: 16px"
                  text
                  @click="onEditGroup(group)"
                  >{{ $t("sub_competition.manage_sub_competition") }}</v-btn
                >
              </div>
            </div>
          </template>
          <template v-slot:footer="{}">
            <div class="col-12 d-flex justify-content-end">
              <v-btn class="ma-2" @click="saveGroupPoints(group)">{{
                $t("sub_competition.save_score")
              }}</v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MasterDataHelper from "@/helpers/master_data_helper.js";

export default {
  name: "LeagueRound",

  props: ["round", "comSecId", "language"],

  components: {},

  computed: {
    ...mapState("data_model", ["roundId"]),
  },

  mounted() {
    this.subCompetitionId = this.$route.params.sid;
  },

  methods: {
    ...mapActions("data_model", ["setRoundId", "setGroup"]),
    ...mapActions("competition_section", ["updateGroupPoints", "deleteGroup"]),

    groupHeaders(group) {
      var headers = [
        {
          text: this.$t("sub_competition.rank"),
          value: "rank",
          class: "grey lighten-4",
          align: "center",
        },
        {
          text: this.$t("sub_competition.team"),
          value: "team",
          width: "60%",
          class: "grey lighten-4",
        },
      ];

      if (group && group.competition_group_stage_team_join) {
        group.competition_group_stage_team_join.map((team) => {
          headers.push({
            text: "",
            value: `result-againt`,
            team: team.team_id,
            class: "grey lighten-4",
            image: team.team_image,
          });
        });
      }

      headers = headers.concat([
        {
          text: this.$t("sub_competition.gd"),
          value: "gd",
          class: "grey lighten-4",
          align: "center",
        },
        {
          text: this.$t("sub_competition.score"),
          value: "points",
          class: "grey lighten-4",
          align: "center",
          width: "10%",
        },
      ]);
      return headers;
    },
    groupName(group) {
      if (this.$t("short_language") === "en") {
        return group.competition_group_stage_name_en;
      }
      return group.competition_group_stage_name;
    },

    saveGroupPoints(group) {
      this.updateGroupPoints({
        sid: this.subCompetitionId,
        csid: this.comSecId,
        gid: group.id,
        payload: group.competition_group_stage_team_join.map((team) => {
          return {
            team_id: team.team_id,
            points: team.points,
          };
        }),
      });
    },

    onEditGroup(group) {
      this.setRoundId(this.round.id);
      this.setGroup(group);
      this.$emit("editGroup", { csid: this.comSecId, gid: group.id });
    },

    async onDeleteGroup(group) {
      this.deletingGroup = group;
      await this.$swal(MasterDataHelper.sweetAlertConfig(this.language)).then(
        async (result) => {
          if (result.value) {
            let group = this.deletingGroup;
            this.deleteGroup({
              sid: this.subCompetitionId,
              csid: this.comSecId,
              gid: group.id,
            });
          }
        }
      );
    },

    confirmDeleteItem() {
      let group = this.deletingGroup;
      this.deleteGroup({
        sid: this.subCompetitionId,
        csid: this.comSecId,
        gid: group.id,
      });
    },

    resultClass(item, vs, append) {
      const result = item.results.find((result) => {
        return result.vs == vs;
      });
      if (result.result === "d") {
        return `draw${append}`;
      } else if (result.result === "w") {
        return `win${append}`;
      } else if (result.result === "l") {
        return `lost${append}`;
      }
      return "";
    },
    hasMatchResult(item, vs) {
      const result = item.results.find((result) => {
        return result.vs == vs;
      });
      return result?.result;
    },
    matchResult(item, vs) {
      const result = item.results.find((result) => {
        return result.vs == vs;
      });
      if (result?.score == "null-null") {
        return "-";
      }
      return result?.score.replace("null", "0");
    },
    matchResultText(item, vs) {
      const result = item.results.find((result) => {
        return result.vs == vs;
      });
      switch (result?.result) {
        case "w":
          return this.$t("competition_score.win");
        case "l":
          return this.$t("competition_score.lose");
        default:
          return this.$t("competition_score.draw");
      }
    },

    onUpdateResult(item, team, group) {
      let match = {
        competition_section_id: this.comSecId,
        groupId: group.id,
        match: {
          id: item.results.find((result) => {
            return result.vs == team;
          }).versus_match_id,
        },
      };
      this.$emit("updateResult", match);
    },
  },

  data() {
    return {
      page: 1,
      pageSize: 10,
      pageCount: 0,
      pageCounts: [10, 20, 50, 100],
      itemsPerPage: 10,
      range: [10, 20],
      subCompetitionId: null,
      dialogConfirmDelete: false,
      deletingGroup: null,
    };
  },
};
</script>

<style>
.clickable {
  cursor: pointer;
}

.centered-input >>> input {
  text-align: center;
}
</style>