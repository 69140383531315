import { appAxios } from '../helpers/app-axios';

export const data_model = {
	namespaced: true,

	state: () => ({
		roundId: '',
		group: {
			competition_group_stage_name: '',
			competition_group_stage_name_en: '',
			competition_group_stage_team_join: [],
		},
		positions: [
			{ value: 'GK', text: 'GK' },
			{ value: 'LB', text: 'LB' },
			{ value: 'CB', text: 'CB' },
			{ value: 'RB', text: 'RB' },
			{ value: 'CM', text: 'CM' },
			{ value: 'LM', text: 'LM' },
			{ value: 'RM', text: 'RM' },
			{ value: 'FW', text: 'FW' },
		],
		managers: [
			{ value: '1', text: 'ศิริศักดิ์ ยอดญาติไทย', image: 'https://cdn.vuetifyjs.com/images/john.jpg' },
			{ value: '2', text: 'ศิริศักดิ์ ยอดญาติไทย', image: 'https://cdn.vuetifyjs.com/images/john.jpg' },
		],
		teams: [
			{
				id: 1, value: 'ไทย', text: 'ไทย',
				image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Thailand.svg/1280px-Flag_of_Thailand.svg.png',
				gd: 10,
				points: 5,
				result: [
					{ id: 1, vs: 2, result: 'w', score: '2-1'}
				]
			},
			{
				id: 2, value: 'เวียดนาม', text: 'เวียดนาม',
				image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Vietnam.svg/1280px-Flag_of_Vietnam.svg.png',
				gd: 10,
				points: 5,
				result: [
					{ id: 1, vs: 1, result: 'l', score: '1-2'}
				]
			},
		],
		referees: [
			{ value: 'กรรมการ 1', text: 'กรรมการ 1' },
			{ value: 'กรรมการ 2', text: 'กรรมการ 2' },
			{ value: 'กรรมการ 3', text: 'กรรมการ 3' },
			{ value: 'กรรมการ 4', text: 'กรรมการ 4' },
			{ value: 'กรรมการ 5', text: 'กรรมการ 5' },
			{ value: 'กรรมการ 6', text: 'กรรมการ 6' },
		],
		players: [
			{ name: 'สุริยา สิงห์มุ้ย', number: '1', image: '' },
			{ name: 'สุริยา สิงห์มุ้ย', number: '1', image: '' },
			{ name: 'สุริยา สิงห์มุ้ย', number: '1', image: '' },
			{ name: 'สุริยา สิงห์มุ้ย', number: '1', image: '' },
			{ name: 'สุริยา สิงห์มุ้ย', number: '1', image: '' },
			{ name: 'สุริยา สิงห์มุ้ย', number: '1', image: '' },
			{ name: 'สุริยา สิงห์มุ้ย', number: '1', image: '' },
			{ name: 'สุริยา สิงห์มุ้ย', number: '1', image: '' },
			{ name: 'สุริยา สิงห์มุ้ย', number: '1', image: '' },
		]
	}),

	actions: {
		setRoundId({ state }, data) {
			state.roundId = data
		},
		updateGroup({ state }, data) {
			state.group.competition_group_stage_team_join = data.map(team => {
				return {
					team_id: team.setting_team_id,
					team_name: team.setting_team_information_name_th,
					team_name_en: team.setting_team_information_name_en,
					team_img: team.setting_team_media_profile_url,
				}
			})
		},
		removeTeamFromGroup({ state }, data) {
			state.group.competition_group_stage_team_join =
				state.group.competition_group_stage_team_join.filter(team => {
					return team.team_id != data.team_id
				})
		},
		clearGroup({ state }) {
			state.group = {
				name: '', teams: [],
			}
		},
		setGroup({ state }, data) {
			state.group = data
		},
		addPlayer({ commit }, data) {
			let payload = {
				"accounts": 0,
				"global_config_positions": 1,
				"team_player_history_shirt_number": data.player.number,
				"team_player_history_status": 0,
			}
			appAxios.get(`team/${data.teamId}/players`, payload).then(response => {
				commit('updateGroupStage', response.data)
			}).catch(error => {
				commit('error', error)
				return { success: false }
			})
		}
	}
}