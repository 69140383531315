import { appAxios } from "../helpers/app-axios";

export const getDistrict = "getDistrict";

export default {
  state: {
    // to trigger state change on component
    isFetching: false,
  },
  mutations: {
    [getDistrict](state, { provinceId, data }) {
      state.isFetching = !state.isFetching
      state[provinceId].data = data;
      state.isFetching = undefined;
    },
    setIsDistrictFetching(state, { provinceId, status }) {
      if (!state[provinceId]) {
        state[provinceId] = {
          isFetching: status,
          data: [],
        };
      } else {
        state[provinceId].isFetching = status;
      }
      state.isFetching = status;
    },
  },
  // getters: {
  //   getDistrict: (state) => (provinceId) => {
  //     return state[provinceId]?.data;
  //   },
  //   getDistrictFetching: (state) => (provinceId) => {
  //     return state[provinceId]?.isFetching;
  //   }
  // },
  actions: {
    async [getDistrict]({ commit, state }, provinceId) {
      if (state[provinceId]?.isFetching || state[provinceId]?.data?.length > 0)
        return;
      commit("setIsDistrictFetching", { provinceId, status: true });
      appAxios
        .get(`regions/${provinceId}/district`)
        .then((response) => {
          commit(getDistrict, {
            provinceId,
            data: response.data?.data?.district,
          });
        })
        .finally(() => {
          commit("setIsDistrictFetching", { provinceId, status: false });
        });
    },
  },
};
