import { render, staticRenderFns } from "./AddSubCompetitionForm.vue?vue&type=template&id=4a3571e8&scoped=true&"
import script from "./AddSubCompetitionForm.vue?vue&type=script&lang=js&"
export * from "./AddSubCompetitionForm.vue?vue&type=script&lang=js&"
import style0 from "./AddSubCompetitionForm.vue?vue&type=style&index=0&id=4a3571e8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a3571e8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDatePicker,VForm,VIcon,VImg,VMenu,VRadio,VRadioGroup,VSelect,VSnackbar,VTextField})
