<template>
  <v-dialog
    :no-click-animation="true"
    :persistent="true"
    :value="dialog"
    max-width="700px"
  >
    <v-card>
      <div class="float-right">
        <v-card-actions>
          <v-btn color="primary" text @click="close" mx-2>
            {{ $t("normal.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!isEditable"
            @click="handleSubmit"
            mx-2
          >
            {{ $t("normal.confirm") }}
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-title>
        <span class="header text-primary">{{
          $t("player.personal_info.relationship_info")
        }}</span>
      </v-card-title>

      <div class="card-body">
        <v-form ref="form" class="needs-validation" novalidate="">
          <div class="row">
            <div class="col-3">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name">{{
                  $t("player.personal_info.related_relationship")
                }}</label>
                <v-select
                  dense
                  :items="relationList"
                  item-value="id"
                  :item-text="'global_config_relation_name_' + language"
                  :loading="isListFetching"
                  outlined
                  :rules="ruleForm"
                  v-model="form.global_config_relations"
                  :readonly="!isEditable"
                  :placeholder="$t('normal.please_select')"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name">{{
                  $t("player.personal_info.first_name_th")
                }}</label>
                <v-text-field
                  v-model="form.account_relation_firstname_th"
                  :readonly="!isEditable"
                  :rules="ruleForm"
                  dense
                  outlined
                  :placeholder="$t('player.personal_info.first_name_th')"
                ></v-text-field>

                <div class="valid-feedback">Looks good!</div>
                <div class="invalid-feedback">Please enter your name.</div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name">{{
                  $t("player.personal_info.last_name_th")
                }}</label>
                <v-text-field
                  v-model="form.account_relation_lastname_th"
                  :readonly="!isEditable"
                  :rules="ruleForm"
                  dense
                  outlined
                  :placeholder="$t('player.personal_info.last_name_th')"
                ></v-text-field>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name">{{
                  $t("player.personal_info.first_name_en")
                }}</label>
                <v-text-field
                  v-model="form.account_relation_firstname_en"
                  :readonly="!isEditable"
                  dense
                  outlined
                  hide-details
                  :placeholder="$t('player.personal_info.first_name_en')"
                ></v-text-field>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name">
                  {{ $t("player.personal_info.last_name_en") }}
                </label>
                <v-text-field
                  v-model="form.account_relation_lastname_en"
                  :readonly="!isEditable"
                  dense
                  outlined
                  hide-details
                  :placeholder="$t('player.personal_info.last_name_en')"
                >
                </v-text-field>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name">
                  {{ $t("player.personal_info.phone") }}
                </label>
                <v-text-field
                  v-model="form.account_relation_phone"
                  :readonly="!isEditable"
                  dense
                  outlined
                  :rules="phoneRules"
                  :placeholder="$t('player.personal_info.phone')"
                ></v-text-field>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label class="form-label" for="basic-addon-name">
                  {{ $t("contact_information.email") }}
                </label>
                <v-text-field
                  v-model="form.account_relation_email"
                  :readonly="!isEditable"
                  dense
                  outlined
                  :rules="emailRules"
                  :placeholder="$t('contact_information.email')"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="my-2">
            <span
              class="text-decoration-underline text-primary cursor-pointer"
              @click="copyAddress"
              >{{ $t("address.copy_address") }}</span
            >
          </div>
          <Address :address.sync="address" />
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Address from "./Address.vue";
import { appAxios } from "../../helpers/app-axios";
import { languageService } from "../../helpers/language";

export default {
  components: {
    Address,
  },
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    relation: null,
    originalAddress: null,
  },
  name: "Relation-Modal",
  methods: {
    copyAddress() {
      if (!this.isEditable) return;
      this.$data.address = { ...this.originalAddress };
    },
    close() {
      this.$emit("update:dialog", false);
    },
    handleSubmit() {
      const validate = this.$refs.form.validate();
      if (!validate) return;
      const matchRelation = this.relationList?.find(
        (ele) => ele.id == this.$data.form.global_config_relations
      );
      this.$data.form = {
        ...this.$data.form,
        relationName: {
          th: matchRelation?.global_config_relation_name_th || "",
          en: matchRelation?.global_config_relation_name_en || "",
        },
        account_relation_address: this.address.address_detail,
        account_relation_address_en: this.address.address_detail_en,
        account_relation_country_id: this.address.country_id,
        account_relation_province_id: this.address.province_id,
        account_relation_district_id: this.address.district_id,
        account_relation_subdistrict_id: this.address.subdistrict_id,
        account_relation_zipcode: this.address.zipcode,
      };
      this.$emit("update:dialog", false);
      this.$emit("commit", this.$data.form);
    },
    getRelationList() {
      this.$data.isListFetching = true;
      appAxios
        .get("setting/global/relation", { params: { active: true } })
        .then((response) => {
          const data = response.data?.data?.global_config_relation?.data;
          if (data) {
            this.$data.relationList = data;
          }
        })
        .finally(() => {
          this.$data.isListFetching = false;
        });
    },
  },
  mounted() {
    this.getRelationList();
  },
  watch: {
    dialog() {
      if (this.relation) {
        this.$data.form = this.relation;
        this.$data.address = {
          country_id: this.relation.account_relation_country_id,
          address_detail: this.relation.account_relation_address,
          address_detail_en: this.relation.account_relation_address_en,
          province_id: this.relation.account_relation_province_id,
          district_id: this.relation.account_relation_district_id,
          subdistrict_id: this.relation.account_relation_subdistrict_id,
          zipcode: this.relation.account_relation_zipcode,
        };
      } else {
        this.$data.address = { country_id: "188" };
        this.$data.form = {
          global_config_relations: null,
          account_relation_firstname_th: null,
          account_relation_lastname_th: null,
          account_relation_firstname_en: null,
          account_relation_lastname_en: null,
          account_relation_phone: null,
          account_relation_email: null,
          account_relation_address: null,
          account_relation_address_en: null,
        };
      }
    },
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  computed: {
    emailRules() {
      const rules = [];

      const isEmail = (value) => {
        // if (!value) return null;
        const emailRegex = new RegExp(
          /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
        );
        if (this.language === "th") {
          return !value || emailRegex.test(value) || "รูปแบบ email ไม่ถูกต้อง";
        } else {
          return !value || emailRegex.test(value) || "Email is not correct";
        }
      };
      rules.push(isEmail);
      return rules;
    },
    isEditable() {
      if (this.isPlayerRoute) {
        return this.$store.state.permission?.permissions?.athlete_edit;
      }
      return this.$store.state.permission?.permissions?.staff_edit;
    },
    isPlayerRoute() {
      return this.$route.path.includes("player");
    },
    phoneRules() {
      const rules = [];
      if (this.language === "th") {
        rules.push((value) => !!value || "กรุณากรอกเบอร์โทรศัพท์");
      } else {
        rules.push((value) => !!value || "Please enter your phone number");
      }
      return rules;
    },
    ruleForm() {
      const rules = [];
      rules.push((v) => !!v || `required`);
      return rules;
    },
  },
  data() {
    return {
      language: "th",
      form: {},
      address: {},
      relationList: [],
      isListFetching: false,
    };
  },
};
</script>

<style></style>
