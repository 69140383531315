import store from '../../store';
import { checkPermission } from '../../helpers/checkPermission';

export default (to, from, next) => {
  const permission = store.state.permission.permissions;
  if (checkPermission(to.path, permission)) {
    next()
  } else {
    next()
    // window.location.href = process.env.VUE_APP_SSO_PATH;
  }
}