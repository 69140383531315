import { appAxios } from "../helpers/app-axios";

export const getSubdistrict = "getSubdistrict";

export default {
  state: {
    isFetching: false,
  },
  mutations: {
    [getSubdistrict](state, { districtId, data }) {
      state.isFetching = undefined;
      state[districtId].data = data;
    },
    setIsFetching(state, { districtId, status }) {
      if (!state[districtId]) {
        state[districtId] = {
          isFetching: status,
          data: [],
        };
      } else {
        state[districtId].isFetching = status;
      }
      state.isFetching = status;
    },
  },
  actions: {
    async [getSubdistrict]({ commit, state }, districtId) {
      if (state[districtId]?.isFetching || state[districtId]?.data?.length > 0)
        return;
      commit("setIsFetching", { districtId, status: true });
      appAxios
        .get(`regions/${districtId}/sub-district`)
        .then((response) => {
          commit(getSubdistrict, {
            districtId,
            data: response.data?.data?.subDistrict,
          });
        })
        .finally(() => {
          commit("setIsFetching", { districtId, status: false });
        });
    },
  },
};
