<template>
  <div class="position-relative w-100 h-100">
    <slot></slot>
    <div v-if="!hideIcon" @click="onClick" class="position-absolute menu-icon">
      <v-icon color="white" small>fa-ellipsis-v</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick(e) {
      this.$emit('onClick', e)
    }
  }
}
</script>

<style scoped lang="scss">
  .menu-icon {
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding: 5px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.5);
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
</style>