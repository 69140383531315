<template>
  <div class="row no-gutters" data-app>
    <div class="col-md-4 col-xl-3 col-12">
      <div class="p-2">
        <div
          class="m-md-2 d-flex flex-column justify-center align-items-center"
        >
          <div class="container">
            <v-img
              v-if="profile"
              class="d-flex justify-content-center center p-1 rounded-pill"
              :src="profile"
              width="100%"
              height="100%"
            />
            <v-img
              v-else
              src="@/assets/images/NoImage.png"
              class="
                profileImage
                d-flex
                justify-content-center
                center
                p-1
                rounded-pill
              "
            ></v-img>
            <v-icon
              style="font-size: 1rem"
              class="ma-2 cursor-pointer camera-icon"
              >fa-camera</v-icon
            >
            <input
              type="file"
              :name="fieldname"
              @change="
                filesChange($event.target.name, $event.target.files);
                fileCount = $event.target.files.length;
              "
              accept="image/*"
              class="input-file"
            />
          </div>

          <div class="mb-2">
            <h4 class="text-center">
              {{
                sub_competition[
                  "setting_sub_competition_name_" + $t("short_language")
                ]
              }}
            </h4>
          </div>
        </div>

        <div
          class="border-top border-bottom py-10 px-3"
          style="overflow-x: auto"
        >
          <div class="text-center h4 my-1">
            {{ $t("contact_information.contact") }}
            <v-icon
              @click="onClickSocialContact"
              style="font-size: 1rem"
              class="ml-2 cursor-pointer"
              >fa-edit</v-icon
            >
          </div>

          <div class="row">
            <div class="col-4">{{ $t("contact_information.contact") }}</div>
            <div class="col-8">
              {{
                social_contacts.setting_sub_competition_social_contact_phone ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ $t("contact_information.email") }}</div>
            <div class="col-8">
              {{
                social_contacts.setting_sub_competition_social_contact_email ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ $t("contact_information.facebook") }}</div>
            <div class="col-8">
              {{
                social_contacts.setting_sub_competition_social_contact_facebook ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ $t("contact_information.line_id") }}</div>
            <div class="col-8">
              {{
                social_contacts.setting_sub_competition_social_contact_line ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ $t("contact_information.instagram") }}</div>
            <div class="col-8">
              {{
                social_contacts.setting_sub_competition_social_contact_instagram ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ $t("contact_information.youtube") }}</div>
            <div class="col-8">
              {{
                social_contacts.setting_sub_competition_social_contact_youtube ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ $t("contact_information.twitter") }}</div>
            <div class="col-8">
              {{
                social_contacts.setting_sub_competition_social_contact_twitter ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ $t("contact_information.website") }}</div>
            <div class="col-8">
              {{
                social_contacts.setting_sub_competition_social_contact_website ||
                "-"
              }}
            </div>
          </div>
          <div class="row">
            <div class="col-4">{{ $t("contact_information.fax") }}</div>
            <div class="col-8">
              {{
                social_contacts.setting_sub_competition_social_contact_fax ||
                "-"
              }}
            </div>
          </div>
        </div>
        <div class="text-center my-3 font-weight-bolder">
          {{ $t("public.status") }}
        </div>
        <div class="row border-bottom m-md-2 py-3">
          <div class="col-6">
            <checkbox
              :checked="sub_competition.setting_sub_competition_published"
              v-model="sub_competition.setting_sub_competition_published"
            >
              <span
                v-if="sub_competition.setting_sub_competition_published"
                class="input-box"
                slot="input-box"
              >
                <v-icon>fa-check</v-icon>
              </span>
              <span v-if="sub_competition.setting_sub_competition_published">{{
                $t("public.public")
              }}</span>
              <span v-else>{{ $t("public.no_public") }}</span>
            </checkbox>
          </div>
          <div class="col-6">
            <div>
              <label> {{ $t("public.create_date") }} </label>
            </div>
            <div>
              <label class="text-primary">
                {{
                  sub_competition.created_at
                    ? checkDate(sub_competition.created_at)
                    : ""
                }}</label
              >
            </div>
          </div>
        </div>
        <div class="row m-md-2">
          <div class="col-6">
            <div>
              <label> {{ $t("public.create_by") }} </label>
            </div>
            <div>
              <label style="word-break: break-all">
                {{ sub_competition.updated_by }}
              </label>
            </div>
          </div>
          <div class="col-6">
            <div>
              <label> {{ $t("public.update_date") }} </label>
            </div>
            <div>
              <label class="text-primary">
                {{
                  sub_competition.updated_at
                    ? checkDate(sub_competition.updated_at)
                    : ""
                }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-8 col-12 border-left">
      <v-form ref="formRef">
        <div>
          <div class="border-bottom p-2">
            <div>
              <h4 class="text-primary">
                {{ $t("create_sub_competition.information") }}
              </h4>
            </div>
            <div>
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("create_sub_competition.name_th") }}
                      <span class="red--text">*</span>
                    </label>
                    <v-text-field
                      v-model="sub_competition.setting_sub_competition_name_th"
                      dense
                      required="true"
                      outlined
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("create_sub_competition.name_en") }}
                      <span class="red--text">*</span>
                    </label>
                    <v-text-field
                      v-model="sub_competition.setting_sub_competition_name_en"
                      dense
                      outlined
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("create_sub_competition.abbreviation") }}
                    </label>
                    <v-text-field
                      v-model="
                        sub_competition.setting_sub_competition_abbreviation
                      "
                      dense
                      outlined
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-3 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("create_sub_competition.select_year") }}
                      <span class="red--text">*</span>
                    </label>
                    <v-text-field
                      v-model="sub_competition.setting_sub_competition_year"
                      dense
                      outlined
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-3 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("create_sub_competition.start_date") }}
                      <span class="red--text">*</span>
                    </label>
                    <v-menu
                      ref="menu"
                      :close-on-content-click="false"
                      bottom
                      left
                      offset-y
                      :offset-x="false"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          :value="
                            dateFormat(
                              sub_competition.setting_sub_competition_start_at
                            )
                          "
                          dense
                          outlined
                          hide-details
                          append-icon="fa-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="us-us"
                        ref="picker"
                        min="1950-01-01"
                        v-model="
                          sub_competition.setting_sub_competition_start_at
                        "
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <div class="col-sm-3 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("create_sub_competition.end_date") }}
                      <span class="red--text">*</span>
                    </label>
                    <v-menu
                      ref="menu"
                      :close-on-content-click="false"
                      bottom
                      left
                      offset-y
                      :offset-x="false"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          :value="
                            dateFormat(
                              sub_competition.setting_sub_competition_end_at
                            )
                          "
                          dense
                          outlined
                          hide-details
                          append-icon="fa-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        locale="us-us"
                        min="1950-01-01"
                        v-model="sub_competition.setting_sub_competition_end_at"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("create_sub_competition.host_name_th") }}
                      <span class="red--text">*</span>
                    </label>
                    <v-text-field
                      v-model="sub_competition.setting_sub_competition_hostname"
                      dense
                      outlined
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("create_sub_competition.host_name_en") }}
                      <span class="red--text">*</span>
                    </label>
                    <v-text-field
                      v-model="
                        sub_competition.setting_sub_competition_hostname_en
                      "
                      dense
                      outlined
                    ></v-text-field>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("create_sub_competition.co_host_name_th") }}
                    </label>
                    <v-text-field
                      v-model="
                        sub_competition.setting_sub_competition_cohostname
                      "
                      dense
                      outlined
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("create_sub_competition.co_host_name_en") }}
                    </label>
                    <v-text-field
                      v-model="
                        sub_competition.setting_sub_competition_cohostname_en
                      "
                      dense
                      outlined
                    ></v-text-field>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("create_sub_competition.type_th") }}
                      <span class="red--text">*</span>
                    </label>
                    <v-text-field
                      v-model="sub_competition.setting_sub_competition_category"
                      dense
                      outlined
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("create_sub_competition.type_en") }}
                      <span class="red--text">*</span>
                    </label>
                    <v-text-field
                      v-model="
                        sub_competition.setting_sub_competition_category_en
                      "
                      dense
                      outlined
                    ></v-text-field>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("normal.gender") }} <span class="red--text">*</span>
                    </label>
                    <v-radio-group
                      v-model="sub_competition.setting_sub_competition_type"
                      active-class="active-radio"
                      row
                      dense
                      class="d-flex align-items-center"
                    >
                      <v-radio
                        on-icon="fa-circle"
                        :label="$t('normal.male')"
                        value="MALE"
                      ></v-radio>
                      <v-radio
                        on-icon="fa-circle"
                        :label="$t('normal.female')"
                        value="FEMALE"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group">
                    <label class="form-label" for="basic-addon-name">
                      {{ $t("sport_type.label") }}
                      <span class="red--text">*</span>
                    </label>
                    <v-select
                      v-model="sub_competition.global_config_sport_categories"
                      :items="sport_categories"
                      :item-text="$t('short_language')"
                      dense
                      outlined
                    ></v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="border-bottom p-2">
            <div>
              <h4 class="text-primary">{{ $t("standings.label") }}</h4>
            </div>
            <div class="row">
              <div class="col-sm-4 col-12">
                <label class="form-label" for="basic-addon-name">
                  {{ $t("standings.winner") }}
                </label>
                <v-select
                  v-model="
                    sub_competition.setting_sub_competition_awards.gold
                  "
                  :items="teamList"
                  :placeholder="$t('normal.please_select')"
                  :menu-props="{ bottom: true, offsetX: false }"
                  dense
                  clearable
                  outlined
                  @change="onGoldValueChange"
                >
                  <template v-slot:selection="{ item }">
                    <v-img
                      :src="item.image"
                      max-height="20"
                      max-width="20"
                      contain
                      class="ma-2"
                    ></v-img>
                    <span class="mx-2">{{
                      language === "th" ? item.name_th : item.name_en
                    }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-img
                      :src="item.image"
                      max-height="20"
                      max-width="20"
                      contain
                      class="ma-2"
                    ></v-img>
                    <span class="mx-2">{{
                      language === "th" ? item.name_th : item.name_en
                    }}</span>
                  </template>
                </v-select>
              </div>
              <div class="col-sm-4 col-12">
                <label class="form-label" for="basic-addon-name">
                  {{ $t("standings.second_place") }}
                </label>
                <v-select
                  v-model="
                    sub_competition.setting_sub_competition_awards.silver

                  "
                  :items="teamList"
                  clearable
                  :placeholder="$t('normal.please_select')"
                  :menu-props="{ bottom: true, offsetX: false }"
                  dense
                  outlined
                >
                  <template v-slot:selection="{ item }">
                    <v-img
                      :src="item.image"
                      max-height="20"
                      max-width="20"
                      contain
                      class="ma-2"
                    ></v-img>
                    <span class="mx-2">{{
                      language === "th" ? item.name_th : item.name_en
                    }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-img
                      :src="item.image"
                      max-height="20"
                      max-width="20"
                      contain
                      class="ma-2"
                    ></v-img>
                    <span class="mx-2">{{
                      language === "th" ? item.name_th : item.name_en
                    }}</span>
                  </template>
                </v-select>
              </div>
              <div class="col-sm-4 col-12">
                <label class="form-label" for="basic-addon-name">
                  {{ $t("standings.third_place") }}
                </label>
                <v-select
                  v-model="
                    sub_competition.setting_sub_competition_awards.bronze

                  "
                  clearable
                  :items="teamList"
                  :placeholder="$t('normal.please_select')"
                  :menu-props="{ bottom: true, offsetX: false }"
                  dense
                  outlined
                >
                  <template v-slot:selection="{ item }">
                    <v-img
                      :src="item.image"
                      max-height="20"
                      max-width="20"
                      contain
                      class="ma-2"
                    ></v-img>
                    <span class="mx-2">{{
                      language === "th" ? item.name_th : item.name_en
                    }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-img
                      :src="item.image"
                      max-height="20"
                      max-width="20"
                      contain
                      class="ma-2"
                    ></v-img>
                    <span class="mx-2">{{
                      language === "th" ? item.name_th : item.name_en
                    }}</span>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="p-2">
            <div>
              <h4 class="text-primary">
                {{ $t("create_competition.history") }}
              </h4>
            </div>
            <div>
              <form class="needs-validation" novalidate="">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name"
                        >{{ $t("create_competition.history_th") }}
                      </label>
                      <vue-editor
                        v-model="
                          sub_competition.setting_sub_competition_history_th
                        "
                      ></vue-editor>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label" for="basic-addon-name"
                        >{{ $t("create_competition.history_en") }}
                      </label>
                      <vue-editor
                        v-model="
                          sub_competition.setting_sub_competition_history_en
                        "
                      ></vue-editor>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </v-form>
    </div>
    <SocialContactModal
      :socialContact="social_contacts"
      :cid="competitionId"
      :sid="subCompetitionId"
      :dialog.sync="showSocialContactModal"
    />
    <v-snackbar
      v-model="showErrorMessage"
      :value="true"
      color="red"
      timeout="5000"
      top
      rounded="pill"
    >
      <div class="d-flex justify-content-center">
        <strong>{{ error }}</strong>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import SocialContactModal from "@/components/Competition/Sub/SocialContactModal.vue";
import { VueEditor } from "vue2-editor";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapActions, mapState } from "vuex";
import { format } from "date-fns";
import { languageService } from "../../../helpers/language";

export default {
  name: "AddSubCompetitionForm",

  components: {
    VueEditor,
    SocialContactModal,
  },
  created() {
    this.subscription = languageService.getCurrent().subscribe((lang) => {
      if (lang === "en") {
        this.language = "en";
      } else {
        this.language = "th";
      }
    });
  },
  data() {
    return {
      language: "th",

      competitionId: null,
      subCompetitionId: null,
      showSocialContactModal: false,
      levels: [
        { text: "ระดับจังหวัด", value: "DISTRICT" },
        { text: "ระดับภาค", value: "ZONE" },
        { text: "ระดับภูมิภาค", value: "CONTINENTAL" },
        { text: "ระดับประเทศ", value: "INTERNATIONAL" },
        { text: "ระดับทวีป", value: "REGIONAL" },
        { text: "ระดับโลก", value: "GLOBAL" },
      ],
      maxSelectDate: format(new Date(), "yyyy-MM-dd"),
      showErrorMessage: false,
      fieldname: "file",
      activeVariable: false,
    };
  },

  mounted() {
    this.competitionId = this.$route.params.cid;
    this.subCompetitionId = this.$route.params.sid;

    this.clearTeamInSubCompetition();

    if (this.competitionId && this.subCompetitionId) {
      this.getSubCompetition({
        cid: this.competitionId,
        sid: this.subCompetitionId,
      });
    }
    if (this.subCompetitionId) {
      this.getTeamsInSubCompetition({ sid: this.subCompetitionId });
    }
    this.getSportCategories();
  },

  watch: {
    error() {
      this.showErrorMessage = true;
    },
    success() {
      if (this.success) {
        if (this.success == "PROFILE_UPLOADED") {
          if (this.competitionId && this.subCompetitionId) {
            this.getSubCompetition({
              cid: this.competitionId,
              sid: this.subCompetitionId,
            });
          }
        } else {
          this.$router.push({
            name: "competition-detail",
            params: { id: this.success },
          });
        }
      }
    },
  },

  computed: {
    ...mapState("competition", ["competition"]),
    ...mapState("sub_competition", [
      "sub_competition",
      "social_contacts",
      "selected_teams",
      "error",
      "success",
      "profile_file",
      "sport_categories",
    ]),
    items() {
      return [
        {
          text: "รายการแข่งขัน",
          disabled: false,
          link: true,
          exact: true,
          to: { name: "competition" },
        },
      ];
    },
    profile() {
      if (this.profile_file) return URL.createObjectURL(this.profile_file);
      return this.sub_competition.setting_sub_competition_media_profile_icon;
    },
    information() {
      return {
        name: "กีฬาซีเกมส์",
      };
    },
    label() {
      return (
        this.$store.state.competition.competitions?.data?.competition || {
          id: "SG0001",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/SEA_Games_logo.svg/1200px-SEA_Games_logo.svg.png",
          name_th: "กีฬาซีเกมส์",
          name_en: "SEA GAMES",
          sub_competition_count: "3 รายการ",
          type: "ฟุตบอลทัวร์นาเมนต์",
          rank: "ระดับภูมิภาค",
          team_type: "ทีมชาติ",
          association: "สมาพันธ์กีฬาแห่งเอเชียตะวันออกเฉียงใต้",
        }
      );
    },
    teamList() {
      return (
        this.selected_teams.data?.map((data) => {
          return {
            ...data,
            image: data.setting_team_media_profile_url || "",
            name_th: data.setting_team_information_name_th || "",
            name_en: data.setting_team_information_name_en || "",
            // value: parseInt(data.id),
            value: {
              team_id: parseInt(data.id),
              team_name_th: data.setting_team_information_name_th || "",
              team_name_en: data.setting_team_information_name_en || "",
              team_profile_url: data.setting_team_media_profile_url || null,
            },
          };
        }) || []
      );
    },
  },

  methods: {
    ...mapActions("sub_competition", [
      "getSubCompetition",
      "getTeamsInSubCompetition",
      "uploadImageProfile",
      "setProfileFile",
      "getSportCategories",
      "clearTeamInSubCompetition",
    ]),
    onClickSocialContact() {
      this.showSocialContactModal = true;
    },
    onClickNextTab() {},
    onCommitSocialContact() {},
    imageSource(item) {
      return (
        item.setting_team_informations?.medias
          ?.setting_team_media_profile_url || ""
      );
    },
    checkDate(e) {
      const formatDate = format(new Date(e), "dd/MM/yyyy");
      return formatDate;
    },

    onGoldValueChange(value) {
      let team = this.teams?.find((team) => parseInt(team.id) == value);
      if (team) {
        this.sub_competition.setting_sub_competition_awards.gold = {
          team_id: parseInt(team.id),
          team_image_url:
            team.setting_team_informations?.medias
              ?.setting_team_media_profile_url,
          team_name:
            team.setting_team_informations[
              "setting_team_information_name_" + this.$t("short_language")
            ],
        };
      }
    },
    dateFormat(date) {
      try {
        return format(new Date(date), "dd/MM/yy");
      } catch (e) {
        return "";
      }
    },
    filesChange(fieldName, fileList) {
      if (!fileList.length) return;

      Array.from(Array(fileList.length).keys()).map((x) => {
        this.setProfileFile(fileList[x]);
      });
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  width: 60%;
}
.input-file {
  left: 0;
  top: 0;
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 80%;
  min-height: 150px;
  position: absolute;
  cursor: pointer;
}
.camera-icon {
  position: absolute;
  right: 20px;
  top: 20px;
}

.active-radio .v-icon {
  color: red;
}
</style>