import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import th from 'vuetify/es5/locale/th'
import en from 'vuetify/es5/locale/en'
import { IconsValues } from './icons';

Vue.use(Vuetify);

export default new Vuetify({
  lang:{
    locales:{ th, en },
    current:'th',
  },
  icons:{
    iconfont: 'fa',
    values: IconsValues
  },
  theme: {
    themes: {
      light: {
        primary: "#0066FF",
				win: '#28C76F',
				'win--bg': '#DEFDEC',
				lost: '#EA5455',
				'lost--bg': '#FFEAEA',
				draw: '#31404B',
				'draw--bg': '#F4F6F8',
				'score': '#0062FF',
      }
    }
  }
});
