import { appAxios } from '../helpers/app-axios';

const state = {
    stadium: {},
    getdata: {},
    obj: {
      stadium: {
        active: null,
        created_at: null,
        created_by: null,
        updated_at: null,
        updated_by: null,
        id: null,
        pitch_details: {
          active: null,
          created_at: null,
          created_by: null,
          updated_at: null,
          updated_by: null,
          id: null,
          pitch_detail_width: null,
          pitch_detail_length: null,
          pitch_detail_grass: null,
          deleted: null
        },
        setting_stadium_owners: {
          active: null,
          created_at: null,
          created_by: null,
          updated_at: null,
          updated_by: null,
          id: null,
          setting_stadium_owner_name_th: null,
          setting_stadium_owner_name_en: null,
          setting_stadium_owner_media_name: null,
          setting_stadium_owner_media_size: null,
          setting_stadium_owner_media_mimetype: null,
          deleted: null
        },
        setting_stadium_social_contacts: {
          active: null,
          created_at: null,
          created_by: null,
          updated_at: null,
          updated_by: null,
          id: null,
          setting_stadium_social_contact_phone: null,
          setting_stadium_social_contact_email: null,
          setting_stadium_social_contact_facebook: null,
          setting_stadium_social_contact_line: null,
          setting_stadium_social_contact_instagram: null,
          setting_stadium_social_contact_twitter: null,
          setting_stadium_social_contact_youtube: null,
          setting_stadium_social_contact_website: null,
          setting_stadium_social_contact_fax: null,
          deleted: null
        },
        setting_stadium_regions: {
          active: null,
          created_at: null,
          created_by: null,
          updated_at: null,
          updated_by: null,
          id: null,
          setting_stadium_region_country_id: null,
          setting_stadium_region_address_detail: null,
          setting_stadium_region_province_id: null,
          setting_stadium_region_district_id: null,
          setting_stadium_region_subdistrict_id: null,
          setting_stadium_region_zipcode: null,
          deleted: null
        },
        setting_stadium_name_th: null,
        setting_stadium_name_en: null,
        setting_stadium_capacity: null,
        setting_stadium_map: null,
        setting_stadium_history_th: null,
        setting_stadium_history_en: null,
        deleted: null
      }
    }
};

const mutations = {
    INIT_DATA: (state, data) => {
      state.stadium = data.data.stadium;
    },
    INIT_DATA_BY_ID: (state, data) => {
      state.getdata = data;
    },
};

const getters = {
  stadium: state => state.stadium,
  getdata: state => state.getdata,
};

const actions = {
  
  async initData({commit}, payload){
    const params = new URLSearchParams(payload);
    await appAxios.get('stadium', {params}).then(response =>{
      commit('INIT_DATA', response.data)
    })
  },
  async initDataByid({commit},payload){
    await appAxios.get('stadium/'+ payload).then(response =>{
      commit('INIT_DATA_BY_ID', response.data.data.stadium)
    })
  },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
