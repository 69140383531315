<template>
  <div>
    <div>
      <v-btn class="download-flow" @click="download"
        ><v-icon class="mr-3"> fa-download </v-icon
        >ดาวน์โหลดผังรูปนักกีฬา</v-btn
      >
    </div>
    <div class="bg-white px-5 py-5">
      <div class="d-flex justify-content-between">
        <div :style="`color:#0066FF;font-size:20px`">{{ nameTitle }}</div>
        <div class="d-flex" v-if="uniform.status === 'WAITING'">
          <div
            :style="`color:#28C76F`"
            class="cursor-pointer"
            @click="approveTeam"
          >
            <v-icon class="mr-3" :style="`color:#28C76F`">$complete</v-icon
            >อนุมัติ
          </div>
          <div
            class="text-danger cursor-pointer "
            @click="onModalRejectComment"
          >
            <v-icon class="mx-3 text-danger">$close</v-icon>ไม่อนุมัติ
          </div>
        </div>
        <div v-else-if="uniform.status === 'APPROVED'">
          <div
            :style="`color:#28C76F`"
            @click="approveTeam"
          >
            อนุมัติแล้ว
          </div>
        </div>
        <div v-else-if="uniform.status === 'REJECTED'">
          <div
           
            class=" text-danger"
            @click="approveTeam"
          >
          ไม่อนุมัติ
          </div>
        </div>
      </div>
      <div>
        สีเสื้อ :
        <span> {{ uniform.shirt_color_1 }}, {{ uniform.shirt_color_2 }}</span>
      </div>
      <div>
        สีกางเกง :
        <span> {{ uniform.shorts_color_1 }}, {{ uniform.shorts_color_2 }}</span>
      </div>
      <hr />
      <div>
        <div class="d-flex">
          <div class="mr-5">รายชื่อบุคลากร</div>
          <div>จำนวน : {{ staffs.length }} คน</div>
        </div>

        <div class="d-flex flex-wrap mt-3">
          <div
            v-for="(item, idx) in staffs"
            class="d-flex flex-wrap"
            :style="`font-size:14px`"
            :key="idx"
          >
            <div class="mr-3">{{ item.positionName }} :</div>
            <div class="mr-10">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="d-flex">
          <div class="mr-5">รายชื่อนักกีฬา</div>
          <div>จำนวน : {{ player.length }} คน</div>
        </div>
        <v-data-table
          :headers="headers"
          :items="player"
          class="elevation-1 row-pointer"
          hide-default-footer
        >
          <template v-slot:[`item.account_information_firstname_th`]="{ item }">
            {{ item.account_information_firstname_th }}
            {{ item.account_information_lastname_th }}
          </template>
          <template v-slot:[`item.account_information_dob`]="{ item }">
            {{ showDate(item.account_information_dob) }}
          </template>
          <template v-slot:[`item.age`]="{ item }">
            {{ age(item.account_information_dob) }} ปี
          </template>
        </v-data-table>
      </div>
    </div>
    <RejectModal :dialog.sync="showRejectModal" @commit="onCommitReject" />
  </div>
</template>
<script>
import { format, differenceInYears } from "date-fns";
import { appAxios } from "../../../helpers/app-axios";
import RejectModal from "@/components/RejectModal.vue";

export default {
  name: "ListTeam",
  props: ["uniform", "player"],
  components: {
    RejectModal,
  },
  mounted() {
    this.teamId = this.$route.params.list.split("-")[0];
    this.getTeamID();
    this.getStaff();
  },
  computed: {
    headers() {
      return [
        {
          text: "ชื่อนักกีฬา",
          value: "account_information_firstname_th",
        },
        {
          text: "เลขที่บัตรประชาชน",
          value: "account_information_citizen_id",
        },
        {
          text: "เบอร์เสื้อชุด 1",
          value: "team_player_history_shirt_number",
        },
        {
          text: "เบอร์เสื้อชุด 2",
          value: "team_player_history_shirt_number_2",
        },
        {
          text: "วันเกิด",
          value: "account_information_dob",
        },
        {
          text: "อายุ",
          value: "age",
        },
        {
          text: "ส่วนสูง",
          value: "account_information_height",
        },
        {
          text: "น้ำหนัก",
          value: "account_information_weight",
        },
      ];
    },
  },
  methods: {
    download(){
      console.log('this.$route.params',this.$route.params)
      const splitTeam = this.$route.params.list.split('-')
      const objData = {
          team_id: splitTeam[0],
          sub_team_id: splitTeam[1],
          setting_competition_id: this.$route.params.cid,
          setting_sub_competition_id:
          this.$route.params.sid,
        };

        appAxios
          .post(`document/export-applicant`, objData, {
            responseType: "blob",
          })
          .then(async (res) => {
            const stream = await res.data;
            const blob = new Blob([stream], { type: "application/pdf" });            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);

            // Create a link element and simulate a click to download
            const link = document.createElement("a");
            link.href = url;
            link.download = "rugby.pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
    },
    async getStaff() {
      await appAxios
        .get(
          `team/${this.$route.params.list.split("-")[0]}/staffs/sub-team/${
            this.$route.params.list.split("-")[1]
          }`
        )
        .then((res) => {
          // console.log('staff',res.data.data.staffs.data)

          const listData = res.data.data.staffs.data.map((s) => {
            return {
              // account_id: s.accounts.account_id,
              name:
                s.accounts.account_informations
                  .account_information_firstname_th +
                " " +
                s.accounts.account_informations.account_information_lastname_th,
              positionName:
                s.global_config_positions.global_config_position_name_th,
            };
          });

          this.staffs = listData;
        });
    },
    async approveTeam() {
      const objData = {
        id: [+this.$route.params.list.split("-")[1]],
        status: "APPROVED",
      };

      appAxios
        .post(
          `sub-competitions/${this.$route.params.sid}/setting-team-in-sub-competition/rugby/status`,
          objData
        )
        .then(() => {
          this.getRugbyTeam();
        });
    },
    async onCommitReject(value) {
      const objData = {
        id: [+this.$route.params.list.split("-")[1]],
        status: "REJECTED",
        reason: value,
      };
      appAxios
        .post(
          `sub-competitions/${this.$route.params.sid}/setting-team-in-sub-competition/rugby/status`,
          objData
        )
        .then(() => {
          this.getRugbyTeam();
        });
    },
    onModalRejectComment() {
      this.$data.showRejectModal = true;
    },

    async getTeamID() {
      await appAxios.get(`team/${this.teamId}`).then((res) => {
        console.log("res", res.data.data.team);
        this.nameTitle =
          res.data.data.team.setting_team_informations.setting_team_information_name_th;
      });
    },

    showDate(date) {
      const formatDate = format(new Date(date), "dd/MM/yyyy");
      return formatDate;
      // return date
    },
    age(date) {
      if (!date) {
        return 0;
      }
      return differenceInYears(new Date(), new Date(date));
    },
  },
  data() {
    return {
      nameTitle: "",
      showRejectModal: false,
      staffs: [],
    };
  },
};
</script>
<style scoped>
.download-flow {
  position: absolute !important;
  right: 50px;
  top: 80px;
  background-color: #555658 !important;
  color: #fff !important;
}
</style>
