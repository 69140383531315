import { appAxios } from '../helpers/app-axios';

export const gallery = {
	namespaced: true,

	state: () => ({
		image: {},
		video: {},
		params: {
			page: 0,
			size: 9,
		}
	}),

	mutations: {
		updateImages(state, data) {
			state.image = data.data.data.team_media
		},
		updateVideos(state, data) {
			state.video = data.data.data.team_media
		}
	},

	actions: {
		getGallery({ state, commit }, data) {
			const params = new URLSearchParams(state.params);
			appAxios.get(`competitions/${data.cid}/sub-competitions/${data.sid}/medias/images`, {
				params
			}).then(response => {
				commit('updateImages', response)
			})
			appAxios.get(`competitions/${data.cid}/sub-competitions/${data.sid}/medias/videos`, {
				params
			}).then(response => {
				commit('updateVideos', response)
			})
		}
	}
}